import React from 'react';

type Props = {
  width?: string;
  height?: string;
  stroke?: string;
};
const ForwardToEndIcon = (props: Props) => {
  const { width = '14', height = '14', stroke = '#F2D093' } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
      <path
        d="M13.0371 0.964355V13.0358"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.983419 11.3272C0.982963 11.4943 1.02757 11.6583 1.11255 11.8022C1.19753 11.946 1.31973 12.0642 1.46627 12.1443C1.60743 12.2259 1.76757 12.2688 1.93056 12.2688C2.09356 12.2688 2.25368 12.2259 2.39484 12.1443L9.07127 7.7801C9.2016 7.69592 9.30875 7.58043 9.38295 7.44417C9.45714 7.30792 9.49602 7.15524 9.49602 7.0001C9.49602 6.84495 9.45714 6.69228 9.38295 6.55603C9.30875 6.41977 9.2016 6.30428 9.07127 6.2201L2.37627 1.89296C2.23511 1.81145 2.07499 1.76855 1.91199 1.76855C1.74899 1.76855 1.58886 1.81145 1.4477 1.89296C1.30116 1.97314 1.17896 2.09136 1.09398 2.23517C1.009 2.37899 0.964391 2.54306 0.964847 2.7101L0.983419 11.3272Z"
        fill={stroke}
      />
    </svg>
  );
};

export default ForwardToEndIcon;
