// src/videoTranscriptionProcessor/constants/karaokeConstants.ts
import { KaraokeConfig } from '../types/karaokeTypes';

export const MAX_GAP_DURATION = 1; // in second
export const MAX_CHARS_INSTAGRAM = 14;
export const MAX_CHARS_DEFAULT = 50;

// src/videoTranscriptionProcessor/constants/karaokeConstants.ts

export const NATURAL_BEAT_DURATION_MS = MAX_GAP_DURATION * 1000;
export const PAUSE_DURATION_MS = 300;
export const MIN_SEGMENT_LENGTH = 3;
export const MAX_SEGMENT_LENGTH = 8;
export const PUNCT_ELEMENT_DURATION_MS = 0; // Estimated duration for punct elements
export const MINIMUM_WORD_OFFSET = 0.001;

export const FILLER_WORDS = [
  'Umm',
  'Um',
  'Uh',
  'Uhh',
  'Eh',
  'You know',
  'Ya know',
  'Hmm',
  'Hm',
  'Ah',
  'Ahh',
  'Er',
];

export const DEFAULT_KARAOKE_CONFIG = {
  width: '76%',
  font_size: '3.333vh',
  font_weight: '600',
  fill_color: 'white',
  background_color: 'rgba(0,0,0,0.8)',
  text_wrap: true,
  x_alignment: '50%',
  background_align_threshold: '15%',
  y: '86%',
  x: '50%',
  line_height: '125%',
  font_family: 'Inter',
  font_style: 'normal',
  stroke_color: 'transparent',
  stroke_width: '0.25 vmin',
  animations: [] as any[],
  // maxCharactersPerElement: MAX_CHARS_DEFAULT, hardcoded in produceKaraokeElements
  hideComma: false,
  hidePeriod: false,
  hideFillers: false,
  instagramEffect: false,
  instagramLines: 5,
  language: 'original',
} as KaraokeConfig;
