import {
  Client,
  SimpleSchemaTypes,
  buildBlockRecord,
} from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import { FileData, Story, StoryDTO } from '../types.ts/story';
import ApiClient from '../apiClient/ApiClient';

export class AssetRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async uploadFile(
    fileData: FileData & { fileName: string },
  ): Promise<SimpleSchemaTypes.Upload> {
    if (!fileData.url && !fileData.file) {
      throw new Error('Either a url or a file must be provided');
    }

    let file = fileData.file!;

    if (fileData.url) {
      const blobObject = await AssetRepository.base64ToBlob(fileData.url);
      const blobData = {
        ...fileData,
        blob: blobObject,
      };

      file = AssetRepository.convertBlobToFile(
        blobData.blob,
        fileData.fileName,
      );
    }

    const result = await this.dClient.uploads.createFromFileOrBlob({
      fileOrBlob: file,
      filename: fileData.fileName,
      ...(fileData.author && { author: fileData.author }),
      ...(fileData.tags && { tags: fileData.tags }),
      default_field_metadata: {
        en: {
          alt: fileData.alt,
          title: fileData.title,
          custom_data: fileData.customData || {},
        },
      },
    });

    return result;
  }

  async update(id: string, data: Record<string, any>) {
    const upload = await this.dClient.uploads.find(id);
    console.log('saved upload', upload);

    await this.dClient.uploads.update(id, {
      ...upload,
      author: data.author || upload.author,
      tags: data.tags || upload.tags,
      default_field_metadata: {
        en: {
          alt: data.alt || upload.default_field_metadata.en.alt,
          title: data.title || upload.default_field_metadata.en.title,
          custom_data:
            data.customData || upload.default_field_metadata.custom_data || {},
        },
      },
    });
  }

  async delete(id: string) {
    await this.dClient.uploads.destroy(id);
  }

  static convertBlobToFile(blobObj: Blob, fileName: string) {
    const newBlob: any = blobObj;
    newBlob.lastModifiedDate = new Date();
    newBlob.name = fileName;
    return newBlob as File;
  }

  static async base64ToBlob(base64Url: string) {
    return fetch(base64Url).then((res) => res.blob());
  }
}
