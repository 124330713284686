import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  AlbumQueryResult,
  PhotoArtifactTab,
  Story,
} from '../../types.ts/story';
import useInitializeDatoUser from '../common/useInitializeDatoUser';
import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { gptService } from '../../services/ChatGPTService';
import SearchInput from '../sidepanel/SearchInput';
import StoryDashboardContent from './StoryDashboardContent';
import ClipDashboardContent from './ClipDashboardContent';
import { checkWordsInString } from '../../utility/general';
import PrimaryActionButton from '../sidepanel/PrimaryActionButton';
import PlayIconRectOutline from '../../svgs/PlayIconRectOutline';
import AddStoriesModal from './user-story/AddStoriesModal';
import NewStoriesQuerySubscriptionComponent, {
  NewStoryUpdate,
} from '../NewStoriesQuerySubscriptionComponent';
import ToastNotification from '../common/ToastNotification';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { v4 as uuid } from 'uuid';
import { getAiDescriptionResponse } from '../../utility/story';

type Props = {
  params: {
    [k: string]: string;
  };
};

enum ContentTypes {
  story = 'Stories',
  clip = 'Clips',
}

function buildTargetUrl(
  params: URLSearchParams,
  storyId: string,
  playbackId: string | undefined,
): string {
  // if (params.get('showcase')) {
  //   const data = [] as string[];

  //   params.forEach((value, key) => {
  //     data.push(`${key}=${value}`);
  //   });

  //   return data.length ? '?' + data.join('&') : '';
  // } else {
  //   return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
  //     playbackId || ''
  //   }`;
  // }

  return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
    playbackId || ''
  }`;
}

const TEMP_ID_FOR_STORY_BEING_CREATED = 'story-from-modal';

const Dashboard: FC<Props> = observer((props) => {
  useInitializeDatoUser();
  const album = videoCreator.organization;
  const [stories, setStories] = useState<AlbumQueryResult['stories']>([]);
  const [allStories, setAllStories] = useState<AlbumQueryResult['stories']>([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [selectedTab, setSelectedTab] = useState<ContentTypes>(
    ContentTypes.story,
  );
  const [inProgressModalIds, setInProgressModalIds] = useState<string[]>([]);
  const [bannerHeight, setBannerHeight] = useState<number | undefined>();
  const [newStoryCompletionPercentRecord, setNewStoryCompletionPercentRecord] =
    useState<Record<string, number>>({});
  const { enableCreatorStudioNav } = useFlagsCombination();

  useEffect(() => {
    if (!album?.id) return;
    (async () => {
      const stories = (await videoCreator.findManyStories(
        album.id,
      )) as AlbumQueryResult['stories'];
      setAllStories(stories);
      setStories(stories);
      initNewStoryCompletionPercentRecord(stories);
    })();
  }, [album]);

  function resetStoryStates(storyId: string) {
    const prevStoryId = urlSearchParams.get('storyId');
    if (prevStoryId !== storyId) {
      videoCreator.selectedPhotoAssets = {
        tab: PhotoArtifactTab.story,
        resource: undefined,
      };
      videoCreator.talkingPointContent = null;
      if (gptService.talkingPointController) {
        gptService.talkingPointController.abort();
      }
    }
  }

  const initNewStoryCompletionPercentRecord = (
    stories: AlbumQueryResult['stories'],
  ) => {
    const record: Record<string, number> = {};
    stories.forEach((s) => {
      if (isStoryLoading(s)) {
        record[s.id] = calculateCompletionPercent(s);
      }
    });
    setNewStoryCompletionPercentRecord(record);
  };

  const isStoryOriginalVideoReady = (s: NewStoryUpdate): boolean => {
    return !!s.originalVideo?.video?.thumbnailUrl;
  };

  const isStoryTranscriptionReady = (s: NewStoryUpdate): boolean => {
    return ['FAILED', 'COMPLETED'].includes(s.transcription?.jobStatus);
  };

  const calculateCompletionPercent = (
    story: AlbumQueryResult['stories'][0],
  ): number => {
    if (isStoryOriginalVideoReady(story) && isStoryTranscriptionReady(story)) {
      return 100;
    } else if (
      isStoryOriginalVideoReady(story) ||
      isStoryTranscriptionReady(story)
    ) {
      return 75;
    } else {
      return 50;
    }
  };

  const getDescription = (story: AlbumQueryResult['stories'][0]) => {
    return getAiDescriptionResponse(story)?.response?.toString() || '';
  };

  const handleSearchContent = (data: string) => {
    if (!data.length) {
      setStories(allStories);
      return;
    }
    const value = data.toLowerCase();
    const stories = allStories.filter((s) => {
      const summary = getDescription(s);
      return (
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value) ||
        checkWordsInString(summary, value)
      );
    });
    setStories(stories);
  };

  const handleSearchClipContent = (data: string) => {
    if (!data.length) {
      setStories(allStories);
      return;
    }
    const value = data.toLowerCase();

    const newStories = [];
    for (let s of allStories) {
      const isInStory =
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value);
      if (isInStory) {
        newStories.push(s);
        continue;
      }
      const clips = s.otherVideos.filter((v) => {
        return checkWordsInString(v.title, value);
      });
      newStories.push({ ...s, otherVideos: clips });
    }
    setStories(newStories);
  };

  const handleNewStoryUpdateReceived = (storyUpdates: NewStoryUpdate[]) => {
    const newAllStories: AlbumQueryResult['stories'] = [];
    allStories.forEach((item) => {
      const story = storyUpdates.find((u) => u.id === item.id);
      if (story) {
        notifyAboutNewStoryUpdate(story, item);
        newAllStories.push({ ...item, ...story });
      } else {
        newAllStories.push(item);
      }
    });
    setAllStories(newAllStories);
    setStories(
      stories.map((item) => {
        const story = storyUpdates.find((u) => u.id === item.id);
        if (story) {
          return { ...item, ...story };
        } else {
          return item;
        }
      }),
    );
  };

  const notifyAboutNewStoryUpdate = (
    story: NewStoryUpdate,
    item: AlbumQueryResult['stories'][0],
  ) => {
    if (
      isStoryOriginalVideoReady(story) &&
      story.transcription?.jobStatus === 'COMPLETED' &&
      // prevent if update is not new
      !(
        isStoryOriginalVideoReady(item) &&
        item.transcription?.jobStatus === 'COMPLETED'
      )
    ) {
      handleNewStoryProgress(100, story.id);
      videoCreator.toastState = {
        state: 'success',
        message: 'Story successfully uploaded',
      };
      return;
    }

    if (
      (isStoryOriginalVideoReady(story) ||
        story.transcription?.jobStatus === 'COMPLETED') &&
      // prevent if update is not new
      !(
        isStoryOriginalVideoReady(item) ||
        item.transcription?.jobStatus === 'COMPLETED'
      )
    ) {
      handleNewStoryProgress(75, story.id);
      return;
    }

    if (
      story.transcription?.jobStatus === 'FAILED' &&
      // prevent if update is not new
      !(item.transcription?.jobStatus === 'FAILED')
    ) {
      handleNewStoryProgress(100, story.id);
      videoCreator.toastState = {
        state: 'error',
        message: 'Transcription generation failed for new story',
      };
      return;
    }

    if (
      story.externalUploadStatus === 'FAILED' &&
      // prevent if update is not new
      !(item.externalUploadStatus === 'FAILED')
    ) {
      handleNewStoryProgress(100, story.id);
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to upload new story',
      };
      return;
    }
  };

  const handleAddStoriesButtonClick = () => {
    const modalId = uuid();
    setInProgressModalIds([...inProgressModalIds, modalId]);
  };

  const closeModal = (modalId: string) => {
    setInProgressModalIds(inProgressModalIds.filter((id) => id !== modalId));
  };

  const handleAddStoriesModalClose = (modalId: string) => {
    // premature closing should hide banner
    handleNewStoryProgress(0, TEMP_ID_FOR_STORY_BEING_CREATED, true);
    closeModal(modalId);
  };

  const getNewStoryCompletionPercent = (storyId?: string): number => {
    const key = storyId || TEMP_ID_FOR_STORY_BEING_CREATED;
    if (key in newStoryCompletionPercentRecord) {
      return newStoryCompletionPercentRecord[key];
    } else {
      return 0;
    }
  };

  const handleNewStoryProgress = (
    percent: number,
    storyId?: string,
    reset?: boolean,
  ) => {
    const updatedRecord = {
      ...newStoryCompletionPercentRecord,
    };
    if (storyId && percent > getNewStoryCompletionPercent(storyId)) {
      updatedRecord[storyId] = percent;
    }
    if (!storyId && percent > getNewStoryCompletionPercent()) {
      updatedRecord[TEMP_ID_FOR_STORY_BEING_CREATED] = percent;
    }
    if (reset) {
      updatedRecord[TEMP_ID_FOR_STORY_BEING_CREATED] = 0;
    }
    setNewStoryCompletionPercentRecord(updatedRecord);
  };

  const handleNewStoryInitialized = (story: Story) => {
    const newStory = story as unknown as AlbumQueryResult['stories'][0];
    // transfer progress from temp id to created story id, will hide banner
    handleNewStoryProgress(getNewStoryCompletionPercent(), newStory.id, true);
    setAllStories([newStory, ...allStories]);
    setStories([newStory, ...stories]);
  };

  const handleNewStoryCreated = (story: Story, modalId: string) => {
    const newStory = story as unknown as AlbumQueryResult['stories'][0];
    handleNewStoryProgress(
      Math.max(50, getNewStoryCompletionPercent(newStory.id)),
      newStory.id,
      true,
    );
    setAllStories(
      allStories.map((item) => (item.id === newStory.id ? newStory : item)),
    );
    setStories(
      stories.map((item) => (item.id === newStory.id ? newStory : item)),
    );
    closeModal(modalId);
  };

  const isStoryLoading = (s: AlbumQueryResult['stories'][0]): boolean => {
    return (
      s.externalUploadStatus !== 'FAILED' &&
      (!isStoryOriginalVideoReady(s) || !isStoryTranscriptionReady(s))
    );
  };

  const newStoryIds = allStories
    .filter((s) => isStoryLoading(s) || !getAiDescriptionResponse(s))
    .map((s) => s.id);

  const getStoryCompletionPercent = (
    s: AlbumQueryResult['stories'][0],
  ): number => {
    return getNewStoryCompletionPercent(s.id);
  };

  const handleStoryDelete = async (story: AlbumQueryResult['stories'][0]) => {
    setAllStories(allStories.filter((s) => s.id !== story.id));
    setStories(stories.filter((s) => s.id !== story.id));

    try {
      await videoCreator.softDeleteStory(story.id);
    } catch (e) {
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to delete story',
      };
      setAllStories(allStories);
      setStories(stories);
    }
  };

  return (
    <Main bannerHeight={bannerHeight}>
      <TopContent>
        <Title>Story Manager</Title>
        <Tabs>
          {Object.entries(ContentTypes).map(([k, v]) => (
            <Tab
              onClick={() => setSelectedTab(v)}
              isSelected={selectedTab === v}
            >
              {v}
            </Tab>
          ))}
        </Tabs>
        <SearchInput
          iconRight={true}
          placeholder={
            selectedTab === ContentTypes.story
              ? 'Search your stories'
              : 'Search your clips'
          }
          radius="10px"
          width="300px"
          handleAction={(text) => {
            if (selectedTab === ContentTypes.story) {
              handleSearchContent(text);
            } else if (selectedTab === ContentTypes.clip) {
              handleSearchClipContent(text);
            }
          }}
          autoSubmitDelay={500}
        />
        {selectedTab === ContentTypes.story && enableCreatorStudioNav && (
          <>
            <AddStoriesButton onClick={handleAddStoriesButtonClick} isActivated>
              <PlayIconRectOutline />
              Add Stories
            </AddStoriesButton>
          </>
        )}
      </TopContent>

      <Table>
        {(() => {
          switch (selectedTab) {
            case ContentTypes.story:
              return (
                <StoryDashboardContent
                  stories={stories}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                  isStoryLoading={isStoryLoading}
                  getStoryCompletionPercent={getStoryCompletionPercent}
                  onStoryDelete={handleStoryDelete}
                />
              );

            case ContentTypes.clip:
              return (
                <ClipDashboardContent
                  stories={stories}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                />
              );
          }
        })()}
      </Table>
      {inProgressModalIds.map((modalId) => (
        <AddStoriesModal
          key={modalId}
          closeModal={handleAddStoriesModalClose}
          onStoryInitialized={handleNewStoryInitialized}
          onStoryCreated={handleNewStoryCreated}
          onStoryProgress={handleNewStoryProgress}
          modalId={modalId}
          setBannerHeight={setBannerHeight}
        />
      ))}
      {!!newStoryIds.length && (
        <NewStoriesQuerySubscriptionComponent
          key={newStoryIds.join('_')}
          storyIds={newStoryIds}
          onDataReceived={handleNewStoryUpdateReceived}
          onError={() => {
            videoCreator.toastState = {
              state: 'error',
              message: 'Failed to update new story',
            };
          }}
        />
      )}
      {videoCreator.toastState && <ToastNotification />}
    </Main>
  );
});

export default Dashboard;

const Main = styled.div<{ bannerHeight?: number }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  padding-bottom: ${(props) => `${(props.bannerHeight || 0) + 20}`}px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  min-width: 440px;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  color: #f3e9d7;
  font-weight: 700;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Tabs = styled.div`
  display: flex;
  gap: 16px;
  margin-right: auto;
  margin-left: 50px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3e9d7;
  cursor: pointer;
  &:hover {
    color: #f2d093;
    text-decoration: underline;
  }
  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
      text-decoration: underline;
    `}
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: 'Inter', sans-serif;
`;

const AddStoriesButton = styled(PrimaryActionButton)`
  width: 240px;
  max-width: 240px;
  margin-left: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;
