import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { createApi } from 'unsplash-js';
import styled from 'styled-components';
import {
  PhotoAsset,
  FileData,
  PhotoArtifactTab,
  Artifact,
} from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { SidebarOption } from '../../types.ts/general';
import CheckIcon from '../../svgs/CheckIcon';
import SearchInput from './SearchInput';
import BackIcon from '../../svgs/BackIcon';
import { Circle } from '../../styles/mainStyle';
import { runInAction } from 'mobx';
import ChatGPTService from '../../services/ChatGPTService';
import { v4 as uuid } from 'uuid';
import { generateImagesWithGPT } from '../../utility/processGPTData';
import SpinningLoading from '../SpinningLoading';
import { getRandomFileName, handleRemoveMedia } from '../../utility/general';
import FileUpload from '../common/FileUpload';
import FileListItem from '../common/FileListItem';
import PhotoIcon from '../../svgs/PhotoIcon';
import PrimaryActionButton from './PrimaryActionButton';
import type { ElementState } from '../../renderer/ElementState';
import { deepClone } from '../../utility/deepClone';
import { AspectRatio } from '../../types.ts/video';

const unsplashApi = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY as string,
});

type Props = {
  selectedAssetFunction?: (asset: any) => void;
};

const logoArtifactToPhotoAsset = (artifact: Artifact): PhotoAsset => {
  return {
    id: artifact.id,
    title: artifact.title,
    src: artifact.responsiveImage!.src,
    type: 'artifact',
    aspectRatio: parseFloat(artifact.width) / parseFloat(artifact.height),
    ...artifact.customData,
  };
};

const photoToPhotoAsset = (artifact: Artifact): PhotoAsset => {
  return {
    id: artifact.id,
    title: artifact.title,
    src: artifact.responsiveImage?.src!,
    type: 'artifact',
    ...artifact.customData,
  };
};

export const ArtifactsAndAssets: React.FC<Props> = observer((props) => {
  const artifact = videoCreator?.story?.storyArtifacts
    ?.filter((a) => a.responsiveImage)
    .map((a) => photoToPhotoAsset(a)) as PhotoAsset[];

  const albumPhotos = videoCreator?.story?._allReferencingShowcases?.flatMap(
    (album) =>
      album.organizationArtifacts
        ?.filter((a) => a.responsiveImage)
        .map((a) => photoToPhotoAsset(a)),
  ) as PhotoAsset[];

  const albumLogos = videoCreator?.story?._allReferencingShowcases?.flatMap(
    (album) =>
      album.organizationLogos
        ?.filter((a) => a.responsiveImage)
        .map((a) => logoArtifactToPhotoAsset(a)),
  ) as PhotoAsset[];

  const { tab, resource, lastSelectedStock, lastSelectedAi, selectedId } =
    videoCreator.selectedPhotoAssets || {};
  const [loadingAi, setLoadingAi] = useState(false);
  const [savingAi, setSavingAi] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadButtonActive, setUploadButtonActive] = useState(false);

  useEffect(() => {
    if (resource?.length || tab !== PhotoArtifactTab.story) return;
    videoCreator.selectedPhotoAssets = {
      tab: PhotoArtifactTab.story,
      resource: artifact,
      lastSelectedStock,
      lastSelectedAi,
      selectedId,
    };
  }, [videoCreator.story?.storyArtifacts, tab]);

  const [showBack, setShowBack] = useState(false);

  const uploadAiPhoto = async (url: string) => {
    if (!searchValue) return;
    try {
      setSavingAi(true);
      const fileName = getRandomFileName(searchValue);

      const newPhotoData: FileData & { fileName: string } = {
        type: 'ai',
        url,
        fileName,
        alt: searchValue,
        title: searchValue,
      };

      const newUpload =
        await videoCreator.assetRepository?.uploadFile(newPhotoData);
      return { url: newUpload?.url, id: newUpload?.id, fileName };
    } catch (error) {
    } finally {
      setSavingAi(false);
    }
  };
  const handleSelectResource = async (
    resource: PhotoAsset,
    tab: PhotoArtifactTab,
  ) => {
    if (props.selectedAssetFunction) {
      props.selectedAssetFunction(resource);
    } else {
      // add to timeline if no custom function provided
      let source = resource?.src;
      let fileName = resource?.slug;
      let title = resource?.title;
      let alt = resource?.alt;
      let id = resource?.id;

      runInAction(() => {
        videoCreator.selectedPhotoAssets = {
          ...videoCreator.selectedPhotoAssets,
          selectedId: resource?.id,
        };
      });

      if (resource.type === 'ai') {
        const data = await uploadAiPhoto(source);
        if (!data?.fileName) return;
        source = data.url || '';
        fileName = data.fileName;
        title = searchValue;
        alt = searchValue;
        id = data.id || resource.id;
      }
      if (tab === PhotoArtifactTab.logo) {
        const sourceAspectRatio = resource.aspectRatio || 1;
        const logoId = uuid();
        videoCreator.currentVideo!.extraElementData = {
          ...(videoCreator.currentVideo?.extraElementData || {}),
          [`logo_el_${logoId}`]: {
            isLogo: true,
          },
        };

        let time = 0;
        let duration = null;
        const videoDataPerTrack = combineVideoDataPerTrack(
          videoCreator.tracks!,
          (el) => videoCreator.isOriginalVideoElement(el.source),
        );
        if (videoDataPerTrack.length) {
          videoDataPerTrack.sort((a, b) => b.duration - a.duration);
          ({ time, duration } = videoDataPerTrack[0]);
        }

        await videoCreator.createElement({
          id: logoId,
          type: 'image',
          source,
          duration,
          time,
          track: videoCreator.getMaxTrack() + 1, // we don't want null to put logo into track after karaoke(32)
          opacity: '40%',
          fit: 'contain',
          x_anchor: '100%',
          y_anchor: '0%',
          ...(videoCreator.currentVideo?.aspectRatio ===
            AspectRatio.AR_16_9 && {
            x: '94%',
            y: '4%',
            width: '12%',
            height: `${(12 / sourceAspectRatio) * (16 / 9)}%`,
          }),
          ...(videoCreator.currentVideo?.aspectRatio ===
            AspectRatio.AR_9_16 && {
            x: '92%',
            y: '5%',
            width: '25%',
            height: `${(25 / sourceAspectRatio) * (9 / 16)}%`,
          }),
          ...(videoCreator.currentVideo?.aspectRatio === AspectRatio.AR_1_1 && {
            x: '95%',
            y: '5%',
            width: '19%',
            height: `${19 / sourceAspectRatio}%`,
          }),
        });
      } else {
        const freeTrack = videoCreator.getFreeMediaTrack('image', 8);
        await videoCreator.createElement({
          type: 'image',
          source,
          duration: '8 s',
          ...(freeTrack && { track: freeTrack }),
          autoplay: true,
          animations: [
            {
              start_scale: '100%',
              end_scale: '110%',
              x_anchor: '50%',
              y_anchor: '50%',
              fade: false,
              scope: 'element',
              easing: 'linear',
              type: 'scale',
              arbor_subType: 'zoomIn',
            },
          ],
        });
      }

      runInAction(() => {
        if (resource.type === 'stock' || resource.type === 'ai') {
          videoCreator.photoDataForDato = {
            ...videoCreator.photoDataForDato,
            [id]: {
              type: resource.type,
              url: source,
              fileName: fileName!,
              title,
              alt,
            },
          };
        }
      });

      if (tab === PhotoArtifactTab.stock) {
        setUnsplashSearch('');
        setUnsplashPage(1);
        videoCreator.selectedPhotoAssets = {
          tab: PhotoArtifactTab.story,
          resource: [],
        };
      }

      videoCreator.sidebarOptions = SidebarOption.editing;
    }
  };

  const handleSelectTab = async (tab: PhotoArtifactTab) => {
    let resource: PhotoAsset[] | undefined = undefined;
    switch (tab) {
      case PhotoArtifactTab.story:
        resource = artifact;
        break;
      case PhotoArtifactTab.album:
        resource = albumPhotos;
        break;
      case PhotoArtifactTab.logo:
        resource = albumLogos;
        break;
      case PhotoArtifactTab.stock:
        resource = lastSelectedStock?.length ? lastSelectedStock : undefined;
        break;
      case PhotoArtifactTab.ai:
        resource = lastSelectedAi?.length ? lastSelectedAi : undefined;
        break;
    }
    setShowBack(false);
    videoCreator.selectedPhotoAssets = {
      tab,
      resource,
      lastSelectedStock,
      lastSelectedAi,
      selectedId,
    };
  };

  const handleSearchArtifacts = (inputString: string) => {
    let resource;
    if (tab === PhotoArtifactTab.story) {
      if (!inputString) {
        resource = artifact;
      } else {
        resource = videoCreator?.story?.storyArtifacts
          ?.filter((a) => a.responsiveImage)
          .map((a) => photoToPhotoAsset(a))
          .filter(
            (a) => a?.title?.toLowerCase()?.includes(inputString.toLowerCase()),
          ) as PhotoAsset[];
      }
    } else if (tab === PhotoArtifactTab.logo) {
      if (!inputString) {
        resource = albumLogos;
      } else {
        resource = videoCreator?.story?._allReferencingShowcases
          ?.flatMap((album) =>
            (album.organizationLogos || [])
              .filter((a) => a.responsiveImage)
              .map((a) => logoArtifactToPhotoAsset(a)),
          )
          .filter((a) =>
            a.title.toLowerCase().includes(inputString.toLowerCase()),
          ) as PhotoAsset[];
      }
    } else {
      if (!inputString) {
        resource = albumPhotos;
      } else {
        resource = videoCreator?.story?._allReferencingShowcases
          ?.flatMap((album) =>
            (album.organizationArtifacts || [])
              .filter((a) => a.responsiveImage)
              .map((a) => photoToPhotoAsset(a)),
          )
          .filter((a) =>
            a.title.toLowerCase().includes(inputString.toLowerCase()),
          ) as PhotoAsset[];
      }
    }

    videoCreator.selectedPhotoAssets = {
      tab,
      resource,
      lastSelectedStock,
      lastSelectedAi,
      selectedId,
    };
  };

  const [unsplashLoading, setUnsplashLoading] = useState(false);
  const [unsplashSearch, setUnsplashSearch] = useState('');
  const [unsplashPage, setUnsplashPage] = useState(1);
  const handleSearchAssets = async (textValue: string) => {
    setUnsplashSearch(textValue);
    setUnsplashPage(1);
  };
  useEffect(() => {
    const fetchUnsplashPhotos = async () => {
      if (!unsplashSearch) return;
      setUnsplashLoading(true);
      const { response } = await unsplashApi.search.getPhotos({
        query: unsplashSearch,
        orientation: 'landscape',
        page: unsplashPage,
      });
      const resource = videoCreator.selectedPhotoAssets?.resource || [];
      let resourcePage = (response?.results.map((a) => ({
        id: a.id,
        title: a.description,
        description: a.description,
        src: a.urls?.regular,
        people: a.user.name,
        type: 'stock',
        alt: a.alt_description,
        slug: `${(a.alt_description || a.description)
          ?.toLowerCase()
          ?.split(' ')
          .join('_')}_${a.id}`,
      })) || []) as PhotoAsset[];
      const newResource =
        unsplashPage === 1 ? resourcePage : resource.concat(resourcePage);
      videoCreator.selectedPhotoAssets = {
        tab: PhotoArtifactTab.stock,
        resource: newResource,
        lastSelectedStock: newResource,
        lastSelectedAi,
        selectedId,
      };
      setShowBack(true);
      setUnsplashLoading(false);
    };
    fetchUnsplashPhotos();
  }, [unsplashSearch, unsplashPage]);

  const handleSearchAi = async (textValue: string) => {
    if (!textValue.trim()) return;
    try {
      setLoadingAi(true);
      setSearchValue(textValue);
      const gpt_service = new ChatGPTService();
      const arborStylePrompt =
        await gpt_service.fetchAiPrompts('Arbor Photo Style');

      let prompt = `${textValue} ${arborStylePrompt?.description || ''}`;

      const result = await generateImagesWithGPT(prompt, 2);
      let resource = result?.map((a) => ({
        id: uuid(),
        title: '',
        description: a.alt,
        src: a.url,
        type: 'ai',
      })) as PhotoAsset[];

      videoCreator.selectedPhotoAssets = {
        tab: PhotoArtifactTab.ai,
        resource,
        lastSelectedStock,
        lastSelectedAi: resource,
        selectedId,
      };
      setShowBack(true);
    } catch (error) {
    } finally {
      setLoadingAi(false);
    }
  };

  const handleRegenerateTitle = async (
    id: string,
    tab: PhotoArtifactTab.story | PhotoArtifactTab.album,
  ) => {
    let storyId, organizationId;
    if (tab === PhotoArtifactTab.story) {
      storyId = videoCreator.story!.id;
      videoCreator.statusUpdateManager.addStoryArtifactsToWatch(
        videoCreator.story!.id,
        videoCreator.story!.storyArtifacts!.filter((a) => a.id === id),
      );
    } else if (tab === PhotoArtifactTab.album) {
      organizationId = videoCreator.organization!.id;
      videoCreator.statusUpdateManager.addOrganizationArtifactsToWatch(
        videoCreator.organization!.id,
        videoCreator.organization!.organizationArtifacts!.filter(
          (a) => a.id === id,
        ),
      );
    }
    try {
      const gptService = new ChatGPTService();
      await gptService.regenerateArtifactTitle({
        artifactId: id,
        storyId,
        organizationId,
      });
    } catch (error) {
      console.error('Error occurred while regenerating title: ', error);
      videoCreator.toastState = {
        state: 'error',
        message: 'Could not regenerate description',
      };
    }
  };

  let stockMessageContent = null;

  if (tab === PhotoArtifactTab.stock) {
    if (!resource) {
      stockMessageContent = (
        <NoResource>
          Search the stock library to add photos to the story
        </NoResource>
      );
    } else if (!resource.length) {
      stockMessageContent = (
        <NoResource>You have no matching assets</NoResource>
      );
    }
  }

  const renderAlbumAndStoryTopActions = () => {
    return (
      <PhotoTopAction>
        <SearchInput
          placeholder="Search"
          handleAction={handleSearchArtifacts}
          radius="5px"
          iconRight
        />
        <FileUpload
          width="100%"
          type={(() => {
            if (tab === PhotoArtifactTab.story) {
              return 'storyArtifacts';
            }
            if (tab === PhotoArtifactTab.logo) {
              return 'organizationLogos';
            }
            return 'organizationArtifacts';
          })()}
          Button={
            <AddFileButton isActivated={uploadButtonActive}>
              <PhotoIcon strokeColor="#03041A" /> Upload Photo
            </AddFileButton>
          }
          showToggle={false}
          onClick={() => {
            setUploadButtonActive(true);
            setTimeout(() => setUploadButtonActive(false), 3000);
          }}
          callback={async () => {
            let photoArtifactsData;
            if (tab === PhotoArtifactTab.story) {
              photoArtifactsData = videoCreator
                .story!.storyArtifacts?.filter((a) => a.responsiveImage)
                .map((a) => photoToPhotoAsset(a)) as PhotoAsset[];
            } else if (tab === PhotoArtifactTab.logo) {
              photoArtifactsData =
                videoCreator.story!._allReferencingShowcases?.flatMap(
                  (album) =>
                    album.organizationLogos
                      ?.filter((a) => a.responsiveImage)
                      .map((a) => logoArtifactToPhotoAsset(a)),
                ) as PhotoAsset[];
            } else {
              photoArtifactsData =
                videoCreator.story!._allReferencingShowcases?.flatMap(
                  (album) =>
                    album.organizationArtifacts
                      ?.filter((a) => a.responsiveImage)
                      .map((a) => photoToPhotoAsset(a)),
                ) as PhotoAsset[];
            }
            videoCreator.selectedPhotoAssets = {
              tab,
              resource: photoArtifactsData,
              lastSelectedStock:
                videoCreator.selectedPhotoAssets.lastSelectedStock,
              lastSelectedAi: videoCreator.selectedPhotoAssets.lastSelectedAi,
              selectedId: videoCreator.selectedPhotoAssets.selectedId,
            };
          }}
          setIsLoading={setIsFileUploading}
        />
      </PhotoTopAction>
    );
  };

  return (
    <Main>
      <Tabs>
        {Object.entries(PhotoArtifactTab).map(([k, v]) => (
          <Tab
            key={k}
            isSelected={tab === v}
            onClick={() => handleSelectTab(v)}
          >
            {v}
          </Tab>
        ))}
      </Tabs>

      {tab === PhotoArtifactTab.stock ? (
        <PhotoTopAction>
          <SearchInput
            placeholder="Search photo"
            handleAction={handleSearchAssets}
            radius="5px"
            iconRight
          />
        </PhotoTopAction>
      ) : tab === PhotoArtifactTab.ai ? (
        <PhotoTopAction>
          <SearchInput
            placeholder="Describe the photo you want to see and hit enter"
            handleAction={handleSearchAi}
            expandHeight
            hideIcons
            radius="5px"
          />
        </PhotoTopAction>
      ) : tab === PhotoArtifactTab.story ||
        tab === PhotoArtifactTab.album ||
        tab === PhotoArtifactTab.logo ? (
        renderAlbumAndStoryTopActions()
      ) : null}

      {showBack && (
        <Back
          onClick={() => {
            setShowBack(false);
            videoCreator.selectedPhotoAssets = {
              tab: PhotoArtifactTab.story,
              resource: artifact,
              lastSelectedStock,
            };
          }}
        >
          <BackIcon />
        </Back>
      )}

      {(loadingAi || savingAi || isFileUploading) && (
        <SpinningLoading
          positionTop="20px"
          text={
            loadingAi
              ? 'Generating Ai photos'
              : savingAi
                ? 'Saving AI photo'
                : isFileUploading
                  ? 'Saving media'
                  : null
          }
        />
      )}

      {tab === PhotoArtifactTab.story ||
      tab === PhotoArtifactTab.album ||
      tab === PhotoArtifactTab.logo ? (
        <PhotoContent>
          {videoCreator.selectedPhotoAssets.resource?.map((r) => (
            <>
              <FileListItem
                key={r.id}
                type={(() => {
                  if (tab === PhotoArtifactTab.story) {
                    return 'storyArtifacts';
                  }
                  if (tab === PhotoArtifactTab.logo) {
                    return 'organizationLogos';
                  }
                  return 'organizationArtifacts';
                })()}
                item={{
                  id: r.id,
                  description: r.description || r.title,
                  src: r.src!,
                }}
                handleClick={() => handleSelectResource(r, tab)}
                isSelected={selectedId === r.id}
                handleRemove={() => handleRemoveMedia(r.id, tab)}
                handleRegenerateTitle={
                  tab === PhotoArtifactTab.story ||
                  tab === PhotoArtifactTab.album
                    ? () => handleRegenerateTitle(r.id, tab)
                    : undefined
                }
                callback={(artifacts, showcases) => {
                  if (tab === PhotoArtifactTab.story && artifacts) {
                    videoCreator.selectedPhotoAssets.resource = artifacts
                      ?.filter((a) => a.responsiveImage)
                      .map((a) => photoToPhotoAsset(a)) as PhotoAsset[];
                  } else if (tab === PhotoArtifactTab.logo && showcases) {
                    videoCreator.selectedPhotoAssets.resource =
                      showcases.flatMap(
                        (album) =>
                          album.organizationLogos
                            ?.filter((a) => a.responsiveImage)
                            .map((a) => photoToPhotoAsset(a)),
                      ) as PhotoAsset[];
                  } else if (tab === PhotoArtifactTab.album && showcases) {
                    videoCreator.selectedPhotoAssets.resource =
                      showcases.flatMap(
                        (album) =>
                          album.organizationArtifacts
                            ?.filter((a) => a.responsiveImage)
                            .map((a) => photoToPhotoAsset(a)),
                      ) as PhotoAsset[];
                  }
                }}
              />
            </>
          ))}
        </PhotoContent>
      ) : (
        <Container>
          {stockMessageContent}
          {resource?.map((r) => (
            <ResourceElement
              key={r.id}
              onClick={() => handleSelectResource(r, tab)}
            >
              <Circle isSelected={selectedId === r.id}>
                {selectedId === r.id && <CheckIcon />}
              </Circle>
              <ResourceImage loading="lazy" srcSet={r.src} />
            </ResourceElement>
          ))}
          {!!(resource && resource.length) && (
            <LoadMoreButton
              disabled={unsplashLoading}
              onClick={() => setUnsplashPage((cur) => cur + 1)}
            >
              Load more photos
            </LoadMoreButton>
          )}
        </Container>
      )}
    </Main>
  );
});
export default ArtifactsAndAssets;
const Main = styled.div`
  margin-top: 5px;
`;
const Back = styled.button`
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: #030419;
  margin-top: 16px;
`;

const AddFileButton = styled(PrimaryActionButton)``;

const PhotoContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const Container = styled.div`
  margin-top: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  column-gap: 16px;
`;
const ResourceElement = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100px;
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  border: 1px solid #484848;
`;
const ResourceImage = styled.img`
  margin: auto;
  min-height: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  object-position: top;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 4px 8px rgba(0, 0, 0, 0.1);
`;
const NoResource = styled.div`
  margin-top: 10px;
  grid-column: 1/3;
  text-align: center;
`;
const Tab = styled.div<{ isSelected?: boolean }>`
  color: ${(props) => (props.isSelected ? '#F2D093' : '')};
  text-decoration: ${(props) => (props.isSelected ? 'underline' : '')};
  cursor: pointer;
  font-size: 14px;
  padding: 10px 5px 10px 0;
`;
const Tabs = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: 16px;
  margin-left: 1px;
`;

const PhotoTopAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LoadMoreButton = styled.button`
  grid-column: 1/3;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background-color: #03041a;
  color: #17c964;
  border: 1px solid #17c964;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  outline: 0;
  transition: 0.2s;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

type VideoData = {
  duration: number;
  time: number;
};
function combineVideoDataPerTrack(
  tracks: Map<number, ElementState[]>,
  extraFilter: (el: ElementState) => boolean = () => true,
): VideoData[] {
  const result: VideoData[] = [];
  for (const [_track, trackElements] of tracks.entries()) {
    if (
      !trackElements.length ||
      !(trackElements[0].source.type === 'video') ||
      !extraFilter(trackElements[0])
    ) {
      continue;
    }

    const videoElements = deepClone(trackElements);
    videoElements.sort(
      (a, b) => parseFloat(a.source.time) - parseFloat(b.source.time),
    );

    const aTime = parseFloat(videoElements[0].source.time);
    const bTime = parseFloat(
      videoElements[videoElements.length - 1].source.time,
    );
    const bDuration = parseFloat(
      videoElements[videoElements.length - 1].source.duration,
    );

    result.push({
      duration: bTime - aTime + bDuration,
      time: aTime,
    });
  }
  return result;
}
