import React, { useRef, useState } from 'react'
import { ActionButton, ActionsWrapper, ShareModalAnimate } from '../../styles/mainStyle'
import styled from 'styled-components'
import SpinningLoading from '../SpinningLoading';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import DeleteIcon from '../../svgs/DeleteIcon';
import AutoResizeableMultilineInput from '../common/AutoResizeableMultilineInput';
import PhotoIcon from '../../svgs/PhotoIcon';
import { observer } from 'mobx-react-lite';
import { Asset } from './types';
import { isIOS } from 'react-device-detect'
import { brollService } from './BrollService';

type Props = {
    asset: Asset;
}

const MediaAsset = observer((props: Props) => {
    const { asset } = props
    const existingAsset = { ...asset }
    const buttonRef = useRef<HTMLDivElement>(null);
    const [openDropdown, toggleDropdown] = useState<boolean>(false);
    const [description, setDescription] = useState<string>(asset.description)
    const [isDeleting, setIsDeleting] = useState(false)
    const [onImageError, setOnImageError] = useState(false)

    useOutsideAlerter(buttonRef, () => {
        toggleDropdown(false);
    });

    const removeAsset = async () => {
        await brollService.removeAsset(setIsDeleting, asset)
    }

    const renameAsset = async () => {
        await brollService.changeDescription(existingAsset, description)
    }

    function renderMedia() {
        if (asset.type === 'photo') {
            return <Image src={asset.imgSrc} />
        }
        if (!isIOS) {
            if (!asset.isPreview && !asset.saved) return <Image src={asset.imgSrc} />
            if (asset.isPreview || asset.saved) return <Video src={asset.videoSrc} ></Video>
        }
        if (!onImageError) {
            return <Image src={asset.videoSrc} onError={() => setOnImageError(true)} ></Image>
        } else {
            return <Video src={asset.videoSrc} autoPlay ></Video>
        }
    }

    return (
        <ExistingAsset>
            <AssetAction type={asset.type}>
                <span className='asset-type'>{asset.type}</span>
                {isDeleting ? <Animate /> : <Ellipsis ref={buttonRef} onClick={() => toggleDropdown(true)}>
                    <EllipsisIcon />
                    <Actions ref={buttonRef} isVisible={openDropdown}>
                        {/* <RenameButton
                            onClick={(e) => {
                            }}
                        >
                            <span>Rename</span>
                        </RenameButton> */}

                        <DeleteButton
                            onClick={(e) => {
                                e.stopPropagation()
                                removeAsset()
                            }}
                        >
                            <span>Delete</span> <DeleteIcon strokeColor="#f3e9d7" />
                        </DeleteButton>
                    </Actions>

                </Ellipsis>}
            </AssetAction>
            <MediaContainer>
                {asset.state === 'pending' && <MediaOverlay />}
                {asset.isPreview && asset.state === 'uploading' &&
                    <SpinningLoading
                        Ico={<PhotoIcon strokeColor="#17C964" strokeWidth={2} />}
                        customStyle={{
                            top: 0,
                            alignItems: 'center',
                            backgroundColor: '#030419cc'
                        }}
                        text="Uploading..."
                    />
                }
                {renderMedia()}
            </MediaContainer>

            <AutoResizeableMultilineInput
                hasOutline={false}
                hasBorder={false}
                borderOnFocus={true}
                placeholder={`Please enter the description`}
                width="100%"
                customHeight="50px"
                value={asset.description}
                disableAutoResize={true}
                onBlur={renameAsset}
                getValue={(description) => {
                    setDescription(description);
                    brollService.files = brollService.files.map(file => {
                        if (file.id === asset.id) {
                            return Object.assign(file, {
                                description
                            })
                        } return file
                    })
                }} />
        </ExistingAsset>
    )
})

export default MediaAsset

const ExistingAsset = styled.div`
    width: 240px;
    min-height: 206px;   
    gap: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media only screen and (max-width: 615px) {
        width: 342px;
    }
`

const MediaContainer = styled.div`
    position: relative;
    width: 100%;
    height: 134px;
    border-radius: 8px;
    backgroundColor: red;
    @media only screen and (max-width: 615px) {
        height: 193px
    }
`
const MediaOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #030419cc;
`

const Image = styled.img`
    width: 100%;
    height: 134px;
    object-fit: cover;
    border-radius: 8px;
    @media only screen and (max-width: 615px) {
       height: 193px
    }
`
const Video = styled.video`
    width: 100%;
    height: 134px;
    object-fit: cover;
    border-radius: 8px;
    @media only screen and (max-width: 615px) {
        height: 193px
    }
`

const AssetAction = styled.div<{ type: 'photo' | 'video' }>`
    display: flex;
    justify-content: space-between;
    position: relative;
    span.asset-type{
        border: 1px solid ${props => props.type === 'photo' ? '#17C964' : '#006FEE'};
        color: ${props => props.type === 'photo' ? '#17C964' : '#006FEE'};
        border-radius: 4px;
        padding: 4px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        text-transform: capitalize;
    }

    button {
        background-color: transparent;
        border: 0;
        outline: 0;
    }
`

const Ellipsis = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Actions = styled(ActionsWrapper)`
    z-index: 1000;
`;

const RenameButton = styled(ActionButton)`
`;

// const SaveButton = styled(ActionButton)``;

const DeleteButton = styled(ActionButton)`
  gap: 20px;
  padding: 12px;
  &:hover {
    background-color: #ef5d6f;
  }
`;

const Animate = styled(ShareModalAnimate)`
`;