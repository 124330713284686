import React from 'react';

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const BoldIcon = (props: Props) => {
  const {
    width = '10',
    height = '12',
    viewBox = '0 0 10 12',
    fillColor = '#03041A',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M0.371094 12V0H5.17578C6.05859 0 6.79492 0.130859 7.38477 0.392578C7.97461 0.654297 8.41797 1.01758 8.71484 1.48242C9.01172 1.94336 9.16016 2.47461 9.16016 3.07617C9.16016 3.54492 9.06641 3.95703 8.87891 4.3125C8.69141 4.66406 8.43359 4.95313 8.10547 5.17969C7.78125 5.40234 7.41016 5.56055 6.99219 5.6543V5.77148C7.44922 5.79102 7.87695 5.91992 8.27539 6.1582C8.67774 6.39648 9.00391 6.73047 9.25391 7.16016C9.50391 7.58594 9.62891 8.09375 9.62891 8.68359C9.62891 9.32031 9.4707 9.88867 9.1543 10.3887C8.8418 10.8848 8.37891 11.2773 7.76562 11.5664C7.15234 11.8555 6.39648 12 5.49805 12H0.371094ZM2.9082 9.92578H4.97656C5.68359 9.92578 6.19922 9.79102 6.52344 9.52148C6.84766 9.24805 7.00977 8.88477 7.00977 8.43164C7.00977 8.09961 6.92969 7.80664 6.76953 7.55273C6.60938 7.29883 6.38086 7.09961 6.08398 6.95508C5.79102 6.81055 5.44141 6.73828 5.03516 6.73828H2.9082V9.92578ZM2.9082 5.02148H4.78906C5.13672 5.02148 5.44531 4.96094 5.71484 4.83984C5.98828 4.71484 6.20313 4.53906 6.35938 4.3125C6.51953 4.08594 6.59961 3.81445 6.59961 3.49805C6.59961 3.06445 6.44531 2.71484 6.13672 2.44922C5.83203 2.18359 5.39844 2.05078 4.83594 2.05078H2.9082V5.02148Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default BoldIcon;
