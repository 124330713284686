import React from 'react';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';

interface AudioPresetProps {
  activeElement: ElementState;
  url: string;
}

export const AudioPreset: React.FC<AudioPresetProps> = (props) => {

  // need to replace click event with a modal that allows user to select a new audio file
  return (
    <Main
      src={props.url}
      controls
      // onClick={async () => {
      //   const upload = await videoCreator.datoContext.selectUpload({multiple: false})
      //   if (upload) {
      //     await videoCreator.renderer?.applyModifications({
      //       [`${props.activeElement.source.id}.source`]: props.url,
      //     });
      //   } else {
      //     console.log('Modal closed!');
      //   }
      // }}
    /> 
  );
};

const Main = styled.audio`
  position: relative;
  margin: 15px 0;
  height: 80px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 100%;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 50%));
  }
`;
