import React from 'react';

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const ItalicIcon = (props: Props) => {
  const {
    width = '9',
    height = '12',
    viewBox = '0 0 9 12',
    fillColor = '#484848',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M8.41785 0L8.291 0.55814C8.11623 0.563777 7.8879 0.589147 7.60601 0.634249C7.32412 0.679352 7.11553 0.721635 6.98022 0.761099C6.74343 0.83439 6.5743 0.947146 6.47282 1.09937C6.37134 1.25159 6.29805 1.4179 6.25295 1.59831L4.22335 10.3679C4.21207 10.4073 4.20362 10.4524 4.19798 10.5032C4.19234 10.5539 4.18952 10.599 4.18952 10.6385C4.18952 10.7681 4.21771 10.8781 4.27409 10.9683C4.33047 11.0585 4.43195 11.1374 4.57853 11.2051C4.66309 11.2445 4.84914 11.2925 5.13667 11.3488C5.42419 11.3996 5.63843 11.4306 5.77937 11.4419L5.65252 12H0.248718L0.375568 11.4419C0.533426 11.4306 0.758937 11.4137 1.0521 11.3911C1.34527 11.3686 1.55386 11.3347 1.67789 11.2896C1.89777 11.2107 2.06408 11.1036 2.17684 10.9683C2.28959 10.8273 2.3657 10.6582 2.40517 10.4609L4.42631 1.68288C4.43758 1.63214 4.44604 1.5814 4.45168 1.53066C4.45732 1.47992 4.46013 1.42918 4.46013 1.37844C4.46013 1.26568 4.43476 1.16702 4.38402 1.08245C4.33892 0.997886 4.24026 0.921776 4.08804 0.854123C3.93582 0.786469 3.72722 0.724454 3.46225 0.668076C3.20291 0.606061 3.01123 0.569415 2.8872 0.55814L3.01405 0H8.41785Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ItalicIcon;
