import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import useInitializeDatoUser from '../common/useInitializeDatoUser';

import BrandKitWebsite from './BrandKitWebsite';
import BrandKitColors from './BrandKitColors';
import BrandKitFonts from './BrandKitFonts';
import BrandKitLinks from './BrandKitLinks';

import BrandKitMediaSection from './BrandKitMediaSection';
import BrandKitTextareaSection from './BrandKitTextareaSection';

type Props = {
  params: Record<string, string>;
};

const BrandKit = observer((props: Props) => {
  useInitializeDatoUser();

  return (
    <Main>
      <div className="container">
        <div className="topbar">
          <h1>Brand Kit</h1>
        </div>

        <div className="sidebar">
          {SIDEBAR_ITEMS.map((item) => (
            <a href={`#${item.id}`} key={item.id}>
              {item.title}
            </a>
          ))}
        </div>

        <div className="main">
          <BrandKitWebsite />
          <BrandKitMediaSection property="organizationLogos" />
          <BrandKitColors type="primary" />
          <BrandKitColors type="secondary" />
          <BrandKitFonts />
          <BrandKitMediaSection property="branding.designElements" />
          <BrandKitMediaSection property="organizationArtifacts" />
          <BrandKitMediaSection property="organizationArtifactsVideo" />
          <BrandKitTextareaSection property="brandVoice" />
          <BrandKitTextareaSection property="avoidWords" />
          <BrandKitTextareaSection property="missionStatement" />
          <BrandKitTextareaSection property="keyMetrics" />
          <BrandKitMediaSection property="branding.companyReports" />
          <BrandKitLinks />
        </div>
      </div>
    </Main>
  );
});

const SIDEBAR_ITEMS = [
  { title: 'Logos', id: 'logos' },
  { title: 'Primary Colors', id: 'primary-colors' },
  { title: 'Secondary Colors', id: 'secondary-colors' },
  { title: 'Fonts', id: 'fonts' },
  { title: 'Design Elements', id: 'design-elements' },
  { title: 'Photos', id: 'photos' },
  { title: 'Videos', id: 'videos' },
  { title: 'Brand Voice', id: 'brand-voice' },
  { title: 'Avoid Words', id: 'avoid-words' },
  { title: 'Mission Statement', id: 'mission-statement' },
  { title: 'Key Metrics', id: 'key-metrics' },
  { title: 'Company Reports', id: 'company-reports' },
  { title: 'Organization Website', id: 'org-website' },
  { title: 'External Links', id: 'external-links' },
];

const Main = styled.main`
  min-height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .container {
    flex-grow: 1;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 40px 20px;
    display: grid;
    grid-template-columns: 288px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'topbar topbar'
      'sidebar main';
    grid-column-gap: 24px;
  }

  .topbar {
    grid-area: topbar;
    padding-bottom: 16px;
    border-bottom: 1px solid #484848;
    margin-bottom: 8px;

    > h1 {
      font-size: 32px;
      font-weight: 700;
      color: #f3e9d7;
      margin: 0;
    }
  }

  .sidebar {
    grid-area: sidebar;
    align-self: flex-start;
    position: sticky;
    top: 8px;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    gap: 4px;

    > a {
      display: block;
      text-decoration: none;
      border-radius: 4px;
      color: #f3e9d7;
      padding: 10px 16px;
      font-weight: 500;
      transition: 0.2s;
      &:hover {
        background-color: #262630;
      }
    }
  }

  .main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > * {
      padding: 24px 0;
    }
    > *:not(:last-child) {
      border-bottom: 1px solid #484848;
    }
  }
`;

export default BrandKit;
