type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  radius?: string;
  fillColor: string;
};
const CircleIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '8'}
      height={props.height || 8}
      viewBox={props.viewBox || '0 0 8 8'}
      fill="none"
    >
      <circle
        cx={props.radius || '4'}
        cy={props.radius || '4'}
        r="4"
        fill={props.fillColor}
      />
    </svg>
  );
};

export default CircleIcon;
