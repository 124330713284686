import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CircleCheckIcon from '../../svgs/CircleCheckIcon';
import CloseIcon from '../../svgs/CloseIcon';

const SavedTag = ({
  isSaved,
  isError,
}: {
  isSaved: boolean;
  isError?: boolean;
}) => {
  const [showSaved, setShowSaved] = useState(isSaved);
  useEffect(() => {
    if (isSaved) setShowSaved(true);
  }, [isSaved]);
  useEffect(() => {
    if (showSaved) setTimeout(() => setShowSaved(false), 4000);
  }, [showSaved]);

  const [showFailed, setShowFailed] = useState(!!isError);
  useEffect(() => {
    if (isError) setShowFailed(true);
  }, [isError]);
  useEffect(() => {
    if (showFailed) setTimeout(() => setShowFailed(false), 4000);
  }, [showFailed]);

  if (isError) {
    return (
      <Container shown={showFailed} failed={showFailed}>
        <CloseIcon width="14" height="14" strokeColor="#ef5d6f" />
        <span>Failed to Save</span>
      </Container>
    );
  }

  return (
    <Container shown={showSaved}>
      <CircleCheckIcon width="14" height="14" strokeColor="#17c964" />
      <span>Saved</span>
    </Container>
  );
};

const Container = styled.div<{ shown: boolean; failed?: boolean }>`
  color: ${({ failed }) => (failed ? '#ef5d6f' : '#17c964')};
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0;
  transform: translateY(4px);
  transition: 0.4s;
  ${({ shown }) =>
    shown &&
    css`
      opacity: 1;
      transform: translateY(0px);
    `}
`;

export default SavedTag;
