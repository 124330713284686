type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};

const TimelineAudioIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox={props.viewBox || '0 0 14 14'}
      fill="none"
    >
      <g clipPath="url(#clip0_2810_1150)">
        <path
          d="M4.81489 10.7152C5.26603 10.7152 5.63174 10.3495 5.63174 9.89835C5.63174 9.44725 5.26603 9.08154 4.81489 9.08154C4.36376 9.08154 3.99805 9.44725 3.99805 9.89835C3.99805 10.3495 4.36376 10.7152 4.81489 10.7152Z"
          stroke={props.strokeColor || '#F2D093'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.48872 9.69423C8.93985 9.69423 9.30556 9.32852 9.30556 8.87738C9.30556 8.42626 8.93985 8.06055 8.48872 8.06055C8.03758 8.06055 7.67188 8.42626 7.67188 8.87738C7.67188 9.32852 8.03758 9.69423 8.48872 9.69423Z"
          stroke={props.strokeColor || '#F2D093'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.63086 9.8979V6.38543L9.30493 5.52051V8.87766"
          stroke={props.strokeColor || '#F2D093'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.964844 11.8753V2.12525C0.964844 1.87898 1.06268 1.6428 1.23682 1.46865C1.41096 1.29451 1.64714 1.19668 1.89342 1.19668H5.31984C5.53196 1.1905 5.7398 1.25716 5.90875 1.38557C6.0777 1.51397 6.19758 1.69637 6.24841 1.9024L6.53627 3.05383H12.1077C12.354 3.05383 12.5902 3.15166 12.7643 3.3258C12.9384 3.49994 13.0363 3.73612 13.0363 3.9824V11.8753C13.0363 12.1215 12.9384 12.3578 12.7643 12.5319C12.5902 12.706 12.354 12.8038 12.1077 12.8038H1.89342C1.64714 12.8038 1.41096 12.706 1.23682 12.5319C1.06268 12.3578 0.964844 12.1215 0.964844 11.8753Z"
          stroke={props.strokeColor || '#F2D093'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2810_1150">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimelineAudioIcon;
