import styled from 'styled-components';
import { CopyIcon } from '../common/icons';
import { useState } from 'react';

type Props = {
  name: string;
  tag: string;
  type: 'save_as' | 'rename';
  onCancel: () => void;
  onSave: (name: string) => void;
};

const CopyContentModal: React.FC<Props> = (props: Props) => {
  const [nameValue, setNameValue] = useState(props.name);

  return (
    <Wrapper>
      <Heading>
        {props.type === 'save_as' ? 'Copy' : 'Rename'} {props.tag}
      </Heading>
      <FieldSet>
        <FieldName>name</FieldName>
        <Input
          value={nameValue}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setNameValue(e.target.value)}
        />
      </FieldSet>
      <Buttons>
        <CancelButton onClick={props.onCancel}>Cancel</CancelButton>
        <SaveButton onClick={() => props.onSave(nameValue)}>
          <CopyIcon /> Save
        </SaveButton>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 459px;
  height: 287px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const FieldSet = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;

  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  background: #03041a;
`;

const FieldName = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Heading = styled.div`
  color: #45d483;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
`;

const Buttons = styled.div`
  display: flex;
  height: 48px;
  gap: 8px;
  cursor: pointer;
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #17c964;

  font-size: 14px;
  font-weight: 700;

  color: #17c964;

  &:hover {
    font-size: 15px;
  }
`;

const SaveButton = styled(CancelButton)`
  background: #17c964;
  color: #03041a;
`;

export default CopyContentModal;
