import InstagramIcon from '../../svgs/InstagramIcon';
import XIcon from '../../svgs/XIcon';
import FacebookIcon from '../../svgs/FacebookIcon';
import LinkedInIcon from '../../svgs/LinkedInIcon';
import YouTubeIcon from '../../svgs/YouTubeIcon';
import YouTubeShortsIcon from '../../svgs/YouTubeShortsIcon';
import styled, { css } from 'styled-components';
import TiktokIcon from '../../svgs/TiktokIcon';

type Props = {
  selectedSocials: string[];
  onSocialSelect: (social: string) => void;
};
const SocialClipButtonList = (props: Props) => {
  const { selectedSocials, onSocialSelect } = props;
  return (
    <SocialButtons>
      <SocialButton
        active={selectedSocials.includes('linkedin')}
        onClick={() => onSocialSelect('linkedin')}
      >
        <LinkedInIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('facebook')}
        onClick={() => onSocialSelect('facebook')}
      >
        <FacebookIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('twitter')}
        onClick={() => onSocialSelect('twitter')}
      >
        <XIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('tiktok')}
        onClick={() => onSocialSelect('tiktok')}
      >
        <TiktokIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('youtube')}
        onClick={() => onSocialSelect('youtube')}
      >
        <YouTubeIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('instagram')}
        onClick={() => onSocialSelect('instagram')}
      >
        <InstagramIcon
          fillColor={
            selectedSocials.includes('instagram') ? '#f2d093' : '#030419'
          }
          strokeColor="currentColor"
        />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('youtube-shorts')}
        onClick={() => onSocialSelect('youtube-shorts')}
      >
        <YouTubeShortsIcon
          fillColor={
            selectedSocials.includes('youtube-shorts') ? '#f2d093' : '#030419'
          }
          strokeColor="currentColor"
        />
      </SocialButton>
    </SocialButtons>
  );
};

export default SocialClipButtonList;

const SocialButton = styled.div.attrs((props: { active: boolean }) => props)`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #484848;
  color: #484848;
  cursor: pointer;
  align-items: center;
  width: 38px;
  box-sizing: border-box;
  justify-content: center;

  ${(props) =>
    props.active
      ? css`
          color: #030419;
          border-color: #f2d093;
          background-color: #f2d093;
        `
      : css`
          &:hover {
            color: #f2d093;
          }
        `}
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;
