import React from 'react'

const CheckIconAlt = () => {
    return (
        <svg width="8" height="7" viewBox="0 0 8 7" fill="none">
            <path d="M7.93038 1.2781L2.94091 6.26755C2.89673 6.31213 2.83657 6.33717 2.77381 6.33717C2.71106 6.33717 2.6509 6.31213 2.60671 6.26755L0.0696172 3.73045C0.0250604 3.6863 0 3.62614 0 3.56335C0 3.50061 0.0250604 3.44045 0.0696172 3.39625L0.39911 3.06676C0.4433 3.02223 0.503456 2.99714 0.56621 2.99714C0.628965 2.99714 0.689121 3.02223 0.733311 3.06676L2.77146 5.10491L7.26669 0.6097C7.3598 0.519423 7.50778 0.519423 7.60089 0.6097L7.93038 0.9439C7.97496 0.98809 8 1.04825 8 1.111C8 1.17375 7.97496 1.23391 7.93038 1.2781Z" fill="#03041A" />
        </svg>
    )
}

export default CheckIconAlt