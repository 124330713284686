type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const MediumIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    fillColor = 'currentColor',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M16.6467 8.80689L17.5349 7.95782V7.77067H14.4664L12.2787 13.2267L9.79173 7.77067H6.57309V7.95782L7.60932 9.20552C7.65966 9.25064 7.69863 9.307 7.72307 9.37002C7.7475 9.43304 7.75671 9.50095 7.74995 9.5682V14.4755C7.76475 14.5626 7.75887 14.6521 7.7328 14.7365C7.70672 14.821 7.66116 14.8982 7.5998 14.9619L6.43246 16.3787V16.5638H9.73886V16.3777L8.57258 14.9629C8.51064 14.8994 8.46397 14.8225 8.43613 14.7383C8.40828 14.654 8.39998 14.5645 8.41186 14.4765V10.2322L11.3143 16.5648H11.6527L14.1449 10.2322V15.2791C14.1449 15.4134 14.1449 15.4387 14.0561 15.5276L13.1595 16.3978V16.5839H17.5127V16.3978L16.6477 15.5476C16.6101 15.5192 16.581 15.481 16.5635 15.4372C16.546 15.3934 16.5407 15.3457 16.5483 15.2992V9.05538C16.5407 9.00883 16.546 8.96109 16.5635 8.9173C16.581 8.87352 16.6091 8.83534 16.6467 8.80689Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5433 2.30722C13.8482 1.89759 10.1191 1.89759 6.424 2.30722C4.29657 2.54513 2.57834 4.22107 2.3288 6.35908C1.8904 10.107 1.8904 13.8933 2.3288 17.6412C2.57834 19.7792 4.29551 21.4552 6.424 21.6931C10.1192 22.1023 13.8482 22.1023 17.5433 21.6931C19.6708 21.4552 21.389 19.7792 21.6385 17.6412C22.0769 13.8933 22.0769 10.107 21.6385 6.35908C21.389 4.22107 19.6718 2.54513 17.5433 2.30722ZM6.59953 3.88271C10.178 3.48606 13.7893 3.48606 17.3678 3.88271C18.7741 4.04131 19.9002 5.1505 20.0641 6.54412C20.4881 10.1691 20.4881 13.8312 20.0641 17.4562C19.9793 18.1383 19.6671 18.7718 19.1779 19.2545C18.6886 19.7372 18.0509 20.0409 17.3678 20.1166C13.7893 20.5132 10.178 20.5132 6.59953 20.1166C5.9164 20.0409 5.27871 19.7372 4.78947 19.2545C4.30022 18.7718 3.988 18.1383 3.90323 17.4562C3.47927 13.8312 3.47927 10.1691 3.90323 6.54412C3.988 5.86207 4.30022 5.22851 4.78947 4.74579C5.27871 4.26307 5.9164 3.95832 6.59953 3.88271Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default MediumIcon;
