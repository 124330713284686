import { CSSProperties, useRef, useState } from 'react';
import type MuxPlayerElement from '@mux/mux-player';
import type { MuxPlayerElementEventMap } from '@mux/mux-player';
import MuxPlayer from '@mux/mux-player-react';
import { VideoSeekSlider } from 'react-video-seek-slider';
import { convertTimeToMMSS } from '../../utility/timeFormat';

import '../../styles/muxPlayerClipStyles.css';
import styled from 'styled-components';
import { getThumbnailTime } from '../../utility/videoClip';

const playerStyle = {
  maxHeight: '100%',
  width: '100%',
  height: '100%',
  borderRadius: 10,
  overflow: 'hidden',
  border: '1px solid #484848',
  aspectRatio: 4 / 3,
  '--pip-button': 'none',
  '--fullscreen-button': 'none',
  '--seek-live-button': 'none',
  '--seek-backward-button': 'none',
  '--seek-forward-button': 'none',
  '--time-range': 'none',
  '--media-object-position': 'center',
  '--time-display': 'none',
  '--duration-display': 'none',
} as CSSProperties;

const styleOnPoster = {
  ...playerStyle,
  '--media-object-fit': 'cover',
};

type Props = {
  clip: {
    startTime: number;
    duration: number;
    muxPlaybackId: string | undefined;
    src: string | undefined;
    poster: string | undefined;
    autoPlay: boolean;
  };
};

const MuxPlayerClip = ({ clip }: Props) => {
  const playerRef = useRef<MuxPlayerElement | null>(null);
  const duration = Math.round(clip.duration);
  const durationFormatted = duration ? convertTimeToMMSS(duration) : '';

  const endTime = clip.startTime + clip.duration;
  const endTimeFormatted = convertTimeToMMSS(
    Math.round(clip.startTime + clip.duration),
  );

  const [currentTimeMs, setCurrentTimeMs] = useState(0);
  const [isShowingPoster, setIsShowingPoster] = useState(true);

  function onMuxTimeUpdate(event: MuxPlayerElementEventMap['timeupdate']) {
    const videoElement = event.target as HTMLVideoElement;
    const seconds = videoElement.currentTime;
    if (
      seconds > clip.startTime &&
      !playerRef.current?.paused &&
      isShowingPoster
    ) {
      setIsShowingPoster(false);
    }
    setCurrentTimeMs((seconds - clip.startTime) * 1000);
    if (seconds > endTime) {
      playerRef.current!.pause();
      playerRef.current!.currentTime = clip.startTime;
    }
  }

  function onSeekSliderChange(newTimeMs: number) {
    playerRef.current!.currentTime = clip.startTime + newTimeMs / 1000;
  }

  return (
    <PlayerContainer onClick={(e) => e.stopPropagation()}>
      <MuxPlayer
        ref={playerRef}
        onTimeUpdate={onMuxTimeUpdate}
        style={isShowingPoster ? styleOnPoster : playerStyle}
        streamType="on-demand"
        playbackId={clip.muxPlaybackId}
        startTime={clip.startTime}
        src={clip.src}
        poster={clip.poster}
        thumbnailTime={!clip.poster ? getThumbnailTime(clip) : undefined}
        autoPlay={clip.autoPlay}
        volume={1}
      />
      <ProgressBarContainer>
        <VideoSeekSlider
          max={clip.duration * 1000}
          currentTime={currentTimeMs}
          onChange={onSeekSliderChange}
          secondsPrefix="00:"
        />
      </ProgressBarContainer>
    </PlayerContainer>
  );
};

const PlayerContainer = styled.div`
  position: relative;
  height: 100%;
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 52px;
  width: 100%;
`;

export default MuxPlayerClip;
