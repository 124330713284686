// src/stores/lib/TrackManager.ts
import { RendererState } from '../../renderer/RendererState';
import { ElementState } from '../../renderer/ElementState';
import { deepClone } from '../../utility/deepClone';
import VideoCreatorStore from '../VideoCreatorStore';
import { KARAOKE_TRACK_NUMBER } from '../VideoCreatorStore';

// TODO: This should be... somewhere else also.
import {
  getElementPositionProperties,
  getOriginalVideoTrackSourceDiff,
} from '../../utility/timeline';

export class TrackManager {
  videoCreatorStore: VideoCreatorStore;

  constructor(videoCreatorStore: any) {
    this.videoCreatorStore = videoCreatorStore;
  }

  updateFrameLockedTracksAfterRearrange(
    oldStateElements: ElementState[],
    newStateElements: ElementState[],
  ) {
    this.videoCreatorStore.frameLockedTracks =
      this.videoCreatorStore.frameLockedTracks
        .map((track) => {
          const elementsInOldSource = oldStateElements.filter(
            (el) => el.track === track,
          );
          if (elementsInOldSource.length > 0) {
            const elementInNewSource = newStateElements.find((el) =>
              elementsInOldSource.find(
                (oldEl) => oldEl.source.id === el.source.id,
              ),
            );
            return elementInNewSource?.track;
          }
        })
        .filter((track) => track !== undefined) as number[];
  }

  async rearrangeTracks(
    track: number,
    direction: 'up' | 'down',
  ): Promise<void> {
    if (track === KARAOKE_TRACK_NUMBER) return;

    const renderer = this.videoCreatorStore.renderer;
    if (!renderer || !renderer.state) {
      return;
    }

    // The track number to swap with
    const targetTrack = direction === 'up' ? track + 1 : track - 1;
    if (targetTrack < 1) {
      return;
    }

    // Elements at provided track
    const elementsCurrentTrack = renderer.state.elements.filter(
      (element) => element.track === track,
    );
    if (elementsCurrentTrack.length === 0) {
      return;
    }

    this.videoCreatorStore.saveVideoStateSnapshot(
      undefined,
      `rearranging tracks`,
    );

    await this.moveTrackPastAnother(track, targetTrack);
  }

  async lockVideoTrackOnStateChange(elements: ElementState[]) {
    if (this.isVideoTrackLocked(elements)) return;
    const activeElement = this.videoCreatorStore.getActiveElement();

    if (
      !activeElement ||
      (activeElement &&
        !this.videoCreatorStore.isOriginalVideoElement(activeElement.source))
    ) {
      await this.lockVideoTrack(elements);
    }
  }

  async lockVideoTrack(elements?: ElementState[]) {
    const videoElements = elements?.filter((e) =>
      this.videoCreatorStore.isOriginalVideoElement(e.source),
    );
    const modifications = {} as Record<string, any>;

    for (let e of videoElements || []) {
      modifications[`${e.source.id}.locked`] = true;
    }
    if (Object.keys(modifications).length > 0) {
      await this.videoCreatorStore.renderer?.applyModifications(modifications);
    }
  }

  async unLockVideoTrack() {
    const elements = this.videoCreatorStore.renderer?.getElements();

    const videoElements = elements?.filter((e) =>
      this.videoCreatorStore.isOriginalVideoElement(e.source),
    );
    if (videoElements?.every((e) => !e.source.locked)) return;

    const modifications = {} as Record<string, any>;

    for (let e of videoElements || []) {
      modifications[`${e.source.id}.locked`] = false;
    }

    if (Object.keys(modifications).length > 0) {
      await this.videoCreatorStore.renderer?.applyModifications(modifications);
    }
  }

  isVideoTrackLocked(elements?: ElementState[]) {
    const videoElements = elements?.filter((e) =>
      this.videoCreatorStore.isOriginalVideoElement(e.source),
    );
    return videoElements?.every((e) => e.source.locked);
  }

  toggleTrackFrameLock(track: number) {
    this.videoCreatorStore.frameLockedTracks =
      this.videoCreatorStore.frameLockedTracks.includes(track)
        ? this.videoCreatorStore.frameLockedTracks.filter((t) => t !== track)
        : [...this.videoCreatorStore.frameLockedTracks, track];
  }

  async applyChangesToFrameLockedTracks(
    prevState: RendererState,
    newState: RendererState,
  ) {
    const frameLockedTrackChanges = getOriginalVideoTrackSourceDiff(
      prevState.elements,
      newState.elements,
    );
    if (Object.keys(frameLockedTrackChanges).length !== 1) {
      console.log(
        'No frame locked tracks changed or more than one, not applying',
      );
      return false;
    }
    const id = Object.keys(frameLockedTrackChanges)[0];
    const trackChanges = getElementPositionProperties(
      newState.elements.find((el) => el.source.id === id)!,
    );
    const modificationObject: any = {};
    for (const element of newState.elements.filter(
      (el) =>
        this.videoCreatorStore.frameLockedTracks.includes(el.track) &&
        el.source.id !== id,
    )) {
      for (const key in trackChanges) {
        modificationObject[`${element.source.id}.${key}`] =
          trackChanges[key] || null;
      }
      modificationObject[`${element.source.id}.clip`] = true;
    }
    if (Object.keys(modificationObject).length > 0) {
      await this.videoCreatorStore.renderer?.applyModifications(
        modificationObject,
      );
      return true;
    }
    return false;
  }

  async moveTrackPastAnother(
    track: number,
    anotherTrack: number,
    modifiedSource: Record<string, any> | undefined = undefined,
  ): Promise<void> {
    const trackDiff = track - anotherTrack;
    const renderer = this.videoCreatorStore.renderer;
    if (
      !renderer ||
      !renderer.state ||
      trackDiff === 0 ||
      track === KARAOKE_TRACK_NUMBER
    ) {
      return;
    }

    const oldStateElements = deepClone(renderer.state.elements);
    const source =
      modifiedSource || deepClone(renderer.getSource(renderer.state));

    const direction = trackDiff > 0 ? 'down' : 'up';
    for (let i = 0; i < Math.abs(trackDiff); i++) {
      // The track number to swap with
      const targetTrack = direction === 'up' ? track + 1 : track - 1;
      if (targetTrack < 1) {
        break;
      }
      // Swap track numbers
      for (const element of source.elements) {
        if (element.track === track) {
          element.track = targetTrack;
        } else if (element.track === targetTrack) {
          element.track = track;
        }
      }
      track = direction === 'up' ? track + 1 : track - 1;
    }

    // Set source by the mutated state
    const newSource =
      this.videoCreatorStore.videoTranscriptionProcessor.adjustTrackNumbersToStartFromOne(
        source,
      );
    await renderer.setSource(newSource, true);
    this.updateFrameLockedTracksAfterRearrange(
      oldStateElements,
      renderer.state.elements,
    );
    this.videoCreatorStore.createDefaultUndoPoint();
  }
}
