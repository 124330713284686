import { useState } from 'react';
import styled from 'styled-components';
import type { Showcase } from '../types.ts/story';

export type OrganizationAvatarProps = {
  album: Showcase;
  username: string | null | undefined;
  logoSize?: number;
};

const OrganizationAvatar = ({
  album,
  username,
  logoSize = 34,
}: OrganizationAvatarProps) => {
  const [albumLogo, setAlbumLogo] = useState<string | undefined>(
    album?.logo?.url,
  );

  return (
    <>
      <OrganizationInfo>
        <span className="album-title">{album.title}</span>
        <span className="username">{username}</span>
      </OrganizationInfo>
      <OrganizationLogo size={logoSize}>
        <img
          src={`${
            album?.logo?.url || albumLogo
          }?crop=focalpoint&fit=crop&fp-x=0.3&fp-y=0.6&w=${logoSize}&h=${logoSize}`}
          onError={() => setAlbumLogo(album?.mainImage?.url)}
        />
      </OrganizationLogo>
    </>
  );
};

OrganizationAvatar.displayName = 'OrganizationAvatar';

export default OrganizationAvatar;

export const OrganizationAvatarWrapper = styled.div`
  display: flex;
`;

const OrganizationInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  .album-title {
    color: #f3e9d7;
    text-align: right;
    font-size: 16px;
    font-weight: 700;
  }

  .username {
    color: #a9a9a9;
    font-size: 12px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    margin-right: 6px;
    .username {
      font-size: 10px;
    }
    .album-title {
      font-size: 14px;
    }
  }
`;

const OrganizationLogo = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    width: min(${(props) => props.size}px, 30px);
    height: min(${(props) => props.size}px, 30px);
  }
`;
