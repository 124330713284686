import OpenAI from 'openai';
import json5 from 'json5';
import { videoCreator } from '../stores/VideoCreatorStore';

type MatchPromptResponse = {
  artifact_description?: string;
  prompt_description: string;
  dalle_prompt?: string;
  similarity: string;
  reason_for_match: string;
};

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_CHATGPT_API_KEY as string,
  dangerouslyAllowBrowser: true,
});

export const processResponseAsListOfStrings = (
  response: string,
): string[] | undefined => {
  let sentences;
  try {
    sentences = json5.parse(response);
  } catch (error: any) {
    sentences = response?.split(/(?:\d+\)|\d+,|\s*-\s*)/)?.filter(Boolean);
  }
  if (Array.isArray(sentences)) {
    return sentences?.map(
      (sentence: string) => sentence?.replace(/,+\s*$/, '.'),
    );
  } else if (typeof sentences === 'object') {
    return Object.values(sentences)?.map(
      (sentence) => (sentence as string)?.replace(/,+\s*$/, '.'),
    );
  }
};

export const getGPTFixingSummary = async (fullText: string) => {
  let params = {
    messages: [
      {
        role: 'system',
        content:
          'You are a transcript editor, you decide how to fix a transcript to what is more likely a proper transcription. You fix mistakes like putting "v" "c" and "r" as seperate "words" when they should together as "VCR". the user will provide you with a json object, at the root are the fields: "storyId", "uploadId", "storyName", "storyTeller", "fullText", and "elemetnts". "fullText" is the full transcript text, "elements" is an array of objects, each object has the fields: "type", "ts", "end_ts", "confidence". "type" is the type of transcript element, it can be either "text" or "punctuation". If it is "text", then it has a "ts" and "end_ts" which are the start and stop timestamp of the text utterance. A "punctuation" type are spaces and punctuation marks. "Confidence" is how confident the original algorithm was that the value was the word actually uttered in the sound file. Summarize the full story first so that all the words make sense in the context of the story.',
      },
      {
        role: 'user',
        content: `Here's the full story text in paragraph form: ${fullText}`,
      },
    ],
    model: 'gpt-4o',
    temperature: 0.7,
  };
};

export const getGPTranscriptReplacements = async (
  fullText: string,
  batchText: string,
  storyTranscriptElements: Array<object>,
) => {
  const summary = await getGPTFixingSummary(fullText);

  let params = {
    messages: [
      {
        role: 'system',
        content:
          'You are a transcript editor, you decide how to fix a transcript to what is more likely a proper transcription. You fix mistakes like putting "v" "c" and "r" as seperate "words" when they should together as "VCR". the user will provide you with a json object, at the root are the fields: "storyId", "uploadId", "storyName", "storyTeller", "fullText", and "elemetnts". "fullText" is the full transcript text, "elements" is an array of objects, each object has the fields: "type", "ts", "end_ts", "confidence". "type" is the type of transcript element, it can be either "text" or "punctuation". If it is "text", then it has a "ts" and "end_ts" which are the start and stop timestamp of the text utterance. A "punctuation" type are spaces and punctuation marks. "Confidence" is how confident the original algorithm was that the value was the word actually uttered in the sound file. Summarize the full story first so that all the words make sense in the context of the story.',
      },
      {
        role: 'user',
        content: `Here's the summary of the entire transcript: ${summary}`,
      },
      {
        role: 'user',
        content: `Here's the part of the story we are currently evaluating for fixes in paragraph form: ${batchText}`,
      },
      {
        role: 'user',
        content: `Here's the same part of the story as an array of json objects where each object represents a word, space or punctuation: ${JSON.stringify(
          storyTranscriptElements,
        )}`,
      },
      {
        role: 'user',
        content:
          'Fix the transcript, combining pieces of text that were seperated by accident, fix spellings and proper names? Do not add changes that are not necessary or superflous, like combining words that are not supposed to be combined. if you are unsure, you should use the string_equality function to check if the strings are the same. You do not need to include all words in the transcript, only the ones that need to be changed. If no change needed, do not include it in the changes array when you call the function apply_replacements. If you are not sure, you can skip the change.',
      },
    ],
    functions: [
      {
        name: 'apply_replacements',
        description: 'Apply the specified replacements to the transcript',
        parameters: {
          type: 'object',
          properties: {
            changes: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  index: {
                    type: 'number',
                    description:
                      'The starting index in the elements array to apply the change to',
                  },
                  count: {
                    type: 'number',
                    description:
                      'The number of elements to apply the change to',
                  },
                  old_value: {
                    type: 'string',
                    description:
                      'The old value of the change. only required for replace and remove',
                  },
                  new_value: {
                    type: 'string',
                    description:
                      'The new value of the change. only required for replace',
                  },
                  reason: {
                    type: 'string',
                    description: 'The reason for the change. Always required',
                  },
                },
              },
            },
          },
          required: ['changes', 'reason'],
        },
      },
    ],
    function_call: { name: 'apply_replacements' },
    // model: 'gpt-3.5-turbo-16k-0613',
    model: 'gpt-4o',
    temperature: 0.3,
  };

  const completion = await openai.chat.completions.create(
    params as OpenAI.ChatCompletionCreateParamsNonStreaming,
  );
  console.log('got completion', completion);
  const response = completion.choices[0].message;
  if (response && response.function_call) {
    if (response.function_call.name === 'apply_replacements') {
      return response.function_call.arguments;
    } else if (response.function_call.name === 'string_equality') {
      try {
        const args = JSON.parse(response.function_call.arguments);
        params.messages.push({
          role: 'assistant',
          content: string_equality(args.a, args.b),
        });
        return await openai.chat.completions.create(
          params as OpenAI.ChatCompletionCreateParamsNonStreaming,
        );
      } catch (error: any) {
        console.log('error parsing arguments', error);
      }
    }
  } else if (response && response.content) {
    console.log('gpt had an idea');
    params.messages.push(response as any);
    return await openai.chat.completions.create(
      params as OpenAI.ChatCompletionCreateParamsNonStreaming,
    );
  }
  return response;
};

export const string_equality = (a: string, b: string) => {
  if (!a || !b) {
    // call chatgpt again with the new message of the inputs being bad
    return `The strings you provided are invalid. Try again.`;
  } else if (a === b) {
    // call chatgpt again with the new message of the strings being equal
    return `The strings "${a}" and "${b} are equal, please continue.`;
  } else {
    // call chatgpt again with the new message of the strings being not equal
    return `The strings "${a}" and "${b} are not equal, please continue.`;
  }
};
export const gpt_functions = [
  {
    name: 'word_count',
    description: 'Count how many words are in a string',
    parameters: {
      type: 'object',
      properties: {
        string: {
          type: 'string',
          description: 'The string to count the words in',
        },
      },
      required: ['string'],
    },
  },
  {
    name: 'string_equality',
    description: 'Check if two strings are equal',
    parameters: {
      type: 'object',
      properties: {
        a: {
          type: 'string',
          description: 'the first string',
        },
        b: {
          type: 'string',
          description: 'the second string',
        },
      },
      required: ['a', 'b'],
    },
  },
];

export const generateImagesWithGPT = async (
  prompt: string,
  numImage?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  size?:
    | '1024x1024'
    | '256x256'
    | '512x512'
    | '1792x1024'
    | '1024x1792'
    | null
    | undefined,
  model = 'dall-e-2',
) => {
  try {
    // if (videoCreator.disableAiImageGenerate) return [];

    const response = await openai.images.generate({
      model,
      prompt,
      n: numImage || 1,
      size: size || '1024x1024',
      response_format: 'b64_json',
    });

    return (
      response?.data?.map((data) => ({
        url: `data:image/png;base64,${data.b64_json}`,
        alt: '',
        width: '',
        height: '',
      })) || []
    );
  } catch (error) {
    console.log('error', error);
    return [];
  }
};
