import { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { videoCreator } from '../stores/VideoCreatorStore';

export default function useCurrentUserType(): 'internal' | 'external' {
  const user = useUser();
  let userType: 'internal' | 'external' = 'external';

  if (videoCreator.datoContext?.currentUserAccessToken) {
    userType = 'internal';
  } else if (!user?.user) {
    console.error('useCurrentUserType: no user at all?');
  }

  useEffect(() => {
    videoCreator.currentUserType = userType;
  }, [userType]);

  return userType;
}
