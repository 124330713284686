import {
  TranscriptElement,
  TranscriptChange,
  TranscriptTextElement,
} from '../../types.ts/transcript';
import {
  getClosestNotRemovedTextIndexToRight,
  getClosestNotRemovedTextIndexToLeft,
} from '../utils';
import ChangeHandler from './ChangeHandler';

class RestoreChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'restore' },
    originalElements: TranscriptElement[],
  ) {
    // debugger;
    const originalIndex =
      transcriptionElementsMutable[change.index].initial_index;

    if (originalIndex === -1) {
      console.warn('No original element found for index', change.index);
    }

    const firstWordIndex = getClosestNotRemovedTextIndexToRight(
      originalIndex,
      originalElements,
    );
    const lastWordIndex = getClosestNotRemovedTextIndexToLeft(
      originalIndex + change.count - 1,
      originalElements,
    );

    const firstWord = originalElements[firstWordIndex];
    const lastWord = originalElements[lastWordIndex];

    const endTs = lastWord?.end_ts ?? 0;
    const startTs = firstWord?.ts ?? 0;
    const intoTs = change.newTs;
    const diffTs =
      lastWordIndex >= firstWordIndex
        ? startTs - (intoTs + change.timeBufferBefore)
        : 0;

    let firstTextIndex = -1,
      lastTextIndex = -1;

    let newlinesCount = 0;
    for (let i = 0; i < change.count; i++) {
      const index = change.index + i;
      const element = transcriptionElementsMutable[index];
      if (element.type === 'text') {
        if (firstTextIndex === -1) {
          firstTextIndex = index;
        }
        lastTextIndex = index;

        element.ts =
          originalElements[originalIndex + i - newlinesCount].ts! -
          diffTs +
          (element.trim_start || 0);
        element.end_ts =
          originalElements[originalIndex + i - newlinesCount].end_ts! -
          diffTs -
          (element.trim_end || 0);
      }
      if (element.value === '\n') {
        newlinesCount++;
      }
      delete transcriptionElementsMutable[index].state; // now unremoved
    }

    if (firstTextIndex > 0) {
      const firstTextEl = transcriptionElementsMutable[
        firstTextIndex
      ] as TranscriptTextElement;
      firstTextEl.buffer_before_ts = change.timeBufferBefore;
      if (firstTextEl.buffer_before_ts < 0) {
        // todo make sure ts < end_ts still
        firstTextEl.ts -= firstTextEl.buffer_before_ts;
        firstTextEl.trim_start =
          (firstTextEl.trim_start || 0) - firstTextEl.buffer_before_ts;
        firstTextEl.buffer_before_ts = 0;
      }
    }
    if (lastTextIndex > 0) {
      const lastTextEl = transcriptionElementsMutable[
        lastTextIndex
      ] as TranscriptTextElement;
      lastTextEl.buffer_after_ts = change.timeBufferAfter;
      if (lastTextEl.buffer_after_ts < 0) {
        // todo make sure ts < end_ts still
        lastTextEl.end_ts += lastTextEl.buffer_after_ts;
        lastTextEl.trim_end =
          (lastTextEl.trim_end || 0) - lastTextEl.buffer_after_ts;
        lastTextEl.buffer_after_ts = 0;
      }
    }

    if (lastWordIndex < firstWordIndex) {
      return;
    }

    const shiftTs =
      endTs - startTs + change.timeBufferAfter + change.timeBufferBefore;

    for (
      let j = change.index + change.count;
      j < transcriptionElementsMutable.length;
      j++
    ) {
      const element = transcriptionElementsMutable[j];
      if (element.type === 'text') {
        element.ts += shiftTs;
        element.end_ts += shiftTs;
      }
    }

    this.fixCapitalizationAt(transcriptionElementsMutable, change.index);
    this.fixCapitalizationAt(
      transcriptionElementsMutable,
      change.index + change.count,
    );
  }
}

export default RestoreChangeHandler;
