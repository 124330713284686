type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const PasteTextIcon = (props: Props) => {
  const {
    width = '16',
    height = '14',
    viewBox = '0 0 14 14',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <g>
        <path
          id="Vector 4589"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.5 2.5h-2c-0.55228 0 -1 -0.44772 -1 -1 0 -0.552285 0.44772 -1 1 -1h2c0.55228 0 1 0.447715 1 1 0 0.55228 -0.44772 1 -1 1Z"
          strokeWidth="1"
        ></path>
        <path
          id="Vector"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.5 8.25h3"
          strokeWidth="1"
        ></path>
        <path
          id="Vector_2"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.5 10.75h3"
          strokeWidth="1"
        ></path>
        <path
          id="Vector 4591"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 12.5v-6c0 -0.55228 -0.4477 -1 -1 -1h-5c-0.55228 0 -1 0.44772 -1 1v6c0 0.5523 0.44772 1 1 1h5c0.5523 0 1 -0.4477 1 -1Z"
          strokeWidth="1"
        ></path>
        <path
          id="Vector 4590"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.5 1.5h-1c-0.552285 0 -1 0.44772 -1 1v7c0 0.5523 0.447715 1 1 1h3"
          strokeWidth="1"
        ></path>
        <path
          id="Vector 4596"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.5 1.5h1c0.55228 0 1 0.44772 1 1v1"
          strokeWidth="1"
        ></path>
      </g>
    </svg>
  );
};

export default PasteTextIcon;
