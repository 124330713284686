type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};
const MusicIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '22'}
      height={props.height || '22'}
      viewBox={props.viewBox || '0 0 22 22'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.07692 20.524C5.77626 20.524 7.15385 19.1954 7.15385 17.5564C7.15385 15.9176 5.77626 14.5889 4.07692 14.5889C2.37758 14.5889 1 15.9176 1 17.5564C1 19.1954 2.37758 20.524 4.07692 20.524Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9226 16.815C19.622 16.815 20.9995 15.4864 20.9995 13.8475C20.9995 12.2085 19.622 10.8799 17.9226 10.8799C16.2232 10.8799 14.8457 12.2085 14.8457 13.8475C14.8457 15.4864 16.2232 16.815 17.9226 16.815Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0005 13.8472V2.2143C20.9998 2.10077 20.9721 1.98892 20.9195 1.88733C20.8671 1.78574 20.7911 1.69713 20.6974 1.62833C20.6038 1.55953 20.4951 1.51237 20.3795 1.49048C20.264 1.46859 20.1449 1.47256 20.0312 1.50208L7.72353 4.79609C7.56088 4.83834 7.41711 4.93088 7.31428 5.05954C7.21145 5.18821 7.15524 5.34587 7.1543 5.50831V17.5567"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.1543 9.396L21.0005 5.68652"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MusicIcon;
