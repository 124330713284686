import styled from 'styled-components';
import AiProducerIcon from '../../svgs/AiProducerIcon';

type Props = {
  handleClick: () => void;
  text: string;
  Icon?: React.ReactNode;
  strokeColor?: string;
  unsetWidth?: boolean;
  disabled?: boolean;
};
const AIGenerateCard = (props: Props) => {
  const {
    Icon = <AiProducerIcon strokeColor={props.strokeColor} />,
    text,
    handleClick,
    disabled = false,
  } = props;
  return (
    <Card
      onClick={handleClick}
      unsetWidth={props.unsetWidth}
      disabled={disabled}
    >
      {Icon}
      {text}
    </Card>
  );
};

export default AIGenerateCard;

const Card = styled.button<{ unsetWidth?: boolean }>`
  display: flex;
  width: ${(props) => (props.unsetWidth ? 'unset' : '100%')};
  margin: 10px auto;
  flex: 1;
  height: 40px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #484848;
  cursor: pointer;
  background-color: #030419;
  color: #f3e9d7;
`;
