import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import _uniqBy from 'lodash/uniqBy';
import { v4 as uuid } from 'uuid';

import AIGenerateCard from '../common/AIGenerateCard';
import { videoCreator } from '../../stores/VideoCreatorStore';

import { PunchListItem, Story } from '../../types.ts/story';
import {
  AIProducerCard,
  //PunchListImageGeneration,
} from '../../types.ts/general';

import FeelingLuckyIcon from '../../svgs/FeelingLuckyIcon';
import SpinningLoading from '../SpinningLoading';
//import TriggerPunchListGenerateModal from './TriggerPunchListGenerateModal';
import { TranscriptElement } from '../../types.ts/transcript';
import ChatGPTService from '../../services/ChatGPTService';
import PunchListContentItem from './PunchListContentItem';
import GeneratedPunchListModal, {
  ModalItem,
} from '../common/GeneratedPunchListModal';

type Props = {
  story?: Story;
  selectedCard?: AIProducerCard;
};

const AIPhotoPunchListProducer: FC<Props> = observer((props) => {
  const [generatingMissingAiImage, setGeneratingMissingAiImage] =
    useState<boolean>(false);
  //const [openGeneratedImages, toggleGeneratedImages] =
  //  useState<PunchListImageGeneration | null>(null);
  const [selectedPunchListId, setSelectedPunchListId] = useState<string>();

  const [selectedAction, setSelectedAction] = useState<string>('photoHunt');
  const [modalItem, toggleModal] = useState<ModalItem>({
    item: null,
    archive: null,
  });

  const punchList = videoCreator.punchListManager.punchListItems;
  const punchListGenerateCount = videoCreator.punchListGenerateCount;

  const [loading, setLoading] = useState(false);

  function getPunchListItemPosition(
    punchListItem: PunchListItem,
    transcriptElements?: TranscriptElement[],
  ): {
    transcriptPosition: {
      startIndex: number;
      endIndex: number;
    };
    duration: number;
    startTime: number;
    endTime: number;
  } | null {
    let targetSentence = punchListItem.line;
    // loop through all transcript elements and track the index of the target sentence across multiple elements
    if (!transcriptElements || !targetSentence) return null;

    let targetSentenceCurrIndex = 0;
    let currElementIndex = 0;
    let targetSentenceElementStartIndex = 0;
    let targetSentenceElementEndIndex = 0;
    let found = false;
    while (currElementIndex < (transcriptElements?.length - 1 || 0)) {
      let currElementValue = transcriptElements[currElementIndex].value;

      // if element contains the start of the target sentence save it as the start of the index
      if (
        currElementValue &&
        transcriptElements[currElementIndex]?.state !== 'removed' &&
        transcriptElements[currElementIndex]?.state !== 'cut'
      ) {
        // console.log('comparing X', targetSentence.substring(targetSentenceCurrIndex, targetSentenceCurrIndex + currElementValue.length).length, 'X to X', currElementValue.length, 'X')
        if (
          targetSentence.substring(
            targetSentenceCurrIndex,
            targetSentenceCurrIndex + currElementValue.length,
          ) === currElementValue
        ) {
          if (targetSentenceCurrIndex === 0) {
            targetSentenceElementStartIndex = currElementIndex;
          }
          targetSentenceCurrIndex += currElementValue.length;
        } else {
          targetSentenceCurrIndex = 0;
        }
      }
      if (targetSentenceCurrIndex === targetSentence.length) {
        targetSentenceElementEndIndex = currElementIndex;
        found = true;
        // punchListItem.found = true;
        break;
      }
      currElementIndex++;
    }

    // RETURN if not found
    if (!found) return null;

    let ts = null;
    let end_ts = null;
    // loop through all transcript elements and track the index of the target sentence across multiple elements
    currElementIndex = targetSentenceElementStartIndex;
    while (currElementIndex <= targetSentenceElementEndIndex) {
      if (videoCreator.finalTranscriptionElements) {
        const currElementFinal =
          videoCreator.finalTranscriptionElements[currElementIndex];

        if (currElementFinal) {
          if (!ts && currElementFinal.ts) {
            ts = currElementFinal.ts;
          }
          if (currElementFinal.end_ts) {
            end_ts = currElementFinal.end_ts;
          }
        }
      }
      currElementIndex++;
    }

    // todo set into current video punchList
    // add changes to transcript
    videoCreator.videoTranscriptionProcessor.addPhotoHighlight(
      targetSentenceElementStartIndex,
      targetSentenceElementEndIndex,
      punchListItem.id!,
    );

    if (!ts || !end_ts) return null;

    return {
      transcriptPosition: {
        startIndex: targetSentenceElementStartIndex,
        endIndex: targetSentenceElementEndIndex,
      },
      duration: Math.min(8, end_ts - ts),
      startTime: ts,
      endTime: end_ts,
    };
  }

  // todo move to separate class (AIProducer?)
  const handleGeneratePhotoPunchList = async () => {
    try {
      setLoading(true);
      // SAVE CURRENT VIDEO BEFORE GENERATING PUNCHLIST

      // delete elments that are related to the current photo punchlist
      let punch_list_element_ids =
        punchList
          ?.filter((pld) => pld.sub_type !== 'manual')
          ?.map((pld) => pld.id) || [];

      let to_delete = videoCreator.state?.elements.filter(
        (e) => e.source.id && punch_list_element_ids.includes(e.source.id),
      );
      let to_delete_ids = to_delete?.map((e) => e.source.id);

      if (to_delete_ids) {
        for (let i = 0; i < to_delete_ids.length; i++) {
          const id = to_delete_ids[i];
          await videoCreator.deleteElementWithTranscription(id, false);
        }
      }
    } catch (error) {
      console.error('test Failed to delete elements', error);
      setLoading(false);
      return;
    }

    const gptService = new ChatGPTService();
    videoCreator.punchListLoading = true;
    try {
      await gptService.regenerateStreamResponse({
        key: 'Photo Punchlist',
        setLoading: (value) => {
          videoCreator.punchListLoading = value;
          setLoading(value);
          if (!value) {
            proceedWithResponseToGeneratePunchList(
              getPunchListFromRawResponse(),
            );
          }
        },
        version: '2',
      });
    } catch (error) {
      console.error('test Failed to generate punch list using stream', error);
      videoCreator.punchListLoading = false;
      setLoading(false);
      return;
    }
  };

  const proceedWithResponseToGeneratePunchList = async (
    punchListResponse: PunchListItem[] | undefined,
  ) => {
    if (!punchListResponse) return;

    const punchListData = punchListResponse?.map((obj: any) => {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          `${key.slice(0, 1).toLowerCase()}${key.slice(1)}`,
          value,
        ]),
      );
    }) as PunchListItem[];
    const formattedResponse = { punchList: punchListData };

    if (!formattedResponse) throw new Error('No response from GPT');

    // find the transcript elements that match the target sentence and set a flag to highlight in the
    // transcript
    const transcriptElements = videoCreator.finalTranscriptionElements;
    const maxTrack = videoCreator.getMaxTrack();
    const currTrack = maxTrack + 1;

    for (let i = 0; i < formattedResponse.punchList.length; i++) {
      let punchListItem = formattedResponse.punchList[i];

      let targetPosition = getPunchListItemPosition(
        punchListItem,
        transcriptElements,
      );

      if (targetPosition) {
        const track = videoCreator.getFreeMediaTrack(
          'image',
          targetPosition.duration,
          targetPosition.startTime,
        );
        await videoCreator.punchListManager.addPunchListItemToTimeline(
          punchListItem,
          targetPosition.duration,
          targetPosition.startTime,
          targetPosition.endTime,
          track || currTrack,
        );
        punchListItem.transcriptPosition = targetPosition.transcriptPosition;
      } else {
        console.log('could not add', punchListItem.line, 'in transcript');
        // punchListItem.found = false;
      }
    }

    formattedResponse.punchList = formattedResponse.punchList.filter(
      (punchListItem) => punchListItem.transcriptPosition,
    );

    videoCreator.punchListManager.punchListItems = (punchList || [])
      .filter((p) => p.sub_type === 'manual')
      .concat(formattedResponse.punchList)
      .sort(
        (a, b) =>
          a.transcriptPosition.startIndex - b.transcriptPosition.startIndex,
      );

    let punchListItems = formattedResponse.punchList;

    // todo set into current video
    // todo type safety
    videoCreator.currentVideo!.punchList = punchListItems;

    videoCreator.punchListGenerateCount = punchListGenerateCount + 1;
  };

  const handleMatchArtifacts = async () => {
    if (!props.story) {
      console.error('Feeling lucky error: no story found');
      return;
    }
    try {
      setGeneratingMissingAiImage(true);
      videoCreator.punchListManager.matchArtifacts((value) => {
        setGeneratingMissingAiImage(value);
      });
    } catch (error) {
      console.error('Feeling lucky error: ', error);
      setGeneratingMissingAiImage(false);
    }
  };

  const getPunchListFromRawResponse = (): PunchListItem[] | undefined => {
    let result: PunchListItem[] | undefined;
    const rawResponse =
      videoCreator?.contentStudioGeneratedContent?.['Photo Punchlist']?.content
        ?.response;
    if (rawResponse && typeof rawResponse === 'string') {
      try {
        const resp = JSON.parse(rawResponse);
        const args = JSON.parse(resp?.function_call?.arguments);
        result = args?.photo_punchlist;
      } catch (error) {
        console.log('Failed to parse punchlist response', error);
      }
    } else {
      result = rawResponse as PunchListItem[] | undefined;
    }
    return result?.map((p) => ({ ...p, id: uuid() }));
  };

  return (
    <Main>
      {(generatingMissingAiImage || videoCreator.punchListLoading) && (
        <SpinningLoading
          positionTop="20px"
          text={
            videoCreator.punchListLoading
              ? 'Hunting for photos to use'
              : undefined
          }
        />
      )}
      {videoCreator.savingStockPhoto && (
        <SpinningLoading positionTop="20px" text="Saving photo" />
      )}

      <ButtonGroup>
        <AIGenerateCard
          handleClick={async () => {
            setSelectedAction('photoHunt');
            await handleGeneratePhotoPunchList();
          }}
          unsetWidth={true}
          strokeColor={selectedAction !== 'photoHunt' ? '#484848' : ''}
          text={loading ? 'Generating...' : 'Photo Hunt'}
          disabled={loading}
        />
        <AIGenerateCard
          handleClick={async () => {
            setSelectedAction('feelingLucky');
            await handleMatchArtifacts();
          }}
          unsetWidth={true}
          text="Feeling Lucky"
          disabled={!punchList?.length || generatingMissingAiImage}
          Icon={
            <FeelingLuckyIcon
              strokeColor={punchList?.length ? '#17C964' : '#484848'}
            />
          }
        />
      </ButtonGroup>

      {/* {modalItem?.item && (
        <Modal
          isOpen={!!modalItem}
          onClose={() => toggleModal({ ...modalItem, item: null })}
          paddingHorizontal="0"
        >
          <TriggerPunchListGenerateModal
            modalItem={modalItem.item}
            onClose={() => toggleModal({ ...modalItem, item: null })}
            toggleGeneratedImages={toggleGeneratedImages}
            openGeneratedImages={openGeneratedImages}
          />
        </Modal>
      )} */}
      <GeneratedPunchListModal
        modalItem={modalItem}
        toggleModal={toggleModal}
      />
      <Content>
        {punchList
          ?.map((punchListItem) => (
            <PunchListContentItem
              key={punchListItem.id}
              punchListItem={punchListItem}
              selectedPunchListId={selectedPunchListId}
              setSelectedPunchListId={setSelectedPunchListId}
              toggleModal={toggleModal}
            />
          ))
          .filter(Boolean)}
      </Content>
    </Main>
  );
});

export default AIPhotoPunchListProducer;

const Main = styled.div``;
const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`;
