type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const CloseIcon = (props: Props) => {
  const {
    width = '14',
    height = '14',
    viewBox = '0 0 14 14',
    strokeColor = '#F3E9D7',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path d="M1 1L13 13M1 13L13 1" stroke={strokeColor} />
    </svg>
  );
};

export default CloseIcon;
