function snakeToCamel(str: string): string {
  return str.replace(/_([a-z])/g, function (_match, letter) {
    return letter.toUpperCase();
  });
}

// Recursive function to transform the object keys
export default function transformKeysToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    // If it's an array, recursively apply the function to each item
    return obj.map((item) => transformKeysToCamelCase(item));
  } else if (obj !== null && typeof obj === 'object') {
    // If it's an object, recursively transform each key
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = snakeToCamel(key);
      acc[camelKey] = transformKeysToCamelCase(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj; // Return the value if it's neither an array nor an object
}
