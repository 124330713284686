type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  strokeWidth?: string;
};

const CircleCheckIcon = (props: Props) => {
  const {
    width = '22',
    height = '21',
    viewBox = '0 0 22 21',
    strokeColor = '#03041A',
    strokeWidth = '1.5',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M1.62549 10.375C1.62549 12.8614 2.61321 15.246 4.37136 17.0041C6.12952 18.7623 8.51408 19.75 11.0005 19.75C13.4869 19.75 15.8715 18.7623 17.6296 17.0041C19.3878 15.246 20.3755 12.8614 20.3755 10.375C20.3755 7.8886 19.3878 5.50403 17.6296 3.74587C15.8715 1.98772 13.4869 1 11.0005 1C8.51408 1 6.12952 1.98772 4.37136 3.74587C2.61321 5.50403 1.62549 7.8886 1.62549 10.375Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M6.54736 11.0781L9.82861 13.8906L15.4536 6.85938"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default CircleCheckIcon;
