import React from 'react'

type Props = {
    width?: string;
    height?: string;
    viewBox?: string;
    strokeColor?: string;
    strokeWidth?: string;
  };
const CloudIcon = (props: Props) => {
    const {
        width = '41',
        height = '40',
        viewBox = '0 0 41 40',
        strokeColor = '#F3E9D7',
        strokeWidth= '2'
      } = props;
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none">
            <path 
                d="M27.309 26.1607H31.109C31.109 26.1607 37.9173 25.2724 37.9173 18.5876C37.9181 17.5704 37.7135 16.5636 37.3158 15.6274C36.9181 14.6912 36.3355 13.8449 35.603 13.1393C34.8704 12.4336 34.0029 11.8831 33.0525 11.5207C32.1021 11.1583 31.0883 10.9915 30.0719 11.0303C29.0692 8.90213 27.386 7.16864 25.2883 6.10366C23.1906 5.03868 20.7978 4.70293 18.488 5.14942C16.1782 5.59592 14.0829 6.79921 12.5332 8.56925C10.9835 10.3393 10.0677 12.5752 9.93032 14.9237C9.10404 14.75 8.24941 14.7627 7.42866 14.9609C6.60792 15.1592 5.84172 15.538 5.18587 16.0698C4.53002 16.6016 4.00103 17.2729 3.63742 18.035C3.27382 18.797 3.08475 19.6306 3.08398 20.4749C3.08398 26.2462 9.14182 26.1559 9.14182 26.1559H13.6749" 
                stroke={strokeColor} 
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M20.5 17.4526V34.8693" 
                stroke={strokeColor} 
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M25.25 22.2026L20.5 17.4526L15.75 22.2026" 
                stroke={strokeColor} 
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default CloudIcon