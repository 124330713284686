import { LogLevel, buildClient } from '@datocms/cma-client-browser';
import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';

type Headers = {
  [key: string]: string;
};

export function createGQLClient({
  includeDrafts,
  excludeInvalid,
  environment,
  authToken,
}: {
  includeDrafts?: boolean;
  excludeInvalid?: boolean;
  environment?: string;
  authToken: string;
}) {
  const headers: Headers = {
    authorization: `Bearer ${authToken}`,
  };

  if (includeDrafts) {
    headers['X-Include-Drafts'] = 'true';
  }

  if (excludeInvalid) {
    headers['X-Exclude-Invalid'] = 'true';
  }

  if (environment) {
    headers['X-Environment'] = environment;
  }

  return new GraphQLClient('https://graphql.datocms.com', { headers });
}

export function request({
  query,
  variables,
  includeDrafts,
  excludeInvalid,
  environment,
}: {
  query: RequestDocument;
  variables?: Variables;
  includeDrafts?: boolean;
  excludeInvalid?: boolean;
  environment?: string;
}) {
  const client = createGQLClient({
    includeDrafts,
    excludeInvalid,
    environment,
    authToken: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!,
  });
  return client.request(query, variables);
}
