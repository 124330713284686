export enum VideoResolution {
  High = 'high',
  Low = 'low',
  Default = 'default',
  Medium = 'medium',
  Original = 'original',
}

export enum AspectRatio {
  AR_16_9 = '16:9',
  AR_1_1 = '1:1',
  AR_9_16 = '9:16',
}

export enum TranscriptionStatus {
  None = 'none',
  Loading = 'loading',
  Failed = 'failed',
  Loaded = 'loaded',
  GenerationFailed = 'generation_failed',
}

export enum SubtitleStatus {
  None = 'none',
  Loading = 'loading',
  Loaded = 'loaded',
  Failed = 'failed',
}
