import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { videoCreator } from '../../stores/VideoCreatorStore';
import ChatGPTService from '../../services/ChatGPTService';

import AutoResizeableMultilineInput from './AutoResizeableMultilineInput';
import CreateIcon from '../../svgs/CreateIcon';
import CheckIconAlt from '../../svgs/CheckIconAlt';

type Props = {
  setIsLoading: (e: boolean) => void;
  supportedTitle: 'Blog' | 'Email';
  setImageSize: Dispatch<
    SetStateAction<Record<string, Record<'width' | 'height', number>> | null>
  >;
  gptService: ChatGPTService;
};

const CreateBlogUI = observer((props: Props) => {
  const [value, setValue] = useState('');
  const [shouldUseBrandVoice, setShouldUseBrandVoice] = useState(false);

  const brandVoice = videoCreator.organization?.branding?.brandVoice;
  useEffect(() => {
    if (brandVoice) setShouldUseBrandVoice(true);
  }, [Boolean(brandVoice)]);

  const handleClick = async () => {
    props.setImageSize(null);
    props.setIsLoading(true);

    await props.gptService.regenerateStreamResponse({
      key: props.supportedTitle,
      setLoading: props.setIsLoading,
      brandText: value,
      brandVoice: shouldUseBrandVoice ? brandVoice : undefined,
    });
  };

  return (
    <Main>
      <Title>Create a blog</Title>
      <SubTitle>Turn your story into engaging content.</SubTitle>
      <AutoResizeableMultilineInput
        placeholder="Describe your voice or provide instructions (Optional)"
        getValue={(text) => setValue(text)}
      />

      <CheckContainer
        onClick={
          brandVoice ? () => setShouldUseBrandVoice((cur) => !cur) : undefined
        }
        disabled={!brandVoice}
        data-tooltip-id="brand-voice-tooltip"
        data-tooltip-content={
          brandVoice ? undefined : 'Add your brand voice on the Brand Kit page'
        }
      >
        <CheckBox isChecked={shouldUseBrandVoice} role="button">
          {shouldUseBrandVoice && <CheckIconAlt />}
        </CheckBox>
        <div className="checkbox-text">Use my Brand Voice</div>
      </CheckContainer>
      <Tooltip
        id="brand-voice-tooltip"
        style={{
          width: '150px',
          color: '#f3e9d7',
          textAlign: 'center',
          padding: '8px',
          borderRadius: '4px',
          fontSize: '12px',
          lineHeight: '120%',
          zIndex: '10',
        }}
      />
      <Button onClick={handleClick}>
        <Icon>
          <CreateIcon />
        </Icon>
        Generate
      </Button>
    </Main>
  );
});

export default CreateBlogUI;

const Main = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
`;
const Title = styled.h2`
  color: #f3e9d7;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;
const SubTitle = styled.span`
  color: #f3e9d7;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 140%;
  margin-bottom: 24px;
`;
const Icon = styled.span`
  color: #03041a;
`;
const CheckContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px;
  margin-top: 16px;
  cursor: pointer;
  &,
  * {
    box-sizing: border-box;
  }
  .checkbox-text {
    color: #f3e9d7;
    font-size: 12px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
`;
const CheckBox = styled.div<{ isChecked: boolean }>`
  border: 0;
  outline: 0;
  background-color: ${(props) => (props.isChecked ? '#4AD067' : 'transparent')};
  width: 16px;
  height: 16px;
  border-radius: 2px;
  overflow: hidden;
  border: ${(props) => !props.isChecked && '1px solid #848484'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button = styled.button`
  display: flex;
  width: 240px;
  height: 40px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #17c964;
  color: #03041a;
  font-weight: bold;
  outline: 0;
  border: 0;
  margin: 16px auto;
  cursor: pointer;
`;
