import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import MuxPlayer from '@mux/mux-player-react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import DownloadIcon from '../../svgs/DownloadIcon';
import { handleDownloadMedia, handleRemoveMedia } from '../../utility/general';
import DeleteIcon from '../../svgs/DeleteIcon';
import {
  ActionButton,
  ActionsWrapper,
  ShareModalAnimate,
} from '../../styles/mainStyle';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { MediaSubType } from '../../types.ts/general';
import { useFlagsCombination } from '../../utility/useFlagsCombination';

type SubType = Omit<MediaSubType, MediaSubType.all>;
type Media = {
  id: string;
  src: string;
  description?: string;
  subType: SubType;
  type: 'video' | 'photo';
  playbackId?: string;
  poster?: string;
  createdAt: Date;
};

type Props = {
  media: Media;
  isInDownloadStream: boolean;
};

const getBorderStyle = (subType: SubType) => {
  switch (subType) {
    case MediaSubType.artifact:
      return css`
        border: 1px solid #f2d093;
        color: #f2d093;
      `;
    case MediaSubType.stock:
      return css`
        border: 1px solid #a5a6f6;
        color: #a5a6f6;
      `;
    case MediaSubType.ai:
      return css`
        border: 1px solid #f178b6;
        color: #f178b6;
      `;
    default:
      return css`
        border: 1px solid #f2d093;
        color: #f2d093;
      `;
  }
};

const MediaCard = (props: Props) => {
  const { media } = props;
  const buttonRef = useRef<HTMLDivElement>(null);
  const [openDropdown, toggleDropdown] = useState<boolean>(false);
  const [downloading, setDownloading] = useState(false);
  const { isDatoUser } = useFlagsCombination();

  useOutsideAlerter(buttonRef, () => {
    toggleDropdown(false);
  });

  async function downloadMedia(
    e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    media: Media,
  ) {
    e.preventDefault();
    e.stopPropagation();
    try {
      const url = media.src;
      if (!url) return;
      toggleDropdown(false);
      setDownloading(true);
      const file = { url: media.src, title: media?.description, id: media.id };
      await handleDownloadMedia(e, file);
    } catch (error) {
      console.log(error);
    } finally {
      toggleDropdown(false);
      setDownloading(false);
    }
  }

  return (
    <Card key={media.id}>
      <div className="top">
        <CardButton subType={media.subType}>
          {media.subType === MediaSubType.ai ? 'Ai' : media.subType}
        </CardButton>

        {downloading || props.isInDownloadStream ? (
          <Animate />
        ) : (
          <Ellipsis ref={buttonRef} onClick={() => toggleDropdown(true)}>
            <EllipsisIcon width="15" fillColor="#F2D093" />

            <Actions isVisible={openDropdown}>
              <DownloadPhoto onClick={(e) => downloadMedia(e, media)}>
                <span>Download</span>
                <div className="icon">
                  <DownloadIcon
                    width="16"
                    height="16"
                    strokeColor="currentColor"
                  />
                </div>
              </DownloadPhoto>

              {isDatoUser && (
                <DeleteButton
                  onClick={() =>
                    handleRemoveMedia(media.id, media.subType, toggleDropdown)
                  }
                >
                  <span>Delete</span>
                  <div className="icon">
                    <DeleteIcon strokeColor="currentColor" />
                  </div>
                </DeleteButton>
              )}
            </Actions>
          </Ellipsis>
        )}
      </div>
      <Source>
        {media.type === 'video' ? (
          <MuxPlayer
            // style={playerStyle}
            streamType="on-demand"
            playbackId={media.playbackId}
            src={media.src}
            poster={media.poster}
          />
        ) : (
          <Image src={media.src} />
        )}
      </Source>
      <div className="bottom">
        <Description>
          {media.description?.slice(0, 1).toUpperCase()}
          {media.description?.slice(1)}
        </Description>
      </div>
    </Card>
  );
};

export default MediaCard;

const Main = styled.main``;

const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tabs = styled.nav`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 750px) {
    margin-top: 10px;
    width: 100%;
    overflow-x: scroll;
  }
`;

const Download = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  &:active svg {
    background-color: #f3e9d7;
  }
  margin-left: auto;
`;

const TabButton = styled.button<{ isSelected?: boolean }>`
  border-radius: 4px;
  background-color: transparent;
  outline: 0;
  border: 1px solid #484848;
  color: #484848;
  cursor: pointer;
  padding: 8px;
  ${(props) =>
    props.isSelected &&
    css`
      border-color: #f2d093;
      background-color: #f2d093;
    `}
`;
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
  @media only screen and (max-width: 750px) {
    justify-content: center;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #484848;
  width: 392px;

  padding: 28px 24px;
  object-fit: cover;
  .top {
    display: flex;
    align-items: center;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media only screen and (max-width: 1300px) {
    width: 300px;
  }
`;

const Description = styled.span`
  flex: 1;
  font-size: 12px;
`;

const CardButton = styled.button<{ subType: Media['subType'] }>`
  margin-right: auto;
  border-radius: 4px;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  padding: 4px 8px;
  height: 28px;

  ${(props) => getBorderStyle(props.subType)}
`;

const Source = styled.div`
  height: 200px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const Image = styled.img`
  height: 100%;
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  object-position: top;
`;

const Video = styled.video``;

const Ellipsis = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Actions = styled(ActionsWrapper)`
  left: 0;
  right: unset;
  top: 10px;
`;

const DownloadPhoto = styled(ActionButton)`
  gap: 20px;
  padding: 12px;
`;

// const SaveButton = styled(ActionButton)``;

const DeleteButton = styled(ActionButton)`
  gap: 20px;
  padding: 12px;
  &:hover {
    background-color: #ef5d6f;
  }
`;

const Animate = styled(ShareModalAnimate)``;
