import styled from 'styled-components';

import PlusIcon from '../../svgs/PlusIcon';

type Props = {
  className?: string;
  size?: number;
  iconSize?: number;
  onClick?: () => void;
};

const AddTile = (props: Props) => {
  const { className, size = 72, iconSize = 40, onClick } = props;
  return (
    <Container
      role="button"
      onClick={onClick}
      size={size}
      className={className}
    >
      <PlusIcon
        width={`${iconSize}`}
        height={`${iconSize}`}
        strokeColor="#f2d093"
      />
    </Container>
  );
};

const Container = styled.div<{ size: number }>`
  background: #484848;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.2s;
  &:hover {
    background: #848484;
    cursor: pointer;
  }
`;

export default AddTile;
