import styled from 'styled-components';

const PrimaryActionButton = styled.button.attrs(
  (props: { disabled: boolean }) => props,
)<{ isActivated?: boolean }>`
  outline: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  background-color: #17c964;
  justify-content: center;
  font-weight: 700;
  flex: 1;
  opacity: ${(props) => (props.isActivated ? '1' : '0.9')};
  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    color: #030419;
    opacity: 0.6;
  `}
`;

export default PrimaryActionButton;
