import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { videoCreator } from '../../stores/VideoCreatorStore';
import GeneratedPunchListModal, { ModalItem } from './GeneratedPunchListModal';

const GlobalGeneratedPunchListModal = observer(() => {
  const element = videoCreator.openPhotoElementReplacementModal?.element;

  const [modalItem, toggleModal] = useState<ModalItem>({
    item: null,
    archive: null,
  });

  useEffect(() => {
    if (element && !modalItem.item) {
      const item = videoCreator.punchListManager.findPunchListItemById(
        element.source.id,
      );
      if (item) {
        toggleModal({ item, archive: null });
      }
    }
    if (!element && modalItem.item) {
      toggleModal({ item: null, archive: null });
    }
  }, [element]);

  return (
    <GeneratedPunchListModal
      modalItem={modalItem}
      toggleModal={toggleModal}
      afterReplacement={() => {
        videoCreator.openPhotoElementReplacementModal = null;
      }}
    />
  );
});

export default GlobalGeneratedPunchListModal;
