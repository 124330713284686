import React, { Dispatch, SetStateAction } from 'react';
import { Music } from '../../types.ts/story';
import styled from 'styled-components';

type Props = {
  music: Music[] | undefined;
  setSelectedGenre: Dispatch<SetStateAction<string>>;
};

const categories: Record<string, string> = {
  chill: 'Chill.png',
  dramatic: 'dramatic.png',
  exciting: 'exciting.png',
  groovy: 'groovy.png',
  happy: 'happy.png',
  hopeful: 'hopeful.png',
  inspirational: 'Inspirational.png',
  love: 'love.png',
  peaceful: 'peaceful.png',
  playful: 'playful.png',
  pop: 'pop.png',
  romantic: 'romantic.png',
  sad: 'sad.png',
  serious: 'serious.png',
  tense: 'tense.png',
  uplifting: 'uplifting.png',
};
const MusicTheme = (props: Props) => {
  return (
    <Theme>
      <>
        {props.music?.map((m) => (
          <ThemeCards
            key={m.id}
            genre={m.genre.toLowerCase()}
            onClick={() => props.setSelectedGenre(m.genre)}
          >
            {m.genre}
          </ThemeCards>
        ))}
      </>
    </Theme>
  );
};

export default MusicTheme;

const Theme = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  & > span {
    font-size: 14px;
  }
  margin-bottom: 20px;
`;

const ThemeCards = styled.div<{ genre: string }>`
  display: flex;
  width: 95px;
  height: 95px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url('/mood_thumbnails/${(props) => categories[props.genre]}');

  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #484848;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  &:hover {
    background: url('/mood_thumbnails/${(props) => categories[props.genre]}');
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
