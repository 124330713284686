import * as Utils from '@datocms/rest-client-utils';
import ApiClient from './ApiClient';
import {
  SimpleSchemaTypes,
  SchemaTypes,
  uploadFileOrBlobToS3,
} from '@datocms/cma-client-browser';

import {
  CancelablePromise,
  CanceledPromiseError,
  makeCancelablePromise,
} from '@datocms/rest-client-utils';
import { CreateUploadFromFileOrBlobSchema } from '@datocms/cma-client-browser/dist/types/resources/Upload';

export type OnProgressUploadingFileInfo = {
  type: 'UPLOADING_FILE';
  payload: {
    progress: number;
  };
};

export type OnProgressRequestingUploadUrlInfo = {
  type: 'REQUESTING_UPLOAD_URL';
  payload: {
    filename: string;
  };
};

export type OnProgressInfo =
  | OnProgressRequestingUploadUrlInfo
  | OnProgressUploadingFileInfo;

export type Options = {
  filename?: string;
  onProgress?: (info: OnProgressInfo) => void;
};

export function uploadFileOrBlobAndReturnPath(
  client: ApiClient,
  fileOrBlob: File | Blob,
  options: Options = {},
): CancelablePromise<string> {
  if (!(options.filename && 'name' in fileOrBlob)) {
    throw new Error('Missing filename, please provide it as an option!');
  }

  const filename = options.filename || fileOrBlob.name;

  let isCanceledBeforeUpload = false;
  let uploadPromise: CancelablePromise<void> | undefined = undefined;

  return makeCancelablePromise(
    async () => {
      if (options.onProgress) {
        options?.onProgress({
          type: 'REQUESTING_UPLOAD_URL',
          payload: { filename },
        });
      }

      const { id, url } = await client.uploadRequest.create({ filename });

      if (isCanceledBeforeUpload) {
        throw new CanceledPromiseError();
      }

      if (options.onProgress) {
        options.onProgress({
          type: 'UPLOADING_FILE',
          payload: {
            progress: 0,
          },
        });
      }

      uploadPromise = uploadFileOrBlobToS3(fileOrBlob, url, options);

      await uploadPromise;

      return id;
    },
    () => {
      if (uploadPromise) {
        uploadPromise.cancel();
      } else {
        isCanceledBeforeUpload = true;
      }
    },
  );
}

export default class ApiUpload {
  static readonly TYPE: 'upload' = 'upload';
  client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  /**
   * Create a new upload using a browser File/Blob object
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/create
   */
  createFromFileOrBlob(
    body: CreateUploadFromFileOrBlobSchema,
  ): CancelablePromise<SimpleSchemaTypes.Upload> {
    let isCanceledBeforeUpload = false;
    let uploadPromise: CancelablePromise<string> | undefined;

    return makeCancelablePromise(
      async () => {
        if (isCanceledBeforeUpload) {
          throw new CanceledPromiseError();
        }

        const { fileOrBlob, filename, onProgress, ...other } = body;

        uploadPromise = uploadFileOrBlobAndReturnPath(this.client, fileOrBlob, {
          filename,
          onProgress,
        });

        const path = await uploadPromise;

        if (onProgress) {
          onProgress({ type: 'CREATING_UPLOAD_OBJECT' });
        }

        return this.create({ ...other, path });
      },
      () => {
        if (uploadPromise) {
          uploadPromise.cancel();
        } else {
          isCanceledBeforeUpload = true;
        }
      },
    );
  }

  /**
   * Create a new upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/create
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  create(body: SimpleSchemaTypes.UploadCreateSchema) {
    return this.rawCreate(
      Utils.serializeRequestBody<SchemaTypes.UploadCreateSchema>(body, {
        type: 'upload',
        attributes: [
          'path',
          'copyright',
          'author',
          'notes',
          'default_field_metadata',
          'tags',
        ],
        relationships: [],
      }),
    ).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadCreateJobSchema>(
        body,
      ),
    );
  }

  /**
   * Create a new upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/create
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawCreate(
    body: SchemaTypes.UploadCreateSchema,
  ): Promise<SchemaTypes.UploadCreateJobSchema> {
    return this.client.request<SchemaTypes.UploadCreateJobSchema>({
      method: 'POST',
      url: '/uploads',
      body,
    });
  }

  /**
   * List all uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/instances
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  list(queryParams?: SimpleSchemaTypes.UploadInstancesHrefSchema) {
    return this.rawList(queryParams).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadInstancesTargetSchema>(
        body,
      ),
    );
  }

  /**
   * List all uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/instances
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawList(
    queryParams?: SchemaTypes.UploadInstancesHrefSchema,
  ): Promise<SchemaTypes.UploadInstancesTargetSchema> {
    return this.client.request<SchemaTypes.UploadInstancesTargetSchema>({
      method: 'GET',
      url: '/uploads',
      queryParams,
    });
  }

  /**
   * Async iterator to auto-paginate over elements returned by list()
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/instances
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  async *listPagedIterator(
    queryParams?: SimpleSchemaTypes.UploadInstancesHrefSchema,
    iteratorOptions?: Utils.IteratorOptions,
  ) {
    for await (const element of this.rawListPagedIterator(
      queryParams,
      iteratorOptions,
    )) {
      yield Utils.deserializeJsonEntity<
        SimpleSchemaTypes.UploadInstancesTargetSchema[0]
      >(element);
    }
  }

  /**
   * Async iterator to auto-paginate over elements returned by rawList()
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/instances
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawListPagedIterator(
    queryParams?: SchemaTypes.UploadInstancesHrefSchema,
    iteratorOptions?: Utils.IteratorOptions,
  ) {
    return Utils.rawPageIterator<
      SchemaTypes.UploadInstancesTargetSchema['data'][0]
    >(
      {
        defaultLimit: 30,
        maxLimit: 500,
      },
      (page: SchemaTypes.UploadInstancesHrefSchema['page']) =>
        this.rawList({ ...queryParams, page }),
      iteratorOptions,
    );
  }

  /**
   * Retrieve an upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/self
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  find(uploadId: string | SimpleSchemaTypes.UploadData) {
    return this.rawFind(Utils.toId(uploadId)).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadSelfTargetSchema>(
        body,
      ),
    );
  }

  /**
   * Retrieve an upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/self
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawFind(uploadId: string): Promise<SchemaTypes.UploadSelfTargetSchema> {
    return this.client.request<SchemaTypes.UploadSelfTargetSchema>({
      method: 'GET',
      url: `/uploads/${uploadId}`,
    });
  }

  /**
   * Delete an upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  destroy(uploadId: string | SimpleSchemaTypes.UploadData) {
    return this.rawDestroy(Utils.toId(uploadId)).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadDestroyTargetSchema>(
        body,
      ),
    );
  }

  /**
   * Delete an upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawDestroy(uploadId: string): Promise<SchemaTypes.UploadDestroyTargetSchema> {
    return this.client.request<SchemaTypes.UploadDestroyTargetSchema>({
      method: 'DELETE',
      url: `/uploads/${uploadId}`,
    });
  }

  /**
   * Update an upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/update
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  update(
    uploadId: string | SimpleSchemaTypes.UploadData,
    body: SimpleSchemaTypes.UploadUpdateSchema,
  ) {
    return this.rawUpdate(
      Utils.toId(uploadId),
      Utils.serializeRequestBody<SchemaTypes.UploadUpdateSchema>(body, {
        id: Utils.toId(uploadId),
        type: 'upload',
        attributes: [
          'path',
          'basename',
          'copyright',
          'author',
          'notes',
          'tags',
          'default_field_metadata',
        ],
        relationships: ['creator'],
      }),
    ).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadUpdateJobSchema>(
        body,
      ),
    );
  }

  /**
   * Update an upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/update
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawUpdate(
    uploadId: string,
    body: SchemaTypes.UploadUpdateSchema,
  ): Promise<SchemaTypes.UploadUpdateJobSchema> {
    return this.client.request<SchemaTypes.UploadUpdateJobSchema>({
      method: 'PUT',
      url: `/uploads/${uploadId}`,
      body,
    });
  }

  /**
   * Batch add tags to uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/batch_add_tags
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   *
   * @deprecated This API call is to be considered private and might change without notice
   */
  batchAddTags(
    body: SimpleSchemaTypes.UploadBatchAddTagsSchema,
    queryParams?: SimpleSchemaTypes.UploadBatchAddTagsHrefSchema,
  ) {
    return this.rawBatchAddTags(
      Utils.serializeRequestBody<SchemaTypes.UploadBatchAddTagsSchema>(body, {
        type: 'upload',
        attributes: ['tags'],
        relationships: [],
      }),
      queryParams,
    ).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadBatchAddTagsJobSchema>(
        body,
      ),
    );
  }

  /**
   * Batch add tags to uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/batch_add_tags
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   *
   * @deprecated This API call is to be considered private and might change without notice
   */
  rawBatchAddTags(
    body: SchemaTypes.UploadBatchAddTagsSchema,
    queryParams?: SchemaTypes.UploadBatchAddTagsHrefSchema,
  ): Promise<SchemaTypes.UploadBatchAddTagsJobSchema> {
    return this.client.request<SchemaTypes.UploadBatchAddTagsJobSchema>({
      method: 'PUT',
      url: '/uploads/batch-add-tags',
      body,
      queryParams,
    });
  }

  /**
   * Delete multiple uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/batch_destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   *
   * @deprecated This API call is to be considered private and might change without notice
   */
  batchDestroy(queryParams?: SimpleSchemaTypes.UploadBatchDestroyHrefSchema) {
    return this.rawBatchDestroy(queryParams).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadBatchDestroyJobSchema>(
        body,
      ),
    );
  }

  /**
   * Delete multiple uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/batch_destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   *
   * @deprecated This API call is to be considered private and might change without notice
   */
  rawBatchDestroy(
    queryParams?: SchemaTypes.UploadBatchDestroyHrefSchema,
  ): Promise<SchemaTypes.UploadBatchDestroyJobSchema> {
    return this.client.request<SchemaTypes.UploadBatchDestroyJobSchema>({
      method: 'DELETE',
      url: '/uploads/batch-destroy',
      queryParams,
    });
  }

  /**
   * Referenced records
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/references
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  references(
    uploadId: string | SimpleSchemaTypes.UploadData,
    queryParams?: SimpleSchemaTypes.UploadReferencesHrefSchema,
  ) {
    return this.rawReferences(Utils.toId(uploadId), queryParams).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadReferencesTargetSchema>(
        body,
      ),
    );
  }

  /**
   * Referenced records
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/references
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawReferences(
    uploadId: string,
    queryParams?: SchemaTypes.UploadReferencesHrefSchema,
  ): Promise<SchemaTypes.UploadReferencesTargetSchema> {
    return this.client.request<SchemaTypes.UploadReferencesTargetSchema>({
      method: 'GET',
      url: `/uploads/${uploadId}/references`,
      queryParams,
    });
  }

  /**
   * Add tags to assets in bulk
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/bulk_tag
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  bulkTag(body: SimpleSchemaTypes.UploadBulkTagSchema) {
    return this.rawBulkTag(
      Utils.serializeRequestBody<SchemaTypes.UploadBulkTagSchema>(body, {
        type: 'upload_bulk_tag_operation',
        attributes: ['tags'],
        relationships: ['uploads'],
      }),
    ).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadBulkTagJobSchema>(
        body,
      ),
    );
  }

  /**
   * Add tags to assets in bulk
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/bulk_tag
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawBulkTag(
    body: SchemaTypes.UploadBulkTagSchema,
  ): Promise<SchemaTypes.UploadBulkTagJobSchema> {
    return this.client.request<SchemaTypes.UploadBulkTagJobSchema>({
      method: 'POST',
      url: '/uploads/bulk/tag',
      body,
    });
  }

  /**
   * Destroy uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/bulk_destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  bulkDestroy(body: SimpleSchemaTypes.UploadBulkDestroySchema) {
    return this.rawBulkDestroy(
      Utils.serializeRequestBody<SchemaTypes.UploadBulkDestroySchema>(body, {
        type: 'upload_bulk_destroy_operation',
        attributes: [],
        relationships: ['uploads'],
      }),
    ).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadBulkDestroyJobSchema>(
        body,
      ),
    );
  }

  /**
   * Destroy uploads
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload/bulk_destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawBulkDestroy(
    body: SchemaTypes.UploadBulkDestroySchema,
  ): Promise<SchemaTypes.UploadBulkDestroyJobSchema> {
    return this.client.request<SchemaTypes.UploadBulkDestroyJobSchema>({
      method: 'POST',
      url: '/uploads/bulk/destroy',
      body,
    });
  }
}
