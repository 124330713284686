import { useEffect, useState } from 'react';
import FileUpload from '../common/FileUpload';
import PhotoIcon from '../../svgs/PhotoIcon';
import styled from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import FileListItem from '../common/FileListItem';
import SpinningLoading from '../SpinningLoading';
import { SidebarOption } from '../../types.ts/general';
import SearchInput from './SearchInput';
import PrimaryActionButton from './PrimaryActionButton';

enum VideoTab {
  story = 'Story',
  organization = 'Organization',
}

type Video = {
  id: string;
  title: string;
  src: string;
  duration: number | undefined;
  thumbnailUrl: string | undefined;
};

const VideoArtifacts = () => {
  const [uploadButtonActive, setUploadButtonActive] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [tab, setTab] = useState<VideoTab>(VideoTab.story);

  const storyVideos = videoCreator?.story?.storyArtifactsVideo?.map((a) => ({
    id: a.id,
    title: a.title,
    src: a.url,
    duration: a.video?.duration,
    thumbnailUrl: a.video?.thumbnailUrl,
  }));

  const organizationVideos =
    (videoCreator.story?._allReferencingShowcases || []).flatMap((album) =>
      (album.organizationArtifactsVideo || []).map((v) => ({
        id: v.id,
        title: v.title,
        src: v.url,
        duration: v.video?.duration,
        thumbnailUrl: v.video?.thumbnailUrl,
      })),
    ) || [];

  const [videos, setVideos] = useState<Video[] | undefined>(storyVideos);

  useEffect(() => {
    if (tab === VideoTab.story) {
      setVideos(storyVideos);
    } else {
      setVideos(organizationVideos);
    }
  }, [tab]);

  const handleSearchArtifacts = (inputString: string) => {
    let resource;
    if (tab === VideoTab.story) {
      resource = videoCreator?.story?.storyArtifactsVideo
        ?.map((a) => ({
          id: a.id,
          title: a.title,
          src: a.url,
          duration: a.video?.duration,
          thumbnailUrl: a.video?.thumbnailUrl,
        }))
        .filter((a) =>
          a.title.toLowerCase().includes(inputString.toLowerCase()),
        );
    } else {
      resource = (videoCreator.story?._allReferencingShowcases || [])
        .flatMap((album) =>
          (album.organizationArtifactsVideo || []).map((v) => ({
            id: v.id,
            title: v.title,
            src: v.url,
            duration: v.video?.duration,
            thumbnailUrl: v.video?.thumbnailUrl,
          })),
        )
        .filter((a) =>
          a.title.toLowerCase().includes(inputString.toLowerCase()),
        );
    }
    setVideos(resource);
  };

  async function handleRemoveVideo(mediaId: string) {
    if (tab === VideoTab.story) {
      let artifactVideos =
        videoCreator.story?.storyArtifactsVideo?.filter(
          (artifact) => artifact.id !== mediaId,
        ) || [];

      videoCreator.story!.storyArtifactsVideo = artifactVideos;
      setVideos(
        artifactVideos.map((v) => ({
          id: v.id,
          title: v.title,
          src: v.url,
          duration: v.video?.duration,
          thumbnailUrl: v.video?.thumbnailUrl,
        })),
      );
      await videoCreator.updateStory(videoCreator.story!);
    } else {
      const albumIdx = videoCreator.story?._allReferencingShowcases?.findIndex(
        (a) => a.organizationArtifactsVideo?.some((v) => v.id === mediaId),
      );
      if (albumIdx !== undefined && albumIdx > -1) {
        videoCreator.story!._allReferencingShowcases[
          albumIdx
        ].organizationArtifactsVideo =
          videoCreator.story!._allReferencingShowcases[
            albumIdx
          ].organizationArtifactsVideo?.filter((a) => a.id !== mediaId) || [];

        setVideos(videos?.filter((v) => v.id !== mediaId) || []);
        await videoCreator.albumRepository?.update(
          videoCreator.story!._allReferencingShowcases[albumIdx]!,
        );
      }
    }
  }

  const renderTopActions = () => {
    return (
      <VideoTopAction>
        <SearchInput
          placeholder="Search"
          handleAction={handleSearchArtifacts}
          radius="5px"
          iconRight
        />
        <FileUpload
          width="100%"
          Button={
            <AddFileButton isActivated={uploadButtonActive}>
              <PhotoIcon strokeColor="#03041A" /> Upload
            </AddFileButton>
          }
          type={
            tab === VideoTab.story
              ? 'storyArtifactsVideo'
              : 'organizationArtifactsVideo'
          }
          showToggle={false}
          onClick={() => {
            setUploadButtonActive(true);
            setTimeout(() => setUploadButtonActive(false), 3000);
          }}
          callback={() => {
            let videoArtifactData;
            if (tab === VideoTab.story) {
              videoArtifactData = (videoCreator.story!.storyArtifactsVideo?.map(
                (v) => ({
                  id: v.id,
                  title: v.title,
                  src: v.url,
                  duration: v.video?.duration,
                  thumbnailUrl: v.video?.thumbnailUrl,
                }),
              ) || []) as Video[];
            } else {
              videoArtifactData = (
                videoCreator.story?._allReferencingShowcases || []
              ).flatMap((album) =>
                (album.organizationArtifactsVideo || []).map((v) => ({
                  id: v.id,
                  title: v.title,
                  src: v.url,
                  duration: v.video?.duration,
                  thumbnailUrl: v.video?.thumbnailUrl,
                })),
              );
            }
            setVideos(videoArtifactData);
          }}
          setIsLoading={setIsFileUploading}
        />
      </VideoTopAction>
    );
  };

  return (
    <Main>
      {isFileUploading && (
        <SpinningLoading positionTop="20px" text="Saving media" />
      )}

      <Tabs>
        {Object.entries(VideoTab).map(([k, v]) => (
          <Tab key={k} isSelected={tab === v} onClick={() => setTab(v)}>
            {v}
          </Tab>
        ))}
      </Tabs>
      {renderTopActions()}

      <VideoContent>
        {videos?.map((r) => (
          <FileListItem
            key={r.id}
            type={
              tab === VideoTab.story
                ? 'storyArtifactsVideo'
                : 'organizationArtifactsVideo'
            }
            item={{
              id: r.id,
              description: r.title,
              src: r.src!,
              thumbnailUrl: r.thumbnailUrl,
            }}
            handleClick={async () => {
              const freeTrack = videoCreator.getFreeMediaTrack(
                'video',
                r.duration || 8,
              );
              await videoCreator.createElement({
                type: 'video',
                source: r.src,
                duration: r.duration || '8 s',
                ...(freeTrack && { track: freeTrack }),
                autoplay: true,
              });
              videoCreator.sidebarOptions = SidebarOption.editing;
            }}
            isSelected={false}
            handleRemove={() => handleRemoveVideo(r.id)}
          />
        ))}
      </VideoContent>
    </Main>
  );
};

export default VideoArtifacts;

const Main = styled.div`
  margin-top: 5px;
`;

const AddFileButton = styled(PrimaryActionButton)``;

const VideoContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const VideoTopAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Tab = styled.div<{ isSelected?: boolean }>`
  color: ${(props) => (props.isSelected ? '#F2D093' : '')};
  text-decoration: ${(props) => (props.isSelected ? 'underline' : '')};
  cursor: pointer;
  font-size: 14px;
  padding: 10px 10px 10px 0;
`;
const Tabs = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: 20px;
  margin-left: 1px;
`;
