import styled, { css } from 'styled-components';
import SpinningLoading from '../../SpinningLoading';
import PhotoIcon from '../../../svgs/PhotoIcon';
import VideoIcon from '../../../svgs/VideoIcon';
import CloudIcon from '../../../svgs/CloudIcon';
import AutoResizeableMultilineInput from '../../common/AutoResizeableMultilineInput';
import DragAndDrop from '../../common/DragAndDrop';
import type { ArtifactFile, ArtifactUpload } from './useStoryCreator';
import { useMemo, useRef } from 'react';
import getScrollbarWidth from '../../../utility/getScrollbarWidth';

export default function BRollUploading({
  handleStoryBRollUpload,
  storyArtifactsFiles,
  storyArtifacts,
  storyArtifactsVideoFiles,
  storyArtifactsVideo,
  saveArtifactNotes,
  setArtifactNotes,
  deleteArtifact,
  saveArtifactVideoNotes,
  setArtifactVideoNotes,
  deleteArtifactVideo,
}: {
  handleStoryBRollUpload: (f: File[]) => void;
  storyArtifactsFiles: ArtifactFile[];
  storyArtifactsVideoFiles: ArtifactFile[];
  storyArtifacts: ArtifactUpload[];
  storyArtifactsVideo: ArtifactUpload[];
  saveArtifactNotes: (internalId: string) => void;
  setArtifactNotes: (internalId: string, notes: string) => void;
  deleteArtifact: (internalId: string) => void;
  saveArtifactVideoNotes: (internalId: string) => void;
  setArtifactVideoNotes: (internalId: string, notes: string) => void;
  deleteArtifactVideo: (internalId: string) => void;
}) {
  const scrollbarWidth = useMemo(() => getScrollbarWidth(), []);
  const isUploading =
    storyArtifacts.length !== storyArtifactsFiles.length ||
    storyArtifactsVideo.length !== storyArtifactsVideoFiles.length;
  return (
    <>
      <BRollStatusText>
        {isUploading ? 'Uploading' : 'Uploaded'}{' '}
        {storyArtifactsFiles.length + storyArtifactsVideoFiles.length} files
        {isUploading ? '...' : ''}
      </BRollStatusText>
      <BRollUploadsContainer scrollbarWidth={scrollbarWidth}>
        <AssetContainer
          disabled={
            storyArtifacts.length !== storyArtifactsFiles.length ||
            storyArtifactsVideo.length !== storyArtifactsVideoFiles.length
          }
        >
          <DragAndDrop
            width={131}
            height={99}
            callback={handleStoryBRollUpload}
            acceptType="all"
            multiple
            description={
              <>
                Upload photo
                <br />
                or video
              </>
            }
            iconJSX={
              <CloudIconWrapper>
                <CloudIcon width="24" height="23" />
              </CloudIconWrapper>
            }
          />
        </AssetContainer>
        <UploadedStoryArtifacts
          storyArtifactsFiles={storyArtifactsFiles}
          storyArtifacts={storyArtifacts}
          saveArtifactNotes={saveArtifactNotes}
          setArtifactNotes={setArtifactNotes}
          deleteArtifact={deleteArtifact}
        />
        <UploadedStoryArtifactsVideo
          storyArtifactsVideoFiles={storyArtifactsVideoFiles}
          storyArtifactsVideo={storyArtifactsVideo}
          saveArtifactVideoNotes={saveArtifactVideoNotes}
          setArtifactVideoNotes={setArtifactVideoNotes}
          deleteArtifactVideo={deleteArtifactVideo}
        />
      </BRollUploadsContainer>
    </>
  );
}

function UploadedStoryArtifacts({
  storyArtifactsFiles,
  storyArtifacts,
  saveArtifactNotes,
  setArtifactNotes,
  deleteArtifact,
}: {
  storyArtifactsFiles: ArtifactFile[];
  storyArtifacts: ArtifactUpload[];
  saveArtifactNotes: (internalId: string) => void;
  setArtifactNotes: (internalId: string, notes: string) => void;
  deleteArtifact: (internalId: string) => void;
}) {
  const result = storyArtifactsFiles.map((f, idx) => {
    const artifact = storyArtifacts.find(
      (a) => a._internalId === f._internalId,
    );
    return (
      <AssetContainerOuter key={artifact?._internalId || `${f.name}${idx}`}>
        <AssetContainer>
          {artifact ? (
            <>
              <ImageAsset file={f} />
              <DeleteButton
                onClick={() => deleteArtifact(artifact._internalId)}
              >
                Delete
              </DeleteButton>
            </>
          ) : (
            <SpinningLoading
              Ico={<PhotoIcon strokeColor="#17C964" strokeWidth={2} />}
              text="Uploading..."
              customStyle={{
                backgroundColor: 'inherit',
              }}
              textStyle={{
                margin: '5px 0 0 0',
                fontSize: '10px',
                fontWeight: '500',
              }}
              spinnerStyle={{
                width: '46px',
                height: '46px',
                marginRight: '10px',
              }}
              iconStyle={{
                top: 'calc(50% - 4px)',
              }}
            />
          )}
        </AssetContainer>
        {artifact && (
          <NotesInputWrapper>
            <NotesInput
              artifact={artifact}
              saveArtifactNotes={saveArtifactNotes}
              setArtifactNotes={setArtifactNotes}
            />
          </NotesInputWrapper>
        )}
      </AssetContainerOuter>
    );
  });

  return <>{result}</>;
}

function UploadedStoryArtifactsVideo({
  storyArtifactsVideoFiles,
  storyArtifactsVideo,
  saveArtifactVideoNotes,
  setArtifactVideoNotes,
  deleteArtifactVideo,
}: {
  storyArtifactsVideoFiles: ArtifactFile[];
  storyArtifactsVideo: ArtifactUpload[];
  saveArtifactVideoNotes: (internalId: string) => void;
  setArtifactVideoNotes: (internalId: string, notes: string) => void;
  deleteArtifactVideo: (internalId: string) => void;
}) {
  const result = storyArtifactsVideoFiles.map((f, idx) => {
    const artifactVideo = storyArtifactsVideo.find(
      (a) => a._internalId === f._internalId,
    );
    return (
      <AssetContainerOuter
        key={artifactVideo?._internalId || `${f.name}${idx}`}
      >
        <AssetContainer>
          {artifactVideo ? (
            <>
              <VideoAsset file={f} />
              <DeleteButton
                onClick={() => deleteArtifactVideo(artifactVideo._internalId)}
              >
                Delete
              </DeleteButton>
            </>
          ) : (
            <SpinningLoading
              Ico={<VideoIcon strokeColor="#17C964" width="22" height="22" />}
              text="Uploading..."
              customStyle={{
                backgroundColor: 'inherit',
              }}
              positionTop="12px"
              textStyle={{
                margin: '5px 0 0 0',
                fontSize: '10px',
                fontWeight: '500',
              }}
              spinnerStyle={{
                width: '46px',
                height: '46px',
                marginRight: '10px',
              }}
            />
          )}
        </AssetContainer>
        {artifactVideo && (
          <NotesInputWrapper>
            <NotesInput
              artifact={artifactVideo}
              saveArtifactNotes={saveArtifactVideoNotes}
              setArtifactNotes={setArtifactVideoNotes}
            />
          </NotesInputWrapper>
        )}
      </AssetContainerOuter>
    );
  });

  return <>{result}</>;
}

function ImageAsset({ file }: { file: ArtifactFile }) {
  const objectURL = useMemo(() => {
    return URL.createObjectURL(file);
  }, [file]);

  return <Image src={objectURL} alt={file.name} />;
}

function VideoAsset({ file }: { file: ArtifactFile }) {
  const objectURL = useMemo(() => {
    return URL.createObjectURL(file);
  }, [file]);

  return <Video src={objectURL} controls={false} />;
}

const AssetContainerOuter = styled.div`
  width: 131px;
`;

const AssetContainer = styled.div<{ disabled?: boolean }>`
  background: #484848;
  position: relative;
  overflow: hidden;
  width: 131px;
  height: 99px;
  border-radius: 8px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: wait;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0); /* Transparent overlay */
        z-index: 1; /* Ensure it blocks interaction */
      }
    `}
  &:hover {
    .delete-button {
      display: block;
    }
    .media {
      opacity: 0.7;
    }
  }
`;

const DeleteButton = styled.button.attrs({ className: 'delete-button' })`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  color: #f2d093;
  border: 1px solid #f2d093;
  border-radius: 4px;
  padding: 8px;
  display: none;
`;

const Image = styled.img.attrs({ className: 'media' })`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  @media only screen and (max-width: 615px) {
  }
`;

const Video = styled.video.attrs({ className: 'media' })`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  @media only screen and (max-width: 615px) {
  }
`;

const BRollStatusText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  font-style: normal;
  color: #f3e9d7;
`;

const BRollUploadsContainer = styled.div<{ scrollbarWidth: number }>`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 16px 24px;
  max-height: 278px;
  overflow-y: auto;
  width: ${(props) => 441 + props.scrollbarWidth}px;
  padding: 0 0 5px 0;
`;

const CloudIconWrapper = styled.div`
  text-align: center;
`;

const NotesInputWrapper = styled.div`
  padding: 3px 1px 0 1px;
`;

function NotesInput({
  artifact,
  saveArtifactNotes,
  setArtifactNotes,
}: {
  artifact: ArtifactUpload;
  saveArtifactNotes: (internalId: string) => void;
  setArtifactNotes: (internalId: string, notes: string) => void;
}) {
  const didChangeRef = useRef<boolean>(false);
  return (
    <AutoResizeableMultilineInput
      hasOutline={false}
      hasBorder={false}
      borderOnFocus={true}
      placeholder={`Click to add description`}
      width="100%"
      customHeight="50px"
      value={artifact.notes || ''}
      disableAutoResize={true}
      onBlur={() => {
        if (didChangeRef.current) {
          didChangeRef.current = false;
          saveArtifactNotes(artifact._internalId);
        }
      }}
      getValue={(description) => {
        didChangeRef.current = true;
        setArtifactNotes(artifact._internalId, description);
      }}
    />
  );
}
