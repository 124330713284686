import styled from 'styled-components';
import RotatingSquare from '../../svgs/RotatingSquare';
import { Dispatch, SetStateAction, useState } from 'react';
import { SupportedGeneratedContentTypes } from '../../types.ts/story';
import { CustomGeneratedTypes } from '../../types.ts/general';
import ChatGPTService from '../../services/ChatGPTService';
import BrandVoiceRevisionModal from '../content-components/BrandVoiceRevisionModal';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';

type Props<T extends SupportedGeneratedContentTypes> = {
  padding?: string;
  setIsLoading: (e: boolean) => void;
  storyId: string | undefined;
  transcriptionId: string | undefined;
  promptTitle: T;
  setImageSize?: Dispatch<
    SetStateAction<Record<string, Record<'width' | 'height', number>> | null>
  >;
  gptService: ChatGPTService;
};

const RegenerateButton = observer(
  <T extends SupportedGeneratedContentTypes>(props: Props<T>) => {
    const {
      padding = '10px 28px',
      setIsLoading,
      promptTitle,
      gptService,
    } = props;

    const contentGenerated = videoCreator.contentStudioGeneratedContent;

    const supportedTitle = promptTitle as CustomGeneratedTypes;
    const { hasBeenGenerated } = contentGenerated?.[supportedTitle] || {};
    const [showRevision, toggleRevisionModal] = useState<boolean>();

    const handleGenerate = async (brandText: string) => {
      try {
        props.setIsLoading(true);
        toggleRevisionModal(false);
        await gptService.regenerateStreamResponse({
          key: supportedTitle,
          brandText,
          setLoading: setIsLoading,
        });
      } catch (error) {
        console.log('Error occurred: ', error);
      } finally {
        props.setIsLoading(false);
        toggleRevisionModal(false);
      }
    };

    const handleClick = async () => {
      if (props.setImageSize) {
        props.setImageSize(null);
      }

      if (supportedTitle === 'Blog') {
        return toggleRevisionModal(true);
      }
      if (!hasBeenGenerated) {
        setIsLoading(true);
        gptService.regenerateStreamResponse({
          key: supportedTitle,
          setLoading: setIsLoading,
        });
      } else {
        toggleRevisionModal(true);
      }
    };
    return (
      <>
        <Main role="button" padding={padding} onClick={handleClick}>
          <RotatingSquare strokeColor="#17C964" />{' '}
          <span>
            {supportedTitle === 'Blog'
              ? 'Regenerate'
              : videoCreator.contentStudioGeneratedContent &&
                  videoCreator.contentStudioGeneratedContent[supportedTitle]
                    ?.hasBeenGenerated
                ? 'Regenerate'
                : 'Generate'}
          </span>
        </Main>
        {showRevision && (
          <BrandVoiceRevisionModal
            title={supportedTitle}
            onClose={() => toggleRevisionModal(false)}
            handleGenerate={handleGenerate}
          />
        )}
      </>
    );
  },
);

export default RegenerateButton;

export const Main = styled.button<{ padding: string }>`
  display: flex;
  padding: ${(props) => props.padding};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  color: #17c964;
  border: 1px solid #17c964;
  width: fit-content;
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  height: 40px;
`;
