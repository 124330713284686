import { useEffect } from 'react';
import { useAuth, useOrganizationList } from '@clerk/clerk-react';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { useSearchParams } from 'react-router-dom';

const useInitializeDatoUser = () => {
  const auth = useAuth();
  const datoContextSet = videoCreator.datoContext.currentUser;
  const datoUser = videoCreator.datoContext.currentRole;
  const orgList = useOrganizationList({
    userMemberships: {
      pageSize: 50,
      infinite: true,
    },
  });

  const [urlSearchParams] = useSearchParams();

  const { storyId, videoId, showcase } =
    Object.fromEntries(urlSearchParams.entries()) || {};
  const story = videoCreator.story;
  const organization = videoCreator.organization;

  const isLoaded =
    auth.isLoaded &&
    (!auth.isSignedIn ||
      (auth.orgSlug && auth.orgSlug === showcase) ||
      (orgList.isLoaded && !orgList.userMemberships.isLoading));

  let orgSlug: string | undefined | null;
  if (showcase) {
    if (auth.orgSlug && auth.orgSlug === showcase) {
      orgSlug = showcase;
    } else {
      orgSlug = orgList.userMemberships.data?.find(
        (memb) => memb.organization.slug === showcase,
      )?.organization.slug;
    }
  } else {
    orgSlug =
      auth.orgSlug || orgList.userMemberships.data?.at(0)?.organization.slug;
  }

  useEffect(() => {
    if (
      datoContextSet &&
      isLoaded &&
      ((!storyId && !organization) ||
        (storyId && story?.id !== storyId) ||
        (videoId && videoCreator.currentVideo?.id !== videoId))
    ) {
      videoCreator.initializeData({
        storyId,
        videoId,
        showcaseSlug: datoUser ? null : orgSlug,
      });
    }
  }, [isLoaded, datoContextSet, storyId, videoId]);
};

export default useInitializeDatoUser;
