import { capitalize } from 'lodash';
import {
  TranscriptChange,
  TranscriptElement,
  TranscriptTextElement,
} from '../../types.ts/transcript';
import {
  getClosestElementIndexToLeftByFilter,
  getClosestElementIndexToRightByFilter,
  isSentenceEnd,
  decapitalize,
} from '../utils';

export default class ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange,
    originalElements?: TranscriptElement[],
  ) {
    throw new Error('Method not implemented.');
  }

  protected fixCapitalizationAt(
    transcriptionElementsMutable: TranscriptElement[],
    index: number,
  ) {
    const elements = transcriptionElementsMutable;
    const visibleElementsFilter = (el: TranscriptElement) =>
      el.state !== 'removed' &&
      el.state !== 'cut' &&
      el.state !== 'muted' &&
      el.value !== ' ' &&
      el.value !== '';

    const closestLeftIndex = getClosestElementIndexToLeftByFilter(
      index - 1,
      elements,
      visibleElementsFilter,
    );
    const closestLeft = elements[closestLeftIndex] as
      | TranscriptTextElement
      | undefined;

    const nextElementIndex = getClosestElementIndexToRightByFilter(
      index,
      elements,
      visibleElementsFilter,
    );

    const nextElement = elements[nextElementIndex] as
      | TranscriptElement
      | undefined;

    if (!closestLeft || isSentenceEnd(closestLeft.value)) {
      if (nextElement?.type === 'text' && nextElement?.value) {
        nextElement.value = capitalize(nextElement.value);
      }
    } else if (
      closestLeft &&
      nextElement?.type === 'text' &&
      nextElement?.value &&
      nextElement.value !== 'I' &&
      !nextElement.value.startsWith("I'") &&
      !nextElement.value.startsWith('I ')
    ) {
      nextElement.value = decapitalize(nextElement.value);
    }
  }
}
