import React, { SetStateAction, forwardRef } from 'react';
import styled from 'styled-components';
import DeleteIcon from '../../svgs/DeleteIcon';
import { observer } from 'mobx-react-lite';
import { BlogOrEmailContent } from '../../types.ts/general';

type Props = {
  content: BlogOrEmailContent;
  pos?: any;
  toggleContentToEditModal: (
    value: SetStateAction<{
      type: 'save_as' | 'rename';
      content: BlogOrEmailContent | null;
    } | null>,
  ) => void;
  setOptionsModal: (e: null) => void;
  deleteContent: (id: number) => void;
};

const ContentSelectorModal = observer(
  forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { pos = {}, content, setOptionsModal } = props;
    return (
      <>
        <Actions ref={ref} isVisible={!!content} style={{ ...pos }}>
          <RenameButton
            onClick={(e) => {
              props.toggleContentToEditModal({
                type: 'rename',
                content,
              });
              setOptionsModal(null);
              e.stopPropagation();
            }}
          >
            <span>Rename</span>
          </RenameButton>

          <DeleteButton
            onClick={(e) => {
              e.stopPropagation();
              props.deleteContent(content?.id!);
            }}
          >
            <span>Delete</span> <DeleteIcon strokeColor="#f3e9d7" />
          </DeleteButton>
        </Actions>
      </>
    );
  }),
);

export default ContentSelectorModal;

const Actions = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;

  //   right: -80px;
  z-index: 2000;
  flex-direction: column;
  width: 110px;
  padding: 0;

  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;
`;

const Action = styled.div`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    white-space: nowrap;
  }
  .icon {
    margin-left: auto;
    display: flex;
  }
  &:not(:first-child) {
    border-top: 1px solid #d9d9d9;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #17c964;
    color: #03041a;
  }
`;

const RenameButton = styled(Action)``;

const DeleteButton = styled(Action)`
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: #ef5d6f;
  }
`;
