import React from 'react';

type Props = {
  width?: string;
  height?: string;
  fill?: string;
};
const PlayForwardIcon = (props: Props) => {
  const { width = '20', height = '12', fill = '#F2D093' } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 20 12" fill="none">
      <path
        d="M0.714851 10.6858C0.71416 10.8675 0.763002 11.0461 0.856131 11.2021C0.94926 11.3583 1.08315 11.486 1.24342 11.5715C1.40096 11.666 1.58119 11.7158 1.76486 11.7158C1.94852 11.7158 2.12874 11.666 2.28629 11.5715L9.54343 6.85729C9.685 6.76689 9.80153 6.64229 9.88224 6.49499C9.96297 6.34768 10.0053 6.18241 10.0053 6.01444C10.0053 5.84647 9.96297 5.68119 9.88224 5.53388C9.80153 5.38658 9.685 5.26197 9.54343 5.17158L2.28629 0.428724C2.12874 0.334295 1.94852 0.284424 1.76486 0.284424C1.58119 0.284424 1.40096 0.334295 1.24342 0.428724C1.08315 0.514395 0.94926 0.642124 0.856131 0.798166C0.763002 0.954224 0.71416 1.13271 0.714851 1.31444V10.6858Z"
        fill={fill}
      />
      <path
        d="M9.9961 10.6858C9.99542 10.8675 10.0443 11.0461 10.1374 11.2021C10.2305 11.3583 10.3644 11.486 10.5247 11.5715C10.6822 11.666 10.8624 11.7158 11.0461 11.7158C11.2298 11.7158 11.41 11.666 11.5675 11.5715L18.8246 6.85729C18.9662 6.76689 19.0828 6.64229 19.1635 6.49499C19.2442 6.34768 19.2865 6.18241 19.2865 6.01444C19.2865 5.84647 19.2442 5.68119 19.1635 5.53388C19.0828 5.38658 18.9662 5.26197 18.8246 5.17158L11.5675 0.428724C11.41 0.334295 11.2298 0.284424 11.0461 0.284424C10.8624 0.284424 10.6822 0.334295 10.5247 0.428724C10.3686 0.512066 10.2375 0.635324 10.1446 0.785909C10.0518 0.936481 10.0005 1.10901 9.9961 1.28587V10.6858Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlayForwardIcon;
