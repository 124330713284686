const ParagraphSkeleton = ({ bgColor }: { bgColor?: string }) => {
  return (
    <svg
      width="196"
      height="92"
      viewBox="0 0 196 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!!bgColor && <rect width="196" height="92" fill={bgColor} />}
      <mask
        id="mask0_19187_26115"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="11"
        width="179"
        height="69"
      >
        <rect x="6" y="11.5" width="168.085" height="8" rx="3" fill="#28293F" />
        <rect x="6" y="23.5" width="179" height="8" rx="3" fill="#28293F" />
        <rect x="6" y="35.5" width="158.626" height="8" rx="3" fill="#28293F" />
        <rect x="6" y="47.5" width="172.451" height="8" rx="3" fill="#28293F" />
        <rect x="6" y="59.5" width="164.447" height="8" rx="3" fill="#28293F" />
        <rect x="6" y="71.5" width="176.817" height="8" rx="3" fill="#28293F" />
      </mask>
      <g mask="url(#mask0_19187_26115)">
        <rect x="6" y="12" width="190" height="68" fill="#484848" />
      </g>
    </svg>
  );
};

export default ParagraphSkeleton;
