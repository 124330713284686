import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArborLogo from '../../svgs/ArborLogo';
import DragAndDrop from '../common/DragAndDrop';
import { videoCreator } from '../../stores/VideoCreatorStore';
import MediaAsset from './MediaAsset';
import ToastNotification from '../common/ToastNotification';
import { observer } from 'mobx-react-lite';
import CheckIconAlt from '../../svgs/CheckIconAlt';
import useInitializeAction from './useInitializeAction';
import _uniqBy from 'lodash/uniqBy';
import { runInAction } from 'mobx';
import { brollService } from './BrollService';
import EmailModal from './EmailModal';
import Modal from '../common/Modal';
import OrganizationAvatar, {
  OrganizationAvatarProps,
  OrganizationAvatarWrapper,
} from '../OrganizationAvatar';

const BrollPage = observer(() => {
  const [viewTerms, toggleViewTerms] = useState(false);
  useInitializeAction();

  useEffect(() => {
    return () => {
      brollService.files.forEach((file) => URL.revokeObjectURL(file.preview));
      brollService.assets.forEach((f) => {
        if (f.videoSrc) URL.revokeObjectURL(f.videoSrc);
        if (f.imgSrc) URL.revokeObjectURL(f.imgSrc);
      });
    };
  }, []);

  if (!brollService.initialized) return null;

  const avatarData: OrganizationAvatarProps | null = (() => {
    const showcases = videoCreator.story?._allReferencingShowcases;
    if (brollService.storyId && showcases?.length && showcases[0]) {
      return {
        album: showcases[0],
        username: videoCreator.story?.storyTeller.name,
      };
    }
    if (brollService.organizationId && videoCreator.organization) {
      return {
        album: videoCreator.organization,
        username: undefined,
      };
    }
    return null;
  })();

  return (
    <Main>
      {videoCreator.toastState && <ToastNotification />}
      <Header>
        <ArborLogo />
        {avatarData && (
          <OrganizationAvatarWrapper>
            <OrganizationAvatar {...avatarData} logoSize={40} />
          </OrganizationAvatarWrapper>
        )}
      </Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          brollService.saveBroll();
        }}
      >
        <Title width={brollService.assets.length > 1 ? '100%' : '449px'}>
          Upload photos and videos to make your story come to life
        </Title>

        <PhotoContainer contentWidth={(brollService.assets.length + 1) * 288}>
          {brollService.assets?.map((asset) => (
            <MediaAsset key={asset.id} asset={asset} />
          ))}
          <DragContainer>
            <DragAndDrop
              width={brollService.assets?.length ? 245 : 350}
              callback={(_files) => {
                const filesWithModifiedPreviews = _files.map((file) =>
                  Object.assign(file, {
                    id: window.crypto.randomUUID() as string,
                    preview: URL.createObjectURL(file),
                    description: '',
                    author: videoCreator.story?.storyTeller.name!,
                  }),
                );
                brollService.files = [
                  ...brollService.files,
                  ...filesWithModifiedPreviews,
                ];
                brollService.assets = _uniqBy(
                  [
                    ...filesWithModifiedPreviews.map((f) => {
                      const type = brollService.getFileFormat(f);
                      return {
                        id: f.id,
                        imgSrc: f.preview,
                        ...(type === 'video' && { videoSrc: f.preview }),
                        description: f.description,
                        type,
                        createdAt: new Date(),
                        isPreview: true,
                        saved: false,
                        state: 'pending' as 'pending',
                      };
                    }),
                    ...brollService.assets,
                  ],
                  'id',
                );
              }}
            />
          </DragContainer>
        </PhotoContainer>

        <CheckContainer>
          <CheckBox
            isChecked={brollService.isChecked}
            role="button"
            onClick={(e) =>
              runInAction(
                () => (brollService.isChecked = !brollService.isChecked),
              )
            }
          >
            {brollService.isChecked && <CheckIconAlt />}
          </CheckBox>
          <div className="terms-text">
            I agree to the
            <span role="button" onClick={() => toggleViewTerms(true)}>
              photo terms
            </span>
          </div>
        </CheckContainer>

        <Button disabled={!brollService.isChecked} type="submit">
          Submit
        </Button>
      </Form>
      {viewTerms && (
        <Modal
          isOpen={true}
          closeModal={() => toggleViewTerms(false)}
          backgroundColor="#03041977"
        >
          <ModalWrapper>
            <Terms>
              <TermsHeader>Photo Terms</TermsHeader>
              <TermsText>
                I represent and warrant that I own the rights in any
                photographs, images, videos, audio recordings, or other content
                that I provide to Arbor, and that I have the right to provide
                such content to Arbor without violating any other person's
                intellectual property rights, privacy rights, or other rights,
                laws, or regulations.
              </TermsText>
            </Terms>
          </ModalWrapper>
        </Modal>
      )}

      {brollService.progressStatus === 'uploaded' &&
        !brollService.assets.some((a) => a.state === 'uploading') && (
          <Modal
            isOpen={true}
            closeModal={() => (brollService.progressStatus = null)}
            backgroundColor="#03041977"
          >
            <ModalWrapper>
              <Terms>
                <TermsHeader>Thank you</TermsHeader>
                <TermsText>
                  Your media is now in the hands of our producers.
                </TermsText>
              </Terms>
            </ModalWrapper>
          </Modal>
        )}

      {(!brollService.contributor.email || !brollService.contributor.name) && (
        <Modal isOpen={true} backgroundColor="#03041977">
          <ModalWrapper>
            <EmailModal
              onSave={(contributor) => brollService.setContributor(contributor)}
            />
          </ModalWrapper>
        </Modal>
      )}
    </Main>
  );
});

export default BrollPage;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 100dvh;
  width: 100%;
  background-color: #030419;
`;
const Form = styled.form`
  max-width: 1600px;
  margin-bottom: auto;
  margin-top: auto;
  padding: 0px 0px 8dvh 0;
  width: 80%;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.span<{ width: string }>`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.73px;
  text-align: center;
  max-width: ${(props) => props.width};
  color: #f3e9d7;
  margin-top: 30px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: normal;
    margin-top: 40px;
  }
  @media (max-width: 540px) {
    font-size: 20px;
  }
`;
const DragContainer = styled.div`
  align-self: flex-top;
  margin-bottom: 20px;
  @media only screen and (max-width: 615px) {
    margin-bottom: 80px;
  }
`;
const CheckBox = styled.div<{ isChecked: boolean }>`
  border: 0;
  outline: 0;
  background-color: ${(props) => (props.isChecked ? '#4AD067' : 'transparent')};
  width: ${(props) => (props.isChecked ? '20px' : '16px')};
  height: ${(props) => (props.isChecked ? '20px' : '16px')};
  border-radius: ${(props) => !props.isChecked && '2px'};
  border: ${(props) => !props.isChecked && '1px solid #848484'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;

  .terms-text {
    color: #848484;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    display: flex;
    align-items: center;
    gap: 3px;

    span {
      color: #17c964;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
`;
const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: #17c964;
  color: #03041a;
  border-radius: 8px;
  width: 240px;
  height: 48px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    border: 1px solid #484848;
    color: #484848;
    background-color: #03041a;
  }
`;
const Terms = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 12px;
`;
const TermsHeader = styled.h3`
  color: #45d483;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.92px;
  text-transform: uppercase;
`;
const TermsText = styled.p`
  text-align: center;
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
`;
const PhotoContainer = styled.div<{ contentWidth: number }>`
  width: min(100%, ${(props) => props.contentWidth}px);
  gap: 24px;
  place-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  // grid-auto-rows: auto;
  margin: 30px auto 30px auto;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ModalWrapper = styled.div`
  background-color: #030419;
  width: 459px;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 0 20px;
  border: 1px solid #484848;
  @media (max-width: 514px) {
    width: 100%;
  }

  @media (max-width: 460px) {
    width: 90%;
  }
`;

const Header = styled.div`
  max-width: 2000px;
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    Array.isArray(props.children) && props.children.length > 1
      ? 'space-between'
      : 'center'};
  padding: 34px 24px 0 38px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 21px 13px 0 28px;
    svg {
      width: 80px;
      height: 24px;
    }
  }
`;
