import { useState } from 'react';
import styled from 'styled-components';

import { useClickAway } from '../../utility/useClickAway';
import { getFileNameFromURL } from '../../utility/files';
import { handleDownloadMedia } from '../../utility/general';

import EllipsisIcon from '../../svgs/EllipsisIcon';
import DownloadIcon from '../../svgs/DownloadIcon';
import DeleteIcon from '../../svgs/DeleteIcon';

type Asset = {
  id: string;
  title: string;
  url?: string;
  downloadUrl?: string;
};

type Props = {
  asset: Asset;
  onDelete?: (asset: Asset) => void | Promise<void>;
  renderImage?: () => React.ReactNode;
};

const AssetItem = (props: Props) => {
  const { asset, onDelete, renderImage } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const title =
    asset.title ||
    getFileNameFromURL(asset?.url, { stripPrefixId: true }) ||
    'Untitled';
  return (
    <Container key={asset.id} isMenuOpen={isMenuOpen}>
      {renderImage ? (
        renderImage()
      ) : asset.url ? (
        <img src={asset.url} />
      ) : (
        <div className="skeleton" />
      )}

      <span>{title}</span>

      <div className="asset-menu" onClick={() => setIsMenuOpen(true)}>
        <div className="asset-menu-btn">
          <EllipsisIcon fillColor="#f2d093" />
        </div>
        {isMenuOpen && asset.url && (
          <AssetDropdown
            title={title}
            file={{
              url: asset.downloadUrl || asset.url,
              fileName: title,
              id: asset.id,
            }}
            onClose={() => setIsMenuOpen(false)}
            onDelete={onDelete && (() => onDelete(asset))}
            style={{ position: 'absolute', top: '28px' }}
          />
        )}
      </div>
    </Container>
  );
};

const Container = styled.div<{ isMenuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  > img {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 1;
    object-fit: contain;
  }

  > .skeleton {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
    background: #484848;
  }

  > span {
    color: #848484;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > .asset-menu {
    position: absolute;
    top: 8px;
    right: 8px;

    > .asset-menu-btn {
      display: flex;
      background: #484848;
      border-radius: 4px;
      padding: 8px;
      cursor: pointer;
      opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
      transition: 0.2s;
    }
  }

  &:hover > .asset-menu > .asset-menu-btn {
    opacity: 1;
  }
`;

type AssetDropdownProps = {
  title: string;
  file: { url: string; fileName: string; id: string };
  onClose: () => void;
  onDelete?: () => void | Promise<void>;
  style?: React.CSSProperties;
};

const AssetDropdown = (props: AssetDropdownProps) => {
  const { title, file, onClose, onDelete, style } = props;
  const ref = useClickAway(onClose);
  return (
    <DropdownMenu ref={ref} style={style}>
      <h3>{title}</h3>
      <div
        className="action-btn action-download"
        onClick={(e) => handleDownloadMedia(e, file)}
      >
        <span>Download</span>
        <DownloadIcon width="24" height="24" strokeColor="#f3e9d7" />
      </div>
      {onDelete && (
        <div
          className="action-btn action-delete"
          onClick={async () => {
            await onDelete();
            onClose();
          }}
        >
          <span>Delete</span>
          <DeleteIcon width="24" height="24" strokeColor="#f3e9d7" />
        </div>
      )}
    </DropdownMenu>
  );
};

const DropdownMenu = styled.div`
  position: absolute;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 8px 16px;
  width: 280px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  > * {
    padding: 16px 0;
  }

  > *:not(:last-child) {
    border-bottom: 1px solid #484848;
  }

  > h3 {
    color: #f3e9d7;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      overflow: auto;
      word-break: break-word;
      text-overflow: initial;
      white-space: initial;
    }
  }

  > .action-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    &:hover {
      padding-left: 4px;
      padding-right: 4px;
      cursor: pointer;
    }
    &.action-download:hover {
      color: #17c964;
      svg path {
        stroke: #17c964;
      }
    }
    &.action-delete:hover {
      color: #ef5d6f;
      svg path {
        stroke: #ef5d6f;
      }
    }
  }
`;

export default AssetItem;
