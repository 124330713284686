import React from 'react';

type Props = {
  width?: string;
  height?: string;
  fillColor?: string;
};

const CirclePlayIcon = (props: Props) => {
  const { width = '48', height = '48', fillColor = '#FFFFFF' } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8764 25.4577L18.9719 34.6396C17.8465 35.29 16.4478 34.4817 16.4478 33.1831V14.8169C16.4478 13.5183 17.8465 12.71 18.9719 13.3604L34.8764 22.5423C35.9947 23.1893 35.9959 24.8119 34.8764 25.4577Z"
        fill={fillColor}
      />
      <path
        d="M24 0C37.2556 0 48 10.7444 48 24C48 37.2556 37.2556 48 24 48C10.7444 48 0 37.2556 0 24C0 10.7444 10.7444 0 24 0ZM24 2.79275C12.2869 2.79275 2.79275 12.2869 2.79275 24C2.79275 35.7131 12.2869 45.2072 24 45.2072C35.7131 45.2072 45.2072 35.7131 45.2072 24C45.2072 12.2869 35.7131 2.79275 24 2.79275Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CirclePlayIcon;
