import styled from 'styled-components';

type Props = {
  setBannerHeight: (height: number | undefined) => void;
  percent: number;
};

const AddStoriesModalBanner = ({ setBannerHeight, percent }: Props) => {
  return (
    <Banner
      ref={(ref) => {
        setBannerHeight(ref?.getBoundingClientRect()?.height);
      }}
    >
      <BannerText>We’re uploading your video...</BannerText>
      <ProgressBarOutline>
        <ProgressBar width={`${(percent / 100) * 404}px`} />
      </ProgressBarOutline>
      <ProgressPercent>{percent}%</ProgressPercent>
    </Banner>
  );
};

const Banner = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 24px;
  z-index: 999;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 14px 8px 16px 0px rgba(0, 0, 0, 0.4);
`;

const BannerText = styled.div`
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ProgressBarOutline = styled.div`
  width: 404px;
  height: 4px;
  background: #484848;
  border-radius: 4px;
  margin: 24px auto 8px;
`;

const ProgressBar = styled.div<{ width: string }>`
  background: #17c964;
  height: 4px;
  width: ${(props) => props.width};
  border-radius: 4px;
`;

const ProgressPercent = styled.div`
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
`;

export default AddStoriesModalBanner;
