import styled, { css } from 'styled-components';
import FeelingLuckyIcon from '../svgs/FeelingLuckyIcon';
import React from 'react';

type Props = {
  text?: string | React.ReactNode;
  Ico?: React.ReactNode;
  positionTop?: string;
  customStyle?: any;
  spinnerStyle?: React.CSSProperties;
  spinnerCircleStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
};
const SpinningLoading = (props: Props) => {
  const {
    text = 'Gathering list of images...',
    Ico = <FeelingLuckyIcon strokeColor="#17c964" />,
    customStyle = {},
    spinnerStyle = {},
    spinnerCircleStyle = {},
    textStyle = {},
    iconStyle = {},
  } = props;
  return (
    <Main positionTop={props.positionTop} style={{ ...customStyle }}>
      <Content>
        <SpinnerWrapper style={{ ...spinnerStyle }}>
          <Icon style={{ ...iconStyle }}>{Ico}</Icon>
          <Spinner style={{ ...spinnerCircleStyle }}></Spinner>
        </SpinnerWrapper>
        <Text style={{ ...textStyle }}>{text}</Text>
      </Content>
    </Main>
  );
};
export default SpinningLoading;

const Main = styled.div<{ positionTop?: string }>`
  position: absolute;
  background-color: #03041999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  ${(props) =>
    props.positionTop &&
    css`
      align-items: flex-start;
      padding-top: ${props.positionTop};
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const Text = styled.p`
  color: #f3e9d7;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;
const SpinnerWrapper = styled.div`
  width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Spinner = styled.div`
  width: 100%;
  height: 100%;
  border: 5px solid #484848;
  border-top: 5px solid #17c964; /* Change color to your preference */
  border-left: 5px solid #17c964;
  border-right: 5px solid #17c964;
  border-radius: 50%;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Icon = styled.div`
  width: fit-content;
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
`;
