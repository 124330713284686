type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const CopyIcon = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <g clipPath="url(#clip0_2946_4131)">
        <rect x="5" y="1" width="10" height="10" rx="2" stroke={strokeColor} />
        <path
          d="M3.72727 5H3C1.89543 5 1 5.89543 1 7V13C1 14.1046 1.89543 15 3 15H9C10.1046 15 11 14.1046 11 13V12.2727"
          stroke={strokeColor}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2946_4131">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
