import { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ContentTab } from '../../types.ts/general';
import PullQuotes from './PullQuotes';
import MediaView from './MediaView';
import BlogView from './BlogView';
import EmailView from './EmailView';
import SocialClipsView from './SocialClipsView';
import TalkingPointStudio from './TalkingPointStudio';

const ContentView: FC<{}> = observer((props) => {
  const [activeTab, setActiveTab] = useState<ContentTab>(ContentTab.video_clip);
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Main>
      <Tabs>
        {Object.entries(ContentTab).map(([k, v]) => (
          <TabItem
            key={k}
            isSelected={activeTab === v}
            onClick={() => {
              wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
              setActiveTab(v);
            }}
          >
            {v}
          </TabItem>
        ))}
        {/* {(activeTab === ContentTab.blog || activeTab === ContentTab.email) && (
          <div className="share">
            <ContentShare />
          </div>
        )} */}
      </Tabs>

      {(() => {
        switch (activeTab) {
          case ContentTab.video_clip:
            return (
              <ContentWrapper ref={wrapperRef}>
                <SocialClipsView />
              </ContentWrapper>
            );
          case ContentTab.blog:
            return (
              <ContentWrapper ref={wrapperRef}>
                <BlogView />
              </ContentWrapper>
            );
          case ContentTab.talking_point_studio:
            return (
              <ContentWrapper ref={wrapperRef}>
                <TalkingPointStudio />
              </ContentWrapper>
            );
          case ContentTab.media:
            return (
              <ContentWrapper ref={wrapperRef}>
                <MediaView />
              </ContentWrapper>
            );
          case ContentTab.pull_quotes:
            return (
              <ContentWrapper ref={wrapperRef}>
                <PullQuotes />
              </ContentWrapper>
            );
          case ContentTab.email:
            return (
              <ContentWrapper ref={wrapperRef}>
                <EmailView />
              </ContentWrapper>
            );
          // case ContentTab.external:
          //   return (
          //     <ContentWrapper ref={wrapperRef}>
          //       <ExternalContent />
          //     </ContentWrapper>
          //   );
          default:
            return <ContentWrapper ref={wrapperRef}></ContentWrapper>;
        }
      })()}
    </Main>
  );
});

export default ContentView;

const Main = styled.div`
  margin-top: 10px;
  position: relative;
`;
const Tabs = styled.nav`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
  .copy,
  .share {
    margin-left: auto;
    display: flex;
    gap: 4px;
    align-items: center;
    color: #f2d093;
  }
  margin-bottom: 15px;
  border-bottom: 1px solid #484848;

  @media only screen and (max-width: 750px) {
    width: 100%;
    overflow-x: scroll;
  }
`;
const TabItem = styled.menu<{ isSelected?: boolean }>`
  display: flex;
  padding: 0;
  cursor: pointer;
  color: #f3e9d7;
  &:hover,
  &:active {
    color: #f2d093;
    border-bottom: 1px solid #f2d093;
  }

  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
      border-bottom: 1px solid #f2d093;
    `}
`;

const ContentWrapper = styled.div`
  min-height: 50vh;
  margin-bottom: 30px;
`;
