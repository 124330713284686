import { useEffect } from 'react';
import { useQuerySubscription } from 'react-datocms/use-query-subscription';
import { videoCreator } from '../stores/VideoCreatorStore';
import { VIDEOS_RENDERING_STATUS_QUERY } from '../utility/gql';
import { VideoRenderingStatus } from '../types.ts/story';

export const useQueryVideos = (
  ids: string[],
): {
  status: string;
  error: any;
  data: { allVideos: VideoRenderingStatus[] } | undefined;
} => {
  const { status, error, data } = useQuerySubscription({
    query: VIDEOS_RENDERING_STATUS_QUERY,
    variables: { ids },
    environment: videoCreator.datoContext.environment,
    token: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!,
  });

  return { status, error, data };
};

const VideosQuerySubscriptionComponent = (props: {
  videoIds: string[];
  onDataReceived: (videos: VideoRenderingStatus[]) => void;
  onError: (error: any) => void;
}) => {
  const { videoIds, onDataReceived, onError } = props;
  const { error, data } = useQueryVideos(videoIds);

  useEffect(() => {
    if (error) {
      onError(error);
    } else if (data?.allVideos?.length) {
      onDataReceived(data.allVideos);
    }
  }, [data, error]);

  return <></>;
};

export default VideosQuerySubscriptionComponent;
