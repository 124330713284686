import styled from 'styled-components';

type Props = {
  value?: string;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  tips?: string;
  onSave?: () => void;
  isSaving?: boolean;
};

const Textarea = (props: Props) => {
  const { value, defaultValue, onChange, placeholder, tips, onSave, isSaving } =
    props;
  const isChanged = value !== defaultValue;
  const valueLength = value?.length || 0;
  return (
    <Container isSaving={isSaving}>
      <div className="input-wrapper">
        <textarea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={valueLength > 1024 ? 'xl' : valueLength > 512 ? 'lg' : ''}
        />
      </div>
      {tips && <div className="tips">{tips}</div>}
      <div className="actions">
        <button disabled={isSaving || !isChanged} onClick={onSave}>
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </Container>
  );
};

export default Textarea;

const Container = styled.div<{ isSaving?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid #484848;
    border-radius: 8px;

    &:focus-within {
      border-color: #f3e9d7;
    }

    > textarea {
      color: #f3e9d7;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      background: transparent;
      outline: none;
      border: none;
      resize: none;
      padding: 24px;
      min-height: 128px;
      &.lg {
        min-height: 256px;
      }
      &.xl {
        min-height: 512px;
      }
      &::placeholder {
        color: #848484;
      }
      & {
        scrollbar-color: #35393a transparent;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }

  .tips {
    color: #848484;
    font-size: 14px;
    font-weight: 400;
  }

  .actions {
    align-self: flex-end;

    > button {
      display: block;
      height: 48px;
      padding: 12px 24px;
      border-radius: 8px;
      background: #17c964;
      color: #03041a;
      font-size: 14px;
      font-weight: 700;
      border: none;
      transition: 0.2s;
      opacity: ${({ isSaving }) => (isSaving ? 0.6 : 1)};
      cursor: ${({ isSaving }) => (isSaving ? 'not-allowed' : 'pointer')};
      &:disabled {
        color: #848484;
        border: 1px solid #848484;
        background: transparent;
        cursor: not-allowed;
      }
      &:hover:not(:disabled) {
        filter: brightness(1.2) saturate(1.2);
      }
    }
  }
`;
