type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const MuteKaraokeIcon = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#D9D9D9',
  } = props;

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <g clipPath="url(#clip0_6492_2534)">
        <path
          d="M6.01784 6.33323H9.98184C10.5459 5.9161 10.9639 5.33165 11.1765 4.6631C11.389 3.99455 11.3852 3.27598 11.1656 2.60972C10.946 1.94345 10.5218 1.36345 9.95342 0.952301C9.385 0.541155 8.70136 0.319824 7.99984 0.319824C7.29831 0.319824 6.61467 0.541155 6.04625 0.952301C5.47784 1.36345 5.05363 1.94345 4.83403 2.60972C4.61443 3.27598 4.61063 3.99455 4.82318 4.6631C5.03574 5.33165 5.4538 5.9161 6.01784 6.33323Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 14.3333V15.6666"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 3.66675H12"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.98207 6.33325L9.38207 13.7206C9.36847 13.8879 9.29228 14.0439 9.16869 14.1575C9.04511 14.271 8.88324 14.3338 8.7154 14.3333H7.2814C7.11356 14.3338 6.95169 14.271 6.8281 14.1575C6.70452 14.0439 6.62833 13.8879 6.61473 13.7206L6.01807 6.33325H9.98207Z"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.12646 7.66675H9.87313"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6492_2534">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MuteKaraokeIcon;
