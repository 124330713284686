type Params = {
  height?: number;
  width?: number;
};

/*
 * https://docs.imgix.com/getting-started/setup/serving-assets
 */
export default function applyImageOptimizations(
  url: string | null | undefined,
  params: Params,
): string {
  if (!url || isSrcSet(url) || !containsDomain(url, 'datocms-assets.com')) {
    return url || '';
  }
  let result = `${url}${
    url.includes('?') ? '&' : '?'
  }auto=format,compress&q=60`;
  if (params.width) {
    result += `&w=${params.width}`;
  }
  if (params.height) {
    result += `&h=${params.height}`;
  }
  return result;
}

function isSrcSet(url: string): boolean {
  return /https?:\/\/[\S]+?\s\d+w(,\s*https?:\/\/[\S]+?\s\d+w)*/.test(url);
}

function containsDomain(url: string, domain: string): boolean {
  try {
    const { hostname } = new URL(url);
    return hostname === domain || hostname.endsWith(`.${domain}`);
  } catch (e) {
    return false;
  }
}
