import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  strokeWidth?: string;
};
const RedoIcon = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#F2D093',
    strokeWidth = '2',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M14.8984 5.34712H5.34742C4.2216 5.34712 3.14189 5.79435 2.34582 6.59042C1.54975 7.38649 1.10252 8.4662 1.10252 9.59202C1.10252 10.7178 1.54975 11.7975 2.34582 12.5936C3.14189 13.3897 4.2216 13.8369 5.34742 13.8369H8.53109"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7148 8.53125L14.8984 5.34758L11.7148 2.1639"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RedoIcon;
