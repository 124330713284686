import {
  Client,
  SimpleSchemaTypes,
  buildBlockRecord,
} from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import {
  Video,
  VideoItem,
  VideoFilesQueryResult,
  VideoVersion,
  ExtraElementData,
} from '../types.ts/story';
import { TranscriptChange } from '../types.ts/transcript';
import { VIDEO_FILES_QUERY } from '../utility/gql';
import { randomString } from '../utility/general';
import ApiClient from '../apiClient/ApiClient';
import { AspectRatio } from '../types.ts/video';

type VideoVersionDTO = Video & { versionId?: string } & {
  editor?: SimpleSchemaTypes.User | SimpleSchemaTypes.ItemVersion['editor'];
} & { transcriptionText?: string };

const PRIMARY_ASPECT_RATIO = AspectRatio.AR_16_9;
const MAX_RECORD_SIZE = 1_300_000;

export class VideoRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async getVersionsByVideoId(
    id: string,
    pageLimit: number,
    page: number = 0,
  ): Promise<VideoVersion[]> {
    const videoVersions = (await this.dClient.itemVersions.list(id, {
      nested: true,
      page: {
        limit: pageLimit,
        offset: page * pageLimit,
      },
    })) as unknown as (SimpleSchemaTypes.ItemVersion &
      VideoItem & { versionId: string })[];

    const editors: any = {};
    videoVersions.forEach((videoVersion) => {
      editors[videoVersion.editor.type + videoVersion.editor.id] =
        videoVersion.editor;
    });

    // change to fetch associated videos
    const latestVersionVideoFiles = (
      (await this.gqlClient.request(VIDEO_FILES_QUERY, {
        id,
      })) as VideoFilesQueryResult
    ).video!;

    await Promise.all(
      Object.keys(editors).map(async (key) => {
        try {
          const user = await this.dClient.users.find(editors[key]);
          editors[key] = user;
        } catch (err) {}
      }),
    );

    const videoVersionsMapped: VideoVersion[] = videoVersions.map(
      (videoVersion) => {
        let extraElementData: Video['extraElementData'] =
          JSON.parse(videoVersion.extra_elements_json) || {};

        const punchList = Object.entries(extraElementData)
          .filter(
            ([id, element]) => (element as ExtraElementData).punchListData,
          )
          .map(([id, element]) => ({
            id,
            ...(element as ExtraElementData | null)?.punchListData!,
          }))
          .sort(
            (a, b) =>
              a.transcriptPosition.startIndex - b.transcriptPosition.startIndex,
          );

        const parentVideoQueryResult =
          latestVersionVideoFiles._allReferencingVideos?.at(0);
        const subtitles = JSON.parse(videoVersion.subtitles_json);
        return {
          ...videoVersion,
          // title: videoVersion.title,
          versionId: videoVersion.id,
          id: id,
          subtitles:
            !subtitles || Object.keys(subtitles).length === 0
              ? null
              : subtitles,
          videoSource: JSON.parse(videoVersion.video_json),
          videoFilePrimary: videoVersion.meta.is_current
            ? latestVersionVideoFiles.videoFilePrimary
            : undefined,
          associatedVideos: latestVersionVideoFiles.associatedVideos,
          parentVideo: parentVideoQueryResult
            ? {
                ...parentVideoQueryResult,
                videoSource: parentVideoQueryResult.videoJson,
                videoJson: undefined,
              }
            : undefined,
          aspectRatio: videoVersion.aspect_ratio,
          transcriptionChanges: (videoVersion.transcription_json
            ? JSON.parse(videoVersion.transcription_json).changelog
            : []) as TranscriptChange[],
          transcriptionSnapshot: JSON.parse(
            videoVersion.transcription_snapshot_json,
          ),
          transcriptionText: videoVersion.transcription_text,
          punchList,
          extraElementData,
          videoStatus: videoVersion.video_status,
          editor: editors[videoVersion.editor.type + videoVersion.editor.id],
          thumbnail: latestVersionVideoFiles?.thumbnail,
          sourcePlatform: videoVersion.source_platform,
          clipJson: videoVersion.clip_json
            ? JSON.parse(videoVersion.clip_json)
            : {},
          isClientReady: videoVersion.is_client_ready,
          isHidden: videoVersion.is_hidden,
          lastActionJson: videoVersion.last_action_json
            ? JSON.parse(videoVersion.last_action_json)
            : {},
          _publishedAt: videoVersion.meta.created_at,
        };
      },
    );

    return videoVersionsMapped;
  }

  async saveOrUpdateVideo(video: Partial<VideoVersionDTO>): Promise<string> {
    let savedVideoId: string;
    if (video.id) {
      savedVideoId = await this.updateVideo(
        video as Partial<VideoVersionDTO> & { id: string },
      );
    } else {
      savedVideoId = await this.createVideo(
        video as Partial<VideoVersionDTO> & { id: undefined },
      );
    }
    return savedVideoId;
  }

  estimateRecordSize(video: Partial<VideoVersionDTO>): number {
    const transcription_snapshot_json = JSON.stringify(
      video.transcriptionSnapshot || {},
    );
    const video_source_json = JSON.stringify(video.videoSource || {});
    const subtitles_json = JSON.stringify(video.subtitles || {});
    const extra_elements_json = JSON.stringify(video.extraElementData || {});
    const title = video.title || '';

    return (
      1.5 *
      (new Blob([video_source_json.replaceAll('"', '\\"')]).size +
        new Blob([subtitles_json.replaceAll('"', '\\"')]).size +
        new Blob([extra_elements_json.replaceAll('"', '\\"')]).size +
        new Blob([title]).size +
        new Blob([transcription_snapshot_json.replaceAll('"', '\\"')]).size)
    );
  }

  async updateVideo(
    video: Partial<VideoVersionDTO> & { id: string },
  ): Promise<string> {
    // todo rework

    const transcription_snapshot_json = JSON.stringify(
      video.transcriptionSnapshot,
    );

    const savedVideo = await this.dClient.items.update(video.id, {
      ...(video.videoSource && {
        video_json: JSON.stringify(video.videoSource),
      }),

      ...(video.subtitles && {
        subtitles_json: JSON.stringify(video.subtitles || {}),
      }),

      ...(video.title && { title: video.title }),

      ...(video.transcriptionChanges && {
        transcription_json: JSON.stringify({
          changelog: video.transcriptionChanges,
        }),
      }),

      ...(video.transcriptionSnapshot &&
        this.estimateRecordSize(video) < MAX_RECORD_SIZE && {
          transcription_snapshot_json,
          transcription_json: JSON.stringify({
            changelog: [],
          }),
        }),

      ...(video.extraElementData && {
        extra_elements_json: JSON.stringify(video.extraElementData),
      }),

      ...(video.thumbnail && {
        thumbnail: {
          upload_id: video.thumbnail.id,
        },
      }),

      ...(video.aspectRatio && {
        aspect_ratio: video.aspectRatio,
      }),

      ...(video.associatedVideos && {
        associated_videos: video.associatedVideos.map((v) => v.id),
      }),

      ...(video.isClientReady != null && {
        is_client_ready: video.isClientReady,
      }),

      ...(video.isHidden != null && {
        is_hidden: video.isHidden,
      }),

      ...(video.clipJson && {
        clip_json: JSON.stringify(video.clipJson),
      }),

      ...(video.lastActionJson && {
        last_action_json: JSON.stringify(video.lastActionJson),
      }),

      //TODO other fields
    });
    //auto-publish
    return savedVideo.id;
  }

  async createVideo(
    video: Partial<VideoVersionDTO> & { id: undefined },
  ): Promise<string> {
    const extraElementData = video.extraElementData || {};
    video.punchList?.forEach((punchListItem) => {
      extraElementData[punchListItem.id!] = {
        ...(extraElementData[punchListItem.id!] || {}),
        punchListData: punchListItem,
      };
    });

    const transcription_snapshot_json = JSON.stringify(
      video.transcriptionSnapshot,
    );

    const itemType = await this.dClient.itemTypes.find('video');
    const savedVideo = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      title: video.title,
      hash: randomString(12),
      slug: randomString(12),
      video_json: JSON.stringify(video.videoSource),
      subtitles_json: JSON.stringify(video.subtitles || {}),
      transcription_json: JSON.stringify({
        changelog: video.transcriptionChanges,
      }),
      ...(video.transcriptionSnapshot &&
        this.estimateRecordSize(video) < MAX_RECORD_SIZE && {
          transcription_snapshot_json,
          transcription_json: JSON.stringify({
            changelog: [],
          }),
        }),
      // transcription_text: video.transcriptionText,
      extra_elements_json: JSON.stringify(extraElementData),
      aspect_ratio: video.aspectRatio || PRIMARY_ASPECT_RATIO,
      associated_videos: video.associatedVideos?.map((v) => v.id),
      source_platform: video.sourcePlatform,
      clip_json: JSON.stringify(video.clipJson || {}),
      is_client_ready: video.isClientReady,
      is_hidden: video.isHidden,
      last_action_json: JSON.stringify(video.lastActionJson || {}),
    });
    //TODO other fields
    //auto-publish
    return savedVideo.id;
  }

  async updateThumbnail(
    id: string,
    thumbnailId: string,
    shareableImageId: string | undefined,
  ): Promise<string> {
    const savedVideo = await this.dClient.items.update(id, {
      thumbnail: {
        upload_id: thumbnailId,
      },
      shareable_image_id: shareableImageId || '',
    });
    return savedVideo.id;
  }
}
