import { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { videoCreator } from '../stores/VideoCreatorStore';
import type { User, SsoUser, Account } from 'datocms-plugin-sdk';
import type { VideoAction } from '../types.ts/story';

// missing from exports
type Organization = {
  attributes: {
    /**
     * Name of the organization
     */
    name: string;
  };
};

export default function useCurrentEditor(): VideoAction['editor'] {
  const user = useUser();
  let editor = 'Unknown';

  if (user?.user) {
    const { fullName, firstName, lastName } = user.user;
    if (fullName) {
      editor = fullName;
    } else if (firstName) {
      editor = [firstName, lastName].filter(Boolean).join(' ');
    }
  } else {
    const currentUser = videoCreator.datoContext?.currentUser;
    if ((currentUser as User)?.attributes?.full_name) {
      editor = (currentUser as User).attributes.full_name;
    } else if ((currentUser as SsoUser | Account)?.attributes?.first_name) {
      editor = [
        (currentUser as SsoUser | Account)?.attributes?.first_name,
        (currentUser as SsoUser | Account)?.attributes?.last_name,
      ]
        .filter(Boolean)
        .join(' ');
    } else if ((currentUser as Organization)?.attributes?.name) {
      editor = (currentUser as Organization)?.attributes?.name;
    }
  }

  useEffect(() => {
    videoCreator.currentEditor = editor;
  }, [editor]);

  return editor;
}
