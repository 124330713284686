import React, { useState } from 'react';
import styled from 'styled-components';
import CreateIcon from '../../../svgs/CreateIcon';
import ChatGPTService, { gptService } from '../../../services/ChatGPTService';
import SpinningLoading from '../../SpinningLoading';
import { observer } from 'mobx-react-lite';

const InitialScreen = observer(() => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Main>
      {isLoading && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text="Generating fundraising brief"
        />
      )}
      <Title>Create a talking point brief.</Title>
      <SubTitle>Let your story help you fundraise</SubTitle>

      <Button
        onClick={async () => {
          setIsLoading(true);
          await gptService.generateTalkingPoint(setIsLoading);
        }}
      >
        <Icon>
          <CreateIcon />
        </Icon>
        Generate
      </Button>
    </Main>
  );
});

export default InitialScreen;

const Main = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  margin-top: 100px;
`;
const Title = styled.h2`
  color: #f3e9d7;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;
const SubTitle = styled.span`
  color: #f3e9d7;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 19.6px;
  margin-bottom: 20px;
`;

const Icon = styled.span`
  color: #03041a;
`;

const Button = styled.button`
  display: flex;
  width: 240px;
  height: 40px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #17c964;
  outline: 0;
  border: 0;
  margin: 20px auto;
  cursor: pointer;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;
