import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import PrimaryActionButton from '../sidepanel/PrimaryActionButton';
import { videoCreator } from '../../stores/VideoCreatorStore';
import TextBrandingTemplates from './TextBrandingTemplates';
import { observer } from 'mobx-react-lite';
import { SaveOrDiscardModal } from '../stage/SaveOrDiscardModal';

type Props = {
  toggleAddNewTemplate: Dispatch<SetStateAction<boolean>>;
  addNewTemplate: boolean;
  setAllowCustomTemplateSave: Dispatch<SetStateAction<boolean>>;
  allowCustomTemplateSave: boolean;
  setTemplateNameError: Dispatch<SetStateAction<boolean>>;
  templateNameError: boolean;
  templateName: string | undefined;
  setTemplateName: Dispatch<SetStateAction<string | undefined>>;
  type: 'basic' | 'karaoke';
  setting: 'text-settings' | 'karaoke-settings';
};
const SaveOrCancelTemplate = observer((props: Props) => {
  return (
    <>
      {videoCreator.textBrandingService.selectedTemplateId === 'custom' &&
        !props.addNewTemplate && (
          <SaveCustomAsTemplate type={props.type}>
            <ChoiceRadio
              isChecked={props.allowCustomTemplateSave}
              onClick={() => {
                props.setAllowCustomTemplateSave(
                  !props.allowCustomTemplateSave,
                );
              }}
            />{' '}
            <span>Save as Template</span>
          </SaveCustomAsTemplate>
        )}

      {props.allowCustomTemplateSave && !props.addNewTemplate && (
        <TextBrandingTemplates
          addNewTemplate={props.addNewTemplate}
          toggleAddNewTemplate={props.toggleAddNewTemplate}
          setTemplateNameError={props.setTemplateNameError}
          templateNameError={props.templateNameError}
          setTemplateName={props.setTemplateName}
          allowCustomTemplateSave={props.allowCustomTemplateSave}
          type={props.type}
        />
      )}

      {(props.addNewTemplate ||
        props.allowCustomTemplateSave ||
        videoCreator.textBrandingService.isTemplateModified) && (
        <StickyWrapper>
          <CancelButton
            onClick={() => {
              props.setAllowCustomTemplateSave(false);
              props.toggleAddNewTemplate(false);
              if (videoCreator.textBrandingService.isTemplateModified) {
                videoCreator.textBrandingService.isTemplateModified = false;
                videoCreator.textBrandingService.modifiedTemplateData = null;
                videoCreator.textBrandingService.isPropsChanged = true;
              }

              videoCreator.textBrandingService.prepareTemplateToSave = false;
            }}
          >
            Cancel
          </CancelButton>

          <SaveButton
            disabled={!videoCreator.organization}
            onClick={async () => {
              if (
                !props.templateName &&
                !videoCreator.textBrandingService.isTemplateModified
              ) {
                props.setTemplateNameError(true);
                return;
              }

              await videoCreator.textBrandingService.save(
                props.templateName!,
                props.type,
                props.setting,
              );

              props.setTemplateName('');
              props.toggleAddNewTemplate(false);
              props.setAllowCustomTemplateSave(false);
            }}
          >
            {videoCreator.textBrandingService.isTemplateModified
              ? 'Update Template'
              : 'Save Template'}
          </SaveButton>
          <NoShowcaseMessage className="no-showcase">
            A story needs to be part of a showcase to have default settings
            available.
          </NoShowcaseMessage>
        </StickyWrapper>
      )}
    </>
  );
});

export default SaveOrCancelTemplate;

const CancelButton = styled(PrimaryActionButton)`
  border: 1px solid #f2d093;
  background-color: #03041a;
  color: #f2d093;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
`;

const SaveButton = styled(PrimaryActionButton)`
  background-color: #17c964;
  color: #03041a;
  border: none;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;

  &:disabled:hover ~ .no-showcase {
    display: flex;
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  bottom: 1px;
  box-shadow: 0px 1px 0 0 #030419;
  background: linear-gradient(180deg, rgba(3, 4, 25, 0.5) 0%, #030419 40%);
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  height: 100px;
  margin-bottom: -15px;
`;

const ChoiceRadio = styled.div<{ isChecked: boolean }>`
  border-radius: 80px;
  border: 1px solid #484848;
  background: ${(props) =>
    props.isChecked ? '#17c964' : 'rgba(3, 4, 25, 0.4)'};
  width: 14px;
  height: 14px;
`;
const SaveCustomAsTemplate = styled.div<{ type: 'basic' | 'karaoke' }>`
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  gap: 10px;
  margin: ${(props) => props.type === 'karaoke' && '15px 0'};
`;

const NoShowcaseMessage = styled.div`
  position: absolute;
  color: #f3e9d7;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  background: #484848;
  top: 0;

  display: none;
  width: 258px;
  height: 42px;
  padding: 2px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
`;
