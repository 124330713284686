import { makeAutoObservable } from 'mobx';
import VideoCreatorStore from '../VideoCreatorStore';
import {
  Artifact,
  PhotoArtifactTab,
  Showcase,
  ShowcaseArtifactsAiTitleUpdate,
  Story,
  StoryArtifactsAiTitleUpdate,
} from '../../types.ts/story';
import { updateStoryShowcase } from '../../utility/story';

class StatusUpdateManager {
  store: VideoCreatorStore;

  watchedStoryArtifacts: Record<Story['id'], Pick<Artifact, 'id' | 'title'>[]> =
    {};
  watchedOrganizationArtifacts: Record<
    Showcase['id'],
    Pick<Artifact, 'id' | 'title'>[]
  > = {};

  constructor(store: VideoCreatorStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  getStoryIdsWithWatchedArtifacts(): string[] {
    return Object.keys(this.watchedStoryArtifacts);
  }

  getOrganizationIdsWithWatchedArtifacts(): string[] {
    return Object.keys(this.watchedOrganizationArtifacts);
  }

  addStoryArtifactsToWatch(storyId: string, artifacts: Artifact[]) {
    if (!this.watchedStoryArtifacts[storyId]) {
      this.watchedStoryArtifacts[storyId] = [];
    }
    this.watchedStoryArtifacts[storyId].push(
      ...artifacts.map((a) => ({
        id: a.id,
        title: a.title,
      })),
    );
  }

  addOrganizationArtifactsToWatch(
    organizationId: string,
    artifacts: Artifact[],
  ) {
    if (!this.watchedOrganizationArtifacts[organizationId]) {
      this.watchedOrganizationArtifacts[organizationId] = [];
    }
    this.watchedOrganizationArtifacts[organizationId].push(
      ...artifacts.map((a) => ({
        id: a.id,
        title: a.title,
      })),
    );
  }

  updateWatchedStoryArtifacts(updates: StoryArtifactsAiTitleUpdate[]) {
    if (!this.store.story) {
      return;
    }
    for (const storyUpdate of updates) {
      if (this.store.story.id !== storyUpdate.id) {
        continue;
      }
      for (const artifactUpdate of storyUpdate.storyArtifacts) {
        const watchedArtifact = this.watchedStoryArtifacts[
          storyUpdate.id
        ]?.find((a) => a.id === artifactUpdate.id);
        if (
          !watchedArtifact ||
          watchedArtifact.title === artifactUpdate.title ||
          !artifactUpdate.title
        ) {
          continue;
        }
        this.updateWatchedStoryArtifact(artifactUpdate);
        this.removeStoryArtifactFromWatched(storyUpdate.id, artifactUpdate.id);
      }
    }
  }

  updateWatchedOrganizationArtifacts(
    updates: ShowcaseArtifactsAiTitleUpdate[],
  ) {
    if (!this.store.organization) {
      return;
    }
    for (const organizationUpdate of updates) {
      if (this.store.organization.id !== organizationUpdate.id) {
        continue;
      }
      for (const artifactUpdate of organizationUpdate.organizationArtifacts) {
        const watchedArtifact = this.watchedOrganizationArtifacts[
          organizationUpdate.id
        ]?.find((a) => a.id === artifactUpdate.id);
        if (
          !watchedArtifact ||
          watchedArtifact.title === artifactUpdate.title ||
          !artifactUpdate.title
        ) {
          continue;
        }
        this.updateWatchedOrganizationArtifact(artifactUpdate);
        this.removeOrganizationArtifactFromWatched(
          organizationUpdate.id,
          artifactUpdate.id,
        );
      }
    }
    if (this.store.story) {
      updateStoryShowcase(this.store.story, this.store.organization);
    }
  }

  private updateWatchedStoryArtifact(
    artifactUpdate: StoryArtifactsAiTitleUpdate['storyArtifacts'][0],
  ) {
    if (!this.store.story?.storyArtifacts) {
      return;
    }
    const artifactIdx = this.store.story.storyArtifacts!.findIndex(
      (a) => a.id === artifactUpdate.id,
    );
    if (artifactIdx > -1) {
      this.store.story.storyArtifacts[artifactIdx].title = artifactUpdate.title;
    }
    if (this.store.selectedPhotoAssets.tab === PhotoArtifactTab.story) {
      this.updateSelectedPhotoAssetsResource(artifactUpdate);
    }
  }

  private updateWatchedOrganizationArtifact(
    artifactUpdate: ShowcaseArtifactsAiTitleUpdate['organizationArtifacts'][0],
  ) {
    if (!this.store.organization?.organizationArtifacts) {
      return;
    }
    const artifactIdx =
      this.store.organization.organizationArtifacts!.findIndex(
        (a) => a.id === artifactUpdate.id,
      );
    if (artifactIdx > -1) {
      this.store.organization.organizationArtifacts[artifactIdx].title =
        artifactUpdate.title;
    }
    if (this.store.selectedPhotoAssets.tab === PhotoArtifactTab.album) {
      this.updateSelectedPhotoAssetsResource(artifactUpdate);
    }
  }

  private updateSelectedPhotoAssetsResource(
    artifactUpdate: StoryArtifactsAiTitleUpdate['storyArtifacts'][0],
  ) {
    this.store.selectedPhotoAssets.resource =
      this.store.selectedPhotoAssets.resource?.map((r) => ({
        ...r,
        title: r.id === artifactUpdate.id ? artifactUpdate.title : r.title,
      }));
  }

  private removeStoryArtifactFromWatched(storyId: string, artifactId: string) {
    this.watchedStoryArtifacts[storyId] = this.watchedStoryArtifacts[
      storyId
    ].filter((a) => a.id !== artifactId);
    if (!this.watchedStoryArtifacts[storyId].length) {
      delete this.watchedStoryArtifacts[storyId];
    }
  }

  private removeOrganizationArtifactFromWatched(
    organizationId: string,
    artifactId: string,
  ) {
    this.watchedOrganizationArtifacts[organizationId] =
      this.watchedOrganizationArtifacts[organizationId].filter(
        (a) => a.id !== artifactId,
      );
    if (!this.watchedOrganizationArtifacts[organizationId].length) {
      delete this.watchedOrganizationArtifacts[organizationId];
    }
  }
}

export default StatusUpdateManager;
