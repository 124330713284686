import { TranscriptElement } from '../../types.ts/transcript';
import KaraokeWord from './KaraokeWord';

// Represents a block of Karaoke text for use throughout rest of timeline.
// Object needs to support legacy system (direct text manipulation)
// and new system (Karaoke subsystem, specifically getting text from
// KaraokeWords[]).
export default class KaraokeClip {
  id: string;
  startTs: number;
  endTs: number;
  text: string; // Legacy Karaoke system
  words: KaraokeWord[]; // New Karaoke System
  startOffset: number = 0;
  endOffset: number = 0;

  constructor(
    id: string,
    startTs: number,
    endTs: number,
    textOrWords: string | KaraokeWord[],
  ) {
    this.id = id;
    this.startTs = startTs;
    this.endTs = endTs;

    // Determine if we're initializing with text or words
    if (typeof textOrWords === 'string') {
      this.text = textOrWords;
      this.words = []; // Initialize with an empty array, as it's not used
    } else {
      this.words = textOrWords;
      this.text = this.words
        .map((word: KaraokeWord): string => {
          return word.getText();
        })
        .join(''); // Spaces already present.
    }
  }

  // Method to apply global clip-level time adjustments
  applyOffsets() {
    this.startTs += this.startOffset;
    this.endTs += this.endOffset;

    // Update each word's timestamp based on the offsets (only if words are present)
    if (this.words.length > 0) {
      this.words.forEach((word) => {
        word.relativeStart += this.startOffset;
        word.relativeEnd += this.endOffset;
      });
    }
  }

  getElements(): TranscriptElement[] {
    return this.words.flatMap((word) => {
      return word.getElements();
    });
  }

  // We can create a curve of pace of words in transcript to better approximate
  // animiation of text!  Thanks ChatGPT o1-preview!
  calculateCubicBezierForWords(): number[] {
    if (this.words.length <= 1 || this.startTs >= this.endTs) {
      return [0, 0, 1, 1]; // Default linear cubic-bezier
    }

    // Normalize word timings and calculate cumulative progress
    const totalDuration = this.endTs - this.startTs;

    // Function to compute cumulative progress at normalized time t
    const computeCumulativeProgressAt = (t: number): number => {
      let cumulativeProgress = 0;

      for (const word of this.words) {
        const wordStart = (word.relativeStart - this.startTs) / totalDuration;
        const wordEnd = (word.relativeEnd - this.startTs) / totalDuration;

        if (wordEnd <= t) {
          // Word has ended before time t, include full duration
          cumulativeProgress += wordEnd - wordStart;
        } else if (wordStart >= t) {
          // Word starts after time t, do not include
          continue;
        } else {
          // Word is in progress at time t, include partial duration
          cumulativeProgress += t - wordStart;
        }
      }

      return cumulativeProgress; // Already normalized
    };

    // Choose t1 and t2 for solving the Bezier control points
    const t1 = 0.3;
    const t2 = 0.6;

    // Compute cumulative progress at t1 and t2
    const p1 = computeCumulativeProgressAt(t1);
    const p2 = computeCumulativeProgressAt(t2);

    // Coefficients for the Bezier curve equations
    const a1 = 3 * (1 - t1) * (1 - t1) * t1;
    const b1 = 3 * (1 - t1) * t1 * t1;
    const c1 = t1 * t1 * t1;

    const a2 = 3 * (1 - t2) * (1 - t2) * t2;
    const b2 = 3 * (1 - t2) * t2 * t2;
    const c2 = t2 * t2 * t2;

    // Right-hand sides of the equations
    const rhs1 = p1 - c1;
    const rhs2 = p2 - c2;

    // Solve the linear system to find y1 and y2
    const denominator = a1 * b2 - a2 * b1;
    if (denominator === 0) {
      // Fallback to linear if system cannot be solved
      return [0, 0, 1, 1];
    }

    const y1 = (rhs1 * b2 - rhs2 * b1) / denominator;
    const y2 = (a1 * rhs2 - a2 * rhs1) / denominator;

    // Ensure control points are within [0,1]
    const y1Clamped = Math.min(Math.max(y1, 0), 1);
    const y2Clamped = Math.min(Math.max(y2, 0), 1);

    // Set x1 and x2 equal to t1 and t2
    const x1 = t1;
    const x2 = t2;

    return [x1, y1Clamped, x2, y2Clamped];
  }
}
