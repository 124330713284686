import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const UpDownIcon = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path d="M8 1.61108V14.3889" stroke={strokeColor} />
      <path
        d="M11.8333 10.5554L7.99999 14.3888L4.16666 10.5554"
        stroke={strokeColor}
      />
      <path
        d="M11.8333 5.44442L7.99999 1.61108L4.16666 5.44442"
        stroke={strokeColor}
      />
    </svg>
  );
};

export default UpDownIcon;
