import {
  TranscriptChange,
  TranscriptTextElement,
  TranscriptElement,
} from '../../types.ts/transcript';
import { getClosestTextIndexToRight } from '../utils';
import ChangeHandler from './ChangeHandler';

class UntrimEndChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'untrim_end' },
    originalElements: TranscriptElement[],
  ) {
    const { fromTs, toTs, newTs: intoTs, type } = change;
    let addedDuration = 0; //toTs - fromTs;

    let { firstIndexToUntrim, lastIndexToUntrim, lastTextElementIndex } =
      this.getRangesToUntrimEnd(
        transcriptionElementsMutable,
        fromTs,
        toTs,
        intoTs,
        originalElements,
      );

    const firstElementToUntrim = originalElements[
      firstIndexToUntrim
    ] as TranscriptTextElement;

    // debugger;
    if (lastTextElementIndex > 0) {
      const lastTextElement = transcriptionElementsMutable[
        lastTextElementIndex
      ] as TranscriptTextElement;
      if (
        firstIndexToUntrim > 0 &&
        firstIndexToUntrim === lastTextElement.initial_index
      ) {
        const newTs =
          lastTextElement.ts! +
          (firstElementToUntrim.end_ts - firstElementToUntrim.ts);
        const newBufferAfter = firstElementToUntrim.buffer_after_ts || 0;
        addedDuration +=
          newTs -
          lastTextElement.end_ts +
          newBufferAfter -
          (lastTextElement.buffer_after_ts || 0);
        lastTextElement.end_ts = newTs;
        lastTextElement.buffer_after_ts =
          firstElementToUntrim.buffer_after_ts || 0;

        lastTextElementIndex++;
        firstIndexToUntrim++;
        // last element overlaps
        // expand first element in existing track
      } else if (firstIndexToUntrim > 0) {
        addedDuration -=
          fromTs -
          firstElementToUntrim.ts +
          (firstElementToUntrim.buffer_before_ts || 0);
        lastTextElementIndex = getClosestTextIndexToRight(
          lastTextElementIndex + 1,
          transcriptionElementsMutable,
        );
        // expand last element of added part
      } else {
        // edge case, last word in the track
      }
    }
    // debugger;
    const elementsCount = lastIndexToUntrim - firstIndexToUntrim;

    for (let i = 0; i <= elementsCount; i++) {
      const originalElement = originalElements[firstIndexToUntrim + i];
      const finalElement =
        transcriptionElementsMutable[lastTextElementIndex + i];
      if (finalElement.initial_index === firstIndexToUntrim + i) {
        delete finalElement.state;
        if (finalElement.type === 'text' && originalElement.type === 'text') {
          // todo handle partial restore of the first word (addedDuration becomes < 0)
          finalElement.buffer_before_ts = originalElement.buffer_before_ts || 0;
          finalElement.ts =
            intoTs + addedDuration + finalElement.buffer_before_ts;
          finalElement.end_ts =
            finalElement.ts + (originalElement.end_ts - originalElement.ts);
          finalElement.buffer_after_ts = originalElement.buffer_after_ts || 0;
          if (i === elementsCount) {
            // last element
            if (
              finalElement.end_ts + finalElement.buffer_after_ts >
              intoTs + (toTs - fromTs)
            ) {
              const newTs = Math.min(
                finalElement.end_ts,
                intoTs + (toTs - fromTs),
              );
              finalElement.buffer_after_ts = intoTs + (toTs - fromTs) - newTs;
              finalElement.trim_end = finalElement.end_ts - newTs;
              finalElement.end_ts = newTs;
            }
          }
          addedDuration +=
            finalElement.end_ts -
            finalElement.ts +
            finalElement.buffer_after_ts +
            finalElement.buffer_before_ts;
        }
      } else {
        // debugger;
        const addedSegment = originalElements
          .slice(firstIndexToUntrim + i, firstIndexToUntrim + elementsCount)
          .map((el) => {
            if (el.type === 'punct') {
              return { ...el, state: 'added' } as TranscriptElement;
            } else {
              const ts = intoTs + toTs - el.ts!; // does el.end_ts - fromTs === addedDuration - buffer_after?
              const end_ts = ts + (el.end_ts - el.ts);
              return { ...el, state: 'added', end_ts, ts } as TranscriptElement;
            }
          });
        // debugger;
        transcriptionElementsMutable.splice(
          lastTextElementIndex + elementsCount,
          0,
          ...addedSegment,
        );
        break;
      }
    }
    // this.onFinalTranscriptionElementsChange(finalElements);
    return {
      fromIndex: lastTextElementIndex,
      toIndex: lastTextElementIndex + elementsCount,
    };
  }

  getRangesToUntrimEnd(
    transcriptionElements: TranscriptElement[],
    fromTs: number,
    toTs: number,
    intoTs: number,
    originalElements: TranscriptElement[],
  ) {
    //@ts-ignore
    let lastTextElementIndex = transcriptionElements.findLastIndex(
      //@ts-ignore
      (el) =>
        el.type === 'text' &&
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.ts < intoTs,
    );
    // debugger;
    //@ts-ignore
    const lastIndexToUntrim = originalElements.findLastIndex(
      //@ts-ignore
      (el) =>
        el.type === 'text' &&
        el.ts! < toTs &&
        el.end_ts! - toTs < toTs - el.ts!,
    );

    //@ts-ignore
    let firstIndexToUntrim = originalElements.findLastIndex(
      //@ts-ignore
      (el) => el.type === 'text' && el.ts < fromTs,
    );

    return { firstIndexToUntrim, lastIndexToUntrim, lastTextElementIndex };
  }
}

export default UntrimEndChangeHandler;
