type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  strokeWidth?: number;
};
const PhotoIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '22'}
      height={props.height || '18'}
      viewBox="0 0 22 18"
      fill="none"
    >
      <path
        d="M2.53846 16.9998H19.4615C20.3112 16.9998 21 16.2835 21 15.3999V2.59998C21 1.71633 20.3112 1 19.4615 1H2.53846C1.68879 1 1 1.71633 1 2.59998V15.3999C1 16.2835 1.68879 16.9998 2.53846 16.9998Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || 1}
      />
      <path
        d="M14.8493 7.73945C15.9073 7.73945 16.765 6.84748 16.765 5.74717C16.765 4.64686 15.9073 3.75488 14.8493 3.75488C13.7913 3.75488 12.9336 4.64686 12.9336 5.74717C12.9336 6.84748 13.7913 7.73945 14.8493 7.73945Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || 1}
      />
      <path
        d="M14.2041 16.9999C13.7264 14.3537 12.3602 11.9701 10.3493 10.2744C8.33842 8.57876 5.81362 7.6813 3.22542 7.74216C2.47868 7.7401 1.73347 7.81243 1 7.95818"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || 1}
      />
      <path
        d="M20.9999 12.1054C19.788 11.6798 18.5175 11.4631 17.2385 11.4639C15.6195 11.4602 14.0169 11.801 12.5293 12.4653"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || 1}
      />
    </svg>
  );
};

export default PhotoIcon;
