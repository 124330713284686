import {
  TranscriptChange,
  TranscriptElement,
  TranscriptTextElement,
} from '../../types.ts/transcript';
import { getClosestTextIndexToLeft } from '../utils';
import ChangeHandler from './ChangeHandler';

class InsertTextChangeHandler extends ChangeHandler {
  apply(
    transcriptElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'insert_text' },
    originalElements: TranscriptElement[],
  ) {
    let elements = transcriptElementsMutable;

    let timeAdded = change.timeBufferBefore;
    let isFirst = true;
    if (change.newIndex !== change.index) {
      const addedSegment = originalElements
        .slice(change.index, change.index + change.count)
        .map((el, i) => {
          let addedElement = {
            ...el,
            initial_index: change.index + i,
            state: 'added',
          } as TranscriptElement;
          if (el.type === 'text') {
            timeAdded += isFirst ? 0 : el.buffer_before_ts || 0;
            (addedElement as TranscriptTextElement).trim_start = Math.max(
              0,
              -timeAdded,
            );
            addedElement.ts = Math.max(change.newTs + timeAdded, change.newTs);
            addedElement.end_ts =
              change.newTs + timeAdded + el.end_ts! - el.ts!;
            timeAdded += el.end_ts! - el.ts! + (el.buffer_after_ts || 0);
            isFirst = false;
          }
          return addedElement;
        });
      const lastAdded = addedSegment[
        getClosestTextIndexToLeft(addedSegment.length - 1, addedSegment)
      ] as TranscriptTextElement;
      const lastBufferDiff =
        change.timeBufferAfter - lastAdded.buffer_after_ts!;
      if (change.timeBufferAfter < 0) {
        lastAdded.buffer_after_ts = 0;
        lastAdded.trim_end = -change.timeBufferAfter;
      } else {
        lastAdded.buffer_after_ts = change.timeBufferAfter;
      }
      timeAdded += lastBufferDiff;
      elements.splice(change.newIndex, 0, ...addedSegment);
    }

    for (let j = change.newIndex + change.count; j < elements.length; j++) {
      const element = elements[j];
      if (element.type === 'text') {
        element.ts += timeAdded;
        element.end_ts += timeAdded;
      }
    }
    elements = elements.filter(Boolean);

    this.fixCapitalizationAt(elements, change.index);
    this.fixCapitalizationAt(elements, change.index + change.count);
    transcriptElementsMutable = elements;
  }
}

export default InsertTextChangeHandler;
