import styled from 'styled-components';
import InfoIcon from '../../svgs/InfoIconAlt';
import { useEffect, useRef, useState } from 'react';
import { VideoAction, VideoVersion } from '../../types.ts/story';

const hasPublishAsLastAction = (videoVersion: VideoVersion): boolean => {
  return (
    !!videoVersion.lastActionJson?.type &&
    [
      'publish',
      'system_render_start',
      'system_render_update',
      'system_render_error',
      'system_render_confirm',
    ].includes(videoVersion.lastActionJson.type)
  );
};

type AggregateVideoVersion = VideoVersion & { pastVersions?: VideoVersion[] };

export const aggregateVersionsOfTheSameLastAction = (
  videoVersions?: VideoVersion[],
): AggregateVideoVersion[] => {
  let result: AggregateVideoVersion[] = [];
  if (!videoVersions?.length) {
    return result;
  }
  for (let i = 0; i < videoVersions.length; i++) {
    const aggregate: AggregateVideoVersion = { ...videoVersions[i] };
    if (hasPublishAsLastAction(videoVersions[i])) {
      aggregate.pastVersions = [];
      for (let j = i + 1; j < videoVersions.length; j++) {
        if (hasPublishAsLastAction(videoVersions[j])) {
          aggregate.pastVersions.push(videoVersions[j]);
          i++;
        } else {
          break;
        }
      }
    }
    result.push(aggregate);
  }
  return result;
};

export const SameLastActionAggregation = ({
  allVersions,
  formattedAction,
  editor,
  sidebarRef,
}: {
  allVersions: VideoVersion[];
  formattedAction: string;
  editor: string;
  sidebarRef: React.RefObject<HTMLDivElement>;
}) => {
  const [tooltip, setTooltip] = useState<DOMRect | null>(null);
  const [topForUpwardPos, setTopForUpwardPos] = useState<number | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsTooltipVisible(false);

    if (tooltip && tooltipRef.current) {
      if (!sidebarRef?.current) {
        setTopForUpwardPos(null);
        setIsTooltipVisible(true);
        return;
      }

      const sidebarRect = sidebarRef.current.getBoundingClientRect();
      const top = tooltip.top + 15;
      const height = tooltipRef.current.getBoundingClientRect().height || 186;
      const hasEnoughSpaceBot = sidebarRect.bottom - top >= height;

      setTopForUpwardPos(hasEnoughSpaceBot ? null : tooltip.top - height);
      setIsTooltipVisible(true);
    }
  }, [tooltip]);

  return (
    <Container
      onMouseEnter={(e) => {
        setTooltip(e.currentTarget.getBoundingClientRect());
      }}
      onMouseMove={(e) => {
        setTooltip(e.currentTarget.getBoundingClientRect());
      }}
      onMouseLeave={() => {
        setTooltip(null);
      }}
    >
      <InfoIcon width="16" height="16" />
      {tooltip && (
        <Tooltip
          top={topForUpwardPos == null ? tooltip.top + 15 : topForUpwardPos}
          left={tooltip.left - 250 - 25}
          onClick={(event) => event.stopPropagation()}
          ref={tooltipRef}
          isVisible={isTooltipVisible}
        >
          <TooltipHeader>
            <InfoIcon /> <TooltipHeaderText>HISTORY DETAILS</TooltipHeaderText>
          </TooltipHeader>
          <TooltipBody>
            <LastAction>
              {formattedAction} by {editor}
            </LastAction>
            {[...allVersions].reverse().map((v) => (
              <VersionAction>
                <span>{toDetailedFormattedAction(v.lastActionJson)}</span>
                <span>{toDetailedTime(v.lastActionJson)}</span>
              </VersionAction>
            ))}
          </TooltipBody>
        </Tooltip>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 32px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #4ad067;
`;

const Tooltip = styled.div<
  Record<'top' | 'left', number> & {
    isVisible?: boolean;
  }
>`
  padding: 16px;
  border-radius: 16px;
  z-index: 10000;
  position: fixed;
  background: #03041a;
  border: 1px solid #484848;
  width: 250px;
  font-family: Inter;
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

const TooltipHeader = styled.div`
  display: flex;
  border: none;
  border-bottom: 1px solid #484848;
  padding-bottom: 16px;
  align-items: center;
`;

const TooltipHeaderText = styled.div`
  margin-left: 16px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
`;

const TooltipBody = styled.div`
  padding: 8px 0 9px 0;
  color: #f3e9d7;
`;

const LastAction = styled.div`
  line-height: 19px;
  font-size: 12px;
  font-weight: 700;
`;

const VersionAction = styled.div`
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const toDetailedFormattedAction = (lastAction?: VideoAction): string => {
  if (!lastAction?.type) {
    return 'Modified';
  }
  return {
    save: 'Saved',
    autosave: 'Autosaved',
    unsave: 'Unsaved',
    publish: 'Published Complete',
    system_render_start: 'Publish Requested',
    system_render_update: 'Published Start',
    system_render_error: 'Published Error',
    system_render_confirm: 'Published Reconfirmed',
  }[lastAction.type];
};

const toDetailedTime = (lastAction?: VideoAction): string => {
  if (!lastAction?.date) {
    return '--:--';
  }
  return new Date(lastAction.date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });
};
