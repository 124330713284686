import { SetStateAction, useRef, useState } from 'react';
import { PunchListItem } from '../../types.ts/story';
import styled, { css } from 'styled-components';
import { runInAction } from 'mobx';
import { videoCreator } from '../../stores/VideoCreatorStore';
import TimelinePhotoIcon from '../../svgs/TimelinePhotoIcon';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import DeleteIcon from '../../svgs/DeleteIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { observer } from 'mobx-react-lite';

type Props = {
  punchListItem: PunchListItem;
  selectedPunchListId: string | undefined;
  setSelectedPunchListId: (value: SetStateAction<string | undefined>) => void;
  toggleModal: (
    value: SetStateAction<{
      item: PunchListItem | null;
      archive: PunchListItem | null;
    }>,
  ) => void;
};
const PunchListContentItem = observer((props: Props) => {
  const {
    punchListItem,
    toggleModal,
    selectedPunchListId,
    setSelectedPunchListId,
  } = props;
  if (!punchListItem.line) return null;
  const line = punchListItem.line;
  const description = punchListItem.description;
  const [isActionClicked, setIsActionClicked] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useOutsideAlerter(buttonRef, () => {
    setIsActionClicked(false);
  });
  const isDisabled = videoCreator.addedPunchListItemId === punchListItem.id;
  return (
    <Entry
      key={punchListItem.id}
      onClick={() => {
        if (isDisabled) return;
        toggleModal({ item: punchListItem, archive: punchListItem });
        setSelectedPunchListId(punchListItem.id);
      }}
      isDisabled={isDisabled}
      isSelected={selectedPunchListId === punchListItem.id}
    >
      <ImageWrapper
        hasImage={!!punchListItem.artifactSrc}
        onClick={() => {
          runInAction(async () => {
            videoCreator.setActiveElements(punchListItem.id!.toString());
          });
        }}
      >
        {punchListItem.artifactSrc ? (
          <Image src={punchListItem.artifactSrc} />
        ) : (
          <TimelinePhotoIcon strokeColor="#828282" />
        )}
      </ImageWrapper>

      <Text>
        <div className="quote">{line}</div>
        <TextDescription>
          <div className="text">{description}</div>
          <div
            className="ellipsis"
            onClick={(e) => {
              e.stopPropagation();
              setIsActionClicked(true);
            }}
          >
            <EllipsisIcon width="12" fillColor="#F2D093" />
          </div>
        </TextDescription>
      </Text>
      <RemoveButton
        isVisible={isActionClicked}
        ref={buttonRef}
        disabled={isDisabled}
        onClick={async (e) => {
          e.stopPropagation();
          if (punchListItem.id) {
            videoCreator.punchListManager.removePunchListItem(punchListItem.id);
            await videoCreator.removeAPhotoHighlightTranscriptChange(
              punchListItem.id,
            );
          }
          setIsActionClicked(false);
        }}
      >
        Remove <DeleteIcon />
      </RemoveButton>
    </Entry>
  );
});

export default PunchListContentItem;

const Entry = styled.div<{ isSelected: boolean; isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;
  height: 59px;
  position: relative;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: #4f4f4f;
      border-radius: 8px;
    `}
`;
const Text = styled.div`
  width: 65%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;
  height: 59px;

  .quote {
    width: 100%;
    white-space: nowrap;
    color: #45d483;
    font-weight: 500;
    line-height: 19.2px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
`;

const TextDescription = styled.div`
  display: flex;
  gap: 3px;
  .text {
    color: #bdbdbd;
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 38px;
    overflow-y: hidden;
  }
  .ellipsis {
    width: 10%;
    margin-left: auto;
    text-align: right;
  }
`;
const ImageWrapper = styled.div<{ hasImage: boolean }>`
  background:
    linear-gradient(0deg, #4f4f4f 0%, #4f4f4f 100%),
    lightgray 50% / cover no-repeat;
  border: 1px solid #03041926;
  color: #4f4f4f;
  width: 90px;
  height: 58px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  object-position: top;
`;
const Icon = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  width: fit-content;
  &:active svg {
    fill: #fff;
  }
`;

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #f3e9d7;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const RemoveButton = styled.button<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  right: 0;
  bottom: -28px;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 15px 12px;
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  gap: 25px;
  z-index: 1;
  cursor: pointer;
`;
