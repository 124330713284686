import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Transcript } from '../transcript/Transcript';

export const LeftSidePanel: React.FC<{}> = observer(() => {
  return (
    <Panel>
      <Transcript />
    </Panel>
  );
});

const Panel = styled.div`
  position: relative;
  padding: 10px 5px 5px;
  width: 380px;
  display: flex;
  border-right: 1px solid #404040;
`;

const Main = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 15px 20px;
  width: 300px;
  display: flex;
`;

const ScrollableArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  padding: 0 10px 15px;
  display: flex;
  flex-direction: column;
`;

const WelcomeScreen = styled(ScrollableArea)`
  padding: 30px;
  height: 100%;
  justify-content: space-between;
`;

// const LinkButton = styled.div`
//   margin: 15px 0;
//   padding: 10px 0;
//   background: #292b2e;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
// `;
