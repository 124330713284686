import { makeAutoObservable } from 'mobx';
import { ElementState } from '../../renderer/ElementState';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';
import { ExtraElementData, Video } from '../../types.ts/story';
import {
  convertFromPixels,
  convertToPixels,
  getMinMaxYPosition,
} from '../../videoTranscriptionProcessor/utils';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';
import { FONT_SIZE_VALUES } from '../../utility/general';
import { AspectRatio } from '../../types.ts/video';

export const DEFAULT_TEXT_CONFIG = {
  duration: 4,
  animations: [
    { type: 'fade', time: 0, duration: 1 },
    { type: 'fade', time: 'end', duration: 1, reversed: true },
  ],
  font_size: '32',
  width: '80%',
  font_family: 'Inter',
  font_weight: '600',
  fill_color: 'rgba(0, 0, 0, 1)',
  background_color: 'rgba(255, 255, 255, 0.7)',
};

export class TextProducer {
  constructor() {
    makeAutoObservable(this);
  }
  config: ElementState['source'] = DEFAULT_TEXT_CONFIG;
  isPropsChanged = false;
  creatingNewText = false;

  initializeConfig() {
    const aspectRatio =
      videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
    const defaultTextSetting =
      this.getBasicTextSettingByAspectRatio(aspectRatio);
    return {
      ...DEFAULT_TEXT_CONFIG,
      ...defaultTextSetting,
    };
  }

  getTextConfig() {
    return this.config;
  }

  getBasicTextSettingByAspectRatio(
    aspectRatio: Video['aspectRatio'] = videoCreator.currentVideo
      ?.aspectRatio || AspectRatio.AR_16_9,
  ) {
    switch (aspectRatio) {
      case AspectRatio.AR_16_9:
        return {
          font_size: '32', // '5.5556 vh', // should be 32
          y: '60%',
          width: '76%',
        };
      case AspectRatio.AR_1_1:
        return {
          font_size: '30', //'5 vh', // should be 30
          y: '55%',
          width: '78%',
        };
      case AspectRatio.AR_9_16:
        return {
          font_size: '24', //'5 vh', // should be 24
          y: '45%',
          width: '84%',
        };
      default:
        return {
          font_size: '32', //'5.5556 vh', // should be 32
          y: '60%',
          width: '76%',
        };
    }
  }

  setDefaultTextConfig(source: ElementState | null = null) {
    const config = this.initializeConfig();
    console.log('Setting default text config', config);
    const defaultConfig = source || {
      ...config,
    };

    this.config = defaultConfig;
  }

  setTextConfig(config: ElementState['source'] | undefined) {
    const configuration: ElementState['source'] = {
      ...(config || this.initializeConfig()),
      text: config?.text || '',
    };
    if (config?.font_size) {
      configuration.font_size = this.getFontSize(config.font_size);
    }
    this.config = configuration;
  }

  async produceText() {
    this.creatingNewText = true;
    const newConfig = { ...this.config };
    delete newConfig.id;
    delete newConfig.track;
    delete newConfig.time;
    delete newConfig.text;
    const initialConfig = this.initializeConfig();

    this.config = newConfig;

    const activeElement = videoCreator.getActiveElement();
    const activeSource = activeElement?.source;

    let targetY = initialConfig.y;

    if (
      activeSource?.type === 'text' &&
      activeSource.track !== KARAOKE_TRACK_NUMBER
    ) {
      const height = Math.ceil(parseFloat(activeSource.font_size) * 1.7);
      targetY = `${parseFloat(activeSource.y || '50') + height}%`;
      const minMaxYPosition = getMinMaxYPosition(
        this.getFontSize(newConfig.font_size).toString(),
      );
      const max = 100 - minMaxYPosition[0];
      if (parseFloat(targetY) > max) {
        targetY = initialConfig.y;
      }
    }
    await videoCreator.createElement({
      type: 'text',
      ...newConfig,
      y: targetY,
      font_size: this.convertToVh(this.config.font_size || '32'),
    });

    const activeElementIds = videoCreator.activeElementIds;
    const configId = activeElementIds[0];
    const templateId = videoCreator.textBrandingService.selectedTemplateId;

    if (configId && templateId) {
      const existingData = videoCreator.currentVideo?.extraElementData || {};

      videoCreator.currentVideo!.extraElementData = {
        ...existingData,
        lowerThirdTemplates: {
          ...(existingData?.lowerThirdTemplates || {}),
          [configId]: templateId,
        } as ExtraElementData | KaraokeConfig | null | undefined,
      };
    }
    this.creatingNewText = false;
  }

  async modifyProperty(
    propertyMap: ElementState['source'],
    switched_template = false,
    configId = this.config.id,
  ) {
    this.setTextConfig({ ...this.config, ...propertyMap });

    for (let property in propertyMap) {
      if (property === 'font_size') {
        propertyMap[property] = this.convertToVh(propertyMap[property]);
      }
    }
    videoCreator.textBrandingService.checkPropsChanged(
      'basic',
      propertyMap,
      switched_template,
    );

    if (!configId) return;
    const isChangingText =
      Object.keys(propertyMap).length === 1 && 'text' in propertyMap;
    await videoCreator.applyVideoStateModifications(
      (Object.keys(propertyMap) as Array<keyof ElementState['source']>).reduce(
        (acc, key) => {
          acc[`${configId}.${key}`] = propertyMap[key];
          return acc;
        },
        {} as any,
      ),
      true,
      isChangingText ? 'changing text' : 'modifying text element',
    );
  }

  getFontSize(fontSize: string | number) {
    if (typeof fontSize === 'number') return this.findClosestValue(fontSize);

    let width = videoCreator.renderer?.getSource()?.width || 1280;
    let height = videoCreator.renderer?.getSource()?.height || 720;
    if (fontSize?.includes('vh')) {
      const value = convertToPixels(parseFloat(fontSize), 'vh', {
        width,
        height,
      });
      return this.findClosestValue(value);
    } else if (fontSize?.includes('vw')) {
      const value = convertToPixels(parseFloat(fontSize), 'vw', {
        width,
        height,
      });
      return this.findClosestValue(value);
    }

    return this.findClosestValue(parseInt(fontSize));
  }

  private findClosestValue(value: number) {
    if (FONT_SIZE_VALUES.includes(value)) return value;

    let minDifference = Infinity;
    let closestValue = FONT_SIZE_VALUES[0];

    for (let i = 0; i < FONT_SIZE_VALUES.length; i++) {
      let difference = Math.abs(FONT_SIZE_VALUES[i] - value);

      if (difference < minDifference) {
        minDifference = difference;
        closestValue = FONT_SIZE_VALUES[i];
      }
    }

    return closestValue;
  }

  convertToVh(px: string | null) {
    let width = videoCreator.renderer?.getSource()?.width || 1280;
    let height = videoCreator.renderer?.getSource()?.height || 720;
    const value = convertFromPixels(parseFloat(px || '32'), 'vh', {
      width,
      height,
    });
    return `${value} vh`;
  }
}
