import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../common/Modal';

type Props = {
  closeModal: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText: React.ReactNode;
  cancelText: React.ReactNode;
  headingText?: React.ReactNode;
};

export const ConfirmModal: React.FC<Props> = ({
  closeModal,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  headingText = 'Are you sure?',
}: Props) => {
  return (
    <Modal isOpen closeModal={closeModal}>
      <Wrapper>
        <Heading>{headingText}</Heading>
        <Buttons>
          <CancelButton onClick={onCancel}>{cancelText}</CancelButton>
          <ConfirmButton onClick={onConfirm}>{confirmText}</ConfirmButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  width: 459px;
  height: 200px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #ef5da8;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  height: 48px;
  gap: 8px;
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ef5da8;
  cursor: pointer;

  font-size: 14px;
  font-weight: 700;

  color: #ef5da8;
`;

const ConfirmButton = styled(CancelButton)`
  background: #ef5da8;
  color: #03041a;
`;
