import styled from 'styled-components';
import type { VideoAction } from '../../types.ts/story';

const LastVideoAction = ({
  lastAction: { type, editor, resolution, date },
}: {
  lastAction: VideoAction;
}) => {
  const formattedAction = {
    save: 'Saved',
    autosave: 'Autosaved',
    unsave: 'Unsaved',
    publish: 'Published',
    system_render_start: 'Modified',
    system_render_update: 'Modified',
    system_render_error: 'Modified',
    system_render_confirm: 'Published',
  }[type];
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
  const formattedTime = new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return (
    <Container>
      {formattedAction} by {editor}
      {type === 'publish' && resolution ? ` in ${resolution}` : ''} on{' '}
      {formattedDate} {formattedTime}
    </Container>
  );
};

const Container = styled.div`
  color: #777777;
  font-size: 10px;
  line-height: 1.21;
  font-weight: 500;
  font-family: Inter, sans-serif;
  margin-top: 4px;
`;

export default LastVideoAction;
