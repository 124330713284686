type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const CameraIcon = (props: Props) => {
  const {
    width = '17',
    height = '13',
    viewBox = '0 0 17 13',
    strokeColor,
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M12.2049 3.06411L11.3844 0.5H5.74333L4.9228 3.06411H2.6664C1.84588 3.06411 1.12793 3.78207 1.12793 4.60258V10.9615C1.12793 11.782 1.84588 12.5 2.6664 12.5H14.4613C15.2818 12.5 15.9998 11.782 15.9998 10.9615V4.60258C15.9998 3.78207 15.2818 3.06411 14.4613 3.06411H12.2049Z"
        stroke={strokeColor || 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58943 9.96157C9.94892 9.96157 11.051 8.85951 11.051 7.50002C11.051 6.14053 9.94892 5.03857 8.58943 5.03857C7.23004 5.03857 6.12793 6.14053 6.12793 7.50002C6.12793 8.85951 7.23004 9.96157 8.58943 9.96157Z"
        stroke={strokeColor || 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CameraIcon;
