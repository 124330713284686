import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { Story } from '../../../types.ts/story';
import { videoCreator } from '../../../stores/VideoCreatorStore';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import {
  startProductionToCorrectAudio,
  startProductionToRemoveMusicFromVideo,
} from '../../../utility/auphonic';
import { bucketPathKey, renameFile, uploadFileToS3 } from '../../../utility/s3';
import { NEW_STORIES_COMPLETION_QUERY } from '../../../utility/gql';

export enum Step {
  one = 1,
  two,
  three,
  four,
  five,
}

export type ArtifactUpload = SimpleSchemaTypes.Upload & { _internalId: string };
export type ArtifactFile = File & { _internalId: string };

export type StoryVideoMusicStrategy = 'keep' | 'process';

type ReturnType = {
  step: Step;
  goToNextStep: () => void;
  canGoToStep: (s: number) => boolean;
  handleStoryVideoUpload: (f: File[]) => void;
  handleStoryBRollUpload: (f: File[]) => void;
  storyName: string;
  setStoryName: (s: string) => void;
  storyType: 'raw' | 'produced' | null;
  setStoryType: (t: 'raw' | 'produced') => void;
  videoMusicStrategy: StoryVideoMusicStrategy;
  setVideoMusicStrategy: (s: StoryVideoMusicStrategy) => void;
  storyArtifactsFiles: ArtifactFile[];
  storyArtifactsVideoFiles: ArtifactFile[];
  storyArtifacts: ArtifactUpload[];
  storyArtifactsVideo: ArtifactUpload[];
  saveArtifactNotes: (internalId: string) => void;
  setArtifactNotes: (internalId: string, notes: string) => void;
  deleteArtifact: (internalId: string) => void;
  saveArtifactVideoNotes: (internalId: string) => void;
  setArtifactVideoNotes: (internalId: string, notes: string) => void;
  deleteArtifactVideo: (internalId: string) => void;
};

type Params = {
  onStoryInitialized: (s: Story) => void;
  onStoryCreated: (s: Story) => void;
  onStoryProgress: (percent: number) => void;
  logError: (...data: any[]) => void;
  logWarning: (...data: any[]) => void;
};

export function useStoryCreator(params: Params): ReturnType {
  const [step, setStep] = useState<Step>(1);
  const [story, setStory] = useState<Story>();

  // step 1 data
  const [storyVideoFile, setStoryVideoFile] = useState<File | null>(null);
  const blankStoryRef = useRef<Story | null>(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);

  // step 2 data
  const [storyType, setStoryType] = useState<'raw' | 'produced' | null>(null);
  const isProcessingAudioRef = useRef<boolean>(false);
  const [videoMusicStrategy, setVideoMusicStrategy] =
    useState<StoryVideoMusicStrategy>('keep');

  // step 3 data
  const [storyName, setStoryName] = useState<string>('');
  const [storyTellerName, _setStoryTellerName] = useState<string>(
    videoCreator?.organization?.title || 'no_org',
  );
  const [storyTellerTitle, _setStoryTellerTitle] = useState<string>(
    'Organization storyteller',
  );

  // step 4 data
  const [storyArtifactsFiles, setStoryArtifactsFiles] = useState<
    ArtifactFile[]
  >([]);
  const [storyArtifacts, setStoryArtifacts] = useState<ArtifactUpload[]>([]);
  const [storyArtifactsVideoFiles, setStoryArtifactsVideoFiles] = useState<
    ArtifactFile[]
  >([]);
  const [storyArtifactsVideo, setStoryArtifactsVideo] = useState<
    ArtifactUpload[]
  >([]);
  const [bRollReady, setBRollReady] = useState<boolean>(false);
  const [storyVideoUploadReady, setStoryVideoUploadReady] =
    useState<boolean>(false);
  const storyArtifactsRef = useRef<ArtifactUpload[]>([]);
  const storyArtifactsFilesRef = useRef<ArtifactFile[]>([]);
  const storyArtifactsVideoRef = useRef<ArtifactUpload[]>([]);
  const storyArtifactsVideoFilesRef = useRef<ArtifactFile[]>([]);
  const lastProgressPercentRef = useRef<number>(0);
  const [nextCallback, setNextCallback] = useState<'init' | 'create'>('init');

  // option to remove music was not selected - proceed with regular video upload
  useEffect(() => {
    if (story && uploadedFileUrl && videoMusicStrategy === 'keep') {
      (async () => {
        await updateStoryWithUploadedFile();
      })();
      setStoryVideoUploadReady(true);
    }
  }, [uploadedFileUrl, story, storyType, videoMusicStrategy]);

  // option to remove music from video is selected - start production to remove music
  useEffect(() => {
    if (
      uploadedFileUrl &&
      videoMusicStrategy === 'process' &&
      !isProcessingAudioRef.current
    ) {
      (async () => {
        isProcessingAudioRef.current = true;
        try {
          if (storyType === 'produced') {
            await startProductionToRemoveMusicFromVideo({
              inputFileUrl: uploadedFileUrl,
            });
          } else {
            await startProductionToCorrectAudio({
              inputFileUrl: uploadedFileUrl,
            });
          }
          setStoryVideoUploadReady(true);
        } catch (error) {
          handleProductionError(error);
        }
      })();
    }
  }, [uploadedFileUrl, storyType, videoMusicStrategy]);

  useEffect(() => {
    if (step === Step.four && !story) {
      handleStoryCreate();
    }
    if (step === Step.five && story?.id && nextCallback === 'init') {
      lastProgressPercentRef.current = Infinity; // stop progress updates after story is initialized
      (async () => {
        params.onStoryInitialized({
          ...story,
          ...(await getStoryUpdates()),
        });
        setNextCallback('create');
      })();
    }
  }, [step, story?.id, nextCallback]);

  useEffect(() => {
    if (
      step === Step.five &&
      story &&
      storyVideoUploadReady &&
      bRollReady &&
      nextCallback === 'create'
    ) {
      (async () => {
        params.onStoryCreated({
          ...story,
          ...(await getStoryUpdates()),
        });
      })();
    }
  }, [step, story, storyVideoUploadReady, bRollReady, nextCallback]);

  useEffect(() => {
    // to prevent methods' closure from capturing stale state
    storyArtifactsRef.current = storyArtifacts;
    storyArtifactsFilesRef.current = storyArtifactsFiles;
    storyArtifactsVideoRef.current = storyArtifactsVideo;
    storyArtifactsVideoFilesRef.current = storyArtifactsVideoFiles;

    // b-roll is uploaded when story artifacts' length matches files' length
    if (
      storyArtifacts.length === storyArtifactsFiles.length &&
      storyArtifactsVideo.length === storyArtifactsVideoFiles.length
    ) {
      // if we are at the last step, time to update story with uploaded b-roll and mark b-roll as ready
      // doing it at the last step makes it less error prone, because b-roll can change multiple times at step 4
      if (story && step === Step.five) {
        (async () => {
          if (
            storyArtifactsFiles.length > 0 ||
            storyArtifactsVideoFiles.length > 0
          ) {
            const storyUpdate = await videoCreator.updateStory({
              id: story.id,
              storyArtifacts: storyArtifacts.map((a) => ({
                id: a.id,
              })),
              storyArtifactsVideo: storyArtifactsVideo.map((a) => ({
                id: a.id,
              })),
            });
            setStory({
              ...story,
              ...storyUpdate,
            });
          }
          setBRollReady(true);
        })();
      }
      // initial b-roll upload is done - time to update progress, prematurely
      if (
        storyArtifactsFiles.length > 0 ||
        storyArtifactsVideoFiles.length > 0 ||
        step === Step.five
      ) {
        callStoryProgressCbOnceFor(50);
      }
    }
  }, [
    storyArtifacts,
    storyArtifactsFiles,
    storyArtifactsVideo,
    storyArtifactsVideoFiles,
    step,
    story?.id,
  ]);

  const getStoryUpdates = async (): Promise<Partial<Story>> => {
    try {
      const queryResult: {
        allStories: Partial<Story>[];
      } = await videoCreator.gqlClient!.request(NEW_STORIES_COMPLETION_QUERY, {
        ids: [story!.id],
      });
      return queryResult.allStories[0];
    } catch (err) {
      params.logWarning('Error fetching story updates', err);
      return {};
    }
  };

  const callStoryProgressCbOnceFor = (percent: number) => {
    if (percent > lastProgressPercentRef.current) {
      lastProgressPercentRef.current = percent;
      params.onStoryProgress(percent);
    }
  };

  const handleProductionError = (error: any) => {
    params.logWarning('Processing music production error', error);
    isProcessingAudioRef.current = false;
    setVideoMusicStrategy('keep'); // to proceed with regular upload
  };

  const handleStoryCreate = async () => {
    if (!blankStoryRef.current) {
      setTimeout(() => {
        handleStoryCreate();
      }, 5 * 1000);
      return;
    }

    try {
      const storyUpdate = await videoCreator.updateStory({
        id: blankStoryRef.current.id,
        title: storyName,
        storyTeller: blankStoryRef.current.storyTeller,
        storyType: storyType!,
      });
      setStory({
        ...blankStoryRef.current,
        ...storyUpdate,
      });
    } catch (err) {
      params.logError('Error updating story with name and storyteller', err);
    }
  };

  const createBlankStory = async (): Promise<Story> => {
    return await videoCreator.createStory({
      title: `user-upload-${uuid()}`,
      storyType: storyType || undefined,
      storyTeller: {
        name: storyTellerName,
        id: (
          await videoCreator.upsertStoryTeller({
            name: storyTellerName,
            title: storyTellerTitle,
          })
        ).id,
      },
      primaryShowcase: videoCreator.organization,
      byExternalUser: true,
    });
  };

  const updateStoryWithUploadedFile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/story/upload-task`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            storyId: story!.id,
            s3Key: bucketPathKey(renameFile(storyVideoFile!, story!.id).name),
          }),
        },
      );
      if (!response.ok) {
        throw new Error(`Not ok: ${response.status}`);
      }
    } catch (err) {
      params.logError('Error updating story with uploaded file', err);
    }
  };

  const handleStoryVideoUpload = async (files: File[]) => {
    if (!files.length) {
      params.logWarning('No files at AddStoriesModal handleStoryVideoUpload');
      return;
    }
    setStoryVideoFile(files[0]);
    callStoryProgressCbOnceFor(25);
    goToNextStep();

    try {
      blankStoryRef.current = await createBlankStory();

      setUploadedFileUrl(
        await uploadFileToS3(renameFile(files[0], blankStoryRef.current.id), {
          onUploadProgress: (progress) => {
            console.log('Upload file progress', progress);
          },
        }),
      );
    } catch (err) {
      params.logError('Story video upload failed', err);
    }
  };

  const handleStoryBRollUpload = async (files: File[]) => {
    const imageFiles = files.filter((f) =>
      f.type.includes('image'),
    ) as ArtifactFile[];
    imageFiles.forEach((f) => {
      f._internalId = uuid();
    });

    const videoFiles = files.filter((f) =>
      f.type.includes('video'),
    ) as ArtifactFile[];
    videoFiles.forEach((f) => {
      f._internalId = uuid();
    });

    let newStoryArtifactsFiles =
      storyArtifactsFilesRef.current.concat(imageFiles);
    setStoryArtifactsFiles(newStoryArtifactsFiles);
    let newStoryArtifactsVideoFiles =
      storyArtifactsVideoFilesRef.current.concat(videoFiles);
    setStoryArtifactsVideoFiles(newStoryArtifactsVideoFiles);

    let newStoryArtifacts = [...storyArtifactsRef.current];
    imageFiles.forEach((file) => {
      videoCreator.datoClient?.uploads
        .createFromFileOrBlob({
          fileOrBlob: file,
          filename: file.name,
        })
        .then((u) => {
          newStoryArtifacts = newStoryArtifacts.concat({
            ...u,
            _internalId: file._internalId,
          });
          setStoryArtifacts(newStoryArtifacts);
        })
        .catch((err) => {
          newStoryArtifactsFiles = newStoryArtifactsFiles.filter(
            (f) => f._internalId !== file._internalId,
          );
          setStoryArtifactsFiles(newStoryArtifactsFiles);
          params.logWarning('Error uploading image b-roll', err);
        });
    });

    let newStoryArtifactsVideo = [...storyArtifactsVideoRef.current];
    videoFiles.forEach((file) => {
      videoCreator.datoClient?.uploads
        .createFromFileOrBlob({
          fileOrBlob: file,
          filename: file.name,
        })
        .then((u) => {
          newStoryArtifactsVideo = newStoryArtifactsVideo.concat({
            ...u,
            _internalId: file._internalId,
          });
          setStoryArtifactsVideo(newStoryArtifactsVideo);
        })
        .catch((err) => {
          newStoryArtifactsVideoFiles = newStoryArtifactsVideoFiles.filter(
            (f) => f._internalId !== file._internalId,
          );
          setStoryArtifactsVideoFiles(newStoryArtifactsVideoFiles);
          params.logWarning('Error uploading video b-roll', err);
        });
    });
  };

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const canGoToStep = (nextStep: number): boolean => {
    if (nextStep === Step.two) {
      return !!storyVideoFile;
    }
    if (nextStep === Step.three) {
      return !!storyType;
    }
    if (nextStep === Step.four) {
      return !!storyName && !!(storyTellerName || '').trim();
    }
    if (nextStep === Step.five) {
      return !!story;
    }
    return true;
  };

  const saveArtifactNotes = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsRef.current.find(
      (a) => a._internalId === internalId,
    );
    await doSaveArtifactNotes(artifact);
  };

  const doSaveArtifactNotes = async (artifact?: ArtifactUpload) => {
    if (!artifact) {
      return;
    }
    try {
      await videoCreator.assetRepository?.update(artifact.id, {
        title: artifact.notes || '',
        alt: artifact.notes || '',
      });
    } catch (err) {
      params.logWarning('Error saving b-roll notes', err);
    }
  };

  const setArtifactNotes = (internalId: string, notes: string): void => {
    setStoryArtifacts(
      storyArtifactsRef.current.map((a) =>
        a._internalId === internalId ? { ...a, notes } : a,
      ),
    );
  };

  const deleteArtifact = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsRef.current.find(
      (a) => a._internalId === internalId,
    );
    if (!artifact) {
      return;
    }

    setStoryArtifacts(
      storyArtifactsRef.current.filter((a) => a._internalId !== internalId),
    );
    setStoryArtifactsFiles(
      storyArtifactsFilesRef.current.filter(
        (f) => f._internalId !== internalId,
      ),
    );

    try {
      await videoCreator.assetRepository?.delete(artifact.id);
    } catch (err) {
      params.logWarning('Error deleting b-roll', err);
    }
  };

  const saveArtifactVideoNotes = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsVideoRef.current.find(
      (a) => a._internalId === internalId,
    );
    await doSaveArtifactNotes(artifact);
  };

  const setArtifactVideoNotes = (internalId: string, notes: string): void => {
    setStoryArtifactsVideo(
      storyArtifactsVideoRef.current.map((a) =>
        a._internalId === internalId ? { ...a, notes } : a,
      ),
    );
  };

  const deleteArtifactVideo = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsVideoRef.current.find(
      (a) => a._internalId === internalId,
    );
    if (!artifact) {
      return;
    }

    setStoryArtifactsVideo(
      storyArtifactsVideoRef.current.filter(
        (a) => a._internalId !== internalId,
      ),
    );
    setStoryArtifactsVideoFiles(
      storyArtifactsVideoFilesRef.current.filter(
        (f) => f._internalId !== internalId,
      ),
    );

    try {
      await videoCreator.assetRepository?.delete(artifact.id);
    } catch (err) {
      params.logWarning('Error deleting b-roll', err);
    }
  };

  return {
    step,
    goToNextStep,
    canGoToStep,
    handleStoryVideoUpload,
    handleStoryBRollUpload,
    storyName,
    setStoryName,
    storyType,
    setStoryType,
    videoMusicStrategy,
    setVideoMusicStrategy,
    storyArtifactsFiles,
    storyArtifactsVideoFiles,
    storyArtifacts,
    storyArtifactsVideo,
    saveArtifactNotes,
    setArtifactNotes,
    deleteArtifact,
    saveArtifactVideoNotes,
    setArtifactVideoNotes,
    deleteArtifactVideo,
  };
}
