type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const ReplaceIcon = (props: Props) => {
  const {
    width = '14',
    height = '13',
    viewBox = '0 0 14 13',
    strokeColor = '#F3E9D7',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M2.38574 12.4985V10.1914H4.69288"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8969 5.39771C13.4709 8.45046 11.6028 11.4769 8.55272 12.2942C6.2183 12.9197 3.83642 12.0783 2.38574 10.335"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.10335 7.60237C0.529291 4.54961 2.39745 1.52315 5.44752 0.705885C7.78177 0.0804228 10.1635 0.921671 11.6142 2.6647"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6142 0.501465V2.80861H9.30713"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplaceIcon;
