import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const TrackUpIcon = (props: Props) => {
  const {
    width = '14',
    height = '14',
    viewBox = '0 0 14 14',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M7 6.53578L7 0.964355"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.32227 3.28578L7.00084 0.964355L4.67941 3.28578"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0361 12.1069L13.0361 9.78551C13.0361 9.27267 12.6204 8.85693 12.1076 8.85693L1.89328 8.85693C1.38043 8.85693 0.964704 9.27267 0.964704 9.7855L0.964704 12.1069C0.964704 12.6198 1.38043 13.0355 1.89328 13.0355L12.1076 13.0355C12.6204 13.0355 13.0361 12.6198 13.0361 12.1069Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrackUpIcon;
