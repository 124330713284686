interface Options {
  stripPrefixId?: boolean;
}

export const getFileNameFromURL = (url?: string, options?: Options) => {
  if (!url) return '';

  const urlWithoutParams = url.split('?')[0];
  let fileName = urlWithoutParams.substring(
    urlWithoutParams.lastIndexOf('/') + 1,
  );

  if (options?.stripPrefixId) {
    const dashIndex = fileName.indexOf('-');
    if (dashIndex !== -1) {
      fileName = fileName.substring(dashIndex + 1);
    }
  }

  return fileName;
};
