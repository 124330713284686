import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { ActionButton, ActionsWrapper } from '../../styles/mainStyle';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';

type Props = {
  action: 'copy' | 'paste';
  secondaryActions?: 'clearVolumeKeyPoints'[];
};
const TimelineContextMenu = observer((props: Props) => {
  const contextRef = useRef<HTMLDivElement>(null);
  const { tmp, copied, pos, action, secondaryActions } =
    videoCreator.timelineClipboard || {};
  const { left, top } = pos || {};
  useOutsideAlerter(contextRef, () => {
    if (videoCreator.timelineClipboard) {
      videoCreator.timelineClipboard.pos = null;
    }
  });

  return (
    <Actions ref={contextRef} left={left} top={top} isVisible={!!pos}>
      {props.action === 'copy' && action === 'copy' && tmp && (
        <CopyElement
          onClick={(e) => {
            e.stopPropagation();
            videoCreator.timelineClipboard!.pos = null;
            videoCreator.timelineClipboard!.copied = {
              element: tmp.element,
              data: null,
            };
          }}
        >
          <span>
            Copy{' '}
            {tmp.element.source.type.charAt(0).toUpperCase() +
              tmp.element.source.type.slice(1)}
          </span>
        </CopyElement>
      )}
      {props.action === 'paste' &&
        action === 'paste' &&
        copied?.element &&
        copied.data && (
          <PasteElement
            onClick={async (e) => {
              e.stopPropagation();
              const { element, data } = copied;
              await videoCreator.createElement({
                name: element?.source.name,
                type: element?.source.type,
                source: element?.source.source,
                autoplay: true,
                track: data?.track,
                volume: element?.source.volume,
                duration: element?.source.duration,
                time: data?.time,
                trim_start: element?.trimStart,
                media_duration: element?.mediaDuration,
              });
              videoCreator.timelineClipboard = null;
            }}
          >
            <span>
              Paste{' '}
              {copied.element.source.type.charAt(0).toUpperCase() +
                copied.element.source.type.slice(1)}
            </span>
          </PasteElement>
        )}
      {!!secondaryActions?.length &&
        tmp &&
        props.action === 'copy' &&
        action === 'copy' &&
        props.secondaryActions
          ?.filter((sa) => secondaryActions.includes(sa))
          .map((sa) => {
            if (sa === 'clearVolumeKeyPoints') {
              return (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    videoCreator.deleteAllVolumeKeyPoints(
                      tmp.element.source.id,
                    );
                    videoCreator.timelineClipboard!.pos = null;
                  }}
                >
                  <span>Remove All Keyframes</span>
                </ActionButton>
              );
            }
            return null;
          })}
    </Actions>
  );
});

export default TimelineContextMenu;

const Actions = styled(ActionsWrapper)<{
  left: number | undefined;
  top: number | undefined;
}>`
  right: unset;
  position: fixed;
  top: ${(props) => props.top && `${props.top}px`};

  ${(props) =>
    props.left &&
    props.left + 110 >= window.innerWidth &&
    css`
      right: 0;
    `}

  ${(props) =>
    props.left &&
    props.left + 110 < window.innerWidth &&
    css`
      left: ${props.left}px;
    `}

  z-index: 10000001;
`;

const CopyElement = styled(ActionButton)``;

const PasteElement = styled(ActionButton)``;
