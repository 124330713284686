type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};
const DeleteIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '14'}
      viewBox={props.viewBox || '0 0 14 14'}
      fill="none"
    >
      <path
        d="M1.42773 3.75H12.5706"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.82031 3.75H11.1775V12.1071C11.1775 12.3534 11.0796 12.5896 10.9055 12.7637C10.7314 12.9378 10.4951 13.0357 10.2489 13.0357H3.74888C3.50261 13.0357 3.26643 12.9378 3.09228 12.7637C2.91815 12.5896 2.82031 12.3534 2.82031 12.1071V3.75Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67773 3.74958V3.2853C4.67773 2.66962 4.92231 2.07915 5.35766 1.6438C5.79301 1.20845 6.38348 0.963867 6.99916 0.963867C7.61484 0.963867 8.20531 1.20845 8.64066 1.6438C9.07602 2.07915 9.32059 2.66962 9.32059 3.2853V3.74958"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.60547 6.53711V10.2528"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.39258 6.53711V10.2528"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
