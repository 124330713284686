import {
  TranscriptChange,
  TranscriptElement,
  TranscriptPunctElement,
} from '../../types.ts/transcript';
import {
  getClosestNotRemovedElementIndexToRight,
  getClosestNotRemovedElementIndexToLeft,
  isSentenceEnd,
} from '../utils';
import ChangeHandler from './ChangeHandler';

class ReplaceChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'replace' },
  ) {
    const _elements = transcriptionElementsMutable;

    const elementsCount = change.count || 1;
    let startTs = null;
    let endTs = null;
    let i = 0;
    while (i < elementsCount) {
      let element = _elements[change.index + i];
      element.old_value = element.value;
      element.state = 'replaced';
      element.value = '';

      if (startTs === null && element.ts != null) {
        startTs = element.ts;
      }
      if (element.end_ts != null) {
        endTs = element.end_ts;
      }
      if (element.type === 'text') {
        element = {
          ...element,
          type: 'punct',
          ts: null,
          end_ts: null,
        } as TranscriptPunctElement;
      }
      i++;
    }

    let extraSpaceAfter = '';
    let nextElementIndex = getClosestNotRemovedElementIndexToRight(
      change.index + elementsCount,
      _elements,
    );
    if (
      !change.newValue?.endsWith(' ') &&
      nextElementIndex > -1 &&
      _elements[nextElementIndex].type === 'text' &&
      !_elements[nextElementIndex].value?.startsWith(' ')
    ) {
      extraSpaceAfter = ' ';
    }

    let extraSpaceBefore = '';
    let prevElementIndex = getClosestNotRemovedElementIndexToLeft(
      change.index - 1,
      _elements,
    );
    if (
      !change.newValue?.startsWith(' ') &&
      prevElementIndex > -1 &&
      !_elements[prevElementIndex].value?.endsWith(' ')
    ) {
      extraSpaceBefore = ' ';
    }

    if (startTs && endTs) {
      _elements[change.index + elementsCount - 1].ts = startTs;
      _elements[change.index + elementsCount - 1].end_ts = endTs;
      _elements[change.index + elementsCount - 1].type = 'text';
    }
    _elements[change.index + elementsCount - 1].value =
      extraSpaceBefore + change.newValue + extraSpaceAfter;

    if (isSentenceEnd(change.newValue!) !== isSentenceEnd(change.oldValue!)) {
      this.fixCapitalizationAt(_elements, change.index + change.count);
    }
  }
}

export default ReplaceChangeHandler;
