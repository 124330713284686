const PlayIconRectOutline = ({
  width = '17',
  height = '16',
  viewBox = '0 0 17 16',
}: {
  width?: string;
  height?: string;
  viewBox?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11297_9691)">
        <path
          d="M14.5238 0.880859H2.47622C1.87134 0.880859 1.38098 1.37121 1.38098 1.9761V14.0237C1.38098 14.6286 1.87134 15.119 2.47622 15.119H14.5238C15.1287 15.119 15.6191 14.6286 15.6191 14.0237V1.9761C15.6191 1.37121 15.1287 0.880859 14.5238 0.880859Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5614 10.4423V5.71086C6.56182 5.6317 6.58314 5.55406 6.62324 5.48579C6.66334 5.41753 6.72076 5.36108 6.78969 5.32216C6.85864 5.28325 6.93663 5.26325 7.01579 5.26419C7.09496 5.26513 7.17246 5.28698 7.24045 5.32753L11.2928 7.68229C11.3611 7.72172 11.4177 7.7784 11.4571 7.84665C11.4965 7.9149 11.5172 7.99231 11.5172 8.0711C11.5172 8.1499 11.4965 8.22731 11.4571 8.29556C11.4177 8.36381 11.3611 8.42049 11.2928 8.45991L7.24045 10.8256C7.17246 10.8662 7.09496 10.888 7.01579 10.889C6.93663 10.8899 6.85864 10.8699 6.78969 10.831C6.72076 10.7921 6.66334 10.7356 6.62324 10.6674C6.58314 10.5991 6.56182 10.5215 6.5614 10.4423Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11297_9691">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlayIconRectOutline;
