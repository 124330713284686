import Fuse from 'fuse.js';

export const getFuse = (fuseData: { key: string }[]) => {
  const fuseOptions = {
    keys: ['key'], // Specify the keys to search within
    threshold: 0.12, // Adjust the threshold as needed
  };
  return new Fuse(fuseData, fuseOptions);
};

export const findClosestKey = (
  fuse: Fuse<{ key: string }>,
  key: string,
): string | undefined => {
  try {
    const result = fuse.search(key);

    if (result.length) {
      return result[0].item.key;
    }
  } catch (error) {
    console.log('fuse key', key);
    console.log('error in findClosestKey: ', error);
  }
};
