import { observer } from 'mobx-react-lite';
import { FC, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

import { AiGeneratedContent, Artifact } from '../../types.ts/story';
import QuoteCard from './QuoteCard';
import RegenerateButton from '../common/RegenerateButton';
import SpinningLoading from '../SpinningLoading';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { initializeContentStudioContent } from '../../utility/general';
import ChatGPTService from '../../services/ChatGPTService';

const gptService = new ChatGPTService();

const PullQuotes: FC<{}> = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const { story, unsavedShareableImages } = videoCreator;
  const { enableShareableImageRegeneration } = useFlagsCombination();

  useEffect(() => {
    initializeContentStudioContent(story?.id, 'Quotes');
  }, [story?.id]);

  const contentGenerated = videoCreator.contentStudioGeneratedContent;

  const [loadingStock, setLoadingStock] = useState(false);

  let generatedContent = contentGenerated?.Quotes?.content?.response;
  const hasBeenGenerated = contentGenerated?.Quotes?.hasBeenGenerated;

  const artifacts = (
    story?.storyArtifacts?.map((artifact) => {
      if (artifact.responsiveImage) {
        return artifact;
      }
    }) || []
  ).filter(Boolean);

  const assets = (
    story?.storyAssets?.map((asset) => {
      if (asset.responsiveImage) {
        return asset;
      }
    }) || []
  ).filter(Boolean);

  useEffect(() => {
    let quoteList = generatedContent as string[];
    if (typeof generatedContent === 'string') {
      quoteList = (generatedContent as string)
        ?.split('\n')
        .filter((q) => q.trim()) as string[];
    }

    let saved = story?.shareableImages || [];

    let unsaved: any = [];
    const stillImages = [...artifacts, ...assets];
    for (let [index, quote] of (quoteList || []).entries()) {
      const image = (
        stillImages.length ? stillImages[index % stillImages.length] : null
      ) as Artifact | null;

      const isInCard = saved.some(
        (q) =>
          q.quote?.trim() === quote.trim() &&
          q.imagefile?.url === (image?.url || ''),
      );

      if (isInCard) continue;

      unsaved.push({
        id: uuid(),
        quote: quote?.replace(/^["'\s-]+|["'\s-]+$/g, '')?.trim(),
        storytellerName: story?.storyTeller?.name,
        ...(image && {
          imagefile: {
            ...image,
          },
        }),
      });
    }

    videoCreator.unsavedShareableImages = unsaved;
  }, [generatedContent]);

  const getLoadingText = () => {
    if (loadingStock) {
      return 'Loading stock photo...';
    }
    if (hasBeenGenerated) {
      return 'Regenerating shareable images...';
    }
    return 'Generating shareable images...';
  };

  return (
    <Main>
      {(isLoading || loadingStock) && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text={getLoadingText()}
        />
      )}
      <div className="buttons">
        {enableShareableImageRegeneration && (
          <RegenerateButton
            setIsLoading={setIsLoading}
            storyId={story?.id}
            transcriptionId={story?.transcription?.elementsJson?.id}
            promptTitle="Quotes"
            gptService={gptService}
          />
        )}
      </div>
      <Quotes>
        {story?.shareableImages?.map((content) => (
          <QuoteCard
            key={content.id}
            id={content.id}
            text={content.quote}
            isSaved={true}
            imageFile={content.imagefile}
            storytellerName={content.storytellerName}
            setLoadingStock={setLoadingStock}
            allReferencingCaptions={content._allReferencingCaptions}
            allReferencingSharedContents={content._allReferencingSharedContents}
          />
        ))}
        {hasBeenGenerated &&
          unsavedShareableImages?.map((content, index) => (
            <QuoteCard
              key={index}
              text={content.quote}
              id={content.id}
              isSaved={false}
              imageFile={content.imagefile}
              storytellerName={content.storytellerName}
              setLoadingStock={setLoadingStock}
            />
          ))}
      </Quotes>
    </Main>
  );
});

export default PullQuotes;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 20px;
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

const Quotes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 auto;
  @media only screen and (min-width: 1800px) {
    gap: 50px;
  }
`;
