import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../svgs/CloseIcon';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  customWidth?: string;
  paddingHorizontal?: string;
  paddingVertical?: string;
  alwaysShowCancel?: boolean;
  backgroundColor?: string;
  hasParentTransparency?: boolean;
  allowPropagation?: boolean;
};
const Modal = (props: Props) => {
  const {
    isOpen,
    onClose,
    children,
    customWidth = '500px',
    paddingHorizontal = '20px',
    paddingVertical = '20px',
    alwaysShowCancel = false,
    backgroundColor = '#03041a',
    hasParentTransparency = true,
    allowPropagation,
  } = props;

  if (!isOpen) return null;

  return (
    <Main
      onMouseUp={(e) => {
        if (allowPropagation) return;
        e.stopPropagation();
      }}
      onClick={(e) => {
        if (allowPropagation) return;
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        if (allowPropagation) return;
        e.stopPropagation();
      }}
      onKeyDownCapture={(e) => {
        if (allowPropagation) return;
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
      hasParentTransparency={hasParentTransparency}
    >
      <Content
        width={customWidth}
        paddingHorizontal={paddingHorizontal}
        paddingVertical={paddingVertical}
        backgroundColor={backgroundColor}
        id="modal"
      >
        {onClose && (
          <Close alwaysShowCancel={alwaysShowCancel} onClick={onClose}>
            <CloseIcon />
          </Close>
        )}

        {children}
      </Content>
    </Main>
  );
};

export default Modal;

const Main = styled.div<{ hasParentTransparency: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.hasParentTransparency && 'rgba(0, 0, 0, 0.5)'};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100001;
  position: fixed;
  top: 0;
  left: 0;
`;

const Content = styled.div<{
  width: string;
  paddingHorizontal: string;
  paddingVertical: string;
  backgroundColor: string;
}>`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.paddingVertical}
    ${(props) => props.paddingHorizontal};
  border-radius: 10px;
  border: 1px solid #484848;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.4);
  text-align: center;
  z-index: 1000;
  width: ${(props) => props.width};
`;

const Close = styled.button<{ alwaysShowCancel: boolean }>`
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  width: fit-content;
  z-index: ${(props) => props.alwaysShowCancel && '12'};
  &:active svg {
    fill: #fff;
  }
`;
