import MainMenu from '../components/MainMenu';
import React from 'react';
import { useClerk, useSession, RedirectToSignIn } from '@clerk/clerk-react';
import styled from 'styled-components';
import GlobalQuerySubscriptionComponent from '../components/GlobalQuerySubscriptionComponent';
import { Insights } from '../utility/insights';

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const clerk = useClerk();
  const session = useSession();
  const ctx =
    window.opener?.ctx ||
    JSON.parse(window.sessionStorage.getItem('ctx') || 'null');

  const isBrollPage = !!(
    window.location.pathname.includes('/broll') &&
    (params?.storyId || params?.organizationId) &&
    params?.env
  );

  console.log(
    'isBrollPage',
    isBrollPage,
    '-contributor-',
    localStorage.getItem('broll-contributor'),
  );

  if (
    clerk.loaded &&
    session.isLoaded &&
    !session?.session &&
    !ctx?.currentUser &&
    !isBrollPage
  ) {
    return (
      <>
        <RedirectToSignIn />
      </>
    );
  }

  if (isBrollPage) return <div>{children}</div>;

  if ((clerk.loaded && session?.session) || ctx?.currentUser) {
    return (
      <div className="editor">
        <Insights />
        <MainMenu params={params} />
        <GlobalQuerySubscriptionComponent />
        {children}
      </div>
    );
  }

  return <LoadingScreen />;
};

const LoadingScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #03041a;
`;

export default RootLayout;
