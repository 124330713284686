type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
  strokeColor?: string;
};
const InstagramIcon = (props: Props) => {
  const {
    width = '20',
    height = '20',
    viewBox = '0 0 16 16',
    fillColor = '#03041A',
    strokeColor = '#484848',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M13.3257 16H2.67433C1.20246 16 0 14.7975 0 13.3257V2.67433C0 1.20246 1.20246 0 2.67433 0H13.3257C14.7975 0 16 1.20246 16 2.67433V13.3257C16 14.8041 14.8041 16 13.3257 16Z"
        fill={strokeColor}
      />
      <path
        d="M8.00326 12.11C6.90593 12.11 5.87431 11.6829 5.09895 10.9076C4.32359 10.1322 3.89648 9.10059 3.89648 8.00326C3.89648 6.90593 4.32359 5.87431 5.09895 5.09895C5.87431 4.32359 6.90593 3.89648 8.00326 3.89648C9.10059 3.89648 10.1322 4.32359 10.9076 5.09895C11.6829 5.87431 12.11 6.90593 12.11 8.00326C12.11 9.10059 11.6829 10.1322 10.9076 10.9076C10.1256 11.6829 9.10059 12.11 8.00326 12.11ZM8.00326 4.77041C6.22256 4.77041 4.77041 6.21599 4.77041 8.00326C4.77041 9.78396 6.21599 11.2361 8.00326 11.2361C9.78396 11.2361 11.2361 9.79053 11.2361 8.00326C11.2295 6.22256 9.78396 4.77041 8.00326 4.77041Z"
        fill={fillColor}
      />
      <path
        d="M12.9106 3.76149C13.3461 3.76149 13.6991 3.40846 13.6991 2.97297C13.6991 2.53748 13.3461 2.18445 12.9106 2.18445C12.4751 2.18445 12.1221 2.53748 12.1221 2.97297C12.1221 3.40846 12.4751 3.76149 12.9106 3.76149Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default InstagramIcon;
