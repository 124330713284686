import React from 'react'

type Props = {
    width?: string;
    height?: string;
    strokeColor?: string;
};

const NewProfileIcon = (props: Props) => {
    const { width = '20', height = '20', strokeColor = 'currentColor' } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_8940_2270)">
                <path
                    d="M14.8991 11.7148V13.8377C14.8991 14.1192 14.7873 14.3892 14.5882 14.5882C14.3892 14.7873 14.1192 14.8991 13.8377 14.8991H11.7148"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.7148 1.10059H13.8377C14.1192 1.10059 14.3892 1.21241 14.5882 1.41147C14.7873 1.61053 14.8991 1.8805 14.8991 2.16201V4.28487"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.10059 4.28487V2.16201C1.10059 1.8805 1.21241 1.61053 1.41147 1.41147C1.61053 1.21241 1.8805 1.10059 2.16201 1.10059H4.28487"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.28487 14.8991H2.16201C1.8805 14.8991 1.61053 14.7873 1.41147 14.5882C1.21241 14.3892 1.10059 14.1192 1.10059 13.8377V11.7148"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.9998 7.46933C9.17221 7.46933 10.1226 6.51889 10.1226 5.34648C10.1226 4.17407 9.17221 3.22363 7.9998 3.22363C6.82738 3.22363 5.87695 4.17407 5.87695 5.34648C5.87695 6.51889 6.82738 7.46933 7.9998 7.46933Z"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.0367 12.2455C11.7625 11.3903 11.2238 10.6444 10.4983 10.1152C9.77285 9.58595 8.89805 9.30078 8.00004 9.30078C7.10204 9.30078 6.22725 9.58595 5.50176 10.1152C4.77627 10.6444 4.2376 11.3903 3.96338 12.2455H12.0367Z"
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8940_2270">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default NewProfileIcon