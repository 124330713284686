import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Story } from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import AiProducerIcon from '../../svgs/AiProducerIcon';
import OpenAI from 'openai';
// import process from 'node:process';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_CHATGPT_API_KEY as string,
  dangerouslyAllowBrowser: true,
  timeout: 60 * 1000,
  maxRetries: 3,
});

const { diff, changed } = require('myers-diff');

import {
  defaultSystemMessage,
  defaultSystemMessage2,
  defaultSystemMessage3,
  defaultUserPrompt,
  defaultUserPrompt2,
  defaultUserPrompt3,
  defaultVideoLength,
  storyQuestions2,
  aiQuestionUnderstanding2,
  giveAITranscript2,
  askForAnswers2,
  askForOutline2,
  askForStory2,
  askForSimpleParagraph2,
  getOriginalSententences2,
  getOriginalSententences3,
  getOriginalSententences4,
} from '../../utility/ai_producer_defaults';
import { observer } from 'mobx-react-lite';

import {
  getNewVideo,
  getNewVideoOriginal,
  getNewVideo3,
  getNewVideo4,
  getNewVideo5,
} from '../../utility/videoProducer';

type Props = {
  story?: Story;
  selectedCard?: string;
};

type Message = {
  sender: string;
  content: string;
};

const AIStoryProducer: FC<Props> = observer((props) => {
  const hasOriginalTranscript = !!videoCreator.originalTranscription;

  const [modelVersion, setModelVersion] = useState('gpt-4o');
  const [producerVersion, setProducerVersion] = useState(1);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isAudioAnalyzing, setIsAudioAnalyzing] = useState(false);
  const [hasUndo, setHasUndo] = useState(false);
  const [systemMessage, setSystemMessage] = useState(defaultSystemMessage);
  const [userPrompt, setUserPrompt] = useState(defaultUserPrompt);
  const [sentenceBasedDiff, setSentenceBasedDiff] = useState(true);
  const [videoLength, setVideoLength] = useState(defaultVideoLength);
  const [hideAdvanced, setHideAdvanced] = useState(true);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [userMessage, setUserMessage] = useState<string>('');

  const [storyQuestions, setStoryQuestions] = useState<string>(storyQuestions2);
  const [aiQuestionUnderstanding, setAiQuestionUnderstanding] =
    useState<string>(aiQuestionUnderstanding2);
  const [giveAITranscript, setGiveAITranscript] =
    useState<string>(giveAITranscript2);
  const [askForAnswers, setAskForAnswers] = useState<string>(askForAnswers2);
  const [askForOutline, setAskForOutline] = useState<string>(askForOutline2);
  const [askForStory, setAskForStory] = useState<string>(askForStory2);
  const [askForSimpleParagraph, setAskForSimpleParagraph] = useState<string>(
    askForSimpleParagraph2,
  );
  const [getOriginalSententences, setGetOriginalSententences] =
    useState<string>(getOriginalSententences2);

  useEffect(() => {
    if (producerVersion === 1) {
      setSystemMessage(defaultSystemMessage);
      setUserPrompt(defaultUserPrompt);
      setMessages([]);
      setUserMessage('');
    } else if (producerVersion === 2) {
      setSystemMessage(defaultSystemMessage2);
      setUserPrompt(defaultUserPrompt2);
      setMessages([]);
      setUserMessage('');
    } else if (producerVersion === 3) {
      setSystemMessage(defaultSystemMessage3);
      setUserPrompt(defaultUserPrompt3);
      setGetOriginalSententences(getOriginalSententences3);
      setMessages([]);
      setUserMessage('');
    } else if (producerVersion === 4) {
      setSystemMessage(defaultSystemMessage3);
      setUserPrompt(defaultUserPrompt3);
      setGetOriginalSententences(getOriginalSententences4);
      setMessages([]);
      setUserMessage('');
    }
  }, [producerVersion]);

  // todo move to separate class (AIProducer?)
  // send all inputs to the backend
  const produceShorterVideo = async () => {
    if (hasUndo) {
      await undoProduction();
    }

    if (userMessage) {
      setMessages((oldArray) => [
        ...oldArray,
        { sender: 'You', content: userMessage },
      ]);
      setUserMessage('');
    }
    setIsGenerating(true);

    setHasUndo(true);
    //create a snapshot before applying changes ?

    let data: any = '';
    const storyTranscription =
      videoCreator.videoTranscriptionProcessor.getFinalTranscriptionText();
    if (producerVersion === 5) {
      console.log('using producer 5');
      const prompts = {
        systemMessage: systemMessage,
        userMessage: userMessage,
        userPrompt: userPrompt,
        videoLength: videoLength,
        getOriginalSententences: getOriginalSententences,
      };
      await getNewVideo5(props.story, storyTranscription, messages, prompts);
    } else if (producerVersion === 4) {
      console.log('using producer 4');
      const prompts = {
        systemMessage: systemMessage,
        userMessage: userMessage,
        userPrompt: userPrompt,
        videoLength: videoLength,
        getOriginalSententences: getOriginalSententences,
      };
      await getNewVideo4(props.story, storyTranscription, messages, prompts);
    } else if (producerVersion === 1) {
      console.log('using producer 1');
      data = await getNewVideoOriginal(
        props.story,
        storyTranscription,
        messages,
        systemMessage,
        userMessage,
        userPrompt,
        videoLength,
      );
    } else if (producerVersion === 2) {
      console.log('using producer 2');
      const prompts = {
        systemMessage: systemMessage,
        userMessage: userMessage,
        userPrompt: userPrompt,
        videoLength: videoLength,
        storyQuestions: storyQuestions,
        aiQuestionUnderstanding: aiQuestionUnderstanding,
        giveAITranscript: giveAITranscript,
        askForAnswers: askForAnswers,
        askForOutline: askForOutline,
        askForStory: askForStory,
        askForSimpleParagraph: askForSimpleParagraph,
        getOriginalSententences: getOriginalSententences,
      };
      data = await getNewVideo(
        props.story,
        storyTranscription,
        messages,
        prompts,
      );
    } else if (producerVersion === 3) {
      console.log('using producer 3');
      const prompts = {
        systemMessage: systemMessage,
        userMessage: userMessage,
        userPrompt: userPrompt,
        videoLength: videoLength,
        getOriginalSententences: getOriginalSententences,
      };
      data = await getNewVideo3(
        props.story,
        storyTranscription,
        messages,
        prompts,
      );
    }
    if (data) {
      setMessages((oldArray: any[]) => [
        ...oldArray,
        { sender: 'AI Producer', content: data },
      ]);
      console.log('data', data);
      // compare data and transcript full text
      if (!sentenceBasedDiff) {
        const rhs = data?.replace(/[,.!?]/g, '').toLowerCase();
        const lhs = videoCreator
          .finalTranscriptionElements!.map((el) => el.value || '')
          .join('')
          .replace(/[,.!?]/g, '')
          .toLowerCase();
        console.log('new', rhs?.length, rhs);
        console.log('original', lhs.length, lhs);
        const changesX = diff(lhs, rhs, {
          compare: 'words',
          ignoreWhitespace: true,
          ignoreCase: true,
          ignoreAccents: true,
        });

        let elementsIndex = 0;
        let characterIndexOfElement = 0;
        console.log('diff X', changesX);
        for (const change of changesX) {
          if (changed(change.lhs)) {
            // deleted
            const { pos, text, del, length } = change.lhs;
            if (length >= 1) {
              // console.log('deleted', lhs.substring(pos, pos + length), pos, length);
              // find the index of the first element that contains the deleted text
              let characterIndex = pos;
              // loop through elements until the character index is greater than the character index of the element
              let notFound = true;
              while (notFound) {
                if (
                  elementsIndex >=
                  videoCreator.finalTranscriptionElements!.length
                ) {
                  notFound = false;
                } else {
                  const element =
                    videoCreator.finalTranscriptionElements![elementsIndex];
                  if (
                    element.value &&
                    (element.type === 'text' || element.value === ' ')
                  ) {
                    characterIndexOfElement += element.value.length;
                    // console.log('looking at element for start', element.type, element.value, characterIndexOfElement);
                  }
                  if (characterIndexOfElement >= characterIndex) {
                    notFound = false;
                  } else {
                    elementsIndex++;
                  }
                }
              }
              // console.log('found start', elementsIndex, videoCreator.originalTranscription!.elements[elementsIndex].value);
              const startCutIndex = elementsIndex;
              elementsIndex++;

              notFound = true;
              while (notFound) {
                if (
                  elementsIndex >=
                  videoCreator.finalTranscriptionElements!.length
                ) {
                  notFound = false;
                } else {
                  const element =
                    videoCreator.finalTranscriptionElements![elementsIndex];
                  if (
                    element.value &&
                    (element.type === 'text' || element.value === ' ')
                  ) {
                    characterIndexOfElement += element.value.length;
                    // console.log('looking at element for end', element.type, element.value, characterIndexOfElement);
                  }
                  if (characterIndexOfElement >= characterIndex + length) {
                    notFound = false;
                  } else {
                    elementsIndex++;
                  }
                }
              }

              // console.log('found end', elementsIndex, videoCreator.originalTranscription!.elements[elementsIndex].value);
              // find the index of the last element that contains the deleted text
              const endCutIndex = elementsIndex;
              console.log('found match', lhs?.substring(pos, pos + length));
              await videoCreator.cutTranscriptAndVideo(
                startCutIndex,
                endCutIndex + 1,
              );

              elementsIndex++;
            }
          }
          if (changed(change.rhs)) {
            // added
            const { pos, text, add, length } = change.rhs;
            if (length >= 1) {
              console.log('added', rhs?.substring(pos, pos + length));
              // characterIndexOfElement += length;
            }
          }
        }
      } else {
        let cursorIndex = 0;
        let sentencesSet: any = {};
        if (
          videoCreator.videoTranscriptionProcessor.finalTranscriptionElements
        ) {
          sentencesSet = videoCreator
            .getSentences(
              videoCreator.videoTranscriptionProcessor
                .finalTranscriptionElements,
            )
            .reduce((acc: any, sentence: any, index: number) => {
              acc[sentence.text as string] = sentence;
              return acc;
            }, {});
        }

        // split data into sentences using '.', '?', and '!'
        const sentences = data.split(/(?<=[.!?])/);
        // initialize Fuse with what was used to generate the data
        if (
          videoCreator.videoTranscriptionProcessor.finalTranscriptionElements
        ) {
          videoCreator.initializeFuse(
            videoCreator.videoTranscriptionProcessor.finalTranscriptionElements,
          );
        } else {
          return;
        }

        for (let i = 0; i < sentences.length; i++) {
          const sentence = sentences[i];
          const result = videoCreator.fuse.search(sentence);
          // console.log('result', result);
          if (result.length > 0) {
            const bestMatch = result[0];

            // move sentence
            console.log(
              'matched sentence',
              sentence,
              'WITH ',
              bestMatch.item.text,
            );
            if (sentencesSet[bestMatch.item.text as any]) {
              delete sentencesSet[bestMatch.item.text as any];
            }
            // console.log('cutting', cursorIndex, bestMatch.item.startIndex - 1)
            // if (bestMatch.item.startIndex - 1 > cursorIndex) {
            //   await videoCreator.cutTranscriptAndVideo(cursorIndex, bestMatch.item.startIndex - 1);
            // }
            // cursorIndex = bestMatch.item.endIndex + 1;
          }
        }

        // for all remaining sentences in the original transcript, cut them (in reverse order)
        const remainingSentences = Object.values(sentencesSet);
        console.log('remainingSentences', remainingSentences);
        for (let i = remainingSentences.length - 1; i >= 0; i--) {
          const sentence: any = remainingSentences[i];
          console.log('cutting', sentence.startIndex, sentence.endIndex + 1);
          await videoCreator.cutTranscriptAndVideo(
            sentence.startIndex,
            sentence.endIndex + 1,
          );
        }

        // remove all um's and any commas next to them, in reverse order, if not already deleted
        const elements =
          videoCreator.videoTranscriptionProcessor.finalTranscriptionElements;
        if (elements) {
          for (let i = elements.length - 1; i >= 0; i--) {
            const element = elements[i];
            if (
              element &&
              element.value &&
              (!element.state || element.state !== 'removed')
            ) {
              const value = element.value.toLowerCase();
              if (value === 'um' || value === 'uh' || value === 'ah') {
                const nextElement =
                  elements.length > i + 1 ? elements[i + 1] : null;
                if (nextElement && nextElement.value === ',') {
                  await videoCreator.cutTranscriptAndVideo(i, i + 2);
                } else {
                  await videoCreator.cutTranscriptAndVideo(i, i + 1);
                }
              } else if (value === 'like' || value === 'you know') {
                // with a comma on each side
                const nextElement =
                  elements.length > i + 1 ? elements[i + 1] : null;
                const previousElement = i - 2 > 0 ? elements[i - 2] : null;
                if (
                  nextElement &&
                  nextElement.value === ',' &&
                  previousElement &&
                  previousElement.value === ','
                ) {
                  await videoCreator.cutTranscriptAndVideo(i - 2, i + 2);
                }
              } else {
                // remove all one word sentences (has a period on each side)
                const nextElement =
                  elements.length > i + 1 ? elements[i + 1] : null;
                const previousElement = i - 2 > 0 ? elements[i - 2] : null;
                if (
                  nextElement &&
                  nextElement.value === '.' &&
                  previousElement &&
                  previousElement.value === '.'
                ) {
                  await videoCreator.cutTranscriptAndVideo(i - 2, i + 2);
                }
              }
            }
          }
        }
      }
    }
    setIsGenerating(false);
  };

  // function to reset state to undo the production
  const undoProduction = async () => {
    setIsGenerating(false);
    setHasUndo(false);
    // videoCreator.videoTranscriptionProcessor.reset();
  };

  // create a chat interface that displays the ai messages recieved and allows user to input new ones to send to the ai
  // the ai messages should be displayed in the chat interface
  // the user should be able to input new messages to send to the ai
  // the user should be able to send the messages to the ai
  // the user should be able to send the messages to the ai and have the ai respond

  // a select drop down that changes the model version of chat gpt to use

  return (
    <Main>
      <Tabs>
        <Tab
          isSelected={producerVersion === 1}
          onClick={() => setProducerVersion(1)}
        >
          AI v1
        </Tab>
        <Tab
          isSelected={producerVersion === 2}
          onClick={() => setProducerVersion(2)}
        >
          AI v2
        </Tab>
        <Tab
          isSelected={producerVersion === 3}
          onClick={() => setProducerVersion(3)}
        >
          AI v3
        </Tab>
        <Tab
          isSelected={producerVersion === 4}
          onClick={() => setProducerVersion(4)}
        >
          AI v4
        </Tab>
        <Tab
          isSelected={producerVersion === 5}
          onClick={() => setProducerVersion(5)}
        >
          AI v5
        </Tab>
        <Select
          value={modelVersion}
          onChange={(e) => setModelVersion(e.target.value)}
        >
          <option value="gpt-3.5-turbo">3.5 Turbo</option>
          <option value="gpt-3.5-turbo-16k">3.5 Turbo 16k</option>
          <option value="gpt-4-Turbo">4 Turbo</option>
          <option value="gpt-4o">4 Omni</option>
          {/* Add more model versions here */}
        </Select>
      </Tabs>
      <div>
        <PresetOption
          id="sentenceBasedDiff"
          active={sentenceBasedDiff}
          onClick={() => setSentenceBasedDiff((val) => !val)}
        >
          Use Sentence Based Diff
        </PresetOption>
      </div>
      <div>
        <label htmlFor="videoLength">Target Video Length (seconds):</label>
        <NumberInput
          id="videoLength"
          name="videoLength"
          type="number"
          placeholder="Video Length"
          value={videoLength}
          onChange={async (e) => {
            setVideoLength(e.target.value);
          }}
        />
      </div>
      {messages.length ? (
        <div>
          {messages.map((message, index) => (
            <Message key={index}>
              <strong>{message.sender}:</strong> {message.content}
            </Message>
          ))}
        </div>
      ) : null}

      <div>
        <label htmlFor="userMessage">Extra Instructions:</label>
        <TextAreaShort
          id="userMessage"
          name="userMessage"
          placeholder="Type your message here..."
          value={userMessage}
          onChange={async (e) => {
            setUserMessage(e.target.value);
          }}
        />
      </div>

      <Card
        onClick={() => {
          produceShorterVideo();
        }}
      >
        <AiProducerIcon />
        {isGenerating
          ? 'Producing...'
          : isAudioAnalyzing
            ? 'Awaiting Audio...'
            : !hasOriginalTranscript
              ? 'Awaiting Transcript...'
              : 'Produce'}
      </Card>
      {hasUndo ? (
        <Card
          onClick={() => {
            undoProduction();
          }}
        >
          <AiProducerIcon />
          Undo
        </Card>
      ) : null}
      <Card
        onClick={() => {
          setHideAdvanced(!hideAdvanced);
        }}
      >
        <AiProducerIcon />
        {hideAdvanced ? 'Show Advanced' : 'Hide Advanced'}
      </Card>
      {hideAdvanced ? null : (
        <>
          <div>
            <label htmlFor="systemMessage">System Message:</label>
            <TextArea
              id="systemMessage"
              name="systemMessage"
              placeholder="System Message"
              value={systemMessage}
              onChange={async (e: any) => {
                setSystemMessage(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="userPrompt">User Prompt:</label>
            <TextArea
              id="userPrompt"
              name="userPrompt"
              placeholder="User Prompt"
              value={userPrompt}
              onChange={async (e) => {
                setUserPrompt(e.target.value);
              }}
            />
          </div>
          {producerVersion === 2 ? (
            <>
              <div>
                <label htmlFor="storyQuestions">Story Questions:</label>
                <TextArea
                  id="storyQuestions"
                  name="storyQuestions"
                  placeholder="Story Questions"
                  value={storyQuestions}
                  onChange={async (e: any) => {
                    setStoryQuestions(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="aiQuestionUnderstanding">
                  AI Question Understanding:
                </label>
                <TextArea
                  id="aiQuestionUnderstanding"
                  name="aiQuestionUnderstanding"
                  placeholder="AI Question Understanding"
                  value={aiQuestionUnderstanding}
                  onChange={async (e: any) => {
                    setAiQuestionUnderstanding(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="giveAITranscript">Give AI Transcript:</label>
                <TextArea
                  id="giveAITranscript"
                  name="giveAITranscript"
                  placeholder="Give AI Transcript"
                  value={giveAITranscript}
                  onChange={async (e: any) => {
                    setGiveAITranscript(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="askForAnswers">Ask For Answers:</label>
                <TextArea
                  id="askForAnswers"
                  name="askForAnswers"
                  placeholder="Ask For Answers"
                  value={askForAnswers}
                  onChange={async (e: any) => {
                    setAskForAnswers(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="askForOutline">Ask For Outline:</label>
                <TextArea
                  id="askForOutline"
                  name="askForOutline"
                  placeholder="Ask For Outline"
                  value={askForOutline}
                  onChange={async (e: any) => {
                    setAskForOutline(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="askForStory">Ask For Story:</label>
                <TextArea
                  id="askForStory"
                  name="askForStory"
                  placeholder="Ask For Story"
                  value={askForStory}
                  onChange={async (e: any) => {
                    setAskForStory(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="askForSimpleParagraph">
                  Ask For Simple Paragraph:
                </label>
                <TextArea
                  id="askForSimpleParagraph"
                  name="askForSimpleParagraph"
                  placeholder="Ask For Simple Paragraph"
                  value={askForSimpleParagraph}
                  onChange={async (e: any) => {
                    setAskForSimpleParagraph(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="getOriginalSententences">
                  Get Original Sentences:
                </label>
                <TextArea
                  id="getOriginalSententences"
                  name="getOriginalSententences"
                  placeholder="Get Original Sentences"
                  value={getOriginalSententences}
                  onChange={async (e: any) => {
                    setGetOriginalSententences(e.target.value);
                  }}
                />
              </div>
            </>
          ) : null}
          {producerVersion === 3 ? (
            <>
              <div>
                <label htmlFor="getOriginalSententences">
                  Get Original Sentences:
                </label>
                <TextArea
                  id="getOriginalSententences"
                  name="getOriginalSententences"
                  placeholder="Get Original Sentences"
                  value={getOriginalSententences}
                  onChange={async (e: any) => {
                    setGetOriginalSententences(e.target.value);
                  }}
                />
              </div>
            </>
          ) : null}
        </>
      )}
    </Main>
  );
});

export default AIStoryProducer;

const Main = styled.div`
  padding-top: 20px;
  font-size: 14px;

  label {
    display: block;
    color: #f3e9d7;
    font-size: 10px;
    margin-top: 10px;
  }
`;

const Message = styled.p`
  opacity: 0.9;
  line-height: 175%;
`;

const Card = styled.button<{ isSelected?: boolean }>`
  display: flex;
  width: 280px;
  margin: 10px auto;
  height: 40px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #484848;
  cursor: pointer;
  background-color: #030419;
  color: #fff;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const TextArea = styled.textarea`
  font-family: 'Inter', sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  padding: 8px;
  margin: 5px 0 10px;
  color: #f3e9d7;
  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;
  &::placeholder {
    color: #484848;
  }
  &:hover {
    border-color: #f2d093;
  }
  &:focus {
    outline: none;
    border-color: #f2d093;
  }
`;
const NumberInput = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin: 5px 0 10px;
  color: #f3e9d7;
  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 14px;
  &::placeholder {
    color: #484848;
  }
  &:hover {
    border-color: #f2d093;
  }
  &:focus {
    outline: none;
    border-color: #f2d093;
  }
`;
const TextAreaShort = styled.textarea`
  font-family: 'Inter', sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 8px;
  margin: 5px 0 10px;
  color: #f3e9d7;
  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;
  &::placeholder {
    color: #484848;
  }
  &:hover {
    border-color: #f2d093;
  }
  &:focus {
    outline: none;
    border-color: #f2d093;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 15px;
`;

const Tab = styled.div<{ isSelected?: boolean }>`
  border: 1px solid ${(props) => (props.isSelected ? '#f2d093' : '#484848')};
  border-radius: 4px;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#03041a' : '#484848')};
  background-color: ${(props) => (props.isSelected ? '#f2d093' : '')};
  cursor: pointer;
  ${(props) =>
    !props.isSelected &&
    css`
      &:hover {
        border-color: #f3e9d7;
        color: #f3e9d7;
      }
    `}
`;

const Select = styled.select`
  border: 1px solid #484848;
  border-radius: 4px;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  color: #484848;
  background-color: #f2d093;
  cursor: pointer;
`;

const PresetOption = styled.div.attrs((props: { active: boolean }) => props)`
  padding: 8px;
  position: relative;
  border-radius: 8px;
  width: calc(50% + 4px);
  box-sizing: border-box;
  border: 1px solid #484848;
  cursor: pointer;
  color: #484848;
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;

  ${(props) =>
    props.active &&
    css`
      color: #f2d093;
    `}

  &:hover {
    color: #f2d093;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #484848;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.active &&
      css`
        font-size: 9px;
        font-weight: 600;
        text-align: center;
        content: '✓';
        background: #4ad067;
      `}
  }
`;
