import styled from 'styled-components';

export const PropertyCaption = styled.div`
  margin: 15px 0 0 0;
  color: #848484;
  font-weight: 500;
  font-size: 10px;
  line-height: 12.1px;
  margin-bottom: 5px;
`;
