type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const RotatingSquare = (props: Props) => {
  const {
    width = '19',
    height = '19',
    viewBox = '0 0 19 19',
    strokeColor = '#03041A',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M2.97587 18.1101V14.8201H6.26586"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9648 7.98404C18.7834 12.3373 16.1195 16.653 11.77 17.8185C8.44112 18.7104 5.04454 17.5106 2.97587 15.0247"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.14737 11.128C0.328766 6.77477 2.99277 2.45901 7.34218 1.29359C10.6708 0.40168 14.0672 1.60131 16.1359 4.08688"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1358 1.00227V4.29227H12.8458"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.69534 9.9986C5.22982 9.91762 5.22982 9.24934 5.69534 9.16835C7.38183 8.87495 8.72321 7.59038 9.08927 5.91815L9.11732 5.78997C9.21805 5.32989 9.87314 5.32702 9.97787 5.7862L10.0119 5.93558C10.3915 7.59993 11.7333 8.87364 13.4151 9.16623C13.8829 9.24764 13.8829 9.91931 13.4151 10.0007C11.7333 10.2933 10.3915 11.567 10.0119 13.2314L9.97787 13.3807C9.87314 13.84 9.21805 13.837 9.11732 13.377L9.08927 13.2488C8.72321 11.5766 7.38183 10.292 5.69534 9.9986Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RotatingSquare;
