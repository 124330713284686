import { FIT_DEFAULT } from '../config/videoSettings';
import { ElementState } from '../renderer/ElementState';
import { videoCreator } from '../stores/VideoCreatorStore';

const FIELDS_TO_NOT_COMPARE = [
  'time',
  'track',
  'duration',
  'trim_start',
  'color_overlay',
  'animations',
];

const FIELDS_POSITIONAL = ['x', 'y', 'width', 'height'];

const SOURCE_FIELDS_DEFAULTS: Record<string, string> = {
  fit: FIT_DEFAULT,
  x: '50%',
  y: '50%',
  width: '100%',
  height: '100%',
};

export const getOriginalVideoTrackSourceDiff = (
  prevTracksState: ElementState[],
  newTracksState: ElementState[],
) => {
  const diff: Record<string, { track: number; changes: Record<string, any> }> =
    {};
  newTracksState.forEach((trackState, index) => {
    if (!videoCreator.frameLockedTracks.includes(trackState.track)) return;
    const prevSource = prevTracksState.find(
      (prevTrackState) => prevTrackState.source.id === trackState.source.id,
    )?.source;
    if (!prevSource) return;
    for (const key in { ...trackState.source, ...prevSource }) {
      if (FIELDS_TO_NOT_COMPARE.includes(key)) {
        continue;
      }

      if (prevSource && prevSource[key] !== trackState.source[key]) {
        diff[trackState.source.id] = diff[trackState.source.id] || {
          track: trackState.track,
          changes: {},
        };
        if (
          !trackState.source.hasOwnProperty(key) &&
          SOURCE_FIELDS_DEFAULTS[key]
        ) {
          diff[trackState.source.id].changes[key] = SOURCE_FIELDS_DEFAULTS[key];
        } else {
          diff[trackState.source.id].changes[key] =
            trackState.source[key] || SOURCE_FIELDS_DEFAULTS[key] || null;
        }
      }
    }
  });
  return diff;
};

export const getElementPositionProperties = (element: ElementState) => {
  const positionProperties: Record<string, any> = {};
  let scaleFactor = 1;
  if (element.source.animations?.length) {
    const currentPlayheadTime = videoCreator.time;
    const animation = element.source.animations[0];
    // assumes animation is from start to end of element time
    // const animationStartTime = animation.time || 0 + (element.globalTime || 0);
    // const animationEndTime = animation.duration

    // assumes linear easing
    if (element.globalTime > currentPlayheadTime && animation.start_scale) {
      scaleFactor = parseFloat(animation.start_scale) / 100;
    } else if (
      element.globalTime + element.duration < currentPlayheadTime &&
      animation.end_scale
    ) {
      scaleFactor = parseFloat(animation.end_scale) / 100;
    } else if (animation.start_scale || animation.end_scale) {
      const scalePerSecond =
        (parseFloat(animation.end_scale || '100') -
          parseFloat(animation.start_scale || '100')) /
        element.duration;
      scaleFactor =
        (parseFloat(animation.start_scale || '100') +
          scalePerSecond * (currentPlayheadTime - element.globalTime)) /
        100;
    }
  }

  for (const key of FIELDS_POSITIONAL) {
    positionProperties[key] =
      element.source[key] || SOURCE_FIELDS_DEFAULTS[key] || null;
    if (
      (scaleFactor !== 1 && positionProperties[key] && key === 'width') ||
      key === 'height'
    ) {
      positionProperties[key] = `${
        parseFloat(positionProperties[key]) * scaleFactor
      }%`;
    }
  }
  return positionProperties;
};
