import React from 'react';

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const DocumentIcon = (props: Props) => {
  const {
    width = '16',
    height = '18',
    viewBox = '0 0 16 18',
    strokeColor = 'currentColor',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M14.5385 15.7692C14.5385 16.0956 14.4087 16.4087 14.178 16.6395C13.9472 16.8703 13.6341 17 13.3077 17H2.23077C1.90434 17 1.5913 16.8703 1.36048 16.6395C1.12967 16.4087 1 16.0956 1 15.7692V2.23077C1 1.90434 1.12967 1.5913 1.36048 1.36048C1.5913 1.12967 1.90434 1 2.23077 1H10.2308L14.5385 5.30769V15.7692Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.69226 5.9231H7.1538"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.69226 9.61536H10.8461"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.69226 13.3076H10.8461"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentIcon;
