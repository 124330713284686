import React, { Fragment, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { KARAOKE_TRACK_NUMBER } from '../../stores/VideoCreatorStore';

interface TimelineTextElementProps {
  element: ElementState;
}

export const TimelineTextElement: React.FC<TimelineTextElementProps> = observer(
  (props) => {
    return (
      <Fragment>
        <Container>
          {props.element.source.type === 'text' ? (
            <Name>{props.element.source.text}</Name>
          ) : null}
          {props.element.source.track === KARAOKE_TRACK_NUMBER ? (
            <Name>
              {props.element.elements
                ?.map((el: any) => el.source.text)
                .join(' ')}
            </Name>
          ) : null}
        </Container>
      </Fragment>
    );
  },
);

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Name = styled.div`
  height: 100%;
  line-height: 120%;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  pointer-events: none;
  position: absolute;
  color: black;
  display: flex;
  align-items: center;
`;
