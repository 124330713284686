type Props = {
  width?: string;
  height?: string;
  fillColor?: string;
  viewBox?: string;
};

const SearchIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox={props.viewBox || '0 0 16 16'}
      fill="none"
    >
      <path
        d="M2.00002 2.00197C4.66932 -0.667324 9.00734 -0.667324 11.6766 2.00197C14.0601 4.38539 14.3094 8.10513 12.4279 10.7744L15.6557 14.0022C15.8751 14.2216 15.998 14.5174 15.998 14.8299C15.998 15.1424 15.8751 15.4349 15.6557 15.6576C15.4363 15.8803 15.1404 16 14.8279 16C14.5155 16 14.2229 15.877 14.0002 15.6576L10.7725 12.4299C9.59572 13.2609 8.2162 13.6831 6.83335 13.6831C5.08152 13.6831 3.32968 13.0149 1.9967 11.6819C-0.665955 9.0093 -0.665955 4.67127 2.00002 2.00197ZM3.1801 10.4985C5.19786 12.5163 8.48213 12.5163 10.4999 10.4985C11.4772 9.52122 12.0157 8.22147 12.0157 6.83862C12.0157 5.45577 11.4772 4.15603 10.4999 3.17873C9.48935 2.16818 8.16633 1.66623 6.83999 1.66623C5.51365 1.66623 4.19064 2.17151 3.1801 3.17873C1.16233 5.19981 1.16233 8.48076 3.1801 10.4985Z"
        fill={props.fillColor || '#333333'}
      />
    </svg>
  );
};

export default SearchIcon;

export const SearchIconAlt = (props: Props) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '17'}
      viewBox={props.viewBox || '0 0 16 17'}
      fill="none"
    >
      <path
        d="M13.0667 14.5L8.86667 10.3C8.53333 10.5667 8.15 10.7778 7.71667 10.9333C7.28333 11.0889 6.82222 11.1667 6.33333 11.1667C5.12222 11.1667 4.09733 10.7471 3.25867 9.908C2.42 9.06889 2.00044 8.044 2 6.83333C1.99956 5.62267 2.41911 4.59778 3.25867 3.75867C4.09822 2.91956 5.12311 2.5 6.33333 2.5C7.54356 2.5 8.56867 2.91956 9.40867 3.75867C10.2487 4.59778 10.668 5.62267 10.6667 6.83333C10.6667 7.32222 10.5889 7.78333 10.4333 8.21667C10.2778 8.65 10.0667 9.03333 9.8 9.36667L14 13.5667L13.0667 14.5ZM6.33333 9.83333C7.16667 9.83333 7.87511 9.54178 8.45867 8.95867C9.04222 8.37556 9.33378 7.66711 9.33333 6.83333C9.33289 5.99956 9.04133 5.29133 8.45867 4.70867C7.876 4.126 7.16756 3.83422 6.33333 3.83333C5.49911 3.83244 4.79089 4.12422 4.20867 4.70867C3.62644 5.29311 3.33467 6.00133 3.33333 6.83333C3.332 7.66533 3.62378 8.37378 4.20867 8.95867C4.79356 9.54356 5.50178 9.83511 6.33333 9.83333Z"
        fill={props.fillColor || '#F2D093'}
      />
    </svg>
  );
};
