type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};
const VideoIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox={props.viewBox || '0 0 14 14'}
      fill="none"
    >
      <path
        d="M1.76758 6.18945H12.3974V11.9768C12.3974 12.4896 11.9816 12.9054 11.4688 12.9054H2.69615C2.18332 12.9054 1.76758 12.4897 1.76758 11.9768V6.18945Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.76643 6.18917L12.034 3.43799L11.5885 1.77538C11.4558 1.28002 10.9466 0.986053 10.4512 1.11878L1.97753 3.38931C1.48217 3.52204 1.1882 4.03121 1.32093 4.52656L1.76643 6.18917Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.20508 10.5449H5.39293"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58398 5.43467L5.17793 2.53223"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9375 4.53526L8.53144 1.63281"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoIcon;
