import React, { useEffect } from 'react';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { brollService } from './BrollService';
import smartlook from 'smartlook-client';

const useInitializeAction = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const smartlookInitialized = smartlook.initialized();

  useEffect(() => {
    if (!brollService.contributor || !smartlookInitialized) return;
    brollService.identifyContributor();
  }, [smartlookInitialized, brollService.contributor]);

  useEffect(() => {
    if ((params.storyId || params.organizationId) && params.env) {
      videoCreator.linkFunctions({
        environment: params.env,
        currentUserAccessToken: process.env.REACT_APP_DATOCMS_API_TOKEN || null,
      } as any);
    }
  }, [params.env]);

  useEffect(() => {
    const { storyId, organizationId, env } = params || {};
    if (!videoCreator.datoContext.currentUserAccessToken) return;
    if (
      !env ||
      (!storyId && !organizationId) ||
      brollService.assets.length ||
      videoCreator.story ||
      videoCreator.organization
    )
      return;

    (async () => {
      brollService.organizationId = organizationId;
      brollService.storyId = storyId;
      brollService.environment = env;

      const data = await brollService.findOne();
      sessionStorage.removeItem('saved_assets');
      if (data) {
        const existingAssets = [
          ...(data.photos || []),
          ...(data?.videos || []),
        ].sort(
          (a, b) =>
            new Date(b._createdAt).getTime() - new Date(a._createdAt).getTime(),
        );

        brollService.assets = existingAssets.map((a) => ({
          id: a.id,
          imgSrc: a.responsiveImage
            ? a.responsiveImage?.src
            : a.video?.thumbnailUrl,
          videoSrc: a?.video?.mp4Url || a.url!,
          type: a.responsiveImage ? 'photo' : 'video',
          description: a.title,
          createdAt: new Date(a._createdAt),
          isPreview: false,
          state: 'uploaded',
        }));
        brollService.initialized = true;
      }
    })();
  }, [videoCreator.datoContext.currentUserAccessToken]);
};

export default useInitializeAction;
