import { computed } from 'mobx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { videoCreator } from '../../stores/VideoCreatorStore';
import { TranscriptElement } from '../../types.ts/transcript';
import { Cursor } from './TranscriptionText';

declare global {
  type CaretPosition = {
    offsetNode: Node;
    offset: number;
  };
  interface Document {
    caretPositionFromPoint?(x: number, y: number): CaretPosition;
  }
}

type TranscriptDiffElement = TranscriptElement & {
  originalValue?: string | null;
};

type ActiveElement = {
  element: TranscriptDiffElement;
  elementIndex: number;
  blockIndex: number;
  rect?: DOMRect;
};

type SelectedElement = {
  element: TranscriptDiffElement;
  elementIndex: number;
  blockIndex: number;
};

type Props = {
  cursor: { from: number; to: number };
};

export const TranscriptionDiff = observer((props: Props) => {
  const { cursor } = props;
  const blocks = useMemo(
    () =>
      computed(() =>
        getTranscriptElementBlocks(
          videoCreator.finalTranscriptionElements || [],
          videoCreator.originalTranscription?.elements || [],
        ),
      ),
    [
      videoCreator.finalTranscriptionElements,
      videoCreator.originalTranscription?.elements,
    ],
  ).get();

  const [activeElement, setActiveElement] = useState<ActiveElement | null>(
    null,
  );
  const [selectedStartElement, setSelectedStartElement] =
    useState<SelectedElement | null>(null);
  const [selectedEndElement, setSelectedEndElement] =
    useState<SelectedElement | null>(null);

  const handleMouseUpSelect = useCallback(() => {
    const selection = window.getSelection();
    if (!selection || !selection.toString()) return;

    const anchorBlockIndex = +(
      selection.anchorNode?.parentElement?.dataset.blockIndex || 0
    );
    const focusBlockIndex = +(
      selection.focusNode?.parentElement?.dataset.blockIndex || 0
    );
    const [startBlockIndex, endBlockIndex] = [
      Math.min(anchorBlockIndex, focusBlockIndex),
      Math.max(anchorBlockIndex, focusBlockIndex),
    ];
    const startBlock = blocks.find(
      (block) => block.indexOffset === startBlockIndex,
    );
    const endBlock = blocks.find(
      (block) => block.indexOffset === endBlockIndex,
    );
    const startElement = getElementFromCharIndex(
      selection.anchorOffset,
      startBlock?.elements,
    );
    const endElement = getElementFromCharIndex(
      selection.focusOffset,
      endBlock?.elements,
    );
    if (startElement)
      setSelectedStartElement({
        element: startElement.element,
        elementIndex: startBlockIndex + startElement.elementIndex,
        blockIndex: startBlockIndex,
      });
    if (endElement)
      setSelectedEndElement({
        element: endElement.element,
        elementIndex: endBlockIndex + endElement.elementIndex,
        blockIndex: endBlockIndex,
      });
  }, [blocks]);

  const renderElementBlocks = useCallback(
    (block: TranscriptElementBlock, index: number) => {
      return (
        <TranscriptDiffBlock
          block={block}
          cursor={
            cursor.from >= block.indexOffset &&
            cursor.from < block.indexOffset + block.elements.length
              ? cursor
              : null
          }
          isPlaying={videoCreator.isPlaying}
          key={`block-${index}`}
          activeDataIndex={
            activeElement && block.indexOffset === activeElement.blockIndex
              ? activeElement.elementIndex
              : undefined
          }
          setActiveElement={setActiveElement}
          handleMouseUpSelect={handleMouseUpSelect}
          startDataIndex={
            selectedStartElement &&
            block.indexOffset === selectedStartElement.blockIndex
              ? selectedStartElement.elementIndex
              : undefined
          }
          endDataIndex={
            selectedEndElement &&
            block.indexOffset === selectedEndElement.blockIndex
              ? selectedEndElement.elementIndex
              : undefined
          }
        />
      );
    },
    [cursor, activeElement],
  );

  useEffect(() => {
    const container = document.getElementById(
      'transcript-scrollable-container',
    );
    if (container) {
      const handleScroll = (e: Event) => {
        if (!e) return;
        const target = e.target;
        if (target instanceof HTMLElement) setActiveElement(null);
      };
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const [scrolled, setInitialScrolled] = useState(false);
  // useEffect(() => {
  //   if (!scrolled && blocks.length) {
  //     const scrollBlock = blocks.find(
  //       (block) => block.state === 'added' || block.state === undefined,
  //     );
  //     const container = document.getElementById(
  //       'transcript-scrollable-container',
  //     );
  //     if (scrollBlock && container) {
  //       const node = document.querySelector(
  //         `[data-block-index="${scrollBlock.indexOffset}"]`,
  //       );
  //       if (node) {
  //         const range = document.createRange();
  //         range.selectNodeContents(node);
  //         const rect = range.getBoundingClientRect();
  //         container.scrollTo({
  //           top: rect.top + container.scrollTop - 168,
  //           behavior: 'smooth',
  //         });
  //       }
  //     }
  //     setInitialScrolled(true);
  //   }
  // }, [scrolled, blocks]);

  return (
    <>
      {blocks.map(renderElementBlocks)}

      {activeElement && (
        <SActiveElement
          key={activeElement.element.initial_index}
          rect={activeElement.rect}
          hide={
            activeElement.element.state === 'replaced' ||
            checkMultipleWords(activeElement.element)
          }
        >
          {activeElement.element.value}
        </SActiveElement>
      )}
    </>
  );
});

type TranscriptElementBlock = {
  elements: TranscriptDiffElement[];
  state: TranscriptDiffElement['state'];
  indexOffset: number;
};

/**
 * Splits the transcript elements into blocks based on their state.
 * Replaced elements and elements with multiple words are treated as
 * a single block.
 */
const getTranscriptElementBlocks = (
  elements: TranscriptDiffElement[],
  original: TranscriptDiffElement[],
) => {
  const blocks: TranscriptElementBlock[] = [];
  let currentBlock: TranscriptDiffElement[] = [];
  let currentState: TranscriptDiffElement['state'] = elements[0].state;
  elements.forEach((el, index, arr) => {
    if (
      el.state === 'replaced' || // cut replaced element block
      checkMultipleWords(el) || // cut pre-multiple word element block
      (currentBlock.length === 1 && checkMultipleWords(currentBlock[0])) || // cut post-multiple word element block
      el.state !== currentState // cut state change element block
    ) {
      blocks.push({
        elements: currentBlock,
        state: currentState,
        indexOffset: index - currentBlock.length,
      });
      currentBlock =
        el.state === 'replaced'
          ? [{ ...el, originalValue: original[el.initial_index]?.value }]
          : [el];
      currentState = el.state;
    } else {
      currentBlock.push(el);
      if (index === arr.length - 1) {
        blocks.push({
          elements: currentBlock,
          state: currentState,
          indexOffset: index - currentBlock.length,
        });
      }
    }
  });
  return blocks;
};

const checkMultipleWords = (element: TranscriptDiffElement) => {
  return (element.value?.split(' ').length || 0) > 2;
};

const getElementFromCharIndex = (
  charIndex: number,
  elements?: TranscriptDiffElement[],
) => {
  if (!elements) return;
  let length = 0;
  for (let i = 0; i < elements.length; i++) {
    const elementCharIndex = length;
    length += elements[i].value?.length || 0;
    if (charIndex <= length)
      return {
        element: elements[i],
        elementIndex: i,
        elementCharIndex,
      };
  }
};

const getRect = (
  textNode: ChildNode,
  element: TranscriptDiffElement,
  elementCharIndex = 0,
) => {
  const range = document.createRange();
  range.setStart(textNode, elementCharIndex);
  range.setEnd(textNode, elementCharIndex + (element.value?.length || 0));
  const rect = range.getBoundingClientRect();
  return rect;
};

type TranscriptDiffBlockProps = {
  block: TranscriptElementBlock;
  cursor: { from: number; to: number } | null;
  isPlaying: boolean;
  activeDataIndex?: number;
  setActiveElement: (active: ActiveElement | null) => void;
  handleMouseUpSelect: (e: React.MouseEvent<HTMLSpanElement>) => void;
  startDataIndex?: number;
  endDataIndex?: number;
};
const TranscriptDiffBlock = (props: TranscriptDiffBlockProps) => {
  const {
    block,
    cursor,
    isPlaying,
    activeDataIndex,
    setActiveElement,
    handleMouseUpSelect,
    startDataIndex,
    endDataIndex,
  } = props;
  const { state, elements } = block;

  const text = useMemo(() => {
    if (state === 'replaced') return elements[0].value || '';
    return elements.map((element) => element.value).join('');
  }, [elements]);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      const firstElement = elements[0];
      if (
        firstElement &&
        (state === 'replaced' || checkMultipleWords(firstElement))
      ) {
        setActiveElement({
          element: firstElement,
          elementIndex: block.indexOffset,
          blockIndex: block.indexOffset,
        });
        return;
      }

      const mousePos = document.caretPositionFromPoint?.(e.clientX, e.clientY);
      const charIndex = mousePos?.offset || 0;
      const match = getElementFromCharIndex(charIndex, elements);
      if (match) {
        setActiveElement({
          element: match.element,
          elementIndex: block.indexOffset + match.elementIndex,
          blockIndex: block.indexOffset,
          rect: e.currentTarget.firstChild
            ? getRect(
                e.currentTarget.firstChild,
                match.element,
                match.elementCharIndex,
              )
            : undefined,
        });
      }
    },
    [block, elements],
  );

  return (
    <>
      {state === 'replaced' && elements[0].originalValue && (
        <SReplacedElement>{elements[0].originalValue}</SReplacedElement>
      )}
      <STranscriptSpan
        data-block-index={block.indexOffset}
        data-index={activeDataIndex}
        data-select-start-index={startDataIndex}
        data-select-end-index={endDataIndex}
        state={state}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpSelect}
        hover={elements.length === 1 && checkMultipleWords(elements[0])}
      >
        {text}
      </STranscriptSpan>
    </>
  );
};

const getColorFromState = (state: TranscriptDiffElement['state']) => {
  switch (state) {
    case 'added':
      return '#7bb975';
    case 'removed':
      return '#cd5c5c';
    case 'replaced':
      return '#bdbdbd';
    case 'muted':
      return '#F69B12';
    case 'cut':
      return '#7bb97599';
    default:
      return '#bdbdbd';
  }
};

const SActiveElement = styled.div<{
  rect?: DOMRect;
  hide?: boolean;
}>`
  display: flex;
  align-items: center;
  position: fixed;
  pointer-events: none;
  background: #030419;
  color: #90ee90;
  ${({ rect }) =>
    rect &&
    css`
      top: ${rect.top}px;
      left: ${rect.left}px;
      right: ${rect.right}px;
      bottom: ${rect.bottom}px;
      width: ${rect.width}px;
      height: ${rect.height}px;
      z-index: 9999;
    `}
  ${({ hide }) =>
    hide &&
    css`
      width: 0;
      height: 0;
    `}
`;

const SReplacedElement = styled.span`
  color: #b59b14;
  text-decoration: line-through;
`;

const STranscriptSpan = styled.span<{
  state: TranscriptDiffElement['state'];
  hover?: boolean;
}>`
  color: ${({ state }) => getColorFromState(state)};
  cursor: pointer;
  ${({ state }) =>
    state &&
    {
      added: css``,
      cut: css`
        text-decoration: line-through #7bb97580;
        cursor: default;
      `,
      removed: css`
        text-decoration: line-through #cd5c5c80;
        cursor: default;
      `,
      replaced: css`
        &:hover {
          color: #90ee90;
        }
      `,
      muted: css``,
    }[state]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        color: #90ee90;
      }
    `}
`;
