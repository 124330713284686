// Import necessary types and utilities
import { TranscriptElement } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';
import { TranscriptChange } from '../../types.ts/transcript';

class ReplaceTsHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'replace_ts' },
  ) {
    const _elements = transcriptionElementsMutable;
    const elementsCount = change.count || 1;

    for (let i = 0; i < elementsCount; i++) {
      const index = change.index + i;
      const element = _elements[index];

      element.ts = change.newTs;
    }
  }
}

export default ReplaceTsHandler;
