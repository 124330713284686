// src/utility/debug.ts

// Command to toggle debug mode manually from the web console
export const setDebugMode = (value: boolean) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('debugMode', value.toString());
    console.log(`Debug mode is now ${value ? 'ON' : 'OFF'}`);
    window.location.reload(); // Reload the page to apply changes
  }
};

// Function to check if in debug mode
export const inDebugMode = () => {
  if (typeof window !== 'undefined') {
    return (
      window.localStorage.getItem('debugMode') === 'true' ||
      process.env.REACT_APP_ARBOR_DEBUG === 'true'
    );
  }
  return false;
};

// Expose the command to the global window object
if (typeof window !== 'undefined') {
  (window as any).setDebugMode = setDebugMode;
}
