import { FC, useState, useEffect, useRef, ChangeEvent } from 'react';
import { FileData, Music } from '../../types.ts/story';
import styled from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SearchInput from './SearchInput';
import _uniqBy from 'lodash/uniqBy';
import SongCollection, { AddSongToTrackOptions } from './SongCollection';
import BackIcon from '../../svgs/BackIcon';
import { SidebarOption } from '../../types.ts/general';
import MusicTheme from './MusicTheme';
import MusicSongItem from './MusicSongItem';
import { observer } from 'mobx-react-lite';
import PrimaryActionButton from './PrimaryActionButton';
import MyAudioLibrary from './MyAudioLibrary';
import { checkWordsInString } from '../../utility/general';

type SongWithGenre = (Music['collection'][0] & { genre: string })[];

type MusicTypes = 'stock-music' | 'my-audio' | 'sound-effect';

const MUSIC_SECTION_WIDTH_PX = 300;

const MusicLibrary: FC<{}> = observer((props) => {
  const stockMusic = videoCreator.stockMusic;

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentSong, setCurrentSong] = useState('');
  const [selectedTab, setSelectedTab] = useState<MusicTypes>('stock-music');
  const [searchValues, setSearchValues] = useState<SongWithGenre>();

  const [selectedGenre, setSelectedGenre] = useState<string>('');

  if (!stockMusic && !videoCreator.story?.myAudios) {
    return <div>You don't have any music themes.</div>;
  }

  const preloadAudio = (url: string) => {
    const audio = new Audio(url);
    audio.preload = 'auto';
    audio.load();
  };

  useEffect(() => {
    if (!selectedGenre) return;
    const selectedCollection = stockMusic?.find(
      (m) => m.genre === selectedGenre,
    );
    if (!selectedCollection) return;

    for (const song of selectedCollection.collection) {
      preloadAudio(song.url);
      if (!videoCreator.audioTracksData[song.url]) {
        videoCreator.loadWaveformForSource({
          source: song.url,
          name: song.title,
        });
      }
    }
  }, [selectedGenre]);

  useEffect(() => {
    if (!searchValues) return;

    for (const song of searchValues) {
      if (!videoCreator.audioTracksData[song.url]) {
        videoCreator.loadWaveformForSource({
          source: song.url,
          name: song.title,
        });
      }
    }
  }, [searchValues]);

  const handleSearchMusic = (text: string) => {
    const collections = stockMusic
      ?.flatMap((m) =>
        m.collection.map((coll) => ({ ...coll, genre: m.genre })),
      )
      .filter((s) => {
        if (selectedGenre) return s.genre === selectedGenre;
        return true;
      }) as SongWithGenre;

    if (!text) return setSearchValues(collections);

    const matched =
      collections?.filter((c) => {
        const checkSongName =
          c.customData?.songName &&
          checkWordsInString(c.customData?.songName, text);

        const checkArtist =
          c.customData?.artist &&
          checkWordsInString(c.customData?.artist, text);

        const checkTags = c.tags?.some((t) =>
          checkWordsInString(t || '', text),
        );

        return (
          checkSongName ||
          checkArtist ||
          c.tags?.includes(text.toLowerCase()) ||
          (text?.length > 3 && checkTags)
        );
      }) || [];

    const uniqueMatched = _uniqBy(matched, 'url');
    setSearchValues(uniqueMatched);
  };

  const handleAddToTrack = async (
    url: string,
    songName: string,
    mediaDuration: string,
    options: AddSongToTrackOptions,
  ) => {
    const songDuration = parseFloat(mediaDuration);
    const freeTrack = videoCreator.getFreeMediaTrack('audio', songDuration);
    const elementSource: Record<string, any> = {
      name: songName.replace(/\b\w/g, (match) => match.toUpperCase()),
      type: 'audio',
      source: url,
      autoplay: true,
      ...(freeTrack && { track: freeTrack }),
      volume: 10,
    };

    if (options?.fitSongToVideo) {
      const availableDuration = videoCreator.getFreeMediaDuration(songDuration);
      if (Math.round(availableDuration) < 1) {
        videoCreator.toastState = {
          state: 'error',
          message: 'Song addition failed, please adjust playhead',
        };
        return;
      }
      elementSource.duration = availableDuration;
    }

    await videoCreator.createElement(
      elementSource,
      true,
      freeTrack ? undefined : videoCreator.pickOriginalVideoTrack,
    );

    videoCreator.sidebarOptions = SidebarOption.editing;
  };

  const renderBackButton = () => (
    <>
      {selectedGenre || searchValues ? (
        <Back
          onClick={() => {
            setSelectedGenre('');
            setSearchValues(undefined);
          }}
        >
          <BackIcon />
        </Back>
      ) : null}
    </>
  );

  const renderThemes = () => (
    <>
      {!selectedGenre && !searchValues ? (
        <>
          {selectedTab === 'stock-music' ? (
            <MusicTheme
              music={stockMusic}
              setSelectedGenre={setSelectedGenre}
            />
          ) : null}
        </>
      ) : null}
    </>
  );

  const renderTabs = () => (
    <Tabs>
      <Tab
        isSelected={selectedTab === 'stock-music'}
        onClick={() => setSelectedTab('stock-music')}
      >
        Stock Music
      </Tab>

      <Tab
        isSelected={selectedTab === 'my-audio'}
        onClick={() => setSelectedTab('my-audio')}
      >
        My Audio
      </Tab>
      <Tab
        isSelected={selectedTab === 'sound-effect'}
        onClick={() => setSelectedTab('sound-effect')}
      >
        Sound Effect
      </Tab>
    </Tabs>
  );

  const renderCollectionByGenre = (music: Music) => (
    <Collection key={music.id}>
      {selectedGenre === music.genre && (
        <Songs>
          {!music.collection.length ? (
            <div className="no-content">You have no song in this theme</div>
          ) : (
            <SongCollection onAddToTrack={handleAddToTrack}>
              {({ handleSongSelect, isSongSelected }) =>
                music.collection?.map((song) => (
                  <MusicSongItem
                    key={song.id}
                    audioRef={audioRef}
                    song={song}
                    currentSong={currentSong}
                    setCurrentSong={setCurrentSong}
                    mood={selectedGenre}
                    onSongClick={handleSongSelect}
                    customTimelineScale={
                      (MUSIC_SECTION_WIDTH_PX /
                        parseFloat(song.customData.duration)) *
                      1
                    }
                    isHighlighted={isSongSelected(song)}
                  />
                ))
              }
            </SongCollection>
          )}
        </Songs>
      )}
    </Collection>
  );

  const renderNoSearchContent = () => (
    <Collection>
      <Songs>
        <div className="no-content">No matching search result.</div>
      </Songs>
    </Collection>
  );

  const renderSearchResults = (
    searchValues: (Music['collection'][0] & { genre: string })[],
  ) => (
    <Collection>
      <Songs>
        <SongCollection onAddToTrack={handleAddToTrack}>
          {({ handleSongSelect, isSongSelected }) =>
            searchValues.map((song) => (
              <MusicSongItem
                key={song.id}
                audioRef={audioRef}
                song={song}
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
                mood={song.genre}
                onSongClick={handleSongSelect}
                customTimelineScale={
                  (MUSIC_SECTION_WIDTH_PX /
                    parseFloat(song.customData.duration)) *
                  1
                }
                isHighlighted={isSongSelected(song)}
              />
            ))
          }
        </SongCollection>
      </Songs>
    </Collection>
  );

  return (
    <Library>
      <audio ref={audioRef} preload="auto">
        <source src="" />
      </audio>

      {renderBackButton()}
      {renderTabs()}

      {selectedTab === 'stock-music' && (
        <SearchInput
          placeholder="Search music"
          handleAction={handleSearchMusic}
          radius="20px"
        />
      )}
      {renderThemes()}

      {selectedTab === 'stock-music' ? (
        <MusicCollection>
          {(() => {
            if (searchValues?.length) {
              return renderSearchResults(searchValues);
            } else if (searchValues?.length === 0) {
              return renderNoSearchContent();
            } else if (selectedGenre) {
              return stockMusic.map((music) => renderCollectionByGenre(music));
            } else {
              return null;
            }
          })()}
        </MusicCollection>
      ) : null}

      {selectedTab === 'my-audio' && (
        <MyAudioLibrary
          currentSong={currentSong}
          audioRef={audioRef}
          handleAddToTrack={handleAddToTrack}
          setCurrentSong={setCurrentSong}
          preloadAudio={preloadAudio}
        />
      )}
    </Library>
  );
});

export default MusicLibrary;

const Back = styled.button`
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: #030419;
  width: 0;
  margin-top: 15px;
`;

const Tab = styled.div<{ isSelected?: boolean }>`
  color: ${(props) => (props.isSelected ? '#F2D093' : '')};
  text-decoration: ${(props) => (props.isSelected ? 'underline' : '')};
  cursor: pointer;
  font-size: 14px;
  padding: 10px 10px 10px 0;
`;

const Tabs = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: 20px;
`;

const Library = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
`;

const Theme = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  & > span {
    font-size: 14px;
  }
  margin-bottom: 20px;
`;

const ThemeCards = styled.div<{ genre?: string }>`
  display: flex;
  width: 88px;
  height: 88px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  border-radius: 4px;
  background-image: ${(props) => `url("/mood_thumbnails/${props.genre}.png")`};
  border: 1px solid #484848;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
`;

const MusicCollection = styled.div`
  margin-top: 20px;
`;

const Collection = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Songs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-transform: capitalize;
  .no-content {
    text-transform: none;
  }
`;
