type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const ShareIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    strokeColor = '#03041A',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M10.625 5.02502H2.625V18.625H16.225V10.625"
        stroke={strokeColor}
      />
      <path d="M9.0249 12.225L18.6249 2.625" stroke={strokeColor} />
      <path d="M12.2251 2.625H18.6251V9.025" stroke={strokeColor} />
    </svg>
  );
};

export default ShareIcon;
