type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};
const AiProducerIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '22'}
      height={props.height || '20'}
      viewBox={props.viewBox || '0 0 22 20'}
      fill="none"
    >
      <path
        d="M10.8847 19.1411L6.21289 19.5243L6.61778 15.1028L16.3351 5.96492C16.4802 5.82455 16.6534 5.71301 16.8446 5.63686C17.0357 5.5607 17.2411 5.52148 17.4485 5.52148C17.656 5.52148 17.8614 5.5607 18.0526 5.63686C18.2438 5.71301 18.417 5.82455 18.562 5.96492L20.5397 7.85145C20.6856 7.98847 20.8015 8.15148 20.8806 8.33109C20.9597 8.51069 21.0004 8.70332 21.0004 8.89788C21.0004 9.09245 20.9597 9.28509 20.8806 9.4647C20.8015 9.6443 20.6856 9.80731 20.5397 9.94432L10.8847 19.1411Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.40987 5.5377C0.863377 5.4477 0.863377 4.70522 1.40987 4.61524C3.38972 4.28924 4.9644 2.86202 5.39414 1.00408L5.42708 0.861668C5.5453 0.350488 6.31436 0.347306 6.43731 0.857488L6.4773 1.02346C6.92294 2.87264 8.49805 4.2878 10.4724 4.61289C11.0217 4.70332 11.0217 5.4496 10.4724 5.54004C8.49805 5.86513 6.92294 7.28029 6.4773 9.12947L6.43731 9.29544C6.31436 9.80563 5.5453 9.80244 5.42708 9.29125L5.39414 9.14885C4.9644 7.2909 3.38972 5.86368 1.40987 5.5377Z"
        stroke={props.strokeColor || '#F2D093'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AiProducerIcon;
