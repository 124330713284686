import { Canvas, Button, FieldError } from 'datocms-react-ui';
import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { buildClient, LogLevel } from '@datocms/cma-client-browser';
import { useState } from 'react';
import styled from 'styled-components';
import { ShareModalAnimate } from '../styles/mainStyle';

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

export default function RestartTranscript({ ctx }: PropTypes) {
  const [error, setError] = useState<string>();
  const [updating, setUpdating] = useState<boolean>(false);
  const dClient = buildClient({
    apiToken: ctx.currentUserAccessToken || null,
    environment: ctx.environment,
    logLevel: LogLevel.NONE,
  });

  const handleRestartTranscript = async () => {
    try {
      const storyId = ctx.item?.id!;
      setUpdating(true);
      await dClient.items.update(storyId, {
        transcription: null,
        locked: false,
      });

      await dClient.items.publish(storyId);
    } catch (err: any) {
      console.error('Error in RestartTranscript handler', err);
      setError(`API Error`);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Canvas ctx={ctx}>
      <Button type="button" onClick={handleRestartTranscript}>
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <span>Restart Transcript Generation</span>
          {updating && <Animate color="#d49417" />}
        </div>
      </Button>
      {error && <FieldError>{error}</FieldError>}
    </Canvas>
  );
}

const Animate = styled(ShareModalAnimate)``;
