import React from 'react'

type Props = {
    width?: string;
    height?: string;
    strokeColor?: string;
}
const LinkIconAlt = (props: Props) => {
    const { width = "17", height = "18", strokeColor = "currentColor" } = props
    return (
        <svg width={width} height={height} viewBox="0 0 17 18" fill="none">
            <path
                d="M7.26953 4.0769L9.07181 2.27449C10.7711 0.57517 13.5262 0.57517 15.2257 2.27449C16.925 3.97383 16.925 6.72897 15.2257 8.42831L13.4233 10.2307"
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.73057 13.9229L7.92831 15.7253C6.22897 17.4246 3.47382 17.4246 1.7745 15.7253C0.0751662 14.026 0.0751675 11.2708 1.7745 9.57144L3.57676 7.76904"
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.9611 6.53809L6.03809 11.4611"
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    )
}

export default LinkIconAlt