type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  fillColor?: string;
};
const RemoveIconAlt = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#F3E9D7',
    fillColor = '#484848',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M0.00195312 8C0.00195313 10.1217 0.844808 12.1566 2.3451 13.6569C3.84539 15.1571 5.88022 16 8.00195 16C10.1237 16 12.1585 15.1571 13.6588 13.6569C15.1591 12.1566 16.002 10.1217 16.002 8C16.002 5.87827 15.1591 3.84344 13.6588 2.34315C12.1585 0.842855 10.1237 0 8.00195 0C5.88022 0 3.84539 0.842855 2.3451 2.34315C0.844808 3.84344 0.00195313 5.87827 0.00195312 8Z"
        fill={fillColor}
      />
      <path
        d="M6 10L10 6"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10L6 6"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemoveIconAlt;
