type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};
const MediaIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '22'}
      viewBox={props.viewBox || '0 0 24 22'}
      fill="none"
    >
<path d="M12.6923 19.9002C13.627 19.9002 14.3846 19.1329 14.3846 18.1864C14.3846 17.24 13.627 16.4727 12.6923 16.4727C11.7577 16.4727 11 17.24 11 18.1864C11 19.1329 11.7577 19.9002 12.6923 19.9002Z" stroke={props.strokeColor || '#F2D093'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.3075 17.7576C21.2421 17.7576 21.9998 16.9904 21.9998 16.0439C21.9998 15.0974 21.2421 14.3301 20.3075 14.3301C19.3728 14.3301 18.6152 15.0974 18.6152 16.0439C18.6152 16.9904 19.3728 17.7576 20.3075 17.7576Z" stroke={props.strokeColor || '#F2D093'}stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.0003 16.044V9.32595C21.9999 9.26038 21.9847 9.19579 21.9558 9.13712C21.9269 9.07845 21.8851 9.02728 21.8336 8.98755C21.7821 8.94782 21.7223 8.92058 21.6588 8.90794C21.5952 8.8953 21.5297 8.89759 21.4672 8.91464L14.698 10.8169C14.6085 10.8413 14.5294 10.8948 14.4729 10.9691C14.4163 11.0434 14.3854 11.1344 14.3849 11.2282V18.1862" stroke={props.strokeColor || '#F2D093'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.3849 13.4733L22.0003 11.3311" stroke={props.strokeColor || '#F2D093'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.91666 9.90001H11.0833C11.5436 9.90001 11.9167 9.50604 11.9167 9.02002V1.98009C11.9167 1.49408 11.5436 1.1001 11.0833 1.1001H1.91666C1.45642 1.1001 1.08333 1.49408 1.08333 1.98009V9.02002C1.08333 9.50604 1.45642 9.90001 1.91666 9.90001Z" stroke={props.strokeColor || '#F2D093'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.58489 4.80675C9.15797 4.80675 9.62255 4.31616 9.62255 3.71099C9.62255 3.10582 9.15797 2.61523 8.58489 2.61523C8.01181 2.61523 7.54723 3.10582 7.54723 3.71099C7.54723 4.31616 8.01181 4.80675 8.58489 4.80675Z" stroke={props.strokeColor || '#F2D093'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.23557 9.89998C7.97681 8.44456 7.23679 7.13361 6.14755 6.20099C5.0583 5.26837 3.6907 4.77476 2.28876 4.80824C1.88428 4.8071 1.48062 4.84689 1.08333 4.92705" stroke={props.strokeColor || '#F2D093'} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9167 7.20784C11.2603 6.97377 10.5721 6.85458 9.87929 6.855C9.00237 6.85299 8.1343 7.04042 7.32849 7.40577" stroke={props.strokeColor || '#F2D093'} stroke-linecap="round" stroke-linejoin="round"/>

</svg>);

};

export default MediaIcon;
