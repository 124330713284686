import React, { Fragment, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { MAX_CANVAS_WIDTH, WaveForm } from './WaveForm';
import { VolumeKeyPoints } from './VolumeKeyPoints';
import { getSongDuration } from '../../utility/timeFormat';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { useWaveformResample } from './useWaveformResample';
import { debounce } from 'lodash';

interface TimelineAudioElementProps {
  element: ElementState;
  onContextMenu?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    el: ElementState,
  ) => void;
}

export const TimelineAudioElement: React.FC<TimelineAudioElementProps> =
  observer((props) => {
    const active = videoCreator.activeElementIds.includes(
      props.element.source.id,
    );

    const [height, setHeight] = useState(0);
    const [numberOfWaveforms, setNumberOfWaveforms] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
      if (ref.current === null) return;
      // @ts-ignore
      setHeight(ref.current?.clientHeight);
    });

    let url = props.element.source.source;
    let type = props.element.source.type;

    if (videoCreator.isOriginalVideoElement(props.element.source)) {
      url = videoCreator.originalVideoAudioUrl;
    }

    useWaveformResample({ url, isInMusicProducer: false });

    const debounceRerenderWaveforms = debounce(() => {
      const numberOfWaveforms =
        Math.floor(
          (props.element.duration * videoCreator.timelineScale) /
            MAX_CANVAS_WIDTH,
        ) + 1;
      setNumberOfWaveforms(numberOfWaveforms);
    }, 750);

    useEffect(() => {
      debounceRerenderWaveforms();
    }, [videoCreator.timelineScale]);

    const handleContextMenu = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      if (videoCreator.isOriginalVideoElement(props.element.source)) return;
      if (props.onContextMenu) props.onContextMenu(e, props.element);
    };

    return (
      <Fragment>
        <Container ref={ref} onContextMenu={handleContextMenu}>
          <Name placement={type === 'audio' ? 'left' : 'right'}>
            {props.element.source.name}{' '}
            {getSongDuration(props.element.duration.toString())}
          </Name>
          {
            // @ts-ignore
            [...Array(numberOfWaveforms)].map((_, index) => {
              const part_trim_start =
                parseFloat(props.element.source.trim_start || '0') +
                (index * props.element.duration) / numberOfWaveforms;
              const part_duration = props.element.duration / numberOfWaveforms;
              return (
                <WaveForm
                  id={`waveform-canvas-${props.element.source.id}-${index}`}
                  key={`${props.element.source.id}-${part_trim_start}-${part_duration}`}
                  url={url}
                  type={type}
                  trim_start={part_trim_start.toFixed(2)}
                  duration={part_duration.toFixed(2)}
                  active={active}
                  height={height}
                />
              );
            })
          }
          <VolumeKeyPoints
            id={props.element.source.id}
            element={props.element}
            type={props.element.source.type}
            height={height}
          />
        </Container>
      </Fragment>
    );
  });

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
`;

const Name = styled.div<{ placement: string }>`
  line-height: 120%;
  font-size: 12px;
  font-weight: 500;
  ${({ placement }) => (placement === 'left' ? 'left: 4px;' : 'right: 4px;')}
  top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  pointer-events: none;
  position: absolute;
`;
