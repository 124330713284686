import { TranscriptChange, TranscriptElement } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';

class MuteChangeHandler extends ChangeHandler {
  apply(
    transcriptElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'mute' | 'restore_mute' },
  ) {
    if (change.type === 'mute') {
      this.applyMuteChange(transcriptElementsMutable, change);
    } else if (change.type === 'restore_mute') {
      this.applyRemoveMuteChange(transcriptElementsMutable, change);
    }
  }

  applyMuteChange(
    transcriptElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'mute' },
  ) {
    const elementsCount = change.count || 1;
    for (let i = 0; i < elementsCount; i++) {
      if (transcriptElementsMutable[change.index + i])
        // OOB check cause this code is insane.
        transcriptElementsMutable[change.index + i].state = 'muted';
    }

    this.fixCapitalizationAt(transcriptElementsMutable, change.index);
  }

  private applyRemoveMuteChange(
    transcriptElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'restore_mute' },
  ) {
    const elementsCount = change.count || 1;

    for (let i = 0; i < elementsCount + 1; i++) {
      const index = change.index + i;
      if (
        transcriptElementsMutable[index] && // OOB check cause this code is insane.
        transcriptElementsMutable[index].state &&
        transcriptElementsMutable[index].state === 'muted'
      ) {
        delete transcriptElementsMutable[index].state;
      }
    }

    this.fixCapitalizationAt(transcriptElementsMutable, change.index);
    this.fixCapitalizationAt(
      transcriptElementsMutable,
      change.index + change.count,
    );
  }
}

export default MuteChangeHandler;
