import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import Modal from '../Modal';
import styled, { css } from 'styled-components';
import { SocialPost, Story, VideoClip, Video } from '../../types.ts/story';
import { set } from 'lodash';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { toPng } from 'html-to-image';
import { AssetRepository } from '../../repositories/AssetRepository';
import { v4 as uuid } from 'uuid';
import { ShareModalDropdown, ShareModalDropdownItem } from '../../styles/mainStyle';
import NewProfileIcon from '../../svgs/NewProfileIcon';
import InstagramIcon from '../../svgs/InstagramIcon';
import SpinningLoading from '../SpinningLoading';
import AutoResizeableMultilineInput from '../common/AutoResizeableMultilineInput';

type PostObject = {
  post?: string;
  mediaUrls?: string[];
  newImageUploadUrl?: string;
  newImageUploadId?: string;
  newImageId?: string;
  platforms?: string[];
  scheduleDate?: string;
  faceBookOptions?: {
    thumbNail?: string;
  };
  instagramOptions?: {
    coverUrl?: string;
    thumbNailOffset: number;
    reels?: boolean;       // required for Reels
    shareReelsFeed?: boolean;
  };
  tikTokOptions?: {
    thumbNailOffset?: string;
  };
  twitterOptions?: {
    thumbNail?: string;
  };
  linkedInOptions?: {
    thumbNail?: string;
  };
  youTubeOptions?: {
    thumbNail?: string;
    title?: string;
    visibility?: string;
  };
};

function extractSrcFromIframe(iframeHtml: string) {
  const regex = /<iframe[^>]*\ssrc="([^"]*)"[^>]*>/i;
  const match = regex.exec(iframeHtml);
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

type Props = {
  platform: string;
  storyId?: string;
  showcaseId?: string;
  showcaseName?: string;
  videoId?: string;
  video?: Pick<Video, 'thumbnail' | 'videoFilePrimary'>;
  sharableImageId?: string;
  title?: string;
  text?: string;
  mediaUrl?: string;
  profiles?: Array<{
    id: string;
    name: string;
    platforms?: {
      displayName: string;
      id: string;
      platform: string;
      profileUrl: string;
      type: string;
      usedQuota: number;
      userImage: string;
      username: string;
    }[];
  }>
  pos?: DOMRect,
  mode: "profile" | "link" | "share" | "connect" | null;
  imageRef?: React.RefObject<HTMLImageElement>;
  Icon?: React.ReactNode;
  onClose?: () => void;
};

// modal to choose profile to create/link/share to
const ShareProfileView = (props: Props) => {
  const { platform, storyId, showcaseId, showcaseName, videoId, sharableImageId, title, text, mediaUrl, profiles, mode, imageRef, video, onClose = () => { } } = props;
  // console.log('profiles', profiles)
  const [profileId, setProfileId] = useState<string>(profiles && profiles.length > 0 ? profiles[0].id : 'new');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [embedRaw, setEmbedRaw] = useState<string>('');
  const linkRef = useRef(false)

  const getNewProfileName = () => {
    const profileNames = profiles?.map(p => p.name)
    let newProfileName = showcaseName!
    let counter = 0

    while (profileNames?.includes(newProfileName)) {
      counter++;
      newProfileName = showcaseName! + counter
    }
    return newProfileName
  }


  async function refreshStory() {
    // update story in store, so that the new profile is added to the list
    if (storyId) {
      const originallyLoading = loading;
      if (!originallyLoading) {
        setLoading(true);
      }
      const story = await videoCreator.findOneStory(storyId);

      if (story) {
        videoCreator.story = story;
      }

      if (!originallyLoading) {
        setLoading(false);
      }
    }
  }

  async function sharePost() {
    setMessage('');
    if (profileId === 'new') {
      setMessage('Please select a profile');
      return;
    }
    let new_post: PostObject = {};
    // setLoading(true);
    videoCreator.toastState = {
      state: 'publishing', message: "We're publishing your story"
    }
    let uploadId = null;
    try {
      if (imageRef && imageRef.current) {
        try {
          const imageUrl = await toPng(imageRef.current);
          const blobObject = await AssetRepository?.base64ToBlob(imageUrl);
          const filename = `${uuid()}.png`;
          const file = AssetRepository.convertBlobToFile(
            blobObject,
            filename,
          );
          const result = await videoCreator.datoClient?.uploads.createFromFileOrBlob({
            fileOrBlob: file,
            filename: filename,
          });
          uploadId = result!.id;
          let url = result!.url;
          new_post = {
            post: text,
            mediaUrls: [url],
            platforms: [platform],
            scheduleDate: new Date().toISOString(),
          };
        } catch (e) {
          console.error(e);
          // setLoading(false);
          videoCreator.toastState = {
            state: 'error', message: "Publish failed, please contact arbor"
          }
          onClose()
          setMessage('Error uploading image');
          return;
        }
      } else {
        new_post = {
          post: text,
          mediaUrls: [mediaUrl || ''],
          platforms: [platform],
          scheduleDate: new Date().toISOString(),
        };
        const currentStory = videoCreator.story;
        let thumbnailUrl =
          video?.thumbnail?.url ||
          currentStory?.thumbnail?.url ||
          video?.videoFilePrimary?.video?.thumbnailUrl
        // add thumbnail based on platform
        if (new_post && (thumbnailUrl || platform === 'youtube')) {
          switch (platform) {
            case 'facebook':
              new_post['faceBookOptions'] = {
                thumbNail: thumbnailUrl,
              };
              break;
            case 'instagram':
              new_post['instagramOptions'] = {
                coverUrl: thumbnailUrl,
                thumbNailOffset: 10000,
                reels: true,
                shareReelsFeed: true,
              };
              break;
            case 'linkedin':
              new_post['linkedInOptions'] = {
                thumbNail: thumbnailUrl,
              };
              break;
            case 'youtube':
              new_post['youTubeOptions'] = {
                thumbNail: thumbnailUrl,
                title: title,
                visibility: 'public',
              };
              break;
            case 'tiktok': // can only do millisecond offset
            case 'x': // can't do thunmbnails
            default:
              break;
          }
        }
      }
      const resp = await fetch(`${process.env.REACT_APP_API_URL}/api/sharing/post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          story_id: storyId,
          upload_id: uploadId,
          video_id: videoId,
          organization_id: showcaseId,
          sharable_content_id: sharableImageId,
          profile_id: profileId,
          post: new_post
        }),
      });

      const json = await resp.json();
      if (json.error || json.message) {
        setMessage(json.error || json.message);
        // setLoading(false);
        videoCreator.toastState = {
          state: 'error', message: "Publish failed, please contact arbor"
        }
        return;
      } else {
        videoCreator.pendingSharedContentIds.push(json.sharedContentId);
      }
    } catch (error) {
      videoCreator.toastState = {
        state: 'error', message: "Publish failed, please contact arbor"
      }
    } finally {
      onClose()
    }
  }

  async function createProfile() {
    if (showcaseName !== '') {
      const profileName = getNewProfileName()
      setLoading(true);
      const resp = await fetch(`${process.env.REACT_APP_API_URL}/api/sharing/create-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          showcase_id: showcaseId,
          profile_name: profileName,
        }),
      });

      const json = await resp.json();
      if (json.error) {
        setMessage(json.error);
        setLoading(false);
        onClose()
        return;
      } else {
        setMessage('');
        window.open(json.url, '_blank')
      }
      await refreshStory();
      setProfileId(json.profile_id);
      setLoading(false);
      onClose()
      videoCreator.showRefreshStoryForSocialProfile = true
    } else {
      setMessage('Please enter a profile name');
    }
  }

  async function linkProfile() {
    setMessage('');
    if (profileId === 'new') {
      setMessage('Please select a profile');
      return;
    }
    setLoading(true);
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/api/sharing/generateJWT`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        profile_id: profileId
      }),
    });

    const json = await resp.json();
    if (json.error) {
      console.error(json.error)
      setMessage(json.error);
      setLoading(false);
      return;
    } else {
      setMessage('');
      window.open(json.url, '_blank')
    }

    await refreshStory();

    setLoading(false);
    videoCreator.showRefreshStoryForSocialProfile = true
  }

  async function connectPost() {
    setMessage('');
    if (profileId === 'new') {
      setMessage('Please select a profile');
      return;
    }

    let postId = ''
    let split = [];

    if (!embedRaw || embedRaw === '') {
      setMessage('Please paste the embed code');
      return;
    }
    // extract the postId based on the platform
    // include checks for url encoded embeds
    switch (platform) {
      case 'facebook':
        split = embedRaw.split('fbid=')
        if (split.length > 1) {
          postId = split[1].split('&')[0];
        }
        if (postId === undefined || postId === '') {
          split = embedRaw.split('fbid%3D')
          if (split.length > 1) {
            postId = split[1].split('%')[0];
          }
        }

        if (postId === undefined || postId === '') {
          // %26id%3D
          split = embedRaw.split('%26id%3D')
          if (split.length > 1) {
            postId = split[1].split('%')[0];
          }
        }

        // if a video
        if (postId === undefined || postId === '') {
          split = embedRaw.split('videos/')
          if (split.length > 1) {
            postId = split[1].split('/')[0];
          }
          if (postId === undefined || postId === '') {
            split = embedRaw.split('videos%2F')
            if (split.length > 1) {
              postId = split[1].split('%')[0];
            }
          }
        }

        // if a post
        if (postId === undefined || postId === '') {
          split = embedRaw.split('story_fbid=')
          if (split.length > 1) {
            postId = split[1].split('&')[0];
          }
          if (postId === undefined || postId === '') {
            split = embedRaw.split('story_fbid%3D')
            if (split.length > 1) {
              postId = split[1].split('%')[0];
            }
          }
        }

        break;
      case 'instagram':
        split = embedRaw.split('instagram.com/p/')
        if (split.length > 1) {
          postId = split[1].split('/')[0];
        }
        if (postId === undefined || postId === '') {
          split = embedRaw.split('instagram.com/p%2F')
          if (split.length > 1) {
            postId = split[1].split('%')[0];
          }
        }
        // if a reel
        if (postId === undefined || postId === '') {
          split = embedRaw.split('instagram.com/reel/')
          if (split.length > 1) {
            postId = split[1].split('/')[0];
          }
          if (postId === undefined || postId === '') {
            split = embedRaw.split('instagram.com/reel%2F')
            if (split.length > 1) {
              postId = split[1].split('%')[0];
            }
          }
        }
        break;
      case 'x':
      case 'twitter':
        split = embedRaw.split('status/')
        if (split.length > 1) {
          postId = split[1].split('?')[0];
        }

        if (postId === undefined || postId === '') {
          split = embedRaw.split('status%2F')
          if (split.length > 1) {
            postId = split[1].split('%')[0];
          }
        }
        break;
      case 'linkedin':
        const iframeSrc = extractSrcFromIframe(embedRaw);
        split = iframeSrc ? iframeSrc.split('linkedin.com/embed/feed/update/urn:li:share:') : []
        if (split.length > 1) {
          postId = 'urn:li:share:' + split[1]
        } else if (iframeSrc) {
          split = iframeSrc.split('linkedin.com/embed/feed/update/urn:li:ugcPost:')
          if (split.length > 1) {
            postId = 'urn:li:ugcPost:' + split[1]
          }
        }

        // if a urn:li:share
        if (postId === undefined || postId === '') {
          split = embedRaw.split('linkedin.com/feed/update/urn:li:share:')
          if (split.length > 1) {
            postId = split[1].split('/')[0];
          }
        }

        // if a urn:li:share
        if (postId === undefined || postId === '') {
          split = embedRaw.split('linkedin.com/posts/urn:li:share:')
          if (split.length > 1) {
            postId = split[1].split('/')[0];
          }
          if (postId === undefined || postId === '') {
            split = embedRaw.split('linkedin.com/posts%2Furn%3Ali%3Ashare%3A')
            if (split.length > 1) {
              postId = split[1].split('%')[0];
            }
          }
        }

        // if a share
        if (postId === undefined || postId === '') {
          split = embedRaw.split('linkedin.com/posts/')
          if (split.length > 1) {
            postId = split[1].split('/')[0];
          }
          if (postId === undefined || postId === '') {
            split = embedRaw.split('linkedin.com/posts%2F')
            if (split.length > 1) {
              postId = split[1].split('%')[0];
            }
          }
        }

        break;
      case 'tiktok':
        split = embedRaw.split('tiktok.com/@')
        if (split.length > 1) {
          postId = split[1].split('/')[0];
        }
        if (postId === undefined || postId === '') {
          split = embedRaw.split('tiktok.com/%40')
          if (split.length > 1) {
            postId = split[1].split('%')[0];
          }
        }
        break;
      case 'youtube':
        split = embedRaw.split('youtube.com/watch?v=')
        if (split.length > 1) {
          postId = split[1].split('&')[0];
        }
        if (postId === undefined || postId === '') {
          split = embedRaw.split('youtube.com/watch%3Fv%3D')
          if (split.length > 1) {
            postId = split[1].split('%')[0];
          }
        }
        break;
      default:
        setMessage('Platform not supported');
        return;
    }

    if (postId.length === 0) {
      setMessage('Could not extract post id');
      return;
    }
    setLoading(true);
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/api/sharing/connectExternal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        story_id: storyId,
        video_id: videoId,
        organization_id: showcaseId,
        sharable_content_id: sharableImageId,
        profile_id: profileId,
        post_id: postId,
        platform: platform,
      }),
    });

    const json = await resp.json();
    if (json.error) {
      setMessage(json.error);
      setLoading(false);
      return;
    } else {
      setMessage('');
    }
    await refreshStory();
    setLoading(false);
    onClose();
  }

  const hasLinks = profiles?.some((profile) => {
    return profile.platforms?.some((p) => p.platform === platform)
  })

  useEffect(() => {
    if (mode !== 'link' || linkRef.current) return
    (async () => {
      if (!hasLinks) {
        await createProfile()
        setLoading(false)
      }
    })()

    return () => {
      linkRef.current = true
    }
  }, [])


  // const selectedProfile = profiles?.find((profile) => {
  //   return profile.id === profileId;
  // })

  // const selectedProfilePlatform = selectedProfile?.platforms?.find((profilePlatform) => {
  //   return profilePlatform.platform === platform
  // })

  const { pos } = props
  const isLeft = (pos?.right || 0) + (pos?.width || 0) + 5 > window.innerWidth

  const renderDropdown = (pos: DOMRect, action: () => Promise<void>) => {
    return <ProfileDropdownWrapper
      top={pos.top}
      isBottom={false}
      left={pos.right}
      isLeft={isLeft}
    >
      {
        profiles?.map(profile => {

          let profilePlatform = profile.platforms?.find((profilePlatform) => {
            return profilePlatform.platform === platform
          });

          if (!profilePlatform) return null

          return <DropdownItem key={profile.id}
            onClick={async () => {
              setProfileId(profile.id)
              await action()
              onClose()
            }}
          >
            <span>{mode === 'link' ? '@' : 'Share'} {profilePlatform?.displayName}</span>
            {props.Icon}
          </DropdownItem>
        })
      }

      {
        mode !== 'link' &&
        <DropdownItem onClick={async () => {
          await createProfile()
          onClose()
        }}>
          <span>New Profile</span>
          <NewProfileIcon />
        </DropdownItem>
      }
    </ProfileDropdownWrapper>
  }

  const renderItemLinkAccount = () => {
    if (mode === 'link' && hasLinks) return renderDropdown(pos!, linkProfile)
    return null
  }

  const renderShareAndProfile = () => {
    if (mode !== 'link') return renderDropdown(pos!, sharePost)
    return null
  }

  return (
    <>
      {loading && <SpinningLoading
        customStyle={{
          top: 0,
          position: 'fixed',
          alignItems: 'center',
        }}
        text="Loading..."
      />}
      {pos ?
        <>{renderItemLinkAccount()} {renderShareAndProfile()}</>
        :
        <OffPlatform>
          <small>
            To track your post and get analytics, paste your embed code.
          </small>
          <AutoResizeableMultilineInput
            placeholder='Paste your link here and hit enter'
            hasOutline={false}
            width="100%"
            getValue={(text) => { setEmbedRaw(text) }}
            handleOnEnter={connectPost}
          />
        </OffPlatform>
      }
    </>
  )
}

export default ShareProfileView;

const ProfileDropdownWrapper = styled(ShareModalDropdown)`
  top: ${({ top, isBottom }) => !isBottom && top + 'px'};
  bottom: ${({ isBottom }) => isBottom && 0};
  left: ${({ left }) => left + 'px'};
  width: 255px;

  ${({ isLeft, left }) => !isLeft && left && css`
    left: ${left + 5}px;
  `}

  ${({ isLeft, left }) => isLeft && left && css`
    left: ${left - (255 * 2) - 5}px;
  `}
`;

const DropdownItem = styled(ShareModalDropdownItem)`
  &:hover {
  color: #03041a;
  background-color: #17c964;
  }

  span {
    text-transform: capitalize;
  }
`;

const OffPlatform = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 5px;

  small {
    font-weight: 400;
    font-size: 10px;
    line-height: 12.1px;
    color: #BDBDBD;
  }
`
