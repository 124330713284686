import { SharedContent } from '../../types.ts/story';
import styled from 'styled-components';
import ShareIcon from '../../svgs/ShareIcon';
import { MouseEvent, useState } from 'react';
import { videoCreator } from '../../stores/VideoCreatorStore';

type Props = {
  allReferencingSharedContents?: SharedContent[];
  social: 'twitter' | 'facebook' | 'linkedin' | string;
  icon?: React.ReactNode;
  onClick: (pos: DOMRect, mode: "profile" | "link" | "share" | "connect") => void;
};

const ShareClipButton = ({
  allReferencingSharedContents,
  social,
  icon,
  onClick
}: Props) => {

  const showcases = videoCreator.story?._allReferencingShowcases;

  function hasProfile() {
    if (showcases && showcases.length > 0 && showcases[0]) {
      return showcases[0].profiles?.length
    } else {
      return false;
    }
  }

  // function linkedToPlatform() {
  //   if (showcases && showcases.length > 0) {
  //     return showcases[0].profiles?.some((profile) => {
  //       return profile.platforms?.some((platform) => platform.platform === social)
  //     })
  //   } else {
  //     return false;
  //   }
  // }

  function finalShareButton() {
    if (allReferencingSharedContents?.some((post: any) => post.platform === social)) {
      return (
        <DropdownItem
          onClick={(e) =>
            onClick(e.currentTarget.getBoundingClientRect(), 'share')
          }>
          <span>Already Shared on {social}</span>
          {icon}
        </DropdownItem>
      )
    } else if (!hasProfile()) {
      return (
        <DropdownItem
          onClick={(e) =>
            onClick(e.currentTarget.getBoundingClientRect(), 'profile')
          }>
          <span>Create Sharing Profile</span>
          <ShareIcon strokeColor="currentColor" width="20" height="20" />
        </DropdownItem>
      )
    }
    // else if (!linkedToPlatform()) {
    //   return (
    //     <DropdownItem
    //       onClick={(e) =>
    //         onClick(e.currentTarget.getBoundingClientRect(), 'link')
    //       }
    //     >
    //       <span>Link Account To {social}</span>
    //       {icon}
    //     </DropdownItem>
    //   )
    // } 

    else {
      return (
        <DropdownItem
          onClick={(e) =>
            onClick(e.currentTarget.getBoundingClientRect(), 'share')
          }>
          <span> {social}</span>
          {icon}
        </DropdownItem>
      )
    }
  }

  return finalShareButton()
};

export default ShareClipButton;

const DropdownItem = styled.button`
  color: #f3e9d7;
  font-size: 12px;
  line-height: 14.52px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 20px 16px 20px 16px;
  display: flex;
  align-items: center;

  span{
    text-transform: capitalize;
  }

  &:hover {
    color: #03041a;
    background-color: #17c964;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #484848;
    padding-bottom: 20px;
  }
`;