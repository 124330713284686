// @ts-nocheck
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import WaveformData from 'waveform-data';

interface WaveFormProps {
  id: string;
  url: string;
  type: string;
  trim_start: string;
  duration: string;
  active?: boolean;
  height: number;
  isInMusicProducer?: boolean;
  range?: number;
  customColor?: string;
  defaultCustomBrightness?: string;
  customResampleWaveform?: WaveformData | null;
}

const MAX_VALUE = 128;
export const MAX_CANVAS_WIDTH = 16384;

export const WaveForm: React.FC<WaveFormProps> = observer((props) => {
  const {
    isInMusicProducer = false,
    range = 128,
    customResampleWaveform,
  } = props;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const isOriginalVideo = props.url === videoCreator.originalVideoAudioUrl;
  const resampledWaveform: WaveformData | null | undefined =
    customResampleWaveform ??
    (isOriginalVideo
      ? videoCreator.resampledOriginalWaveForm
      : videoCreator.audioTracksData[props.url]?.resampledWaveform);

  const scaleY = (amplitude, height) => {
    const padding_top = props.type === 'video' ? 0 : 14;
    const maxHeight = height - padding_top;
    return maxHeight - (amplitude * maxHeight) / range + padding_top;
  };

  const drawWaveform = (
    waveform: WaveformData,
    startTime,
    endTime,
    factor = 1,
    step = 1,
  ) => {
    console.log('draw waveform', props.url, factor, step, waveform.scale);
    const ctx = canvasRef.current.getContext('2d');
    const width = canvasRef.current.clientWidth;
    const height = canvasRef.current.clientHeight;
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    ctx.clearRect(0, 0, width, height);

    let color = isOriginalVideo ? `#004493` : '#9f66d8';
    const channel = waveform.channel(0);
    const maxSamples = channel.max_array();
    let maxSample = isOriginalVideo
      ? videoCreator.maxOriginalWaveformSample
      : 0.8 * MAX_VALUE;
    const scaleValueY = props.type === 'video' ? MAX_VALUE / maxSample : 1;

    // ctx.beginPath();

    ctx.strokeStyle = props.customColor || color;
    ctx.fillStyle = props.customColor || color;

    if (props.defaultCustomBrightness) {
      ctx.filter = `brightness(${props.defaultCustomBrightness})`;
    }

    try {
      const startIndex = waveform.at_time(startTime);
      const endIndex = Math.min(waveform.at_time(endTime), waveform.length - 1);

      // console.log('startIndex', startIndex, endIndex, waveform, factor, startTime, endTime);

      for (let i = startIndex; i <= endIndex; i += step) {
        let vals = [maxSamples[i]];
        if (step >= 2 && i + 1 <= endIndex) {
          vals.push(maxSamples[i + 1]);
        }
        if (step === 3 && i + 2 <= endIndex) {
          vals.push(maxSamples[i + 2]);
        }

        const val = (vals[0] + (vals[1] || 0) + (vals[2] || 0)) / step; // avg of N samples

        ctx.rect(
          (i - startIndex + 1) * 2 * factor,
          scaleY(val * scaleValueY, height),
          0.5,
          scaleY(0, height),
        );
      }
    } catch (e) {
      console.error('draw wave form error', e);
    }

    ctx.closePath();
    ctx.stroke();
  };

  const generateWaveform = async () => {
    if (!resampledWaveform || !canvasRef.current) {
      console.log(
        'No resampledWaveform or canvasRef.current',
        resampledWaveform,
        canvasRef.current,
      );
      return;
    }

    const elementDuration = parseFloat(
      props.duration ||
        videoCreator.audioTracksData[props.url].originalTrackDuration,
    );
    const startTime = parseFloat(props.trim_start || '0');
    const endTime =
      parseFloat(props.trim_start || '0') + parseFloat(elementDuration);

    let factor = 0.5;
    let step = 1; // scale > 256 ? 2 : 1;

    if (!isInMusicProducer) {
      const timelineScale = videoCreator.timelineScale;
      const newFactor =
        resampledWaveform.sample_rate / timelineScale / resampledWaveform.scale;
      if (newFactor < 0.99) {
        factor = 0.5 / newFactor;
      }
    }

    drawWaveform(resampledWaveform, startTime, endTime, factor, step);
  };

  // const debounceGenerateWaveform = debounce(generateWaveform, 750);

  useEffect(() => {
    if (isVisible) {
      generateWaveform();
    }
  }, [
    props.active,
    props.trim_start,
    props.defaultCustomBrightness,
    resampledWaveform,
    isVisible,
  ]);

  useEffect(() => {
    const intersectObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          // calculate canvas X-asix coordinates that are visible in viewport
          // VISIBLE 1213.546875 168 1381.546875 0 1400 168 1381.546875
          // VISIBLE 1232 168 1400 0 1400 168 1645.78125
          return;
        }
        setIsVisible(false);
      },
      {
        root: null,
        threshold: 0.01, // Array.from({ length: 100 }, (_, i) => i * 0.01), // array from 0 to 1 with step 0.01 set offset 0.1 means trigger if atleast 10% of element in viewport
      },
    );

    intersectObserver.observe(canvasRef.current);
  }, []);

  return (
    <div style={{ position: 'relative', flex: '1', height: '100%' }}>
      <Wave
        isInMusicProducer={isInMusicProducer}
        id={props.id}
        ref={canvasRef}
      />
    </div>
  );
});

const Wave = styled.canvas<{ isInMusicProducer: boolean }>`
  height: 100%;
  width: 100%;
  ${(props) =>
    !props.isInMusicProducer &&
    css`
      position: absolute;
      top: 2px;
      left: 0;
    `}
`;
