import {
  TranscriptChange,
  TranscriptElement,
  TranscriptTextElement,
} from '../../types.ts/transcript';
import {
  getClosestNotRemovedTextIndexToRight,
  getClosestNotRemovedTextIndexToLeft,
  getClosestTextIndexToLeft,
  getClosestTextIndexToRight,
  getClosestElementIndexToLeftByFilter,
} from '../utils';
import ChangeHandler from './ChangeHandler';

class RemoveChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'remove' | 'cut' },
  ) {
    this.reapplyKaraokeBreaksForChange(transcriptionElementsMutable, change);
    this.applyHelper(transcriptionElementsMutable, change);
    this.fixCapitalizationAt(transcriptionElementsMutable, change.index);
  }

  private applyHelper(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'remove' | 'cut' },
  ) {
    // move break to new position as element may get removed together with break marker
    // easier to apply before element removal

    const elements = transcriptionElementsMutable;
    const firstWordIndex = getClosestNotRemovedTextIndexToRight(
      change.index,
      elements,
    );
    const firstWord = elements[firstWordIndex] as TranscriptTextElement;
    const lastWordIndex = getClosestNotRemovedTextIndexToLeft(
      change.index + change.count - 1,
      elements,
    );
    const lastWord = elements[lastWordIndex] as TranscriptTextElement;

    const startTs = firstWord?.ts ?? 0;
    const endTs = lastWord?.end_ts ?? startTs;

    if (change.version === 2 && firstWord && lastWord) {
      const prevWordIndex = getClosestTextIndexToLeft(
        change.index - 1,
        elements,
      );
      const nextWordIndex = getClosestTextIndexToRight(
        change.index + change.count,
        elements,
      );

      const startBufferDiff =
        firstWord.buffer_before_ts! - change.timeBufferBefore;
      // debugger;
      if (startBufferDiff > 0.00001) {
        if (prevWordIndex > -1) {
          this.increaseBufferAfter(elements, prevWordIndex, startBufferDiff);
        } else {
          firstWord.buffer_before_ts = change.timeBufferBefore;
        }
      } else if (startBufferDiff < -0.00001) {
        this.increaseBufferBefore(elements, firstWordIndex, -startBufferDiff);
      }

      const endBufferDiff = lastWord.buffer_after_ts! - change.timeBufferAfter;
      if (endBufferDiff > 0.00001) {
        if (nextWordIndex > -1) {
          this.increaseBufferBefore(elements, nextWordIndex, endBufferDiff);
        } else {
          lastWord.buffer_after_ts = change.timeBufferAfter;
        }
      } else if (endBufferDiff < -0.00001) {
        this.increaseBufferAfter(elements, lastWordIndex, -endBufferDiff);
      }
    }

    for (let i = change.index; i < change.index + change.count; i++) {
      if (i >= 0 && i < elements.length) {
        elements[i].state = change.type === 'remove' ? 'removed' : change.type;
        delete elements[i].karaoke_break;
        delete elements[i].karaoke_break_end_ts_diff;
        delete elements[i].karaoke_break_start_ts_diff;
        // TODO: ASK ROMAN WHY INITIAL INDEX IS IMPORTANT HERE
        // if (elements[i].initial_index >= 0) {
        //   elements[i].state = change.type === 'remove' ? 'removed' : change.type;
        // } else {
        //   elements.splice(i, 1);
        //   i--;
        //   change.count--;
        // }
      }
    }

    if (startTs - change.timeBufferBefore >= endTs + change.timeBufferAfter) {
      console.warn('Not deleted any text: Incorrect time range', change);
      return;
    }

    if (change.inPlace) {
      // do not shift everything after the cut
      return;
    }

    const timeChange =
      endTs - startTs + change.timeBufferAfter + change.timeBufferBefore;

    for (let j = change.index + change.count; j < elements.length; j++) {
      const element = elements[j];
      if (element.type === 'text') {
        element.ts -= timeChange;
        element.end_ts -= timeChange;
      }
    }
  }

  private increaseBufferAfter(
    transcriptionElementsMutable: TranscriptElement[],
    index: number,
    time: number,
  ) {
    if (time <= 0) {
      throw Error('Buffer time to increase should be positive');
    }

    const nextWord = transcriptionElementsMutable[
      getClosestTextIndexToRight(index + 1, transcriptionElementsMutable)
    ] as TranscriptTextElement | undefined;
    const currentWord = transcriptionElementsMutable[
      index
    ] as TranscriptTextElement;

    currentWord.buffer_after_ts = (currentWord.buffer_after_ts || 0) + time;
    if (nextWord) {
      nextWord.buffer_before_ts = (nextWord.buffer_before_ts || 0) - time;
      if (nextWord.buffer_before_ts < 0) {
        // todo make sure ts < end_ts still
        nextWord.ts -= nextWord.buffer_before_ts;
        nextWord.trim_start =
          (nextWord.trim_start || 0) - nextWord.buffer_before_ts;
        nextWord.buffer_before_ts = 0;
      }
    }
    // console.debug(currentWord.value + ' buffer after increased by ' + time);
    // console.debug('Current and next word:', currentWord, nextWord);
  }

  private increaseBufferBefore(
    transcriptionElementsMutable: TranscriptElement[],
    index: number,
    time: number,
  ) {
    if (time <= 0) {
      throw Error('Buffer time to increase should be positive');
    }
    const prevWord = transcriptionElementsMutable[
      getClosestTextIndexToLeft(index - 1, transcriptionElementsMutable)
    ] as TranscriptTextElement | undefined;
    const currentWord = transcriptionElementsMutable[
      index
    ] as TranscriptTextElement;

    currentWord.buffer_before_ts = (currentWord.buffer_before_ts || 0) + time;
    if (prevWord) {
      prevWord.buffer_after_ts = (prevWord.buffer_after_ts || 0) - time;
      if (prevWord.buffer_after_ts < 0) {
        // todo make sure ts < end_ts still
        prevWord.end_ts += prevWord.buffer_after_ts;
        prevWord.trim_end = (prevWord.trim_end || 0) - prevWord.buffer_after_ts;
        prevWord.buffer_after_ts = 0;
      }
    }
    // console.debug(currentWord.value + ' buffer before increased by ' + time);
    // console.debug('Current and prev word:', currentWord, prevWord);
  }

  private reapplyKaraokeBreaksForChange(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'remove' | 'cut' },
  ) {
    const elements = transcriptionElementsMutable;
    for (let i = 0; i < change.count; i++) {
      const element = elements[change.index + i];
      if (!element.karaoke_break) continue;
      const prevElementIndex = getClosestElementIndexToLeftByFilter(
        change.index - 1,
        elements,
        (el) =>
          Boolean(
            el.state !== 'cut' &&
              el.state !== 'removed' &&
              el.state !== 'muted' &&
              el.value,
          ),
      );
      const prevElement = elements[prevElementIndex];
      if (!prevElement) break;
      const prevTextElementIndex = getClosestElementIndexToLeftByFilter(
        prevElementIndex,
        elements,
        (el) =>
          Boolean(
            el.type === 'text' &&
              el.value &&
              el.state !== 'cut' &&
              el.state !== 'removed' &&
              el.state !== 'muted',
          ),
      );
      const prevKaraokeBreakIndex = getClosestElementIndexToLeftByFilter(
        prevElementIndex,
        elements,
        (el) => Boolean(el.karaoke_break),
      );
      if (
        elements[prevTextElementIndex] &&
        prevTextElementIndex > prevKaraokeBreakIndex
      ) {
        prevElement.karaoke_break = true;
      }
    }
  }
}

export default RemoveChangeHandler;
