import React from 'react'

const ArborLogo = () => {
  return (
    <svg width="107" height="32" viewBox="0 0 107 32" fill="none">
        <path 
            d="M16.596 31.5778C14.8109 31.5778 14.6458 30.6644 14.7244 29.3365H14.5593C13.3719 31.0789 10.6982 31.9923 7.85147 31.9923C3.00737 31.9923 0.380859 29.0909 0.380859 25.8594C0.380859 20.2255 8.19748 18.0686 14.3077 18.0686C14.3077 15.6661 12.8607 13.8393 10.0612 13.8393C6.70336 13.8393 6.4124 16.2418 3.69152 16.2418C2.66923 16.2418 1.81994 15.7045 1.81994 14.5838C1.81994 12.5958 5.97989 11.3524 9.76238 11.3524C15.33 11.3524 19.828 13.3404 19.828 18.6443V27.5558C19.828 29.6282 21.9513 29.0909 21.9513 30.2883C21.9513 31.2017 21.6131 31.5702 19.9146 31.5702H16.6039L16.596 31.5778ZM14.2998 20.3022C9.37705 20.3022 6.23153 23.2036 6.23153 25.8134C6.23153 27.717 7.84361 29.6666 10.0533 29.6666C12.8135 29.6666 14.2133 27.0952 14.2998 25.1072V20.3022Z" 
            fill="#F3E9D7"
        />
        <path 
            d="M26.1034 31.5778C25.0024 31.5778 24.5306 31.1633 24.5306 30.4572C24.5306 28.8837 26.528 30.1655 26.528 27.433V19.0588C26.528 14.2922 23.6813 16.0729 23.6813 14.2922C23.6813 13.0871 24.9553 12.8414 26.1427 12.2581C27.0313 11.8436 28.4782 11.3447 29.0759 11.3447C30.9475 11.3447 31.2384 14.8678 31.3721 16.1957H31.4586C32.4337 13.916 34.557 11.3447 37.1048 11.3447C38.8427 11.3447 39.9515 12.6265 39.9515 14.2461C39.9515 15.3668 39.4404 16.5718 37.9541 16.5718C37.3172 16.5718 36.2555 15.9041 35.4062 15.9041C33.6683 15.9041 32.0484 18.7671 32.0484 21.8757V27.4253C32.0484 30.1578 34.0458 28.876 34.0458 30.4495C34.0458 31.1557 33.5818 31.5702 32.473 31.5702H26.1034V31.5778Z" 
            fill="#F3E9D7"
        />
        <path 
            d="M42.4129 5.38067C42.4129 3.35428 40.2032 3.80715 40.2032 2.56368C40.2032 1.94195 40.7143 1.69633 41.2176 1.57352C41.8546 1.40465 45.2124 0 46.3998 0C47.7131 0 47.9254 0.867354 47.9254 2.73255V13.8777C49.6633 12.2581 51.7472 11.3524 54.2086 11.3524C60.0671 11.3524 63.2913 15.5356 63.2913 21.0544C63.2913 27.7246 58.3686 32 51.4877 32C45.8808 32 42.3972 29.4286 42.3972 23.8791V5.38067H42.4129ZM47.9333 22.6663C47.9333 27.9319 49.8835 29.5054 52.2269 29.5054C55.3253 29.5054 57.4485 27.1873 57.4485 22.129C57.4485 17.0708 55.4118 14.0082 52.6909 14.0082C50.7407 14.0082 47.9333 15.3361 47.9333 19.5654V22.674V22.6663Z" 
            fill="#F3E9D7"
        />
        <path 
            d="M76.8485 31.9923C70.4788 31.9923 65.3438 27.8014 65.3438 21.6685C65.3438 15.5356 70.4788 11.3447 76.8485 11.3447C83.2182 11.3447 88.3532 15.5279 88.3532 21.6685C88.3532 27.8091 83.2182 31.9923 76.8485 31.9923ZM76.8485 29.5054C80.0333 29.5054 82.4947 26.8496 82.4947 21.6685C82.4947 16.4874 80.0333 13.8316 76.8485 13.8316C73.6637 13.8316 71.2023 16.4874 71.2023 21.6685C71.2023 26.8496 73.6637 29.5054 76.8485 29.5054Z" 
            fill="#F3E9D7"
        />
        <path 
            d="M92.7727 31.5778C91.6718 31.5778 91.1999 31.1633 91.1999 30.4572C91.1999 28.8837 93.1973 30.1655 93.1973 27.433V19.0588C93.1973 14.2922 90.3506 16.0729 90.3506 14.2922C90.3506 13.0871 91.6246 12.8414 92.812 12.2581C93.7006 11.8436 95.1476 11.3447 95.7452 11.3447C97.6089 11.3447 97.9078 14.8678 98.0414 16.1957H98.1279C99.1031 13.916 101.226 11.3447 103.774 11.3447C105.512 11.3447 106.621 12.6265 106.621 14.2461C106.621 15.3668 106.11 16.5718 104.623 16.5718C103.986 16.5718 102.925 15.9041 102.076 15.9041C100.338 15.9041 98.7177 18.7671 98.7177 21.8757V27.4253C98.7177 30.1578 100.715 28.876 100.715 30.4495C100.715 31.1557 100.251 31.5702 99.1424 31.5702H92.7727V31.5778Z" 
            fill="#F3E9D7"
        />
    </svg>

  )
}

export default ArborLogo