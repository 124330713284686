import { AlbumStory, Showcase, Story } from '../types.ts/story';

export const getAiDescriptionResponse = (story: AlbumStory) => {
  return story?.aiResponse?.responses?.find(
    (response) => response.title === 'Description',
  );
};

export const updateStoryShowcase = (
  storyData: Story,
  album: Showcase,
): void => {
  storyData.primaryShowcase = album;
  const showcaseIdx = storyData._allReferencingShowcases?.findIndex(
    (s) => s.id === album.id,
  );
  if (showcaseIdx > -1) {
    storyData._allReferencingShowcases[showcaseIdx] = album;
  } else {
    storyData._allReferencingShowcases.push(album);
  }
};
