import * as Utils from '@datocms/rest-client-utils';
import { SimpleSchemaTypes, SchemaTypes } from '@datocms/cma-client-browser';
import ApiClient from './ApiClient';

export default class ApiItem {
  static readonly TYPE: 'item' = 'item';
  client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  /**
   * List all records
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/instances
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  async list(queryParams?: SimpleSchemaTypes.ItemInstancesHrefSchema) {
    const body = await this.rawList(queryParams);
    return Utils.deserializeResponseBody<SimpleSchemaTypes.ItemInstancesTargetSchema>(
      body,
    );
  }

  /**
   * List all records
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/instances
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawList(
    queryParams?: SchemaTypes.ItemInstancesHrefSchema,
  ): Promise<SchemaTypes.ItemInstancesTargetSchema> {
    return this.client.request<SchemaTypes.ItemInstancesTargetSchema>({
      method: 'GET',
      url: '/items',
      queryParams,
    });
  }

  /**
   * Create a new record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/create
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  async create(body: SimpleSchemaTypes.ItemCreateSchema) {
    const body_3 = await this.rawCreate(
      Utils.serializeRequestBody<SchemaTypes.ItemCreateSchema>(body, {
        type: 'item',
        attributes: '*',
        relationships: ['item_type', 'creator'],
      }),
    );
    return Utils.deserializeResponseBody<SimpleSchemaTypes.ItemCreateTargetSchema>(
      body_3,
    );
  }

  /**
   * Create a new record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/create
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawCreate(
    body: SchemaTypes.ItemCreateSchema,
  ): Promise<SchemaTypes.ItemCreateTargetSchema> {
    return this.client.request<SchemaTypes.ItemCreateTargetSchema>({
      method: 'POST',
      url: '/items',
      body,
    });
  }

  /**
   * Update a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/update
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  async update(
    itemId: string | SimpleSchemaTypes.ItemData,
    body: SimpleSchemaTypes.ItemUpdateSchema,
  ) {
    const body_2 = await this.rawUpdate(
      Utils.toId(itemId),
      Utils.serializeRequestBody<SchemaTypes.ItemUpdateSchema>(body, {
        id: Utils.toId(itemId),
        type: 'item',
        attributes: '*',
        relationships: ['item_type', 'creator'],
      }),
    );
    return Utils.deserializeResponseBody<SimpleSchemaTypes.ItemUpdateTargetSchema>(
      body_2,
    );
  }

  /**
   * Update a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/update
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawUpdate(
    itemId: string,
    body: SchemaTypes.ItemUpdateSchema,
  ): Promise<SchemaTypes.ItemUpdateTargetSchema> {
    return this.client.request<SchemaTypes.ItemUpdateTargetSchema>({
      method: 'PUT',
      url: `/items/${itemId}`,
      body,
    });
  }

  /**
   * Retrieve a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/self
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  async find(
    itemId: string | SimpleSchemaTypes.ItemData,
    queryParams?: SimpleSchemaTypes.ItemSelfHrefSchema,
  ) {
    const body = await this.rawFind(Utils.toId(itemId), queryParams);
    return Utils.deserializeResponseBody<SimpleSchemaTypes.ItemSelfTargetSchema>(
      body,
    );
  }

  /**
   * Retrieve a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/self
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawFind(
    itemId: string,
    queryParams?: SchemaTypes.ItemSelfHrefSchema,
  ): Promise<SchemaTypes.ItemSelfTargetSchema> {
    return this.client.request<SchemaTypes.ItemSelfTargetSchema>({
      method: 'GET',
      url: `/items/${itemId}`,
      queryParams,
    });
  }

  /**
   * Delete a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  destroy(itemId: string | SimpleSchemaTypes.ItemData) {
    return this.rawDestroy(Utils.toId(itemId)).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.ItemDestroyJobSchema>(
        body,
      ),
    );
  }

  /**
   * Delete a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/destroy
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawDestroy(itemId: string): Promise<SchemaTypes.ItemDestroyJobSchema> {
    return this.client.request<SchemaTypes.ItemDestroyJobSchema>({
      method: 'DELETE',
      url: `/items/${itemId}`,
    });
  }

  /**
   * Publish a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/publish
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  async publish(
    itemId: string | SimpleSchemaTypes.ItemData,
    body?: SimpleSchemaTypes.ItemPublishSchema,
    queryParams?: SimpleSchemaTypes.ItemPublishHrefSchema,
  ) {
    const body_2 = await this.rawPublish(
      Utils.toId(itemId),
      body
        ? Utils.serializeRequestBody<SchemaTypes.ItemPublishSchema>(body, {
            type: 'selective_publish_operation',
            attributes: ['content_in_locales', 'non_localized_content'],
            relationships: [],
          })
        : null,
      queryParams,
    );
    return Utils.deserializeResponseBody<SimpleSchemaTypes.ItemPublishTargetSchema>(
      body_2,
    );
  }

  /**
   * Publish a record
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/item/publish
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawPublish(
    itemId: string,
    body?: SchemaTypes.ItemPublishSchema,
    queryParams?: SchemaTypes.ItemPublishHrefSchema,
  ): Promise<SchemaTypes.ItemPublishTargetSchema> {
    return this.client.request<SchemaTypes.ItemPublishTargetSchema>({
      method: 'PUT',
      url: `/items/${itemId}/publish`,
      body,
      queryParams,
    });
  }
}
