type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const PlusIcon = ({
  width = '40',
  height = '40',
  viewBox = '0 0 40 40',
  strokeColor = '#F3E9D7',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path d="M20 8V32" stroke={strokeColor} />
      <path d="M8 20L32 20" stroke={strokeColor} />
    </svg>
  );
};

export default PlusIcon;
