type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};
const KaraokeIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '48'}
      height={props.height || '22'}
      viewBox={props.viewBox || '0 10 48 16'}
      fill="none"
    >
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.4157 8.61601C37.067 12.342 33.9361 18.7094 28.8118 17.9295L19.035 26.5515C18.3824 27.1275 17.4369 26.6864 16.8021 26.1529C15.8423 27.1846 15.6363 27.7424 15.909 28.0207C16.8474 28.9783 22.3122 28.3658 25.4169 29.3781C27.6188 30.0963 28.9929 31.563 28.0058 34.7189C27.8474 35.2293 27.0889 34.9826 27.2472 34.4735C29.4301 27.4676 17.3942 30.6881 15.3447 28.5966C14.7314 27.9708 14.8826 27.0448 16.2366 25.578C15.7115 24.9303 15.272 23.9606 15.8401 23.291L24.2892 13.3142C23.5247 8.08753 29.7632 4.89035 33.4157 8.61601ZM17.0476 25.2788C17.3263 25.5279 18.1384 26.268 18.5136 25.9374L19.6782 24.9106C18.766 24.3309 18.017 23.5666 17.4489 22.6345L16.4427 23.823C16.158 24.1584 16.7404 24.9604 17.0476 25.2788ZM23.4447 18.2148L22.4551 19.2246C22.0836 19.6038 22.6481 20.181 23.0196 19.8007L24.0092 18.7908C24.382 18.4117 23.8163 17.8357 23.4447 18.2148ZM26.4207 18.9634C24.9166 18.3946 23.8329 17.2876 23.2758 15.7529L17.9981 21.9869C18.5411 22.9687 19.3509 23.7963 20.3143 24.3505L26.4207 18.9634ZM23.8854 15.0333C24.3498 16.7261 25.468 17.8672 27.1269 18.3412L27.8617 17.6935C26.2981 17.1466 25.0572 15.8816 24.5201 14.2836L23.8854 15.0333ZM31.7306 16.6459C28.6783 15.8827 26.2943 13.45 25.5477 10.3354C23.9603 13.4378 26.1682 17.1757 29.6109 17.1757C30.3397 17.1745 31.0684 16.9983 31.7306 16.6459ZM26.1788 9.39959C26.6647 12.8191 29.2966 15.5048 32.6476 16.0007C35.801 13.1486 33.8063 7.82359 29.6109 7.82359C28.3355 7.82359 27.0803 8.36303 26.1788 9.39959Z" fill={props.strokeColor || '#F2D093'}/>
    </svg>
  );
};

export default KaraokeIcon;
