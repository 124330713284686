import * as Utils from '@datocms/rest-client-utils';
import ApiClient from './ApiClient';
import { SimpleSchemaTypes, SchemaTypes } from '@datocms/cma-client-browser';

export default class ApiUploadRequest {
  static readonly TYPE: 'upload_request' = 'upload_request';
  client: ApiClient;

  constructor(client: ApiClient) {
    this.client = client;
  }

  /**
   * Request a new upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload-request/create
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  create(body: SimpleSchemaTypes.UploadRequestCreateSchema) {
    return this.rawCreate(
      Utils.serializeRequestBody<SchemaTypes.UploadRequestCreateSchema>(body, {
        type: 'upload_request',
        attributes: ['filename'],
        relationships: [],
      }),
    ).then((body) =>
      Utils.deserializeResponseBody<SimpleSchemaTypes.UploadRequestCreateTargetSchema>(
        body,
      ),
    );
  }

  /**
   * Request a new upload
   *
   * Read more: https://www.datocms.com/docs/content-management-api/resources/upload-request/create
   *
   * @throws {ApiError}
   * @throws {TimeoutError}
   */
  rawCreate(
    body: SchemaTypes.UploadRequestCreateSchema,
  ): Promise<SchemaTypes.UploadRequestCreateTargetSchema> {
    return this.client.request<SchemaTypes.UploadRequestCreateTargetSchema>({
      method: 'POST',
      url: '/upload-requests',
      body,
    });
  }
}
