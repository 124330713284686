// YouTube Icon Svg
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  fillColor?: string;
};
const YouTubeIcon = (props: Props) => {
  const {
    width = '18',
    height = '20',
    viewBox = '0 0 18 20',
    strokeColor = '#F3E9D7',
    fillColor = 'black',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M10.0967 1.23107C10.9293 0.756906 11.9158 0.631502 12.8406 0.882246C13.7654 1.13299 14.5534 1.73951 15.0325 2.56933C15.5116 3.39915 15.6429 4.38485 15.3977 5.31113C15.1524 6.23742 14.5506 7.02903 13.7237 7.51307L12.9837 7.93907C13.7925 7.91108 14.5874 8.15447 15.2419 8.63049C15.8964 9.1065 16.3728 9.78779 16.5953 10.5659C16.8178 11.344 16.7737 12.1741 16.4698 12.9242C16.166 13.6743 15.62 14.3012 14.9187 14.7051L7.89867 18.7581C7.06589 19.239 6.07615 19.3695 5.14719 19.1207C4.21823 18.872 3.42614 18.2643 2.94517 17.4316C2.4642 16.5988 2.33375 15.6091 2.58252 14.6801C2.83128 13.7511 3.43889 12.959 4.27167 12.4781L5.01067 12.0501C4.20272 12.0765 3.4091 11.8322 2.75582 11.3561C2.10254 10.88 1.62705 10.1992 1.40485 9.42197C1.18264 8.64472 1.22647 7.81552 1.52936 7.06602C1.83225 6.31653 2.37684 5.6897 3.07667 5.28507L10.0967 1.23107Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.9897 9.48507L10.9902 9.48539C11.0781 9.54019 11.1506 9.61646 11.2009 9.70703C11.2512 9.7976 11.2776 9.89948 11.2776 10.0031C11.2776 10.1067 11.2512 10.2085 11.2009 10.2991C11.1506 10.3897 11.0781 10.466 10.9902 10.5207L10.9896 10.5211L8.1979 12.2669C7.79089 12.5211 7.2627 12.2284 7.2627 11.7481V8.25807C7.2627 7.77829 7.79102 7.48562 8.19749 7.73994L8.1977 7.74007L10.9897 9.48507Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </svg>
  );
};

export default YouTubeIcon;
