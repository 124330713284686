type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const QuoteStartIcon = (props: Props) => {
  const {
    width = '28',
    height = '24',
    viewBox = '0 0 28 24',
    fillColor = '#03041A',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M22.2733 23.5068C20.4867 23.5068 19.06 22.8268 17.9933 21.4668C16.9267 20.0801 16.3933 18.4134 16.3933 16.4668C16.3933 13.2134 17.1267 10.3068 18.5933 7.74678C20.0867 5.16011 22.2333 2.89345 25.0333 0.946777L26.6333 3.30678C25.14 4.45344 23.9267 5.66678 22.9933 6.94678C22.0867 8.22678 21.6333 9.34678 21.6333 10.3068C21.6333 11.2134 21.94 11.9068 22.5533 12.3868C23.1667 12.8668 23.8733 13.3068 24.6733 13.7068C25.5 14.1068 26.22 14.6268 26.8333 15.2668C27.4467 15.9068 27.7533 16.8401 27.7533 18.0668C27.7533 19.6134 27.2333 20.9068 26.1933 21.9468C25.1533 22.9868 23.8467 23.5068 22.2733 23.5068ZM6.75333 23.5068C4.99333 23.5068 3.58 22.8268 2.51333 21.4668C1.44666 20.0801 0.91333 18.4134 0.91333 16.4668C0.91333 13.2134 1.64666 10.3068 3.11333 7.74678C4.58 5.16011 6.71333 2.89345 9.51333 0.946777L11.1133 3.30678C9.62 4.45344 8.40666 5.66678 7.47333 6.94678C6.54 8.22678 6.07333 9.34678 6.07333 10.3068C6.07333 11.2134 6.38 11.9068 6.99333 12.3868C7.63333 12.8668 8.35333 13.3068 9.15333 13.7068C9.95333 14.1068 10.66 14.6268 11.2733 15.2668C11.9133 15.9068 12.2333 16.8401 12.2333 18.0668C12.2333 19.6134 11.7133 20.9068 10.6733 21.9468C9.63333 22.9868 8.32666 23.5068 6.75333 23.5068Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default QuoteStartIcon;
