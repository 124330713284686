import React from 'react'

type Props = {
    width?: string;
    height?: string;
    strokeColor?: string
    strokeWidth?: number;
}
const CircleExclammationIcon = (props: Props) => {
    const { width = "18", height = "18", strokeColor = "black", strokeWidth = 1.5 } = props
    return (
        <svg width={width} height={height} viewBox="0 0 17 18" fill="none">
            <path
                d="M9 6.75V9.5625M15.75 9C15.75 9.88642 15.5754 10.7642 15.2362 11.5831C14.897 12.4021 14.3998 13.1462 13.773 13.773C13.1462 14.3998 12.4021 14.897 11.5831 15.2362C10.7642 15.5754 9.88642 15.75 9 15.75C8.11358 15.75 7.23583 15.5754 6.41689 15.2362C5.59794 14.897 4.85382 14.3998 4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 7.20979 2.96116 5.4929 4.22703 4.22703C5.4929 2.96116 7.20979 2.25 9 2.25C10.7902 2.25 12.5071 2.96116 13.773 4.22703C15.0388 5.4929 15.75 7.20979 15.75 9ZM9 11.8125H9.006V11.8185H9V11.8125Z"
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    )
}

export default CircleExclammationIcon