import React from 'react';

type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
};
const PencilIcon = (props: Props) => {
  const { width = '17', height = '18', strokeColor = '#F3E9D7' } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 17 18" fill="none">
      <path
        d="M4.9971 15.9447L0.499023 17L1.55432 12.5026L12.6479 1.4077C12.7771 1.27845 12.9305 1.17591 13.0993 1.10596C13.2681 1.03601 13.4491 1 13.6319 1C13.8146 1 13.9956 1.03601 14.1644 1.10596C14.3332 1.17591 14.4866 1.27845 14.6158 1.4077L16.092 2.88318C16.2213 3.01238 16.3238 3.16578 16.3938 3.33461C16.4637 3.50345 16.4997 3.68442 16.4997 3.86717C16.4997 4.04993 16.4637 4.23089 16.3938 4.39973C16.3238 4.56857 16.2213 4.72197 16.092 4.85117L4.9971 15.9447Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6018 5.34427L12.1562 1.89941"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6091 6.3372L11.1719 2.8833"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.17198 15.7711L1.72852 12.3276"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;
