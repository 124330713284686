import styled from 'styled-components';
import { CopyIcon } from '../common/icons';
import { FormEvent, useState } from 'react';

type Props = {
  onSave: (contributor: Record<'name' | 'email', string>) => void;
};


const EmailModal: React.FC<Props> = (props: Props) => {
  const [contributor, setContributor] = useState<Record<'name' | 'email', string>>({ name: '', email: '' })
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSave(contributor);
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Heading>
        Enter your email
      </Heading>
      <FieldSet>
        <FieldName>Name</FieldName>
        <Input
          type="name"
          required
          placeholder='Please enter your name'
          value={contributor.name}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setContributor({ ...contributor, name: e.target.value })}
        />
      </FieldSet>
      <FieldSet>
        <FieldName>Email</FieldName>
        <Input
          type="email"
          required
          placeholder='Please enter your email'
          value={contributor.email}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setContributor({ ...contributor, email: e.target.value })}
        />
      </FieldSet>
      <SaveButton>
        Save
      </SaveButton>
    </Form>
  );
};

const Form = styled.form`
  max-width: 459px;
  min-width: 330px;  
  height: 391px;
  border-radius: 16px;
  padding: 48px 24px;
  box-sizing: border-box;
`;

const FieldSet = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;

  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  background: #03041a;
`;

const FieldName = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Heading = styled.div`
  color: #45d483;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
`;

const SaveButton = styled.button`
  width: 100%;
  outline: none;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #17c964;
  cursor: pointer;

  font-size: 14px;
  font-weight: 700;

  color: #17c964;

  &:hover {
    font-size: 15px;
  }
  background: #17c964;
  color: #03041a;
`;

export default EmailModal;
