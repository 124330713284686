import React from 'react';

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const LeftAlignIcon = (props: Props) => {
  const {
    width = '13',
    height = '14',
    viewBox = '0 0 13 14',
    fillColor = '#484848',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M6 2C6.55228 2 7 1.55228 7 1C7 0.447715 6.55228 0 6 0V2ZM0 2H6V0H0L0 2Z"
        fill={fillColor}
      />
      <path
        d="M10 6C10.5523 6 11 5.55228 11 5C11 4.44772 10.5523 4 10 4V6ZM0 6H10V4H0V6Z"
        fill={fillColor}
      />
      <path
        d="M4 10C4.55229 10 5 9.55228 5 9C5 8.44772 4.55229 8 4 8V10ZM0 10H4V8H0V10Z"
        fill={fillColor}
      />
      <path
        d="M12 14C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12V14ZM0 14H12V12H0V14Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default LeftAlignIcon;
