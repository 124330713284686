import styled from 'styled-components';

export const Select = styled.select`
  display: block;
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  color: #fff;
  background-color: #2b3035;
  border: none;
  border-radius: 5px;

  // Arrow
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 10'%3E%3Cpath d='m0.993 2.02 5.25 5.25c0.966 0.966 2.534 0.966 3.5-0l5.264-5.264' fill='none' stroke='%23fff' strokeWidth='2px'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 16px auto;

  &:focus {
    outline: 1px solid #2a85ff;
  }
`;


export const ImprovedSelect = styled(Select)`
margin: 0;
background-color: transparent;
border: 1px solid #484848;
border-radius: 8px;
padding: 0 10px;
&:focus {
  border: 1px solid #484848;
  outline: 0;
}
height: 32px;
width: 100%;
appearance: none;
box-shadow: 5px 8px 5px rgba(0, 0, 0, 0.8);
font-size: 14px;
line-height: 16.94px;
font-weight: 400;

background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13'%3E%3Cpath d='M2 4.33008L6 8.33008L10 4.33008' fill='none' stroke='%23F2D093' stroke-width='1px'/%3E%3C/svg%3E");
`;