import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import styled from 'styled-components';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import SpinningLoading from '../SpinningLoading';
import DocumentIcon from '../../svgs/DocumentIcon';
import type { VideoAction, VideoVersion } from '../../types.ts/story';
import { Pager } from '../common/Pager';
import {
  SameLastActionAggregation,
  aggregateVersionsOfTheSameLastAction,
} from './VideoVersionsAggregation';

const toFormattedAction = (lastAction?: VideoAction): string => {
  if (!lastAction?.type) {
    return 'Modified';
  }
  return {
    save: 'Saved',
    autosave: 'Autosaved',
    unsave: 'Unsaved',
    publish: 'Published',
    system_render_start: 'Modified',
    system_render_update: 'Modified',
    system_render_error: 'Modified',
    system_render_confirm: 'Published',
  }[lastAction.type];
};

const toPublishingInfo = (lastAction?: VideoAction): string => {
  if (!lastAction?.type) {
    return '';
  }
  const { type, resolution } = lastAction;
  return type === 'publish' && resolution ? ` in ${resolution}` : '';
};

const toEditor = (video: VideoVersion): string => {
  return (
    video.lastActionJson?.editor ||
    (video.editor as SimpleSchemaTypes.User).full_name ||
    'Arbor Platform'
  );
};

const toFormattedEditor = (video: VideoVersion): string => {
  let formattedEditor = toEditor(video);
  if (video.lastActionJson?.type === 'autosave') {
    formattedEditor = ` (${formattedEditor})`;
  } else {
    formattedEditor = ' by ' + formattedEditor;
  }
  return formattedEditor;
};

export const VideoVersionHistory: React.FC<{
  sidebarRef: React.RefObject<HTMLDivElement>;
}> = observer((props) => {
  function switchToVersion(versionId: string) {
    videoCreator.setCurrentVersionVideo(versionId);
  }
  const page = videoCreator.currentVideoVersionsPage;
  const totalPages = videoCreator.currentVideoVersionsTotalPages;
  let currentDateString: string;
  return (
    <Wrapper>
      {videoCreator.isVersionsHistoryLoading && (
        <SpinningLoading
          text="Updating..."
          customStyle={{ minHeight: '260px' }}
          Ico={
            <DocumentIcon width={'24'} height={'26'} strokeColor="#17c964" />
          }
        />
      )}
      <List>
        {!videoCreator.currentVideoVersions?.length && (
          <NoVersions>
            No saved versions for this clip yet. Save the first one.
          </NoVersions>
        )}
        {aggregateVersionsOfTheSameLastAction(
          videoCreator.currentVideoVersions,
        ).map((video) => {
          let showNewDay = false;
          const versionDate = new Date(video.meta.created_at);
          const versionDateString = versionDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
          // check if video created_at date is today
          const isToday =
            new Date().toDateString() === versionDate.toDateString();
          if (!currentDateString || currentDateString !== versionDateString) {
            currentDateString = versionDateString;
            showNewDay = true;
          }

          return (
            <>
              {showNewDay ? (
                <Weekday>
                  {isToday
                    ? 'Today'
                    : versionDate.toLocaleDateString('en-Us', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'short',
                      })}
                </Weekday>
              ) : null}

              <ListElementContainer
                key={video.id}
                onClick={() => switchToVersion(video.versionId)}
              >
                <ListElement
                  isCurrent={
                    videoCreator.currentVideo?.versionId === video.versionId
                  }
                >
                  <VersionDate>
                    {new Date(video.meta.created_at).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      },
                    )}
                  </VersionDate>
                  {videoCreator.currentVideo?.versionId === video.versionId && (
                    <Tag>Current Version</Tag>
                  )}
                  <EditingInfo>
                    {toFormattedAction(video.lastActionJson)}
                    {toFormattedEditor(video)}
                    {toPublishingInfo(video.lastActionJson)} at{' '}
                    {new Date(video.meta.created_at).toLocaleTimeString(
                      'en-US',
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                      },
                    )}
                  </EditingInfo>
                  {!!video.pastVersions?.length && (
                    <SameLastActionAggregation
                      allVersions={[video, ...video.pastVersions]}
                      formattedAction={toFormattedAction(video.lastActionJson)}
                      editor={toEditor(video)}
                      sidebarRef={props.sidebarRef}
                    />
                  )}
                </ListElement>
              </ListElementContainer>
            </>
          );
        })}
      </List>
      <Pager
        page={page}
        totalPages={totalPages}
        toPage={(page: number) =>
          videoCreator.loadCurrentVideoVersionsHistory(page)
        }
      />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
`;

const List = styled.div`
  width: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const Weekday = styled.div`
  margin-top: 16px;
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ListElementContainer = styled.div`
  padding: 8px 0;
  width: 100%;
  box-sizing: border-box;
  margin: 4px 0;
  cursor: pointer;
  border-bottom: 1px solid #404040;
`;

const ListElement = styled.div.attrs((props: { isCurrent: boolean }) => props)`
  padding: 12px 8px;
  position: relative;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 8px;
  ${(props) =>
    props.isCurrent &&
    `
    background: #484848;
    padding: 8px;
  `}

  &:hover {
    border: 1px solid #404040;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #d7d7e1;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.isCurrent &&
      `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      background: #4ad067;
    `}
  }
`;

const VersionDate = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  color: #4ad067;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
`;

const EditingInfo = styled.div`
  color: #777;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
`;

const NoVersions = styled.div`
  padding: 0px 40px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin-top: 16px;
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
