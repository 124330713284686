import React from 'react';
import styled from 'styled-components';

type PagerProps = {
  page: number;
  totalPages?: number;
  toPage: (page: number) => void;
};

export const Pager = (props: PagerProps) => {
  const { page, totalPages, toPage } = props;
  return (
    <PagerWrapper>
      <PageButton
        onClick={() => {
          if (page > 0) {
            toPage(page - 1);
          }
        }}
        disabled={page === 0}
      >
        {'←'}
      </PageButton>
      {page > 0 && (
        <PageButton
          onClick={() => {
            toPage(0);
          }}
          active={false}
        >
          {'1'}
        </PageButton>
      )}
      {page > 1 && <span>...</span>}
      <PageButton
        onClick={() => {
          toPage(page + 1);
        }}
        active={true}
      >
        {page + 1}
      </PageButton>
      <PageButton
        onClick={() => {
          toPage(page + 1);
        }}
        disabled={!!totalPages && page === totalPages - 1}
      >
        {'→'}
      </PageButton>
    </PagerWrapper>
  );
};

const PagerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const PageButton = styled.button.attrs(
  (props: { active?: boolean; disabled?: boolean }) => props,
)`
  background: ${(props) => (props.active ? '#4ad067' : '#2a2a2a')};
  border: none;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    background: ${(props) => (props.disabled ? '#2a2a2a' : '#4ad067')};
  }
`;
