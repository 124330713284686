import React from 'react';

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeWidth?: string;
  strokeColor?: string;
};
const MiddleAlignIcon = (props: Props) => {
  const {
    width = '15',
    height = '14',
    viewBox = '0 0 15 14',
    strokeWidth = '2',
    strokeColor = '#03041A',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M4.33331 1H10.3333"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M2.33331 5H12.3333"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5.33331 9H9.33331"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M1.33331 13H13.3333"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MiddleAlignIcon;
