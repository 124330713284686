import React from 'react';

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
  strokeColor?: string;
  strokeWidth?: string;
};
const CapitalizeIcon = (props: Props) => {
  const {
    width = '19',
    height = '12',
    viewBox = '0 0 19 12',
    fillColor = '#484848',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M11.0573 11.0187H8.75049L12.2654 0.836914H15.0396L18.5495 11.0187H16.2427L13.6923 3.16362H13.6127L11.0573 11.0187ZM10.9131 7.0166H16.362V8.697H10.9131V7.0166Z"
        fill={fillColor}
      />
      <path
        d="M3.3192 11.1627C2.83199 11.1627 2.3978 11.0782 2.01665 10.9091C1.63549 10.7368 1.33388 10.4832 1.11182 10.1485C0.893066 9.81041 0.783691 9.38949 0.783691 8.8857C0.783691 8.46146 0.86158 8.10516 1.01736 7.81681C1.17313 7.52845 1.38525 7.29645 1.65372 7.12078C1.92219 6.94512 2.22711 6.81254 2.56849 6.72306C2.91319 6.63357 3.27446 6.57059 3.6523 6.53414C4.09643 6.48773 4.45438 6.44465 4.72616 6.40487C4.99794 6.36179 5.19515 6.29881 5.31778 6.21595C5.44041 6.13309 5.50173 6.01046 5.50173 5.84806V5.81823C5.50173 5.50336 5.4023 5.25975 5.20344 5.0874C5.00789 4.91505 4.72948 4.82888 4.36821 4.82888C3.98705 4.82888 3.68379 4.9134 3.45841 5.08243C3.23303 5.24815 3.08388 5.45696 3.01096 5.70885L1.05216 5.54976C1.15159 5.08574 1.34714 4.6847 1.63881 4.34664C1.93047 4.00525 2.30666 3.74342 2.76736 3.56112C3.23137 3.37552 3.7683 3.28271 4.37815 3.28271C4.80239 3.28271 5.20841 3.33243 5.59619 3.43186C5.98729 3.53129 6.33364 3.68541 6.63525 3.89422C6.94018 4.10303 7.18047 4.37149 7.35613 4.69962C7.5318 5.02443 7.61963 5.41387 7.61963 5.86794V11.0185H5.61111V9.95956H5.55145C5.42881 10.1982 5.26475 10.4087 5.05926 10.591C4.85377 10.7699 4.60684 10.9108 4.31849 11.0135C4.03014 11.113 3.69704 11.1627 3.3192 11.1627ZM3.92574 9.70104C4.23729 9.70104 4.51238 9.63972 4.75102 9.51709C4.98966 9.39114 5.17692 9.22211 5.31281 9.00999C5.4487 8.79787 5.51665 8.55757 5.51665 8.28911V7.47874C5.45036 7.52182 5.35921 7.5616 5.24321 7.59806C5.13052 7.6312 5.00292 7.66269 4.8604 7.69252C4.71788 7.71903 4.57536 7.74389 4.43284 7.76709C4.29032 7.78698 4.16106 7.8052 4.04505 7.82178C3.79648 7.85824 3.57938 7.91624 3.39378 7.99578C3.20817 8.07533 3.06399 8.18305 2.96125 8.31894C2.8585 8.45151 2.80713 8.61723 2.80713 8.8161C2.80713 9.10445 2.91153 9.32486 3.12034 9.47732C3.33246 9.62647 3.60093 9.70104 3.92574 9.70104Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CapitalizeIcon;
