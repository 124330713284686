import { useEffect } from 'react';

export const useAnimatedTimeChange = (
  started: boolean,
  startTime: number,
  callback: (timestampMs: number) => void,
) => {
  useEffect(() => {
    let animationFrameId: number;
    let startTs: number;
    const animate = (timestamp: number) => {
      if (startTs === undefined) {
        startTs = timestamp;
      }
      const elapsed = timestamp - startTs;
      callback(elapsed);
      animationFrameId = requestAnimationFrame(animate);
    };
    if (started) {
      animationFrameId = requestAnimationFrame(animate);
    } else {
      callback(0);
    }
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [started, startTime]);
};
