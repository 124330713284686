import React from 'react'

type Props = {
    width?: string;
    height?: string;
    strokeColor?: string
}
const SendIcon = (props: Props) => {
    const { width = "17", height = "18", strokeColor = "currentColor" } = props
    return (
        <svg width={width} height={height} viewBox="0 0 17 18" fill="none">
            <path
                d="M7.26923 10.2308L0.5 7.15385L16.5 1L10.3462 17L7.26923 10.2308Z"
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M7.26953 10.2309L10.9618 6.53857"
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    )
}

export default SendIcon