import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import VerticalLine from '../../svgs/VerticalLine';
import UndoIcon from '../../svgs/UndoIcon';
import RedoIcon from '../../svgs/RedoIcon';
import AspectRatiosDropdown from './AspectRatiosDropdown';
import { timeFormat } from '../../utility/timeFormat';
import PlayForwardIcon from '../../svgs/PlayForwardIcon';
import ForwardToEndIcon from '../../svgs/ForwardToEndIcon';
import { FullScreenIconAlt } from '../../svgs/FullScreenIcon';
import { Tooltip } from 'react-tooltip';
import DebugModal from '../debug/DebugModal';
import { inDebugMode } from '../../utility/debug';
import { AspectRatio } from '../../types.ts/video';

interface Props {
  storyTellerName: null | string;
}
export const Stage: React.FC<Props> = observer((props) => {
  const [videoPlayerHeight, setVideoPlayerHeight] = React.useState<number>(100);
  const [playAreaHeight, setPlayAreaHeight] = React.useState<number>(80);

  const [isDebugModalOpen, setDebugModalOpen] = useState(false);
  const [debugContent, setDebugContent] = useState<any>(null);

  React.useEffect(() => {
    const timelineHeight = parseFloat(videoCreator.timelineHeight);
    let height = 100;
    let playArea = 80;
    if (timelineHeight > 50) {
      height = 100 - 1.8 * (timelineHeight - 50);
    }
    if (timelineHeight > 50) {
      playArea = 80 - (timelineHeight - 50) / 2;
    }
    setVideoPlayerHeight(height);
    setPlayAreaHeight(playArea);
  }, [videoCreator.timelineHeight]);

  const makeFullScreen = () => {
    videoCreator.isPlayerFullScreen = true;
    videoCreator.renderer?.setMode('player');
  };

  const exitFullScreen = () => {
    videoCreator.renderer?.setMode('interactive');
    videoCreator.isPlayerFullScreen = false;
    videoCreator.renderer?.setTool('text');
  };

  const openDebugModal = () => {
    const debugContent = videoCreator.getDebugContent();
    setDebugContent(debugContent);
    setDebugModalOpen(true);
  };

  const closeDebugModal = () => {
    setDebugContent(null);
    setDebugModalOpen(false);
  };

  const currTime =
    videoCreator.isPlayheadDragging && videoCreator.tempDragTime
      ? videoCreator.tempDragTime
      : videoCreator.time;

  return (
    <Main isFullScreen={videoCreator.isPlayerFullScreen}>
      <MainArea isFullScreen={videoCreator.isPlayerFullScreen}>
        {inDebugMode() && (
          <VideoStoreDebug>
            <VideoStoredDebugButton onClick={() => openDebugModal()}>
              DEBUG
            </VideoStoredDebugButton>
            {isDebugModalOpen && (
              <DebugModal
                onClose={closeDebugModal}
                content={debugContent}
                title="Debug Data"
              />
            )}
          </VideoStoreDebug>
        )}
        <MediaContainer isFullScreen={videoCreator.isPlayerFullScreen}>
          <PlayerContainer
            playAreaHeight={playAreaHeight}
            videoPlayerHeight={videoPlayerHeight}
            timelineHeight={parseFloat(videoCreator.timelineHeight)}
            isFullScreen={videoCreator.isPlayerFullScreen}
          >
            {!videoCreator.isPlayerFullScreen && (
              <TopItems
                aspectRatio={videoCreator.currentVideo?.aspectRatio}
                timelineHeight={parseFloat(videoCreator.timelineHeight)}
              >
                <TopContent>
                  <LoadingIndicator>
                    {videoCreator.isLoading && (
                      <LoadingText>Loading...</LoadingText>
                    )}
                    {videoCreator.isSaving && (
                      <LoadingText>Saving...</LoadingText>
                    )}
                  </LoadingIndicator>
                  <TopActions>
                    <TopLeftAction>
                      <AspectRatiosDropdown />
                    </TopLeftAction>
                  </TopActions>
                </TopContent>
              </TopItems>
            )}
            <VideoPlayer
              isFullScreen={videoCreator.isPlayerFullScreen}
              videoPlayerHeight={videoPlayerHeight}
              aspectRatio={videoCreator.currentVideo?.aspectRatio}
              ref={(element) => {
                if (element && element !== videoCreator.renderer?.element) {
                  videoCreator.initializeVideoPlayer(element);
                }
              }}
            />
            {!videoCreator.isPlayerFullScreen && (
              <BottomItems
                aspectRatio={videoCreator.currentVideo?.aspectRatio}
                timelineHeight={parseFloat(videoCreator.timelineHeight)}
              >
                <BottomItemsLeft>
                  <UndoRedo>
                    <IconButton
                      data-tooltip-id="undo-redo-tooltip"
                      data-tooltip-content={
                        videoCreator.canUndo
                          ? `Undo ${videoCreator.getUndoLabel() || ''}`
                          : 'Nothing to undo'
                      }
                      unsetHeight={true}
                      background={false}
                      disabled={!videoCreator.canUndo}
                      onClick={() => {
                        videoCreator.undo();
                      }}
                    >
                      <UndoIcon />
                    </IconButton>

                    <VerticalLine width="2" height="16" viewBox="0 0 2 16" />

                    <IconButton
                      data-tooltip-id="undo-redo-tooltip"
                      data-tooltip-content={
                        videoCreator.canRedo
                          ? `Redo ${videoCreator.getRedoLabel() || ''}`
                          : 'Nothing to redo'
                      }
                      unsetHeight={true}
                      background={false}
                      disabled={!videoCreator.canRedo}
                      onClick={() => {
                        videoCreator.redo();
                      }}
                    >
                      <RedoIcon />
                    </IconButton>
                    <Tooltip
                      id="undo-redo-tooltip"
                      style={{
                        width: '120px',
                        color: '#f3e9d7',
                        textAlign: 'center',
                        padding: '8px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        lineHeight: '120%',
                        zIndex: '10',
                      }}
                    />
                  </UndoRedo>
                </BottomItemsLeft>
                <BottomItemsMid>
                  <PlayBackward
                    onClick={() => {
                      if (!videoCreator.renderer?.ready) return;
                      const wasPlaying = videoCreator.isPlaying;
                      videoCreator.renderer?.setTime(0);
                      if (wasPlaying) {
                        videoCreator.renderer?.play();
                      }
                    }}
                  >
                    <ForwardToEndIcon width="30" height="18" />
                  </PlayBackward>

                  <PlayBackward
                    onClick={() => {
                      if (!videoCreator.renderer?.ready) return;
                      const wasPlaying = videoCreator.isPlaying;
                      videoCreator.renderer?.setTime(videoCreator.time - 15);
                      if (wasPlaying) {
                        videoCreator.renderer?.play();
                      }
                    }}
                  >
                    <PlayForwardIcon width="30" height="18" />
                  </PlayBackward>
                  <PlayButton
                    background={false}
                    onClick={() => {
                      if (!videoCreator.isPlaying) {
                        videoCreator.refreshSourceAndPlay();
                      } else {
                        videoCreator.renderer?.pause();
                      }
                    }}
                  >
                    {!videoCreator.isPlaying ? (
                      <svg
                        viewBox="0 0 24 24"
                        fill="#f2d093"
                        width="40"
                        height="40"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        width="40"
                        height="40"
                        fill="#f2d093"
                      >
                        <path d="M14,19H18V5H14M6,19H10V5H6V19Z" />
                      </svg>
                    )}
                  </PlayButton>
                  <PlayForward
                    onClick={() => {
                      if (!videoCreator.renderer?.ready) return;
                      const wasPlaying = videoCreator.isPlaying;
                      videoCreator.renderer?.setTime(videoCreator.time + 15);
                      if (wasPlaying) {
                        videoCreator.renderer?.play();
                      }
                    }}
                  >
                    <PlayForwardIcon width="30" height="18" />
                  </PlayForward>
                  <PlayForward
                    onClick={() => {
                      if (!videoCreator.renderer?.ready) return;
                      videoCreator.renderer?.setTime(videoCreator.duration);
                    }}
                  >
                    <ForwardToEndIcon width="30" height="18" />
                  </PlayForward>
                </BottomItemsMid>
                <BottomItemsRight>
                  <Time curHasHours={currTime > 3600}>
                    <span className="current">{timeFormat(currTime)}</span>
                    <VerticalLine />
                    <span className="end">
                      {timeFormat(
                        videoCreator.duration,
                        videoCreator.duration > 3600,
                      )}
                    </span>
                  </Time>
                  <FullscreenAction role="button" onClick={makeFullScreen}>
                    <FullScreenIconAlt />
                  </FullscreenAction>
                </BottomItemsRight>
              </BottomItems>
            )}
          </PlayerContainer>
        </MediaContainer>
        {videoCreator.isPlayerFullScreen && (
          <div>
            <ExitFullScreenButton onClick={exitFullScreen}>
              Exit Fullscreen
            </ExitFullScreenButton>
          </div>
        )}
      </MainArea>
    </Main>
  );
});

const Main = styled.div<{ isFullScreen: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MainArea = styled.div<{ isFullScreen: boolean }>`
  position: relative;

  display: flex;
  ${(props) =>
    props.isFullScreen &&
    css`
      height: 80%;
      width: 100%;
      margin: auto;

      @media (max-width: 2100px) {
        gap: 20px;
      }

      @media (max-width: 1600px) {
        gap: 15px;
      }

      @media (max-width: 1400px) {
        gap: 10px;
      }

      @media (max-width: 1200px) {
        gap: 5px;
      }
    `}

  ${(props) =>
    !props.isFullScreen &&
    css`
      flex: 1;
    `}
`;

const VideoStoreDebug = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const VideoStoredDebugButton = styled.button.attrs(
  (props: { isPressed: boolean; outlined: boolean }) => props,
)`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? '#F2D093' : 'transparent')};
  color: ${(props) => (props.isPressed ? '#030419' : '#484848')};
  border: ${(props) =>
    props.outlined
      ? `1px solid ${props.isPressed ? '#F2D093' : '#484848'}`
      : 'none'};

  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const TopItems = styled.div<{
  aspectRatio?: AspectRatio;
  timelineHeight: number;
}>`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;

  ${({ aspectRatio, timelineHeight }) =>
    (aspectRatio === AspectRatio.AR_1_1 ||
      aspectRatio === AspectRatio.AR_9_16) &&
    css`
      margin-top: ${timelineHeight > 40 ? '-20px' : 0};
    `}

  ${({ aspectRatio, timelineHeight }) =>
    (!aspectRatio || aspectRatio === AspectRatio.AR_16_9) &&
    css`
      margin-top: ${timelineHeight > 40
        ? '-20px'
        : timelineHeight < 20
          ? '20px'
          : 0};
    `}
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingIndicator = styled.div`
  margin-top: 10px;
  margin-right: 0;
  display: flex;
  align-items: center;
  .line {
    opacity: 0.3;
  }
  position: relative;
`;

const TopActions = styled.div`
  display: flex;
  flex-directon: row;
  justify-content: space-between;
  margin-top: 30px;
`;

const TopLeftAction = styled.div`
  margin-right: 0;
  display: flex;
  align-items: center;
`;

const PlayButton = styled.div<{
  disabled?: boolean;
  background?: boolean;
  margin?: number;
}>`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Time = styled.div<{ curHasHours: boolean }>`
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 0 6px;
  gap: 2px;
  .current {
    opacity: 0.8;
    color: #f2d093;
    display: flex;
    ${({ curHasHours }) => css`
      min-width: ${curHasHours ? '74px' : '56px'};
    `}
  }
  .end {
    opacity: 0.4;
    display: flex;
    justify-content: flex-end;
    min-width: 56px;
  }
`;

const IconButton = styled.div<{
  disabled?: boolean;
  background?: boolean;
  margin?: number;
  unsetHeight?: boolean;
}>`
  display: flex;
  margin-right: ${(props) => (props.unsetHeight ? '' : '5px')};
  margin-left: ${(props) => (props.unsetHeight ? '5px' : '')};
  width: 24px;
  height: ${(props) => (props.unsetHeight ? '' : '24px')};
  padding: ${(props) => (props.unsetHeight ? '0' : '2px')};
  background: ${(props) => (props.background === false ? '' : '#333')};
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    opacity: ${(props) => (props.disabled ? '40%' : 'auto')};
  }
`;

const LoadingText = styled.div`
  margin-right: 20px;
  color: #a3a5a5;
  position: absolute;
  top: 10px;
  right: 0;
`;

const MediaContainer = styled.div<{ isFullScreen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isFullScreen &&
    css`
      width: 80%;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
    `}

  ${(props) =>
    !props.isFullScreen &&
    css`
      width: 100%;
      height: 100%;
      margin: 0 auto;
    `}
`;

const PlayerContainer = styled.div<{
  playAreaHeight: number;
  videoPlayerHeight: number;
  timelineHeight: number;
  isFullScreen: boolean;
}>`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;

  ${(props) =>
    !props.isFullScreen &&
    css`
      display: grid;
      grid-template-rows: ${props.videoPlayerHeight < 100 ? '50px' : '10px'} ${props.playAreaHeight}% ${props.videoPlayerHeight <
        40
          ? '0 0 0px'
          : '10px 10% 40px'};

      row-gap: ${props.videoPlayerHeight < 60 && '5px'};
    `}

  flex-direction: column;
  margin-top: 0;
`;

const VideoPlayer = styled.div<{
  aspectRatio?: AspectRatio.AR_16_9 | AspectRatio.AR_1_1 | AspectRatio.AR_9_16;
  videoPlayerHeight: number;
  isFullScreen: boolean;
}>`
  padding-right: 0;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.isFullScreen &&
    css`
      margin: auto;
    `}

  ${(props) =>
    !props.isFullScreen &&
    css`
      width: 100%;
      height: 100%;
      grid-row: ${props.videoPlayerHeight < 0 ? 2 : 2 / 4};
      height: ${props.videoPlayerHeight}%;
    `}
`;

const BottomItems = styled.div<{
  aspectRatio?: AspectRatio.AR_16_9 | AspectRatio.AR_1_1 | AspectRatio.AR_9_16;
  timelineHeight: number;
}>`
  width: 80%;
  display: flex;
  align-items: center;
  z-index: 1;

  ${({ aspectRatio, timelineHeight }) =>
    (!aspectRatio || aspectRatio === AspectRatio.AR_16_9) &&
    css`
      margin: 0 auto ${timelineHeight > 60 ? '50px' : '80px'} auto;
    `}
  ${({ aspectRatio, timelineHeight }) =>
    (aspectRatio === AspectRatio.AR_1_1 ||
      aspectRatio === AspectRatio.AR_9_16) &&
    css`
      margin: 0 auto 30px auto;
    `}
`;

const BottomItemsLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  gap: 2px;
`;
const BottomItemsMid = styled.div`
  display: flex;
  justify-content: center;
  // flex-direction: column;
  gap: 16px;
  align-items: center;
  flex: 1;
  position: relative;
`;
const BottomItemsRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 8px;
`;

const UndoRedo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  * {
    margin: 0;
    padding: 0;
  }
`;

const PlayForward = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PlayBackward = styled.div`
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ExitFullScreenButton = styled.button`
  border-radius: 4px;
  border: 1px solid #f2d093;
  background: #f2d093;
  outline: none;
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #03041a;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;

  margin-top: 0;
  margin-right: 70px;
`;

const FullscreenAction = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
