import { useEffect } from 'react';
import { useQuerySubscription } from 'react-datocms/use-query-subscription';
import { videoCreator } from '../stores/VideoCreatorStore';
import { STORIES_ARTIFACT_AI_TITLE_QUERY } from '../utility/gql';
import { StoryArtifactsAiTitleUpdate } from '../types.ts/story';

export const useQueryStories = (
  ids: string[],
): {
  status: string;
  error: any;
  data: { allStories: StoryArtifactsAiTitleUpdate[] } | undefined;
} => {
  const { status, error, data } = useQuerySubscription({
    query: STORIES_ARTIFACT_AI_TITLE_QUERY,
    variables: { ids },
    environment: videoCreator.datoContext.environment,
    token: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!,
  });

  return { status, error, data };
};

const StoryArtifactsAiTitleSubscription = (props: {
  storyIds: string[];
  onDataReceived: (updates: StoryArtifactsAiTitleUpdate[]) => void;
  onError: (error: any) => void;
}) => {
  const { storyIds, onDataReceived, onError } = props;
  const { error, data } = useQueryStories(storyIds);

  useEffect(() => {
    if (error) {
      onError(error);
    } else if (data?.allStories?.length) {
      onDataReceived(data.allStories);
    }
  }, [data, error]);

  return <></>;
};

export default StoryArtifactsAiTitleSubscription;
