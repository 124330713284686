import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface TextInputProps {
  text: string;
  modifyElement: (text: string) => void;
}

export const TextInput: React.FC<TextInputProps> = (props) => {
  const { text, modifyElement } = props;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputText, setInputText] = useState<string>(text);
  useEffect(() => {
    setInputText(text);
  }, [text]);

  return (
    <TextArea
      value={inputText}
      ref={inputRef}
      placeholder="Enter Text"
      onChange={(e) => setInputText(e.target.value)}
      onBlur={async (e) => {
        modifyElement(e.target.value);
      }}
    />
  );
};

const TextArea = styled.textarea`
  font-family: Inter, sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #484848;
  text-align: left;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  width: 100%;
  resize: none;

  &:focus {
    outline: 1px solid #f3e9d7;
  }
`;
