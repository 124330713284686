import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const CutIcon = (props: Props) => {
  const {
    width = '16',
    height = '14',
    viewBox = '0 0 16 14',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M4.11111 3L1.88889 3C1.39797 3 1 3.27552 1 3.61538L1 10.3846C1 10.7245 1.39797 11 1.88889 11H4.11111C4.60204 11 5 10.7245 5 10.3846L5 3.61538C5 3.27552 4.60204 3 4.11111 3Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1111 3L11.8889 3C11.398 3 11 3.27552 11 3.61538L11 10.3846C11 10.7245 11.398 11 11.8889 11H14.1111C14.602 11 15 10.7245 15 10.3846V3.61538C15 3.27552 14.602 3 14.1111 3Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 1V13" stroke={strokeColor} strokeLinecap="round" />
    </svg>
  );
};

export default CutIcon;
