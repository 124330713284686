
import { useEffect } from 'react';
import { useQuerySharedContent } from '../content-components/useQuerySharedContent';

const QuerySubscriptionComponent = (props: { sharedContentIds: string[], onDataReceived: (sharedContnet: any) => void, onError: () => void }) => {
  const { sharedContentIds, onDataReceived, onError } = props;

  const { status, error, data } = useQuerySharedContent(sharedContentIds)
  // console.log('useQuerySharedContent status, error, data', status, error, data)

  useEffect(() => {
    if (error) {
      console.error(error)
      onError()
    } else if (data?.allSharedContents?.length) {
      // console.log('sharedContents', data.allSharedContents)
      onDataReceived(data.allSharedContents)
    }
  }, [data, error])

  return (<></>);

}

export default QuerySubscriptionComponent;