import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../Modal';
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const toPercent = (value: number) => Math.round(value * 100);

const getCurrentCropFromUrl = (
  url: string,
  originalDimension: { width: number; height: number },
): PercentCrop | undefined => {
  const match = url.match(/rect=(\d+),(\d+),(\d+),(\d+)/);
  if (!match) {
    return;
  }
  const { width: widthFull, height: heightFull } = originalDimension;
  const [, x, y, width, height] = match.map(Number);
  return {
    x: toPercent(x / widthFull),
    y: toPercent(y / heightFull),
    width: toPercent(width / widthFull),
    height: toPercent(height / heightFull),
    unit: '%',
  };
};

const CropModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  selectedImage: string;
  onSave: (croppedImage: string) => void;
}> = ({ isOpen, onClose, selectedImage, onSave }) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [originalImageDimensions, setOriginalImageDimensions] = useState<{ width: number; height: number } | null>(null);

  const originalImageUrl = selectedImage.split('?')[0];

  const handleSave = () => {
    if (!completedCrop || !originalImageDimensions) {
      return;
    }

    const { width, height, x, y } = completedCrop;
    const scaleX = originalImageDimensions.width / imgRef.current!.width;
    const scaleY = originalImageDimensions.height / imgRef.current!.height;

    const cropX = Math.floor(x * scaleX);
    const cropY = Math.floor(y * scaleY);
    const cropWidth = Math.floor(width * scaleX);
    const cropHeight = Math.floor(height * scaleY);

    const newImageURL = `${originalImageUrl}?rect=${cropX},${cropY},${cropWidth},${cropHeight}`;

    onSave(newImageURL);
    onClose();
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    const originalDimension = { width: image.naturalWidth, height: image.naturalHeight };
    setOriginalImageDimensions(originalDimension);
    setCrop(getCurrentCropFromUrl(selectedImage, originalDimension))
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <Title>Crop</Title>
        <SubTitle>Adjust the crop area to your liking</SubTitle>
        <CropContainer>
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img ref={imgRef} src={originalImageUrl} alt="Crop me" onLoad={(e) => onImageLoaded(e.currentTarget)} />
          </ReactCrop>
        </CropContainer>
        <Button onClick={handleSave}>Save Crop</Button>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
`;

const Title = styled.h3`
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  color: #45d483;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  width: 60%;
`;

const SubTitle = styled.h5`
  color: #f3e9d7;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

const CropContainer = styled.div`
  max-width: 100%;
  max-height: 70vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 70vh;
  }
`;

const Button = styled.button`
  background: #45d483;
  color: #03041a;
  font-weight: 600;
  border: 1px solid #45d483;
  border-radius: 30px;
  padding: 8px 12px;
  padding-left: 14px;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
`;

export default CropModal;
