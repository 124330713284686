import React from 'react';
import styled from 'styled-components';
import CopyIcon from '../../svgs/CopyIcon';
import { handleCopyToClipboard } from '../../utility/general';

type Props = {
  text?: string;
  iconWidth?: string;
  iconHeight?: string;
  iconColor?: string;
  iconViewBox?: string;
  action?: () => void;
};
const CopyToClipboard = (props: Props) => {
  const { text, iconColor, iconHeight, iconWidth, iconViewBox } = props;
  const handleClick = () => {
    if (props.action) {
      return props.action();
    }

    handleCopyToClipboard(text || '');
  };
  return (
    <Button className="copy" onClick={handleClick}>
      <CopyIcon
        width={iconWidth}
        height={iconHeight}
        strokeColor={iconColor}
        viewBox={iconViewBox}
      />
    </Button>
  );
};

export default CopyToClipboard;
const Button = styled.button`
  cursor: pointer;
  margin-left: auto;
  color: transparent;
  background-color: transparent;
  border: 0;
  outline: 0;
  &:active {
    svg {
      fill: white;
    }
  }
`;
