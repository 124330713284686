import React, { useState } from 'react';
import styled from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';
import Modal from '../common/Modal';
import { CopyIcon } from '../common/icons';

type Props = {
  closeModal: () => void;
  action: () => void;
};

export const SaveOrDiscardModal: React.FC<Props> = observer((props: Props) => {
  const { action, closeModal } = props;
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveData = async () => {
    if (isSaving) return;
    setIsSaving(true);
    videoCreator.currentVideo!.punchList =
      videoCreator.punchListManager.punchListData!;
    await videoCreator.saveStoryAndVideo();
    setIsSaving(false);
  };

  return (
    <Modal isOpen closeModal={closeModal}>
      <Wrapper>
        <Heading>Current version not saved</Heading>
        <Buttons>
          <CancelButton
            disabled={isSaving}
            onClick={() => {
              if (isSaving) return;
              action();
            }}
          >
            Discard
          </CancelButton>
          <SaveButton
            disabled={isSaving}
            onClick={async () => {
              if (isSaving) return;
              await handleSaveData();
              action();
            }}
          >
            <CopyIcon />
            {isSaving ? 'Saving' : 'Save'}
          </SaveButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
});

const Wrapper = styled.div`
  width: 459px;
  height: 200px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
`;

const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  height: 48px;
  gap: 8px;
`;

const CancelButton = styled.div<{ disabled: boolean }>`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #17c964;
  cursor: pointer;

  font-size: 14px;
  font-weight: 700;

  color: #17c964;

  ${(props) =>
    !props.disabled &&
    `&:hover {
      font-size: 15px;
    }
  }`}

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.7;
  `}
`;

const SaveButton = styled(CancelButton)`
  background: #17c964;
  color: #03041a;
`;
