type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const ThumbnailIcon = (props: Props) => {
  const {
    width = '25',
    height = '24',
    viewBox = '0 0 25 24',
    strokeColor = '#F3E9D7',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M7.7244 20.7551H21.255C22.1342 20.7551 22.8469 20.0424 22.8469 19.1633V8.81632C22.8469 7.93717 22.1342 7.22449 21.255 7.22449H7.7244C6.84525 7.22449 6.13257 7.93717 6.13257 8.81632V19.1633C6.13257 20.0424 6.84525 20.7551 7.7244 20.7551Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.15308 16.7755V4.83671C2.15308 4.41452 2.32079 4.00964 2.61931 3.71111C2.91784 3.41259 3.32273 3.24487 3.74491 3.24487H18.0714"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08789 20.6277L13.3438 14.0216C13.4918 13.859 13.6718 13.7288 13.8726 13.6392C14.0733 13.5496 14.2905 13.5026 14.5103 13.5011C14.7302 13.4996 14.9479 13.5437 15.1499 13.6305C15.3518 13.7174 15.5337 13.8451 15.6838 14.0057L21.9715 20.5959"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbnailIcon;
