type Props = {
  width?: string;
  height?: string;
  strokeColor?: string;
  viewBox?: string;
};
const TimelinePhotoIcon = (props: Props) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.width || '16'}
      viewBox={props.viewBox || '0 0 14 14'}
      fill="none"
    >
      <g clipPath="url(#clip0_2810_1135)">
        <path
          d="M1.89342 13.0353H12.1077C12.6206 13.0353 13.0363 12.6196 13.0363 12.1067V1.89244C13.0363 1.3796 12.6206 0.963867 12.1077 0.963867H1.89342C1.38058 0.963867 0.964844 1.3796 0.964844 1.89244V12.1067C0.964844 12.6196 1.38058 13.0353 1.89342 13.0353Z"
          stroke={props.strokeColor || '#F2D093'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.125 13.0352L8.90357 6.6466C8.98699 6.57712 9.09214 6.53906 9.20071 6.53906C9.30928 6.53906 9.41442 6.57712 9.49785 6.6466L13.0357 9.1816"
          stroke={props.strokeColor || '#F2D093'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.67997 6.07087C5.44922 6.07087 6.07282 5.44727 6.07282 4.67801C6.07282 3.90876 5.44922 3.28516 4.67997 3.28516C3.91071 3.28516 3.28711 3.90876 3.28711 4.67801C3.28711 5.44727 3.91071 6.07087 4.67997 6.07087Z"
          stroke={props.strokeColor || '#F2D093'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2810_1135">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimelinePhotoIcon;
