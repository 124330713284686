type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  fillColor?: string;
};
const ViewsIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    strokeColor = 'currentColor',
    fillColor = 'none',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillColor}>
      <path
        d="M22.9382 10.352C23.2194 10.7025 23.375 11.1578 23.375 11.63C23.375 12.1022 23.2194 12.5575 22.9382 12.908C21.1579 15.0635 17.1055 19.26 12.375 19.26C7.64447 19.26 3.59215 15.0635 1.81184 12.908C1.53065 12.5575 1.375 12.1022 1.375 11.63C1.375 11.1578 1.53065 10.7025 1.81184 10.352C3.59215 8.19649 7.64447 4 12.375 4C17.1055 4 21.1579 8.19649 22.9382 10.352Z"
        stroke={strokeColor}
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.3747 15.0214C14.2475 15.0214 15.7658 13.5032 15.7658 11.6303C15.7658 9.75749 14.2475 8.23926 12.3747 8.23926C10.5019 8.23926 8.98364 9.75749 8.98364 11.6303C8.98364 13.5032 10.5019 15.0214 12.3747 15.0214Z"
        stroke={strokeColor}
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ViewsIcon;
