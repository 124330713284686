import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const TrackDownIcon = (props: Props) => {
  const {
    width = '14',
    height = '14',
    viewBox = '0 0 14 14',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M7.00098 7.46422L7.00098 13.0356"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67871 10.7142L7.00014 13.0356L9.32157 10.7142"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.964844 1.89258L0.964844 4.21401C0.964844 4.72684 1.38058 5.14258 1.89342 5.14258L12.1077 5.14258C12.6206 5.14258 13.0363 4.72684 13.0363 4.21401L13.0363 1.89258C13.0363 1.37973 12.6206 0.964006 12.1077 0.964006L1.89342 0.964006C1.38058 0.964006 0.964844 1.37973 0.964844 1.89258Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrackDownIcon;
