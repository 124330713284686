import { Canvas } from 'datocms-react-ui';
import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import styled from 'styled-components';

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

export default function UpdateClipSource({ ctx }: PropTypes) {
  return (
    <Canvas ctx={ctx}>
      <Warning>
        If replacing a file and you already edited, it must be the same length
      </Warning>
    </Canvas>
  );
}

const Warning = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: var(--alert-color);
`;
