type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const ArrowUpIcon = (props: Props) => {
  const {
    width = '14',
    height = '8',
    viewBox = '0 0 14 8',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path d="M13 7L7 1L1 7" stroke={strokeColor} strokeLinecap="round" />
    </svg>
  );
};

export default ArrowUpIcon;
