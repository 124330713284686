type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const VerticalLine = (props: Props) => {
  return (
    <svg
      width={props.width || '2'}
      height={props.height || '10'}
      viewBox={props.viewBox || '0 0 2 10'}
      fill="none"
    >
      <path
        d={`M1 0L1 ${props.height || '10'}`}
        stroke={props.strokeColor || '#3D3D3D'}
      />
    </svg>
  );
};

export default VerticalLine;
