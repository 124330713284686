type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const ConstantContactIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    fillColor = 'currentColor',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8787 10.1857C15.0561 10.7179 15.5528 11.0372 16.1204 11.0372C16.2623 11.0372 16.4042 11.0017 16.5461 10.9662C17.2202 10.7179 17.575 9.97282 17.3267 9.29873C16.9009 8.12794 16.014 7.2055 14.7722 6.63785C14.1336 6.35402 13.3531 6.63785 13.0338 7.27646C12.75 7.91507 13.0338 8.69559 13.6724 9.0149C14.311 9.29873 14.7013 9.68899 14.8787 10.1857Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4529 2.02565C13.7433 1.88374 13.0692 2.34496 12.9273 3.05453C12.7854 3.76409 13.2466 4.43818 13.9562 4.5801C16.9009 5.14775 18.8877 7.13454 19.3844 10.0083C19.4908 10.6469 20.0585 11.0726 20.6616 11.0726C20.7326 11.0726 20.8035 11.0726 20.8745 11.0372C21.5841 10.8953 22.0453 10.2212 21.9388 9.54707C21.2648 5.64445 18.462 2.80618 14.4529 2.02565Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1534 15.0107C9.98265 15.0107 8.98925 14.0528 8.98925 12.8465C8.98925 11.6758 9.94717 10.6824 11.1534 10.6824C11.863 10.6824 12.4661 10.1147 12.4661 9.36967C12.4661 8.62462 11.8985 8.05697 11.1534 8.05697C8.52803 8.05697 6.39933 10.1857 6.39933 12.8111C6.39933 15.4365 8.52803 17.5652 11.1534 17.5652C13.7788 17.5652 15.9075 15.4365 15.9075 12.8111C15.9075 12.1015 15.3399 11.4984 14.5948 11.4984C13.8853 11.4984 13.2821 12.066 13.2821 12.8111C13.3176 14.0528 12.3242 15.0107 11.1534 15.0107Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9942 11.5694C18.2846 11.5694 17.6815 12.137 17.6815 12.8821C17.6815 16.5008 14.7367 19.4456 11.1179 19.4456C7.49915 19.4456 4.55444 16.5008 4.55444 12.8821C4.55444 9.26326 7.49915 6.31855 11.1179 6.31855C11.8275 6.31855 12.4306 5.7509 12.4306 5.00585C12.4306 4.29628 11.863 3.69315 11.1179 3.69315C6.11549 3.69315 2 7.80864 2 12.8466C2 17.8845 6.11549 22 11.1534 22C16.1914 22 20.3069 17.8845 20.3069 12.8466C20.3069 12.137 19.7392 11.5694 18.9942 11.5694Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ConstantContactIcon;
