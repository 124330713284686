import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';

export const withClerkProvider = (Component: React.ComponentType) => {
  return (props: any) => {
    return (
      // @ts-ignore
      <ClerkProvider
        signInUrl={process.env.REACT_APP_API_URL}
        publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
      >
        <Component {...props} />
      </ClerkProvider>
    );
  };
};
