import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useOutsideAlerter } from './useClickOutside';
import {
  convertMMSSToSeconds,
  convertTimeToMMSS,
} from '../../utility/timeFormat';
import Modal from '../common/Modal';

export const EditTimingModal: React.FC<{
  currentText: string;
  type: 'input' | 'textarea';
  discard: () => void;
  currentTimestamp?: number;
  changeTimestamp?: (ts: number) => void;
  minStart?: number; // Minimum start timestamp to avoid overlap
  maxEnd?: number; // Maximum end timestamp to avoid clip
}> = ({
  type,
  currentTimestamp,
  changeTimestamp,
  discard,
  minStart = 0,
  maxEnd = Infinity,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const timestampRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Unified validation function
  const validateTimestamp = (value: string | undefined): boolean => {
    if (value) {
      const timestamp = parseFloat(value);
      if (timestamp < minStart || timestamp > maxEnd) {
        setError(
          `Timestamp must be between ${minStart.toFixed(
            3,
          )} and ${maxEnd.toFixed(3)}`,
        );
        return false;
      }
    }
    setError(null);
    return true;
  };

  const handleSave = () => {
    if (changeTimestamp && timestampRef.current?.value) {
      if (validateTimestamp(timestampRef.current.value)) {
        changeTimestamp(parseFloat(timestampRef.current.value));
      }
    }
  };

  const handleTimestampChange = () => {
    if (timestampRef.current?.value) {
      validateTimestamp(timestampRef.current.value);
    }
  };

  return (
    <Modal isOpen={true} closeModal={discard}>
      <Wrapper type={type}>
        <Heading>Edit Timing</Heading>
        <FieldSet>
          <FieldName>Edit Transcription</FieldName>
          {currentTimestamp !== undefined && (
            <RangeInput
              type="number"
              step={0.001}
              ref={timestampRef}
              defaultValue={currentTimestamp.toFixed(3)}
              min={minStart.toFixed(3)}
              max={maxEnd.toFixed(3)}
              onChange={handleTimestampChange}
            />
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FieldSet>
        <Buttons>
          <CancelButton onClick={discard}>Cancel</CancelButton>
          <SubmitButton onClick={handleSave} disabled={!!error}>
            Save
          </SubmitButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div<{ type: 'input' | 'textarea' }>`
  width: 459px;
  height: ${(props) => (props.type === 'textarea' ? '315px' : '287px')};
  overflow: ${(props) => props.type === 'textarea' && 'auto'};

  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
`;

const FieldSet = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Ranges = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
  margin-top: 6px;
  justify-content: space-between;
`;

const RangeInput = styled.input`
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;

  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  background: #03041a;
`;

const TextInput = styled.input`
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;

  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  background: #03041a;
`;

const FieldName = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TextArea = styled.textarea`
  font-family: 'Inter', sans-serif;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 8px 6px;
  border-radius: 4px;
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  border: 1px solid #f3e9d7;
`;

const Buttons = styled.div`
  display: flex;
  height: 48px;
  gap: 8px;
  cursor: pointer;
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #17c964;

  font-size: 14px;
  font-weight: 700;

  color: #17c964;

  &:hover {
    font-size: 15px;
  }
`;

const SubmitButton = styled(CancelButton)<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? '#gray' : '#17c964')};
  color: ${(props) => (props.disabled ? '#808080' : '#03041a')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const ErrorMessage = styled.div`
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 8px;
`;
