import { useState } from 'react';
import styled from 'styled-components';
import DeleteIcon from '../../svgs/DeleteIcon';
import { AlbumQueryResult } from '../../types.ts/story';
import { ConfirmModal } from '../common/ConfirmModal';

const ActionDeleteStory = ({
  story,
  onDelete,
}: {
  story: AlbumQueryResult['stories'][0];
  onDelete: (s: AlbumQueryResult['stories'][0]) => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    onDelete(story);
    closeModal();
  };

  return (
    <>
      <IconWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        <DeleteIcon
          width="24px"
          height="24px"
          strokeColor={isHovered ? '#F2D093' : '#848484'}
        />
      </IconWrapper>
      {isModalOpen && (
        <ConfirmModal
          closeModal={closeModal}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          confirmText="Delete"
          cancelText="Cancel"
          headingText="Are you sure you want to delete this story?"
        />
      )}
    </>
  );
};

export default ActionDeleteStory;

const IconWrapper = styled.span`
  cursor: pointer;
`;
