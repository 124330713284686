type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const BackIcon = (props: Props) => {
  const {
    width = '19',
    height = '13',
    viewBox = '0 0 19 13',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M18 6H0"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 0L0 6L6 12"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackIcon;
