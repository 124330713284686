type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const FeelingLuckyIcon = (props: Props) => {
  const {
    width = '25',
    height = '24',
    viewBox = '0 0 25 24',
    strokeColor = '#484848',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M20.0685 10.9954L12.5952 12.2937L13.8935 4.82039C14.0345 4.23369 14.3934 3.72261 14.8974 3.39096C15.4015 3.0593 16.0129 2.93193 16.6075 3.03472C17.202 3.13751 17.7352 3.46275 18.0987 3.94439C18.4621 4.42602 18.6286 5.02794 18.5644 5.62789L18.4219 6.46706L19.261 6.32456C19.5769 6.24872 19.9049 6.23845 20.2248 6.2944C20.5447 6.35035 20.8498 6.47132 21.1211 6.64985C21.3924 6.82837 21.6242 7.06064 21.8022 7.33231C21.9802 7.60398 22.1006 7.90927 22.1559 8.2293C22.2113 8.54934 22.2004 8.87733 22.1239 9.19299C22.0475 9.50864 21.9071 9.80527 21.7114 10.0645C21.5158 10.3238 21.2691 10.5401 20.9865 10.7002C20.7039 10.8604 20.3915 10.9608 20.0685 10.9954Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2971 4.82039L12.5954 12.2937L5.12211 10.9954C4.79917 10.9608 4.48676 10.8604 4.20418 10.7002C3.9216 10.5401 3.67487 10.3238 3.47923 10.0645C3.28359 9.80527 3.1432 9.50864 3.06674 9.19299C2.99029 8.87733 2.97939 8.54934 3.03472 8.2293C3.09004 7.90927 3.21043 7.60398 3.38842 7.33231C3.56642 7.06064 3.79824 6.82837 4.06956 6.64985C4.34088 6.47132 4.64594 6.35035 4.96587 6.2944C5.2858 6.23845 5.61381 6.24872 5.92961 6.32456L6.76878 6.46706L6.62628 5.62789C6.56203 5.02794 6.72855 4.42602 7.09201 3.94439C7.45547 3.46275 7.98862 3.13751 8.58319 3.03472C9.17775 2.93193 9.78915 3.0593 10.2932 3.39096C10.7973 3.72261 11.1562 4.23369 11.2971 4.82039Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0685 13.5913L12.5952 12.293L13.8935 19.7663C14.0345 20.353 14.3934 20.8641 14.8974 21.1957C15.4015 21.5274 16.0129 21.6548 16.6075 21.552C17.202 21.4492 17.7352 21.1239 18.0987 20.6423C18.4621 20.1607 18.6286 19.5588 18.5644 18.9588L18.4219 18.1196L19.261 18.2621C19.5769 18.338 19.9049 18.3482 20.2248 18.2923C20.5447 18.2363 20.8498 18.1154 21.1211 17.9369C21.3924 17.7583 21.6242 17.5261 21.8022 17.2544C21.9802 16.9827 22.1006 16.6774 22.1559 16.3574C22.2113 16.0374 22.2004 15.7094 22.1239 15.3937C22.0475 15.0781 21.9071 14.7814 21.7114 14.5222C21.5158 14.2629 21.2691 14.0466 20.9865 13.8864C20.7039 13.7263 20.3915 13.6259 20.0685 13.5913Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2971 19.7663L12.5954 12.293L5.12211 13.5913C4.79917 13.6259 4.48676 13.7263 4.20418 13.8864C3.9216 14.0466 3.67487 14.2629 3.47923 14.5222C3.28359 14.7814 3.1432 15.0781 3.06674 15.3937C2.99029 15.7094 2.97939 16.0374 3.03472 16.3574C3.09004 16.6774 3.21043 16.9827 3.38842 17.2544C3.56642 17.5261 3.79824 17.7583 4.06956 17.9369C4.34088 18.1154 4.64594 18.2363 4.96587 18.2923C5.2858 18.3482 5.61381 18.338 5.92961 18.2621L6.76878 18.1196L6.62628 18.9588C6.55044 19.2746 6.54017 19.6026 6.59612 19.9225C6.65207 20.2425 6.77304 20.5475 6.95156 20.8189C7.13009 21.0902 7.36236 21.322 7.63403 21.5C7.9057 21.678 8.21099 21.7984 8.53102 21.8537C8.85106 21.909 9.17905 21.8981 9.49471 21.8217C9.81036 21.7452 10.107 21.6048 10.3662 21.4092C10.6255 21.2135 10.8419 20.9668 11.002 20.6842C11.1621 20.4017 11.2625 20.0892 11.2971 19.7663Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FeelingLuckyIcon;
