type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  strokeWidth?: string;
  d?: string;
};
const ArrowDown = (props: Props) => {
  const {
    width = '18',
    height = '11',
    viewBox = '0 0 18 11',
    strokeColor = '#fff',
    d = 'M1 1L9 9L17 1',
    strokeWidth = '2',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path d={d} stroke={strokeColor} strokeWidth={strokeWidth} />
    </svg>
  );
};

export default ArrowDown;
