import React from 'react';
import styled from 'styled-components';

import Modal from '../common/Modal';
import InfoIcon from '../../svgs/InfoIcon';

type Props = {
  enabled: boolean;
  variant: 'system-issues' | 'user-upload-failed';
  onClose?: () => void;
};

const StatusMessageModal: React.FC<Props> = (props: Props) => {
  const { enabled, variant } = props;

  const [isOpen, setIsOpen] = React.useState(true);

  if (!enabled || !isOpen) {
    return null;
  }

  const message = getMessageByVariant(variant);
  const handleClose = () => {
    setIsOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Modal isOpen closeModal={handleClose}>
      <Wrapper style={getStyleOverridesByVariant(variant)}>
        <Heading>
          <InfoIcon /> <span>{message}</span>
        </Heading>
      </Wrapper>
    </Modal>
  );
};

const getMessageByVariant = (variant: string) => {
  switch (variant) {
    case 'system-issues':
      return 'We’re experiencing system issues\nwe’re actively investigating Sorry for the interruption';
    case 'user-upload-failed':
      return 'Upload Failed\nPlease contact arbor';
    default:
      return 'Something went wrong. Please try again later.';
  }
};

const getStyleOverridesByVariant = (variant: string): React.CSSProperties => {
  switch (variant) {
    case 'user-upload-failed':
      return {
        width: '459px',
        padding: '48px 24px',
      };
    default:
      return {};
  }
};

export default StatusMessageModal;

const Wrapper = styled.div`
  width: 648px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 36px 48px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #ef5da8;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  white-space: pre-line;

  > svg {
    flex-shrink: 0;
  }
`;
