import styled from 'styled-components';
import CheckIcon from '../../svgs/CheckIcon';

export const AddSongActionOptions = ({
  fitSongToVideo,
  setFitSongToVideo,
  disabled,
}: {
  fitSongToVideo: boolean;
  setFitSongToVideo: (fitSongToVideo: boolean) => void;
  disabled?: boolean;
}) => {
  const color = fitSongToVideo ? '#17C964' : '#03041A';
  const borderColor = fitSongToVideo ? '#17C964' : '#484848';
  const toggleFitSongToVideo = () => {
    setFitSongToVideo(!fitSongToVideo);
  };

  return (
    <CheckboxContainer onClick={toggleFitSongToVideo} disabled={disabled}>
      <Check color={color} borderColor={borderColor}>
        {fitSongToVideo && (
          <CheckIcon
            width="9"
            height="9"
            fillColor="#03041A"
            strokeColor="#03041A"
            strokeWidth="0.75"
          />
        )}
      </Check>
      <CheckboxLabel>Fit song to end of video</CheckboxLabel>
    </CheckboxContainer>
  );
};

const Check = styled.div<{
  color: string;
  borderColor: string;
}>`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 2px;
`;

export const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  margin-top: 8px;
  display: flex;
  gap: 10px;
  user-select: none;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.6;
  `}
`;

export const CheckboxLabel = styled.div`
  text-transform: none;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

export default AddSongActionOptions;
