type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const DownloadIcon = (props: Props) => {
  const {
    width = '20',
    height = '20',
    viewBox = '0 0 20 20',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M18.3859 15.191V19.184H0.816406V15.191"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.60059 14.3924V1.61459"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1943 10.7986L9.60059 14.3924L6.00684 10.7986"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
