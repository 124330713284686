import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  strokeWidth?: string;
};
const UndoIcon = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#F2D093',
    strokeWidth = '2',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M1.10156 5.34712H10.6526C11.7784 5.34712 12.8581 5.79435 13.6542 6.59042C14.4503 7.38649 14.8975 8.4662 14.8975 9.59202C14.8975 10.7178 14.4503 11.7975 13.6542 12.5936C12.8581 13.3897 11.7784 13.8369 10.6526 13.8369H7.46891"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.28524 8.53125L1.10156 5.34758L4.28524 2.1639"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UndoIcon;
