import { Video, VideoClip } from '../types.ts/story';
import { groupBy } from './groupBy';
import { getAssociatedVideoWithStoryVideoField } from './videoClip';

export function timeFormat(inputTime: number, hideFrames = false): string {
  // Calculate the number of frames
  const framesPerSecond = 24; // Assuming 24 frames per second
  const totalFrames = Math.floor(inputTime * framesPerSecond);

  // Calculate hours, minutes, seconds, and frames
  const frames = totalFrames % framesPerSecond;
  const totalSeconds = Math.floor(totalFrames / framesPerSecond);
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  // Format the time with leading zeros
  const formattedTime: string = `${
    hours ? hours.toString().padStart(2, '0') + ':' : ''
  }${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}${
    hideFrames ? '' : `:${frames.toString().padStart(2, '0')}`
  }`;

  return formattedTime;
}

export function convertTimeToMMSS(time: number, milliseconds = false) {
  const minutes = Math.floor(time / 60);
  const remainingSeconds = Math.floor(time % 60);

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  if (milliseconds) {
    const remainingMilliseconds = (time % 1).toFixed(3).slice(2);
    return `${formattedMinutes}:${formattedSeconds}.${remainingMilliseconds}`;
  }

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function convertMMSSToSeconds(time: string) {
  // format is MM:SS.SSS
  const [minutes, seconds] = time.split(':');
  return parseInt(minutes) * 60 + parseFloat(seconds);
}

export const getSongDuration = (duration: string): string => {
  if (!duration) return '00';
  const [timeString] = duration.split(' ');
  const time = Math.round(Number(timeString));
  let hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

const getDurationFromSource = (source: Video['videoSource']) => {
  // in case end-marker was moved
  if (source.duration) {
    const duration = parseFloat(source.duration);
    return {
      formattedDuration: convertTimeToMMSS(duration),
      duration,
    };
  }

  if (!source?.elements) {
    return {
      formattedDuration: '--:--',
      duration: 0,
    };
  }

  const tracks = groupBy(
    source?.elements,
    (element: Record<string, any>) => element.track,
  );

  let durationsPerTrack: number[] = [];
  for (const [_track, trackElements] of tracks.entries()) {
    let elements = trackElements.filter(
      (e) => e.hasOwnProperty('time') && e.hasOwnProperty('duration'),
    );
    if (!elements.length) {
      continue;
    }

    elements.sort((a, b) => parseFloat(b.time) - parseFloat(a.time));

    const aTime = parseFloat(elements[0].time);
    const aDuration = parseFloat(elements[0].duration);
    durationsPerTrack.push(aTime + aDuration);
  }

  if (durationsPerTrack.length) {
    durationsPerTrack.sort((a, b) => b - a);
    const [duration] = durationsPerTrack;
    return {
      formattedDuration: convertTimeToMMSS(duration),
      duration,
    };
  }
};

export const getFormattedDurationForClip = (video: VideoClip) => {
  const videoWithElements = video as Video & {
    videoJson: Video['videoSource'];
  };
  const duration =
    video.videoFilePrimary?.video?.duration ||
    getAssociatedVideoWithStoryVideoField(video, 'duration')?.videoFilePrimary
      ?.video?.duration ||
    video.clipJson?.duration;
  if (duration)
    return {
      formattedDuration: convertTimeToMMSS(Math.round(duration)),
      duration: Math.round(duration),
    };

  const { videoJson, videoSource } = videoWithElements || {};
  const data = videoJson || videoSource;

  if (data) {
    const duration = getDurationFromSource(data);
    if (duration) return duration;
  }

  return {
    formattedDuration: '--:--',
    duration: null,
  };
};
