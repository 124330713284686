import React from 'react';

type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const RightAlignIcon = (props: Props) => {
  const {
    width = '14',
    height = '14',
    viewBox = '0 0 14 14',
    fillColor = '#484848',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M7.66663 0C7.11434 0 6.66663 0.447715 6.66663 1C6.66663 1.55228 7.11434 2 7.66663 2V0ZM7.66663 2H13.6666V0H7.66663V2Z"
        fill={fillColor}
      />
      <path
        d="M3.66663 4C3.11434 4 2.66663 4.44772 2.66663 5C2.66663 5.55228 3.11434 6 3.66663 6V4ZM3.66663 6H13.6666V4H3.66663V6Z"
        fill={fillColor}
      />
      <path
        d="M9.66663 8C9.11434 8 8.66663 8.44772 8.66663 9C8.66663 9.55228 9.11434 10 9.66663 10V8ZM9.66663 10H13.6666V8H9.66663V10Z"
        fill={fillColor}
      />
      <path
        d="M1.66663 12C1.11434 12 0.666626 12.4477 0.666626 13C0.666626 13.5523 1.11434 14 1.66663 14V12ZM1.66663 14H13.6666V12H1.66663V14Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default RightAlignIcon;
