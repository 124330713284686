import { useReducer } from 'react';
import styled from 'styled-components';

import ArrowDown from '../../svgs/ArrowDown';
import SavedTag from '../common/SavedTag';

type Props = {
  title: string;
  children: React.ReactNode;
  id?: string;
  defaultOpen?: boolean;
  isSaved?: boolean;
};

const Accordion = (props: Props) => {
  const { title, children, isSaved, id, defaultOpen = true } = props;
  const [isOpen, toggleAccordion] = useReducer((state) => !state, defaultOpen);

  return (
    <Container id={id}>
      <AccordionButton onClick={toggleAccordion} isOpen={isOpen}>
        <h2>{title}</h2>
        {isSaved !== undefined && <SavedTag isSaved={isSaved} />}
        <ArrowDown width="12" strokeColor="#f2d093" />
      </AccordionButton>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </Container>
  );
};

const Container = styled.div``;

const AccordionButton = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  margin: 0 -10px;
  border-radius: 4px;
  user-select: none;
  transition: 0.2s;
  gap: 16px;
  &:hover {
    background-color: #262630;
  }
  > h2 {
    color: #f3e9d7;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
  > svg {
    color: #f2d093;
    transition: 0.2s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
    margin-left: auto;
  }
`;

const AccordionContent = styled.div`
  margin-top: 24px;
`;

export default Accordion;
