import { TranscriptChange, TranscriptElement } from '../../types.ts/transcript';
import { getClosestNotRemovedElementIndexToRight } from '../utils';
import ChangeHandler from './ChangeHandler';

class KaraokeBreaksChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange,
  ) {
    switch (change.type) {
      case 'add_karaoke_break':
        this.applyAddKaraokeBreakChange(transcriptionElementsMutable, change);
        break;
      case 'remove_karaoke_break':
        this.applyRemoveKaraokeBreakChange(
          transcriptionElementsMutable,
          change,
        );
        break;
      case 'change_karaoke_end_break_time':
      case 'change_karaoke_start_break_time':
        this.applyChangeKaraokeBreakTime(transcriptionElementsMutable, change);
        break;
      case 'remove_all_karaoke_breaks':
        this.applyRemoveAllKaraokeBreaksChange(transcriptionElementsMutable);
        break;
    }
  }

  private applyAddKaraokeBreakChange(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'add_karaoke_break' },
  ) {
    if (Array.isArray(change.index)) {
      for (let i of change.index) {
        transcriptionElementsMutable[i].karaoke_break = true;
      }
      return;
    }
    transcriptionElementsMutable[change.index].karaoke_break = true;
  }

  private applyRemoveKaraokeBreakChange(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'remove_karaoke_break' },
  ) {
    const elementsCount = change.count || 1;
    for (let i = 0; i < elementsCount; i++) {
      delete transcriptionElementsMutable[change.index + i].karaoke_break;
      delete transcriptionElementsMutable[change.index + i]
        .karaoke_break_end_ts_diff;
      const nextElIdx = getClosestNotRemovedElementIndexToRight(
        change.index + i + 1,
        transcriptionElementsMutable,
      );
      if (nextElIdx > -1) {
        delete transcriptionElementsMutable[nextElIdx]
          .karaoke_break_start_ts_diff;
      }
    }
  }

  private applyChangeKaraokeBreakTime(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & {
      type: 'change_karaoke_start_break_time' | 'change_karaoke_end_break_time';
    },
  ) {
    if (change.type === 'change_karaoke_start_break_time') {
      transcriptionElementsMutable[change.index].karaoke_break_start_ts_diff =
        change.timeShift;
    } else {
      transcriptionElementsMutable[change.index].karaoke_break_end_ts_diff =
        change.timeShift;
    }
  }

  private applyRemoveAllKaraokeBreaksChange(
    transcriptionElementsMutable: TranscriptElement[],
  ) {
    for (let el of transcriptionElementsMutable) {
      delete el.karaoke_break;
      delete el.karaoke_break_start_ts_diff;
      delete el.karaoke_break_end_ts_diff;
    }
  }
}

export default KaraokeBreaksChangeHandler;
