import { TranscriptChange, TranscriptElement } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';

class InsertPunctuationChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'insert_punct' },
  ) {
    transcriptionElementsMutable.splice(change.index, 0, {
      type: 'punct',
      value: change.value,
      old_value: null,
      initial_index: -1,
      ts: null,
      end_ts: null,
      state: 'added',
      ...change.options,
    });

    if (change.value !== '\n') {
      this.fixCapitalizationAt(
        transcriptionElementsMutable,
        change.index + change.count,
      );
    }
  }
}

export default InsertPunctuationChangeHandler;
