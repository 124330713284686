type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  fillColor?: string;
};
const SharesIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    strokeColor = 'currentColor',
    fillColor = 'none',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillColor}>
      <path
        d="M6.85969 14.6071C8.50809 14.6071 9.84439 13.2708 9.84439 11.6224C9.84439 9.97399 8.50809 8.6377 6.85969 8.6377C5.21129 8.6377 3.875 9.97399 3.875 11.6224C3.875 13.2708 5.21129 14.6071 6.85969 14.6071Z"
        stroke={strokeColor}
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1351 20.2448C19.7834 20.2448 21.1198 18.9084 21.1198 17.2601C21.1198 15.6117 19.7834 14.2754 18.1351 14.2754C16.4867 14.2754 15.1504 15.6117 15.1504 17.2601C15.1504 18.9084 16.4867 20.2448 18.1351 20.2448Z"
        stroke={strokeColor}
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1351 8.96939C19.7834 8.96939 21.1198 7.63309 21.1198 5.98469C21.1198 4.33629 19.7834 3 18.1351 3C16.4867 3 15.1504 4.33629 15.1504 5.98469C15.1504 7.63309 16.4867 8.96939 18.1351 8.96939Z"
        stroke={strokeColor}
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.52612 10.2962L15.469 7.31152"
        stroke={strokeColor}
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.52612 12.9492L15.469 15.9339"
        stroke={strokeColor}
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SharesIcon;
