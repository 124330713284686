import { videoCreator } from '../stores/VideoCreatorStore';
import axios from 'axios';

const API_ORIGIN = process.env.REACT_APP_API_URL;

export async function uploadFileToS3(
  file: File,
  options?: {
    onUploadProgress?: (progress: number) => void;
  },
): Promise<string> {
  const response = await fetch(`${API_ORIGIN}/api/aws-presigned-url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      key: bucketPathKey(file.name),
    }),
  });
  const { signedPutUrl, signedGetUrl } = await response.json();

  await axios.put(signedPutUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent: any) => {
      if (options?.onUploadProgress && progressEvent?.total) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        options.onUploadProgress(progress);
      }
    },
  });

  return signedGetUrl;
}

export function renameFile(file: File, newBasename: string): File {
  const fileExtension = file.name.split('.').slice(-1)[0];
  const newName = `${newBasename}.${fileExtension}`;

  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export function bucketPathKey(fileKey: string): string {
  return `externalUploads/${
    videoCreator.organization?.id || 'no_org'
  }/${fileKey}`;
}
