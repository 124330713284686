import styled from 'styled-components';
import ShareIcon from '../../svgs/ShareIcon';
import MediumIcon from '../../svgs/MediumIcon';
import MailChimpIcon from '../../svgs/MailChimpIcon';
import ConstantContactIcon from '../../svgs/ConstantContactIcon';
import CopyIcon from '../../svgs/CopyIconAlt';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { useRef, useState } from 'react';

type Props = {
  type?: 'Blog' | 'Email';
  padding?: string;
  color?: string;
  bgColor?: string;
  onCopy?: () => void;
};
const ContentShare = (props: Props) => {
  const {
    padding = '10px 28px',
    color = '#17c964',
    bgColor = 'transparent',
    type = 'Blog',
    onCopy = () => {},
  } = props;

  const [openDropdown, toggleDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(dropdownRef, () => {
    toggleDropdown(false);
  });

  return (
    <Main ref={dropdownRef}>
      <Button
        padding={padding}
        color={color}
        bgColor={bgColor}
        onClick={() => {
          toggleDropdown(!openDropdown);
        }}
      >
        <ShareIcon strokeColor={color} /> <span>Share</span>
      </Button>

      {openDropdown && (
        <Dropdown>
          <Row
            onClick={() => {
              onCopy();
              toggleDropdown(false);
            }}
          >
            <span>Copy</span> <CopyIcon width="25" height="25" />
          </Row>
          <HorizontalLine />
          {type === 'Blog' && (
            <>
              <Row>
                <span>Medium (Coming Soon)</span> <MediumIcon />
              </Row>
              <HorizontalLine />
            </>
          )}
          <Row>
            <span>Mailchimp (Coming Soon)</span>{' '}
            <MailChimpIcon width="24" height="24" />
          </Row>
          <HorizontalLine />
          <Row>
            <span>Constant Contact (Coming Soon)</span>{' '}
            <ConstantContactIcon width="25" height="25" />
          </Row>
        </Dropdown>
      )}
    </Main>
  );
};

export default ContentShare;

const Main = styled.div`
  position: relative;
`;

const HorizontalLine = styled.span`
  width: 100%;
  height: 1px;
  background-color: #484848;
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  top: 50px;
  right: 0;
  width: 255px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #03041a;
  z-index: 4;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
`;

const Row = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #f3e9d7;

  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 16px;
  margin: 0;
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    color: #03041a;
    background-color: #17c964;
  }
`;

export const Button = styled.button<{
  padding: string;
  color: string;
  bgColor?: string;
}>`
  display: flex;
  padding: ${(props) => props.padding};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  width: 148px;
  height: 40px;
  outline: 0;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  color: #03041a;
  cursor: pointer;
  span {
    color: ${(props) => props.color};
  }
`;
