import styled, { css } from 'styled-components';
import Modal from '../Modal';
import {
  VideoClip,
  ShareableImagePreviewType,
  ShareableImageType,
} from '../../types.ts/story';
import LinkedInIcon from '../../svgs/LinkedInIcon';
import FacebookIcon from '../../svgs/FacebookIcon';
import XIcon from '../../svgs/XIcon';
import InstagramIcon from '../../svgs/InstagramIcon';
import TiktokIcon from '../../svgs/TiktokIcon';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import ClipPostItem from './ClipPostItem';
import YouTubeIcon from '../../svgs/YouTubeIcon';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import SocialProfileRefreshOverlay from '../common/SocialProfileRefreshOverlay';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { runInAction } from 'mobx';
import { AspectRatio } from '../../types.ts/video';

type Props = {
  onClose: () => void;
  clip?: VideoClip;
  shareable?: ShareableImagePreviewType;
  children: ReactNode;
  dimensions: string[];
  onSelectDimension: (dimension: string) => void;
  hideShareButton: boolean;
  imageRef?: React.RefObject<HTMLImageElement>;
  shareableImageToggle?: Dispatch<SetStateAction<ShareableImageType | null>>;
};

enum TabItems {
  posts = 'Social Posts',
  templates = 'Captions (Coming Soon)',
}

const ShareModal = observer((props: Props) => {
  const { clip, dimensions, shareable } = props;
  const [selectedTab, setSelectedTab] = useState<TabItems>(TabItems.posts);
  const [expanded, setExpanded] = useState('LinkedIn');
  const [defaultDimension, setDefaultDimension] = useState<string>(
    AspectRatio.AR_1_1,
  );
  const { enableCaptionTemplatesTab } = useFlagsCombination();

  const onExpand = (name: string) => {
    setExpanded(name);
    setDefaultDimension(
      ['LinkedIn', 'Facebook'].includes(name)
        ? AspectRatio.AR_1_1
        : AspectRatio.AR_16_9,
    );
  };

  useEffect(() => {
    if (dimensions.includes(defaultDimension)) {
      props.onSelectDimension(defaultDimension);
    } else if (dimensions.length > 0) {
      props.onSelectDimension(dimensions[0]);
    }
  }, []);

  return (
    <Modal
      isOpen
      allowPropagation
      onClose={() => {
        if (videoCreator.showRefreshStoryForSocialProfile) {
          runInAction(
            () => (videoCreator.showRefreshStoryForSocialProfile = false),
          );
        } else {
          props.onClose();
        }
      }}
      paddingVertical="0"
      customWidth="min(80%, 1250px)"
      alwaysShowCancel={videoCreator.showRefreshStoryForSocialProfile}
    >
      <Main>
        {videoCreator.showRefreshStoryForSocialProfile && (
          <SocialProfileRefreshOverlay />
        )}

        <LeftPane>{props.children}</LeftPane>
        <RightPane>
          <Tabs>
            {Object.values(TabItems).map((t) => (
              <Tab
                isSelected={selectedTab === t}
                onClick={() => {
                  if (t === TabItems.templates && !enableCaptionTemplatesTab)
                    return;
                  setSelectedTab(t);
                }}
              >
                {t}
              </Tab>
            ))}
          </Tabs>

          <PostContent>
            {selectedTab === TabItems.posts && (
              <div>
                <ClipPostItem
                  Icon={<LinkedInIcon fillColor="currentColor" />}
                  dimensions={dimensions} // AspectRatios
                  defaultDimension={defaultDimension}
                  name="LinkedIn"
                  clip={clip}
                  shareable={shareable}
                  expandFirst={true}
                  lowerLimit={70}
                  upperLimit={100}
                  onSelectDimension={props.onSelectDimension}
                  expanded={expanded}
                  onExpand={onExpand}
                  hideShareButton={props.hideShareButton}
                  imageRef={props.imageRef}
                  shareableImageToggle={props.shareableImageToggle}
                />
                <ClipPostItem
                  Icon={<FacebookIcon />}
                  dimensions={dimensions}
                  defaultDimension={defaultDimension}
                  name="Facebook"
                  clip={clip}
                  shareable={shareable}
                  lowerLimit={40}
                  upperLimit={80}
                  onSelectDimension={props.onSelectDimension}
                  expanded={expanded}
                  onExpand={onExpand}
                  hideShareButton={props.hideShareButton}
                  imageRef={props.imageRef}
                  shareableImageToggle={props.shareableImageToggle}
                />
                <ClipPostItem
                  Icon={<InstagramIcon strokeColor="#F3E9D7" />}
                  dimensions={dimensions}
                  defaultDimension={AspectRatio.AR_1_1}
                  name="Instagram"
                  clip={clip}
                  shareable={shareable}
                  lowerLimit={40}
                  upperLimit={80}
                  onSelectDimension={props.onSelectDimension}
                  expanded={expanded}
                  onExpand={onExpand}
                  hideShareButton={props.hideShareButton}
                  imageRef={props.imageRef}
                  shareableImageToggle={props.shareableImageToggle}
                />
                {clip && (
                  <ClipPostItem
                    Icon={<YouTubeIcon />}
                    dimensions={dimensions}
                    defaultDimension={AspectRatio.AR_16_9}
                    name="YouTube"
                    clip={clip}
                    shareable={shareable}
                    lowerLimit={100}
                    upperLimit={140}
                    onSelectDimension={props.onSelectDimension}
                    expanded={expanded}
                    onExpand={onExpand}
                    hideShareButton={props.hideShareButton}
                    imageRef={props.imageRef}
                    shareableImageToggle={props.shareableImageToggle}
                  />
                )}
                <ClipPostItem
                  Icon={<XIcon />}
                  dimensions={dimensions}
                  defaultDimension={defaultDimension}
                  name="Twitter"
                  clip={clip}
                  shareable={shareable}
                  showBorderBottom={true}
                  lowerLimit={100}
                  upperLimit={140}
                  onSelectDimension={props.onSelectDimension}
                  expanded={expanded}
                  onExpand={onExpand}
                  hideShareButton={props.hideShareButton}
                  imageRef={props.imageRef}
                  shareableImageToggle={props.shareableImageToggle}
                />
                {clip && (
                  <ClipPostItem
                    Icon={<TiktokIcon fillColor="#F3E9D7" />}
                    dimensions={dimensions}
                    defaultDimension={AspectRatio.AR_9_16}
                    name="TikTok"
                    clip={clip}
                    shareable={shareable}
                    showBorderBottom={true}
                    lowerLimit={100}
                    upperLimit={140}
                    onSelectDimension={props.onSelectDimension}
                    expanded={expanded}
                    onExpand={onExpand}
                    hideShareButton={props.hideShareButton}
                    imageRef={props.imageRef}
                    shareableImageToggle={props.shareableImageToggle}
                  />
                )}
              </div>
            )}
          </PostContent>
        </RightPane>
      </Main>
    </Modal>
  );
});

export default ShareModal;

const Main = styled.div`
  color: #f3e9d7;
  padding: 0 0 0 20px;
  text-align: left;
  display: grid;
  grid-template-columns: 56% 44%;
  grid-gap: 20px;
`;

const LeftPane = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
`;

const RightPane = styled.div`
  border-left: 1px solid #484848;
  padding: 20px;
  padding-right: 0;
  margin-left: 20px;
`;

const PostContent = styled.div`
  padding-right: 20px;
  overflow-y: scroll;
  overscroll-behavior: contain;
  max-height: 565px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  h2,
  h5 {
    margin: 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    color: #a9a9a9;
  }
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
`;

const VideoPlayer = styled.div`
  //   width: min(100%, 640px);
  width: 100%;
  height: 361px;
  border: 1px solid #484848;
  padding-right: 0;
  border-radius: 20px;
  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-left: 0;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 25px;
`;

const Button = styled.button<{
  borderColor?: string;
  color?: string;
  backgroundColor?: string;
  width?: string;
}>`
  display: flex;
  flex: 1;
  width: 100%;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #484848;
  cursor: pointer;
  background-color: #030419;
  color: #f3e9d7;
`;

const Tabs = styled.nav`
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 10px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  ${(props) =>
    props.isSelected &&
    css`
      border-bottom: 1px solid #f2d093;
      color: #f2d093;
      font-weight: 700;
    `}
`;
