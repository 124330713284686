import React, { Fragment, useEffect, useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { PropertyCaption } from './PropertyCaption';
import PropertyDropdown from '../common/PropertyDropdown';
import SliderSelect from '../common/PlainSlider';
import styled from 'styled-components';
import lodash from 'lodash';

interface ElementAnimationSettingsProps {
  activeElement: ElementState;
}

export const ElementAnimationSettings: React.FC<
  ElementAnimationSettingsProps
> = (props) => {
  const { animations, duration } = props.activeElement.source;

  const [prevDuration, setPrevDuration] = useState(duration);

  const elementAnimation =
    animations?.find((keyframe: any) => !!keyframe.arbor_subType)
      ?.arbor_subType ?? 'none';

  // // if duration changes we need to update the animation speed
  // useEffect(() => {
  //   if (animations) {
  //     const speed = speedLookup(
  //       animations?.find((keyframe: any) => !!keyframe.arbor_subType),
  //     );

  //     if (speed) {
  //       let newSpeed = speed;
  //       if (prevDuration !== 0 && duration !== 0) {
  //         newSpeed = `${(parseFloat(speed) / prevDuration) * duration}%`;
  //       }
  //       console.log('newSpeed', newSpeed);
  //       setAnimationDebounce(elementAnimation, newSpeed);
  //       setPrevDuration(duration);
  //     }
  //   }
  // }, [duration]);

  const speedLookup = (keyframe: any) => {
    if (!keyframe || !keyframe.arbor_subType) return null;
    switch (keyframe.arbor_subType) {
      case 'zoomOut':
        return keyframe.start_scale || '126%';
      case 'zoomIn':
        return keyframe.end_scale || '126%';
      case 'panLeft':
      case 'panRight':
      case 'panUp':
      case 'panDown':
        return keyframe.end_scale || '126%';
      case 'panLeftWithZoom':
      case 'panRightWithZoom':
      case 'panUpWithZoom':
      case 'panDownWithZoom':
        return keyframe.end_scale || '126%';
      default:
        return null;
    }
  };

  const elementAnimationSpeed = speedLookup(
    animations?.find((keyframe: any) => !!keyframe.arbor_subType),
  );

  const animationTypeLookup = (subType: string) => {
    switch (subType) {
      case 'zoomIn':
      case 'zoomOut':
        return 'scale';
      case 'none':
        return 'none';
      default:
        return 'pan';
    }
  };

  const setAnimation = async (subType: any = null, speed: any = null) => {
    console.log('setAnimation', subType, speed);
    // Remove existing animation from list
    const newAnimations =
      animations?.filter((keyframe: any) => keyframe.time) ?? [];

    const type = animationTypeLookup(subType);

    if (type !== 'none') {
      const animation: any = { type };

      if (!!subType) {
        animation.arbor_subType = subType;
        animation.scope = 'element';
        animation.easing = 'linear';
        switch (subType) {
          case 'zoomIn':
            animation.start_scale = '100%';
            animation.end_scale = speed || '126%';
            animation.fade = false;
            break;
          case 'zoomOut':
            animation.start_scale = speed || '126%';
            animation.end_scale = '100%';
            animation.fade = false;
            break;
          case 'panLeft':
            animation.start_x = '100%';
            animation.start_y = '50%';
            animation.end_x = '0%';
            animation.end_y = '50%';
            animation.fade = false;
            animation.start_scale = speed || '126%';
            animation.end_scale = speed || '126%';
            break;
          case 'panRight':
            animation.start_x = '0%';
            animation.start_y = '50%';
            animation.end_x = '100%';
            animation.end_y = '50%';
            animation.fade = false;
            animation.start_scale = speed || '126%';
            animation.end_scale = speed || '126%';
            break;
          case 'panUp':
            animation.start_x = '50%';
            animation.start_y = '100%';
            animation.end_x = '50%';
            animation.end_y = '0%';
            animation.fade = false;
            animation.start_scale = speed || '126%';
            animation.end_scale = speed || '126%';
            break;
          case 'panDown':
            animation.start_x = '50%';
            animation.start_y = '0%';
            animation.end_x = '50%';
            animation.end_y = '100%';
            animation.fade = false;
            animation.start_scale = speed || '126%';
            animation.end_scale = speed || '126%';
            break;
          case 'panLeftWithZoom':
            animation.start_x = '100%';
            animation.start_y = '50%';
            animation.end_x = '0%';
            animation.end_y = '50%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '126%';
            animation.fade = false;
            break;
          case 'panRightWithZoom':
            animation.start_x = '0%';
            animation.start_y = '50%';
            animation.end_x = '100%';
            animation.end_y = '50%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '126%';
            animation.fade = false;
            break;
          case 'panUpWithZoom':
            animation.start_x = '50%';
            animation.start_y = '100%';
            animation.end_x = '50%';
            animation.end_y = '0%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '126%';
            animation.fade = false;
            break;
          case 'panDownWithZoom':
            animation.start_x = '50%';
            animation.start_y = '0%';
            animation.end_x = '50%';
            animation.end_y = '100%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '126%';
            animation.fade = false;
            break;
          default:
            break;
        }
      }

      newAnimations.push(animation);
    }

    await videoCreator.applyVideoStateModifications(
      {
        [`${props.activeElement.source.id}.animations`]: newAnimations,
      },
      true,
      'changing animation',
    );
  };

  const setAnimationDebounce = lodash.debounce(setAnimation, 250);

  const animationEntries = videoCreator.isImageElementComposition(
    props.activeElement,
  )
    ? BlackFrameSettings
    : ElementAnimationTypes;

  return (
    <Fragment>
      <PropertyCaption>Element Animation</PropertyCaption>
      <PropertyDropdown
        value={elementAnimation}
        onChange={async (value) => {
          await setAnimation(value, elementAnimationSpeed);
        }}
        defaultValue={elementAnimation}
        values={[
          { caption: 'None', value: 'none' },
          ...Object.entries(animationEntries).map(([type, caption]) => ({
            caption,
            value: type,
          })),
        ]}
      />

      {elementAnimation === 'none' ? null : (
        <>
          <PropertyCaption>Element Animation Speed</PropertyCaption>
          <SliderWrapper>
            <SliderSelect
              getValue={(newValue) =>
                setAnimationDebounce(elementAnimation, newValue + '%')
              }
              defaultValue={elementAnimationSpeed}
              currValue={parseFloat(elementAnimationSpeed)}
              min={101}
              max={140}
              ticks={[
                { value: 110, label: 'Very Slow' },
                { value: 120, label: 'Slow' },
                { value: 126, label: 'Normal' },
                { value: 140, label: 'Fast' },
              ]}
            />
          </SliderWrapper>
        </>
      )}
    </Fragment>
  );
};

const ElementAnimationTypes = {
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  panLeft: 'Pan Left',
  panRight: 'Pan Right',
  panUp: 'Pan Up',
  panDown: 'Pan Down',
  panLeftWithZoom: 'Pan Left With Zoom',
  panRightWithZoom: 'Pan Right With Zoom',
  panUpWithZoom: 'Pan Up With Zoom',
  panDownWithZoom: 'Pan Down With Zoom',
};

const BlackFrameSettings = {
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  panUp: 'Pan Up',
  panDown: 'Pan Down',
  panUpWithZoom: 'Pan Up With Zoom',
  panDownWithZoom: 'Pan Down With Zoom',
};

interface AnimationSpeedProps {
  [index: string]: string;
}

const SliderWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 12px;
`;
