type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const HighlightIcon = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#03041A',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M15.1722 15.1553H1.55713"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99514 1.21844L3.27295 7.18346L9.61524 12.0949L13.9546 5.06799C14.2829 4.53629 14.1542 3.84221 13.6569 3.4637L10.6168 1.10035C10.1257 0.718645 9.42568 0.76962 8.99514 1.21844Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61058 12.0889L3.27476 7.18115L3.79018 10.0166C3.85181 10.3556 3.76652 10.7049 3.55555 10.9773L2.61328 12.1941L4.92394 13.9839L5.86644 12.7669C6.07742 12.4944 6.39423 12.3244 6.7379 12.2992L9.61058 12.0889Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67359 13.7933L3.60975 15.1441L0.827148 15.1482L2.94742 12.4565L4.67359 13.7933Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HighlightIcon;
