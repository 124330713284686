type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const RemoveIcon = (props: Props) => {
  const {
    width = '14',
    height = '13',
    viewBox = '0 0 14 13',
    strokeColor = '#F3E9D7',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M4.39111 9.1087L9.6085 3.89131"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6085 9.1087L4.39111 3.89131"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.5C1 8.0913 1.63214 9.61742 2.75736 10.7426C3.88258 11.8679 5.4087 12.5 7 12.5C8.5913 12.5 10.1174 11.8679 11.2426 10.7426C12.3679 9.61742 13 8.0913 13 6.5C13 4.9087 12.3679 3.38258 11.2426 2.25736C10.1174 1.13214 8.5913 0.5 7 0.5C5.4087 0.5 3.88258 1.13214 2.75736 2.25736C1.63214 3.38258 1 4.9087 1 6.5Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemoveIcon;
