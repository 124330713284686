export default function getScrollbarWidth(): number {
  // Create a temporary div element
  const div = document.createElement('div');
  div.style.overflow = 'scroll'; // Enable scrolling
  div.style.visibility = 'hidden'; // Hide the element
  div.style.width = '100px'; // Set a width
  div.style.height = '100px'; // Set a height

  // Append the div to the body
  document.body.appendChild(div);

  // Get the width of the scrollbar
  const scrollbarWidth = div.offsetWidth - div.clientWidth;

  // Remove the temporary div from the document
  document.body.removeChild(div);

  return scrollbarWidth;
}
