import { makeAutoObservable, runInAction } from 'mobx';
import { ElementState } from '../renderer/ElementState';
import { videoCreator } from './VideoCreatorStore';
import FadeProducer from '../fadeEffectProcessor/FadeProducer';

class TimelineStore {
  overrideTimelineElementsState: Record<string, Partial<ElementState>> = {};

  constructor() {
    makeAutoObservable(this);
  }

  resetElementsStateOverrides() {
    this.overrideTimelineElementsState = {};
  }

  applyPlacementOffsetToActiveElements(offset: number) {
    const activeElementIds = videoCreator.activeElementIds;
    const state = videoCreator.state;
    if (!state || !activeElementIds.length) {
      return;
    }
    this.applyPlacementOffsetToElements(
      state.elements.filter((element) =>
        activeElementIds.includes(element.source.id),
      ),
      offset,
    );
  }

  ifElementsOverlapsWith(
    elements: ElementState[],
    newPlacement: ElementState,
    otherElements: ElementState[],
  ) {
    return otherElements.some((elOther) => {
      if (elements.find((el) => el.source.id === elOther.source.id)) {
        return false;
      }
      const elOtherEnd = elOther.globalTime + elOther.duration;
      const newPlacementEnd = newPlacement.globalTime + newPlacement.duration;
      return (
        (elOther.globalTime <= newPlacement.globalTime &&
          elOtherEnd >= newPlacement.globalTime) ||
        (elOther.globalTime <= newPlacementEnd &&
          elOtherEnd >= newPlacementEnd) ||
        (elOther.globalTime >= newPlacement.globalTime &&
          elOtherEnd <= newPlacementEnd)
      );
    });
  }

  applyPlacementOverrideToElement(
    elementId: string,
    placement: Partial<
      Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>
    >,
  ) {
    runInAction(() => {
      this.overrideTimelineElementsState[elementId] = placement;
    });
  }

  async applyPlacements() {
    const allElements = videoCreator.state?.elements || [];
    const overridenElementIds = Object.keys(this.overrideTimelineElementsState);
    const selectedElements = allElements.filter((el) =>
      overridenElementIds.includes(el.source.id),
    );
    if (overridenElementIds.length === 0 || selectedElements.length === 0)
      return;

    let mainTrackElementsSelected = [];

    if (overridenElementIds.length > 1) {
      mainTrackElementsSelected = selectedElements.filter((el) =>
        videoCreator.isOriginalVideoElement(el.source),
      );
    } else {
      mainTrackElementsSelected = [
        selectedElements.find(
          (el) =>
            !videoCreator.isImageElement(el) &&
            !(
              el.source.type === 'video' &&
              !videoCreator.isOriginalVideoElement(el.source)
            ),
        ),
      ].filter(Boolean) as ElementState[];
    }

    const firstElement = mainTrackElementsSelected[0] ?? selectedElements[0];
    const newPlacement = {
      ...firstElement,
      ...this.overrideTimelineElementsState[firstElement.source.id],
    };

    // only for moving elements
    // TODO handle move and trim cases separately
    if (
      mainTrackElementsSelected.length > 0 &&
      firstElement.duration === newPlacement.duration
    ) {
      const lastElement =
        mainTrackElementsSelected[mainTrackElementsSelected.length - 1];
      if (mainTrackElementsSelected.length > 1) {
        newPlacement.duration =
          lastElement.globalTime +
          lastElement.duration -
          firstElement.globalTime;
      }
      const overlapsWith = this.ifElementsOverlapsWith(
        mainTrackElementsSelected,
        newPlacement,
        allElements.filter((el) => el.track === firstElement.track),
      );

      if (overlapsWith || mainTrackElementsSelected.length > 1) {
        const nextElement = allElements.find(
          (el) =>
            el.track === lastElement.track &&
            el.globalTime > lastElement.globalTime,
        );
        //@ts-ignore
        const prevElement = allElements.findLast(
          (el: ElementState) =>
            el.track === firstElement.track &&
            el.globalTime < firstElement.globalTime,
        );

        if (
          (prevElement?.globalTime || 0) + (prevElement?.duration || 0) >
          newPlacement.globalTime
        ) {
          newPlacement.globalTime = videoCreator.snapTime(
            (prevElement?.globalTime || 0) + (prevElement?.duration || 0),
          );
        } else if (
          nextElement &&
          nextElement.globalTime <
            newPlacement.globalTime + newPlacement.duration
        ) {
          newPlacement.globalTime = videoCreator.snapTime(
            Math.max(nextElement.globalTime - newPlacement.duration, 0),
          );
        }
      }

      this.applyPlacementOffsetToActiveElements(
        newPlacement.globalTime - firstElement.globalTime,
      );
    }

    const leftmostElementTime = selectedElements.reduce((acc, el) => {
      const elTime =
        this.overrideTimelineElementsState[el.source.id].globalTime ??
        el.globalTime;
      return elTime < acc ? elTime : acc;
    }, Infinity);
    if (leftmostElementTime < 0) {
      newPlacement.globalTime -= leftmostElementTime;
      this.applyPlacementOffsetToElements(
        selectedElements,
        newPlacement.globalTime - firstElement.globalTime,
      );
    }

    if (overridenElementIds.length > 1) {
      await videoCreator.moveElements(
        overridenElementIds,
        newPlacement.globalTime - firstElement.globalTime,
      );
      const fadeProducer = new FadeProducer();
      await fadeProducer.tidyOriginalVideoOverlay();
    } else {
      await videoCreator.applyPlacement(firstElement, newPlacement);
    }

    this.resetElementsStateOverrides();
  }

  // offset - number of seconds to shift the elements by
  applyPlacementOffsetToElements(elements: ElementState[], offset: number) {
    runInAction(() => {
      elements.forEach((element) => {
        this.overrideTimelineElementsState[element.source.id] = {
          globalTime: element.globalTime + offset,
        };
      });
    });
  }
}

export const timelineStore = new TimelineStore();
