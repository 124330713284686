import styled from 'styled-components';

type Props = {
  src: string;
  alt: string;
};

const ImageComponent = (props: Props) => {
  const {
    src,
    alt,
  } = props;

  return (
    <Image
      className="image"
      src={src!}
      alt={alt!}
    />
  );
};

export default ImageComponent;

const Image = styled.img``;

