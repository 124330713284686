import React from 'react'

const GraphIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_8760_809)">
                <path
                    d="M0.571289 0.571289V15.4284H15.4284"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
                <path
                    d="M4 7.42822L6.85714 10.2854L11.4286 3.42822L15.4286 6.28537"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8760_809">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default GraphIcon