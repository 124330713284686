type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const TiktokIcon = (props: Props) => {
  const {
    width = '20',
    height = '20',
    viewBox = '0 0 16 18',
    fillColor = '#03041A',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M11.3605 1.14669L10.965 0.5H8.57123V6.32447L8.56308 12.0137C8.56716 12.0559 8.57123 12.1024 8.57123 12.1447C8.57123 13.5691 7.45389 14.7315 6.07555 14.7315C4.69722 14.7315 3.57987 13.5733 3.57987 12.1447C3.57987 10.7203 4.69722 9.55793 6.07555 9.55793C6.361 9.55793 6.6383 9.61288 6.89521 9.70587V6.86549C6.63015 6.819 6.35693 6.79363 6.07555 6.79363C3.23325 6.79786 0.916992 9.19866 0.916992 12.1489C0.916992 15.0992 3.23325 17.5 6.07963 17.5C8.92601 17.5 11.2423 15.0992 11.2423 12.1489V5.3819C12.274 6.45127 13.6075 7.49528 15.0837 7.82919V4.92541C13.481 4.18995 11.8866 2.0174 11.3605 1.14669Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default TiktokIcon;
