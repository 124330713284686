type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const CreateIcon = (props: Props) => {
  const {
    width = '18',
    height = '18',
    viewBox = '0 0 18 18',
    strokeColor = 'currentColor',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M6.55779 4.66455H2.24038C1.91322 4.66455 1.59947 4.79452 1.36813 5.02584C1.1368 5.25718 1.00684 5.57095 1.00684 5.8981V15.7665C1.00684 16.0936 1.1368 16.4073 1.36813 16.6388C1.59947 16.87 1.91322 17.0001 2.24038 17.0001H13.3423C14.0235 17.0001 14.5758 16.4477 14.5758 15.7665V10.8323"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00684 7.74829H9.02487"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.09082 13.916L5.94114 12.0656L4.09082 10.2153"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7915 13.916H9.64182"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.48824 5.23593C9.05535 5.16062 9.05535 4.53919 9.48824 4.46388C11.0565 4.19105 12.3039 2.99652 12.6443 1.44151L12.6704 1.32232C12.764 0.894481 13.3732 0.891819 13.4706 1.31882L13.5023 1.45773C13.8552 3.0054 15.103 4.18984 16.6668 4.46191C17.1019 4.53761 17.1019 5.1622 16.6668 5.23789C15.103 5.50998 13.8552 6.69441 13.5023 8.24208L13.4706 8.38099C13.3732 8.80799 12.764 8.80533 12.6704 8.3775L12.6443 8.2583C12.3039 6.70328 11.0565 5.50877 9.48824 5.23593Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreateIcon;
