type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const CopyIcon = (props: Props) => {
  const {
    width = '22',
    height = '20',
    viewBox = '0 0 22 20',
    strokeColor = 'currentColor',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <rect
        x="7.57153"
        y="2"
        width="11.4286"
        height="11.4286"
        rx="2"
        stroke={strokeColor}
      />
      <path
        d="M6.11678 6.57141H4.9999C3.89533 6.57141 2.9999 7.46684 2.9999 8.57141V16C2.9999 17.1045 3.89533 18 4.9999 18H12.4285C13.533 18 14.4285 17.1046 14.4285 16V14.8831"
        stroke={strokeColor}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CopyIcon;
