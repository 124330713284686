import styled, { css } from 'styled-components';

type Props = {
  selectedDuration: number | null;
  setSelectedDuration: (duration: number | null) => void;
};

const AVAILABLE_DURATION_OPTIONS = [15, 30, 60, 90, 120];

const ClipDurationButtonList = (props: Props) => {
  const { selectedDuration, setSelectedDuration } = props;
  const onFilterButtonClick = (option: number) => {
    if (option === selectedDuration) {
      setSelectedDuration(null);
    } else {
      setSelectedDuration(option);
    }
  };
  return (
    <DurationFilterButtons>
      {AVAILABLE_DURATION_OPTIONS.map((option) => (
        <FilterButton
          active={selectedDuration === option}
          onClick={() => onFilterButtonClick(option)}
        >
          {option}
        </FilterButton>
      ))}
    </DurationFilterButtons>
  );
};

export default ClipDurationButtonList;

const FilterButton = styled.div.attrs((props: { active: boolean }) => props)`
  font-size: 10px;
  font-weight: 600;
  display: flex;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #484848;
  color: #484848;
  cursor: pointer;
  align-items: center;
  width: 34px;
  box-sizing: border-box;
  justify-content: center;

  ${(props) =>
    props.active
      ? css`
          color: #030419;
          border-color: #f2d093;
          background-color: #f2d093;
        `
      : css`
          &:hover {
            color: #f2d093;
          }
        `}
`;

const DurationFilterButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  width: fit-content;
  justify-content: center;
`;
