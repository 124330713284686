import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import CloudIcon from '../../svgs/CloudIcon';
// import RemoveIcon from '../../svgs/RemoveIcon';

// Interface to extend Blob type
interface ExtendedFile extends File {
  id: string;
  preview: string;
}

type Props = {
  width?: number;
  height?: number;
  callback: (file: File[]) => void;
  acceptType?: 'all' | 'image' | 'video';
  multiple?: boolean;
  description?: React.ReactNode;
  iconJSX?: React.ReactNode;
};

const DragAndDrop = ({
  width = 350,
  height = 137,
  callback,
  acceptType = 'all',
  multiple = true,
  description = 'Drag and drop your files here',
  iconJSX = (
    <Icon>
      <CloudIcon />
    </Icon>
  ),
}: Props) => {
  const isMobile = window.innerWidth <= 615;
  const [files, _setFiles] = useState<ExtendedFile[]>([]);

  const formats: string[] = {
    all: ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'video/mp4'],
    image: ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'],
    video: ['video/mp4'],
  }[acceptType];

  const { getRootProps, getInputProps }: any = useDropzone({
    accept: {
      all: {
        'image/png': ['.png'],
        'image/gif': ['.gif'],
        'image/jpg': ['.jpg', '.jpeg'],
        'video/mp4': ['.mp4'],
      },
      image: {
        'image/png': ['.png'],
        'image/gif': ['.gif'],
        'image/jpg': ['.jpg', '.jpeg'],
      },
      video: {
        'video/mp4': ['.mp4'],
      },
    }[acceptType],
    multiple,
    onDragEnter: (event: React.DragEvent<HTMLElement>) => {
      console.log('e', event);
    },
    onError: (err: Error) => {
      console.log('error', err);
    },
    onDropRejected: (fileRejections: any, event: any) => {
      console.log('fileRejected', fileRejections, event);
    },
    onDrop: useCallback<(_: File[]) => void>((acceptedFiles) => {
      callback(acceptedFiles);
    }, []),
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  //const RenderFile = (f: ExtendedFile, idx: number) => {
  //  const removeFile = () => {
  //    const filteredFiles = files.filter((file) => file.preview !== f.preview);
  //    const newFiles = filteredFiles.map((f) =>
  //      Object.assign(f, {
  //        preview: URL.createObjectURL(f),
  //      }),
  //    );
  //    setFiles(newFiles);
  //  };
  //  return (
  //    <Container filesCount={files.length} idx={idx}>
  //      <Actions className="actions">
  //        <Button type="button" onClick={removeFile}>
  //          <span>
  //            <RemoveIcon /> Remove
  //          </span>
  //        </Button>
  //      </Actions>
  //      {f.type !== 'video/mp4' ? (
  //        <Image
  //          src={f.preview}
  //          width={100}
  //          height={100}
  //          alt="Image"
  //          idx={idx}
  //          filesCount={files.length}
  //          onLoad={() => {
  //            URL.revokeObjectURL(f.preview);
  //          }}
  //        />
  //      ) : (
  //        <Video idx={idx} src={f.preview} filesCount={files.length} />
  //      )}
  //    </Container>
  //  );
  //};

  return (
    <DragZone className="container" width={width} height={height}>
      {isMobile ? (
        <File
          type="file"
          title=""
          multiple
          accept={formats.join(',')}
          onChange={(e) => {
            const fileList = e.target.files;
            if (fileList) {
              const filesArray = Array.from(fileList) as File[];
              callback(filesArray);
            }
          }}
        />
      ) : (
        <DragContent {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {iconJSX}
          <Text>{description}</Text>
        </DragContent>
      )}

      {isMobile && (
        <DragContent>
          {iconJSX}
          <Text>{description}</Text>
        </DragContent>
      )}
    </DragZone>
  );
};

export default DragAndDrop;

const DragZone = styled.section<{ width: number; height: number }>`
  display: flex;
  position: relative;
  background-color: #484848;
  border-radius: 8px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  @media only screen and (max-width: 615px) {
    width: 343px;
    height: 206px;
  }
`;

const File = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Icon = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
`;

const DragContent = styled.div`
  weight: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  color: #f3e9d7;
`;
const Text = styled.span`
  font-weight: 400;
  color: #f3e9d7;
  font-size: 12px;
  font-family: Inter;
  line-height: 16.8px;
  text-align: center;
`;

//const Container = styled.div<{ filesCount: number; idx: number }>`
//  display: flex;
//  min-width: 0;
//  overflow: hidden;
//  width: calc(100% / ${(props) => Math.min(props.filesCount, 3)});
//  height: ${(props) => (props.filesCount > 3 ? 'calc(50%)' : '100%')};
//  position: relative;
//
//  ${({ filesCount, idx }) =>
//    filesCount > 2 &&
//    css`
//      border-top-left-radius: ${filesCount > 2 && idx === 0 && '0.5rem'};
//      border-top-right-radius: ${filesCount > 2 && idx === 2 && '0.5rem'};
//      border-bottom-left-radius: ${filesCount > 2 && idx === 3 && '0.5rem'};
//      border-bottom-right-radius: ${filesCount > 2 && idx === 5 && '0.5rem'};
//    `}
//  ${({ filesCount, idx }) =>
//    filesCount === 2 &&
//    css`
//      border-top-left-radius: ${idx === 0 && '0.5rem'};
//      border-bottom-left-radius: ${idx === 0 && '0.5rem'};
//      border-top-right-radius: ${idx === 1 && '0.5rem'};
//      border-bottom-right-radius: ${idx === 1 && '0.5rem'};
//    `}
//  ${({ filesCount, idx }) =>
//    filesCount === 1 &&
//    css`
//      border-radius: 0.5rem;
//    `}
//
//  &:hover > .actions {
//    opacity: 1;
//  }
//`;

//const Actions = styled.div`
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  top: 0;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  gap: 5px;
//  opacity: 0;
//  transition: opacity 300ms;
//  z-index: 50;
//
//  &:hover {
//    background-color: rgba(209, 213, 219, 0.5);
//  }
//`;

//const Button = styled.button`
//  // width: 32px;
//  margin: auto;
//  border-radius: 8px;
//  font-size: 12px;
//  padding: 12px 16px;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  outline: 0;
//  border: 0;
//  background-color: #03041a;
//  color: #f3e9d7;
//  span {
//    display: flex;
//    align-items: center;
//    gap: 5px;
//    cursor: pointer;
//  }
//`;

//const Image = styled.img<{ idx: number; filesCount: number }>`
//  display: block;
//  ${({ filesCount, idx }) =>
//    filesCount > 2 &&
//    css`
//      border-top-left-radius: ${filesCount > 2 && idx === 0 && '0.5rem'};
//      border-top-right-radius: ${filesCount > 2 && idx === 2 && '0.5rem'};
//      border-bottom-left-radius: ${filesCount > 2 && idx === 3 && '0.5rem'};
//      border-bottom-right-radius: ${filesCount > 2 && idx === 5 && '0.5rem'};
//    `}
//  ${({ filesCount, idx }) =>
//    filesCount === 2 &&
//    css`
//      border-top-left-radius: ${idx === 0 && '0.5rem'};
//      border-bottom-left-radius: ${idx === 0 && '0.5rem'};
//      border-top-right-radius: ${idx === 1 && '0.5rem'};
//      border-bottom-right-radius: ${idx === 1 && '0.5rem'};
//    `}
//  ${({ filesCount, idx }) =>
//    filesCount === 1 &&
//    css`
//      border-radius: 0.5rem;
//    `}
//  object-fit: cover;
//  width: 100%;
//  height: 100%;
//  object-position: top;
//`;

//const Video = styled.video<{ idx: number; filesCount: number }>`
//  display: block;
//  ${({ filesCount, idx }) =>
//    filesCount > 2 &&
//    css`
//      border-top-left-radius: ${filesCount > 2 && idx === 0 && '0.5rem'};
//      border-top-right-radius: ${filesCount > 2 && idx === 2 && '0.5rem'};
//      border-bottom-left-radius: ${filesCount > 2 && idx === 3 && '0.5rem'};
//      border-bottom-right-radius: ${filesCount > 2 && idx === 5 && '0.5rem'};
//    `}
//  ${({ filesCount, idx }) =>
//    filesCount === 2 &&
//    css`
//      border-top-left-radius: ${idx === 0 && '0.5rem'};
//      border-bottom-left-radius: ${idx === 0 && '0.5rem'};
//      border-top-right-radius: ${idx === 1 && '0.5rem'};
//      border-bottom-right-radius: ${idx === 1 && '0.5rem'};
//    `}
//  ${({ filesCount, idx }) =>
//    filesCount === 1 &&
//    css`
//      border-radius: 0.5rem;
//    `}
//  object-fit: cover;
//  width: 100%;
//  height: 100%;
//  object-position: top;
//`;

//const DisplayFiles = styled.div`
//  display: flex;
//  width: 100%;
//  height: 100%;
//  flex-wrap: wrap;
//`;
//
//const MoreItems = styled.div`
//  color: #f3e9d7;
//  font-weight: 700;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  text-align: center;
//  margin: 0 auto;
//  font-size: 10px;
//`;
