import { TranscriptElement, TranscriptChange } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';

class PhotoHighlightChangeHandler extends ChangeHandler {
  apply(
    _transcriptionElementsMutable: TranscriptElement[],
    _change: TranscriptChange & { type: 'photo_highlight' },
  ) {
    // kept for legacy reasons, this change type should not be stored at transcription_json anymore
  }
}

export default PhotoHighlightChangeHandler;
