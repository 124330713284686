type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const PaperPlaneIcon = ({
  width = '10',
  height = '10',
  viewBox = '0 0 10 10',
  strokeColor = '#03041A',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28195 7.47369L5.62868 8.81424C5.71131 8.89889 5.81462 8.96054 5.92839 8.99306C6.04216 9.02559 6.16244 9.02781 6.27734 8.99956C6.39296 8.97266 6.49972 8.91651 6.58735 8.83644C6.67498 8.75636 6.74055 8.65514 6.77773 8.54242L8.98935 1.91376C9.03547 1.78956 9.045 1.65472 9.0168 1.52526C8.98854 1.3958 8.92374 1.27718 8.83 1.18349C8.73632 1.0898 8.61772 1.02498 8.48824 0.996735C8.35883 0.968493 8.22397 0.978016 8.09974 1.02417L1.47111 3.23578C1.3545 3.27561 1.25065 3.34586 1.17028 3.43926C1.08991 3.53266 1.03594 3.64583 1.01395 3.76706C0.991257 3.87733 0.99631 3.99151 1.02866 4.09934C1.06101 4.20716 1.11964 4.30527 1.19929 4.38483L2.89197 6.07752L2.83637 8.22119L4.28195 7.47369Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.79176 1.1601L2.89209 6.07753"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaperPlaneIcon;
