import {
  TranscriptElement,
  TranscriptChange,
  TranscriptTextElement,
} from '../../types.ts/transcript';
import { getClosestTextIndexToLeft } from '../utils';
import ChangeHandler from './ChangeHandler';

class UntrimStartChangeHandler extends ChangeHandler {
  apply(
    transcriptElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'untrim_start' },
    originalElements: TranscriptElement[],
  ) {
    const { fromTs, toTs, newTs: intoTs } = change;
    let addedDuration = toTs - fromTs;

    let { firstIndexToUntrim, lastIndexToUntrim, firstTextElementIndex } =
      this.getRangesToUntrimStart(
        transcriptElementsMutable,
        fromTs,
        toTs,
        intoTs,
        originalElements,
      );

    const lastElementToUntrim = originalElements[
      lastIndexToUntrim
    ] as TranscriptTextElement;

    // debugger;
    if (firstTextElementIndex > 0) {
      const firstTextElement = transcriptElementsMutable[
        firstTextElementIndex
      ] as TranscriptTextElement;
      if (
        lastIndexToUntrim > 0 &&
        lastIndexToUntrim === firstTextElement.initial_index
      ) {
        const newTs =
          firstTextElement.end_ts! -
          (lastElementToUntrim.end_ts - lastElementToUntrim.ts);
        const newBufferBefore = lastElementToUntrim.buffer_before_ts || 0;
        addedDuration -=
          firstTextElement.ts -
          newTs +
          newBufferBefore -
          (firstTextElement.buffer_before_ts || 0);
        firstTextElement.ts = newTs;
        firstTextElement.buffer_before_ts =
          lastElementToUntrim.buffer_before_ts || 0;

        firstTextElementIndex--;
        lastIndexToUntrim--;
        // last element overlaps
        // expand first element in existing track
      } else if (lastIndexToUntrim > 0) {
        addedDuration +=
          lastElementToUntrim.end_ts -
          toTs +
          (lastElementToUntrim.buffer_after_ts || 0);
        firstTextElementIndex = getClosestTextIndexToLeft(
          firstTextElementIndex - 1,
          transcriptElementsMutable,
        );
        // expand last element of added part
      } else {
        // edge case, last word in the track
      }
    }
    // debugger;
    const elementsCount = lastIndexToUntrim - firstIndexToUntrim;

    for (let i = elementsCount; i >= 0; i--) {
      const originalElement = originalElements[firstIndexToUntrim + i];
      const finalElement =
        transcriptElementsMutable[firstTextElementIndex - elementsCount + i];
      if (finalElement.initial_index === firstIndexToUntrim + i) {
        delete finalElement.state;
        if (finalElement.type === 'text' && originalElement.type === 'text') {
          // todo handle partial restore of the first word (addedDuration becomes < 0)
          finalElement.buffer_after_ts = originalElement.buffer_after_ts || 0;
          finalElement.end_ts =
            intoTs + addedDuration - finalElement.buffer_after_ts;
          finalElement.ts =
            finalElement.end_ts - (originalElement.end_ts - originalElement.ts);
          finalElement.buffer_before_ts = originalElement.buffer_before_ts || 0;
          if (i === 0) {
            // first element
            if (finalElement.ts - finalElement.buffer_before_ts < intoTs) {
              const newTs = Math.max(finalElement.ts, intoTs);
              finalElement.buffer_before_ts = newTs - intoTs;
              finalElement.trim_start = newTs - finalElement.ts;
              finalElement.ts = newTs;
            }
          }
          addedDuration -=
            finalElement.end_ts -
            finalElement.ts +
            finalElement.buffer_after_ts +
            finalElement.buffer_before_ts;
        }
      } else {
        // debugger;
        const addedSegment = originalElements
          .slice(firstIndexToUntrim, firstIndexToUntrim + i)
          .map((el) => {
            if (el.type === 'punct') {
              return { ...el, state: 'added' } as TranscriptElement;
            } else {
              const end_ts = intoTs + el.end_ts! - fromTs; // does el.end_ts - fromTs === addedDuration - buffer_after?
              const ts = end_ts - (el.end_ts - el.ts);
              return { ...el, state: 'added', end_ts, ts } as TranscriptElement;
            }
          });
        // debugger;
        transcriptElementsMutable.splice(
          firstTextElementIndex - elementsCount,
          0,
          ...addedSegment,
        );
        break;
      }
    }
    return { fromIndex: firstTextElementIndex, toIndex: firstTextElementIndex };
  }

  getRangesToUntrimStart(
    transcriptElements: TranscriptElement[],
    fromTs: number,
    toTs: number,
    intoTs: number,
    originalElements: TranscriptElement[],
  ) {
    const addedDuration = toTs - fromTs;

    let firstTextElementIndex = transcriptElements.findIndex(
      (el) =>
        el.type === 'text' &&
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.end_ts > intoTs + addedDuration,
    );
    // debugger;
    const firstIndexToUntrim = originalElements.findIndex(
      (el) =>
        el.type === 'text' &&
        el.end_ts! > fromTs &&
        el.end_ts! - fromTs > fromTs - el.ts!,
    );

    let lastIndexToUntrim = originalElements.findIndex(
      (el) => el.type === 'text' && el.end_ts! > toTs,
    );
    return { firstIndexToUntrim, lastIndexToUntrim, firstTextElementIndex };
  }
}

export default UntrimStartChangeHandler;
