import React from 'react';

const InfoIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M9 6.83333V10.1667M17 9.5C17 10.5506 16.7931 11.5909 16.391 12.5615C15.989 13.5321 15.3997 14.414 14.6569 15.1569C13.914 15.8997 13.0321 16.489 12.0615 16.891C11.0909 17.2931 10.0506 17.5 9 17.5C7.94943 17.5 6.90914 17.2931 5.93853 16.891C4.96793 16.489 4.08601 15.8997 3.34315 15.1569C2.60028 14.414 2.011 13.5321 1.60896 12.5615C1.20693 11.5909 1 10.5506 1 9.5C1 7.37827 1.84285 5.34344 3.34315 3.84315C4.84344 2.34285 6.87827 1.5 9 1.5C11.1217 1.5 13.1566 2.34285 14.6569 3.84315C16.1571 5.34344 17 7.37827 17 9.5ZM9 12.8333H9.00711V12.8404H9V12.8333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoIcon;
