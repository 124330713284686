import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useOutsideAlerter } from './useClickOutside';
import { TranscriptContextMenuActions } from '../../types.ts/general';
import ReplaceIcon from '../../svgs/ReplaceIcon';
import CameraIcon from '../../svgs/CameraIcon';
import RemoveIcon from '../../svgs/RemoveIcon';
import MuteKaraokeIcon from '../../svgs/MuteKaraokeIcon';
import CutTextIcon from '../../svgs/CutTextIcon';
import PasteTextIcon from '../../svgs/PasteTextIcon';
import RefreshIcon from '../../svgs/PasteTextIcon';
import { getDocumentHeight } from '../../utility/general';
import CreateIcon from '../../svgs/CreateIcon';

const CONTENT_PADDING = 40;
const MAX_CONTEXT_HEIGHT = 200;

export const TranscriptContextMenu: React.FC<{
  positionXY: { x: number; y: number };
  actions: Array<TranscriptContextMenuActions>;
  actionCallback: (action: TranscriptContextMenuActions | 'close') => void;
  clientHeight: number;
  overridenActionTitles?: Partial<Record<TranscriptContextMenuActions, string>>;
}> = ({
  positionXY,
  actions,
  actionCallback,
  clientHeight,
  overridenActionTitles,
}) => {
  const menuConfig = [
    {
      action: 'replace',
      title: 'Replace',
      icon: (
        <ReplaceIcon width={'16'} height={'14'} strokeColor="currentColor" />
      ),
      extraClass: '',
      disabled: false,
    },
    {
      action: 'saveAsClip',
      title: 'Save as Clip',
      icon: (
        <CreateIcon width={'16'} height={'14'} strokeColor="currentColor" />
      ),
      extraClass: '',
      disabled: false,
    },
    {
      action: 'hideKaraoke',
      title: 'Hide Karaoke',
      icon: <MuteKaraokeIcon strokeColor="currentColor" />,
      extraClass: '',
      disabled: false,
    },
    {
      action: 'showKaraoke',
      title: 'Show Karaoke',
      icon: <MuteKaraokeIcon strokeColor="currentColor" />,
      extraClass: '',
      disabled: false,
    },
    {
      action: 'removeText',
      title: 'Remove Text',
      icon: (
        <RemoveIcon width={'16'} height={'16'} strokeColor="currentColor" />
      ),
      extraClass: 'remove-text',
      disabled: false,
    },
    {
      action: 'removeTextLoading',
      title: 'Remove Text',
      icon: (
        <RemoveIcon width={'16'} height={'16'} strokeColor="currentColor" />
      ),
      extraClass: 'remove-text',
      disabled: true,
    },
    {
      action: 'removePhoto',
      title: 'Remove Photo',
      icon: <RemoveIcon strokeColor="currentColor" />,
      extraClass: 'remove-photo',
      disabled: false,
    },
    {
      action: 'removePhotoLoading',
      title: 'Remove Photo',
      icon: <RemoveIcon strokeColor="currentColor" />,
      extraClass: 'remove-photo',
      disabled: true,
    },
    {
      action: 'addPhoto',
      title: 'Add Photo',
      icon: <CameraIcon />,
      extraClass: 'add-photo',
      disabled: false,
    },
    {
      action: 'addPhotoLoading',
      title: 'Add Photo',
      icon: <CameraIcon />,
      extraClass: 'add-photo',
      disabled: true,
    },
    {
      action: 'restore',
      title: 'Restore',
      icon: (
        <ReplaceIcon width={'16'} height={'14'} strokeColor="currentColor" />
      ),
      extraClass: '',
      disabled: false,
    },
    {
      action: 'cutText',
      title: 'Cut Text',
      icon: (
        <CutTextIcon width={'16'} height={'15'} strokeColor="currentColor" />
      ),
      extraClass: '',
      disabled: false,
    },
    {
      action: 'pasteTextBefore',
      title: 'Paste Text',
      icon: (
        <PasteTextIcon width={'16'} height={'14'} strokeColor="currentColor" />
      ),
      extraClass: '',
      disabled: false,
    },
    {
      action: 'pasteTextAfter',
      title: 'Paste After',
      icon: (
        <PasteTextIcon width={'16'} height={'14'} strokeColor="currentColor" />
      ),
      extraClass: '',
      disabled: false,
    },
    {
      action: 'editTiming',
      title: 'Edit Transcript Timing',
      icon: (
        <RefreshIcon width={'16'} height={'14'} strokeColor="currentColor" />
      ),
      extraClass: '',
      disabled: false,
    },
  ];

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(wrapperRef, () => actionCallback('close'));

  function getMenuItem(action: TranscriptContextMenuActions) {
    const menuItemConfig = menuConfig.find((item) => item.action === action);

    if (!menuItemConfig) return null;

    return (
      <MenuItem
        disabled={menuItemConfig.disabled}
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => actionCallback(action)}
        className={menuItemConfig.extraClass}
      >
        <span>{overridenActionTitles?.[action] || menuItemConfig.title}</span>
        <div className="icon">{menuItemConfig.icon}</div>
      </MenuItem>
    );
  }

  const contentHeight = clientHeight + CONTENT_PADDING;
  return (
    <MenuWrapper
      positionXY={positionXY}
      contentHeight={contentHeight}
      ref={wrapperRef}
      onMouseUp={(e) => e.stopPropagation()}
    >
      {actions.map((action) => (
        <div key={action}>{getMenuItem(action)}</div>
      ))}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div.attrs(
  (props: { positionXY: { x: number; y: number }; contentHeight: number }) =>
    props,
)`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100001;

  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;

  left: ${(props) => props.positionXY.x}px;

  ${(props) =>
    (props.positionXY.y + MAX_CONTEXT_HEIGHT <= props.contentHeight ||
      props.positionXY.y <= 350) &&
    css`
      top: ${props.positionXY.y}px;
    `}

  ${(props) =>
    props.positionXY.y + MAX_CONTEXT_HEIGHT > props.contentHeight &&
    props.positionXY.y > 350 &&
    css`
      bottom: ${getDocumentHeight() - props.positionXY.y}px;
    `}
`;

const MenuItem = styled.div<{
  disabled?: boolean;
}>`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
  }
  .icon {
    margin-left: auto;
    display: flex;
  }

  &.add-photo:hover {
    background-color: #17c964;
  }
  &.remove-photo:hover {
    background-color: #ef5d6f;
  }

  &.remove-text:hover {
    background-color: #ef5d6f;
  }

  border-radius: 8px;

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:hover {
    background-color: #17c964;
    color: #03041a;
  }

  ${(props) => (props.disabled ? 'cursor: wait; opacity: 0.5;' : '')}
`;
